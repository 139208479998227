import React from "react";
import _get from "lodash/get";

import { useAppState } from "../../../../../context";
import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";
import JobsListingEffect from "../../../../effects/JobsListingEffect/JobsListingEffect";

const NetworkingSettings = ({ isFetching }) => {
  const { notificationSettings } = useAppState("notifications");

  return isFetching ? (
    <JobsListingEffect count={3} />
  ) : (
    <>
      <h2 className="title-primary mb-15">Networking Settings</h2>
      <NotificationSettingPanel
        title="Messages from network"
        subTitle="Receive alerts whenever someone sends you a message."
        type="network_messages"
        appPush={_get(notificationSettings, "network_message_push", false)}
        email={_get(notificationSettings, "network_message_email", false)}
        showEmailFrequency={true}
        emailFrequencyKey={"unread_messages_job_frequency"}
      />
      <NotificationSettingPanel
        title="Messages from group chats"
        subTitle="Receive alerts whenever someone sends a message in your group chats."
        type="group_messages"
        appPush={_get(notificationSettings, "group_message_push", false)}
        email={_get(notificationSettings, "group_message_email", false)}
        showEmailFrequency={true}
        emailFrequencyKey={"unread_group_messages_job_frequency"}
      />
      <NotificationSettingPanel
        title="Invites to group chats"
        subTitle="Receive alerts whenever someone adds you to a group chat."
        type="group_invites"
        appPush={_get(notificationSettings, "group_invite_push", false)}
        email={_get(notificationSettings, "group_invite_email", false)}
      />
    </>
  );
};

export default NetworkingSettings;
