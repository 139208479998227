import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .right-section {
    margin-top: 74px;
    &__options {
      display: flex;
      justify-content: flex-end;
      position: relative;
      img {
        cursor: pointer;
      }
      &__list {
        list-style: none;
        box-shadow: var(--card-box-shadow);
        border-radius: 10px;
        padding: 0 20px;
        width: 265px;
        position: absolute;
        z-index: 50;
        top: 30px;
        right: 0;
        background-color: #ffffff;
        li {
          margin: 0px;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          color: var(--primary-dark-color);
          text-align: center;
          padding: 22px 0 18px;
          cursor: pointer;
          + li {
            border-top: 2px solid #eaeef3;
          }
        }
      }
    }
    &__members {
      &__header {
        margin: 40px 0 20px 0;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
      &__creator {
        height: 80px;
        border-radius: 40px;
        background: #ffffff;
        box-shadow: var(--card-box-shadow);
        display: flex;
        align-items: center;
        padding-inline: 15px;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          margin: 0 10px 0 0;
        }
        &__title {
          &__line-one {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 4px;
            &__name {
              display: inline-block;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 18px;
              margin-top: 5px;
            }
            &__tag {
              min-width: fit-content;
              padding: 6px 10px;
              height: 25px !important;
              margin: 0 0 0 0;
              color: #ffffff;
              background: var(--link-color);
              font-family: "TTCommons-Regular";
              text-align: center;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 16px;
              border-radius: 12.5px;
            }
          }
          &__line-two {
            span {
              color: var(--gray-text-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
            }
          }
        }
      }
    }
    &__saved {
      cursor: pointer;
      margin: 15px 0 15px 0;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow);
      display: flex;
      align-items: center;
      padding-inline: 20px;
      img {
        margin-right: 10px;
      }
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin-top: 3px;
      }
      &:hover {
        box-shadow: var(--card-box-shadow-hover);
      }
    }

    .details {
      text-align: center;
      position: relative;
      padding: 20px;
      .MuiCardContent-root {
        padding: 0;
      }
      .max-width-wrap {
        .MuiAvatar-root {
          width: 90px;
          height: 90px;
          margin: -40px auto 12px;
        }
      }

      .title-primary {
        color: VAR(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        margin: 0 0 4px 0;
        padding: 4px 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .created {
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        span {
          color: var(--primary-dark-color);
          &:first-child {
            color: var(--field-label-color);
          }
        }
        .MuiAvatar-root {
          width: 25px;
          height: 25px;
          margin: -4px 0px 0 0px;
        }
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .MuiChip-root {
          border-radius: 12.5px;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          padding: 4px 8px 0;
          min-width: 69px;
          height: auto;
          span.MuiChip-label {
            padding: 0;
          }
        }
        &__gray {
          .MuiChip-root {
            background-color: var(--icon_background);
            color: var(--primary-dark-color);
          }
        }
        &__blue {
          .MuiChip-root {
            background-color: var(--link-color);
            color: #ffffff;
          }
        }
        &__red {
          .MuiChip-root {
            background: rgba(247, 100, 95, 0.09);
            color: #a80c06;
          }
        }
      }
      .decription {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        margin: 0px auto 15px auto;
        .nlink-secondary {
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          margin-left: 5px;
          line-height: 17px;
        }
      }
      .participant {
        margin: 13px 0 10px 0;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        padding: 0;
      }
      .type {
        margin: 0px 0 13px 0;
        color: var(--link-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        padding: 0;
      }
      .members {
        .member {
          display: flex;
          align-items: center;
          .MuiAvatar-root {
            width: 40px;
            height: 40px;
            margin: 0 10px 0 0;
          }
          .content {
            display: flex;
            width: calc(100% - 60px);
            .header-primary {
              text-align: left;
              &.name {
                display: flex;
              }
              span,
              a {
                line-height: 17px;
                padding-top: 2px;
                display: inline-block;
                &.elips {
                  margin-right: 6px;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block !important;
                  max-width: 100px;
                  font-size: 18px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 19px;
                  color: var(--primary-color);
                  &:focus {
                    text-decoration: underline;
                  }
                }
                &.network-participant-pointer {
                  cursor: pointer !important;
                  text-decoration: none;
                }
                &.creator {
                  color: var(--gray-text-color);
                  letter-spacing: 0;
                  font-family: "TTCommons-Regular";
                  font-weight: normal;
                  white-space: nowrap;
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 19px;
                }
              }
            }
            .tag {
              margin-left: 6px;
              div {
                color: var(--gray-text-color);
                font-size: 18px;
                font-family: "TTCommons-Regular";
              }
              .MuiChip-root {
                background: var(--light-gray-color);
                height: 20px;
                border-radius: 10.5px;
                background-color: #e5eaf7;
                color: ${PRIMARY_COLOR};
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0px;
                line-height: 15px;
                text-align: center;
              }
            }
          }
          + .member {
            margin-top: 15px;
          }
        }
      }

      button.view-more {
        margin: 30px 0 0 0;
        padding: 4px 0 2px 0;
        height: auto;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 100%;
        max-width: 164px;
        .content {
          min-width: auto;
        }
      }
      button.follow-topic {
        height: auto;
        width: 100%;
        max-width: 238px;
        padding: 20.85px 10px 14.85px 10px;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24.3px;
        text-align: center;
      }
      &.ntopics-cards-wrapper {
        max-width: 100%;
        overflow: visible;
        .card-close-btn {
          position: absolute;
          top: 0;
          right: 0;
        }
        .max-width-wrap {
          max-width: 285px;
          margin: 0 auto;
        }
      }
    }
    .no-data {
      margin-top: 60px;
    }
  }
`;
