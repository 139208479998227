import { useEffect, useState } from "react";
import { searchConversation } from "../services/chat.service";
import { useAppState } from "../context";

export function useSearchDebounce({ delay = 350, current_page = 1 } = {}) {
  const [loaded, setIsLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchedConversations, setSearchedConversations] = useState([]);
  const {chats=[]} = useAppState("chat");

  const doSearchConversation = async (query, current_page) => {
    try {
      setIsLoading(true);
      const response = await searchConversation(query, current_page);
      let totalSearchResults = {};
      if (response) {
        const messages = response.data;
        if(current_page > 1) {
          totalSearchResults = {
              ...messages,
              messages: [...searchResults.messages, ...messages?.messages],
            }
        } else {
          totalSearchResults = messages;
        }
        setSearchResults(totalSearchResults);
        if (totalSearchResults?.conversations?.length) {
          let conversationWindowIds = totalSearchResults?.conversations?.map(
            (conv) => conv.conversation_window_id
          );
          let searchedChats = chats.filter((chat) =>
            conversationWindowIds.includes(chat.conversation_window_id)
          );
          setSearchedConversations(searchedChats);
        } else {
          setSearchedConversations([])
        }

        setIsLoading(false);
        setIsLoaded(true);
      }
    } catch (error) {
      const { status } = error || {};
      if (status === 400) {
        setIsLoading(false);
        setIsLoaded(false);
      }
    }
  };
  useEffect(() => {
    const delayFn = setTimeout(() => {
      setSearchQuery(searchQuery);
      setIsLoaded(false);
      searchQuery &&
        searchQuery.trim() !== "" &&
        searchQuery?.length > 0 &&
        doSearchConversation(searchQuery, current_page);
      if (!searchQuery) {
        setSearchedConversations([]);
        setSearchResults([]);
        setIsLoading(false);
        setIsLoaded(false);
      }
    }, delay);
    return () => clearTimeout(delayFn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, delay, current_page]);

  return {
    searchQuery,
    setSearchQuery,
    isLoading,
    searchResults,
    loaded,
    setIsLoaded,
    searchedConversations,
  };
}
