import React from "react";
import EmptyListStyles from "./EmptyListStyles";
import CardCustom from "../CardCustom/CardCustom";

const EmptyList = ({ title, subTitle }) => {
  return (
    <EmptyListStyles>
      {/* <CardCustom className="empty-save-box">
        <div className="empty-item">
          {title && <h3 className="header-primary">{title}</h3>}
          {subTitle && <div className="text-grey">{subTitle}</div>}
        </div>
      </CardCustom> */}
      <div className="facelift-empty-boxwrap">
        {title && <h3>{title}</h3>}
        {subTitle && <p>{subTitle}</p>}
      </div>
    </EmptyListStyles>
  );
};

export default EmptyList;
