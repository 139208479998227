import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import moment from "moment";

import { COURSES_LIST } from "../../constant/mockdata";

const initialState = {
  users: null,
  users_local: null,
  permissions: {
    pastOrUpcomingWebinarsAvailable: false,
  },
  permissionsExpiry: null,
  studentViewPreference: "international",
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserPermissions: (state, action) => {
      const allowedCourses = COURSES_LIST.filter(
        (course) =>
          course.permission === true ||
          action.payload[course.permission] === true
      );

      state.permissions = {
        ...state.permissions,
        ...action.payload,
        // Set menu permission based on courses permission
        courses: allowedCourses && !isEmpty(allowedCourses) ? true : false,
      };
      state.permissionsExpiry = moment()
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm");
    },
    updatePersistState: (state, action) => ({ ...state, ...action.payload }),
    updateUsersLocalState: (state, action) => {
      state.users_local = { ...state.users_local, ...action.payload };
    },
    updatePastOrUpcomingWebinarsAvailable: (state, action) => {
      state.permissions = {
        ...state.permissions,
        pastOrUpcomingWebinarsAvailable: action.payload,
      };
    },
    updateUsersPersistState: (state, action) => {
      const latestUsersState = {
        ...state.users,
        ...action.payload,
      };

      state.users = latestUsersState;
      state.users_local = latestUsersState;
      state.studentViewPreference = latestUsersState.student_view_preference;
    },
    updateUserProfileImage: (state, action) => {
      const latestUsersState = {
        ...state.users,
        profile_image_url: action.payload,
        user_profile_url: action.payload,
      };

      state.users = latestUsersState;
      state.users_local = latestUsersState;
    },
  },
});

export const {
  updateUserPermissions,
  updatePersistState,
  updateUsersLocalState,
  updateUsersPersistState,
  updateUserProfileImage,
  updatePastOrUpcomingWebinarsAvailable,
} = userReducer.actions;

export default userReducer.reducer;
