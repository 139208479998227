import React from "react";
import EmptyListWithImageStyles from "./EmptyListWithImageStyles";
import EmptyImage from "../../assets/svg/error404.svg";

const EmptyListWithImage = ({
  title,
  subTitle,
  Image = EmptyImage,
  className, // to apply necessary styles to the image wrapper
  containerClassname, // for dynamic container class
}) => {
  return (
    <EmptyListWithImageStyles>
      <div className={`empty-list-with-image ${containerClassname ?? ""}`}>
        <div className="img-wrap">
          <img src={Image} alt="error" className={className} />
        </div>
        {title && <h3>{title}</h3>}
        {subTitle && <p>{subTitle}</p>}
      </div>
    </EmptyListWithImageStyles>
  );
};

export default EmptyListWithImage;
