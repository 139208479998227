import React, { memo } from "react";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import SearchMessageViewStyles from "./SearchMessageViewStyles";
import { useAppState } from "../../../../context";
import ChatUsersList from "../ChatUsersList/ChatUsersList";

const SearchMessageView = () => {
  const { isChatsLoading, isChatsLoaded, isGroupCreating } =
    useAppState("chat");

  return (
    <SearchMessageViewStyles>
      <div className="create-new-chat-container">
        {!isChatsLoaded || isChatsLoading || isGroupCreating ? (
          <div className="preview-container">
            {Array.from(Array(5).keys()).map((item, index) => (
              <div className="preview-container__item" key={index}>
                <ShimmerCategoryItem
                  hasImage
                  imageType="circular"
                  imageWidth={60}
                  imageHeight={60}
                  title
                  css={{ marginTop: "5px" }}
                />
              </div>
            ))}
          </div>
        ) : (
          <ChatUsersList />
        )}
      </div>
    </SearchMessageViewStyles>
  );
};

export default memo(SearchMessageView);
