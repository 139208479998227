import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import { Avatar } from "@mui/material";

import ViewProfileCardStyles from "./ViewProfileCardStyles";
import Close from "../../../../assets/svg/close.svg";
import CloseFocusIcon from "../../../../assets/FocusIcons/Common/Close.svg";
import LocationIcon from "../../../../assets/svg/f-location-pin.svg";

import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { NoUserImage } from "../../../../helper/helper";
const ViewProfileCard = ({
  data,
  showBtn = false,
  onBtnClick = () => {},
  onClose = () => {},
  scheduledAppointment = null,
  isUserCard = false,
}) => {
  const userName = !isUserCard
    ? data?.user?.first_name + " " + data?.user?.last_name
    : data?.first_name + " " + data?.last_name;

  return (
    <Grid item xs={12}>
      <ViewProfileCardStyles>
        {data && Object.keys(data).length > 0 ? (
          <CardCustom className="view-profile-card text-primary">
            {showBtn && (
              <a
                href="#"
                className="card-close-btn"
                onClick={(e) => {
                  e.preventDefault();
                  onClose(e);
                }}
                onFocus={() => {
                  let img = document.getElementById(`CloseIcon`);
                  if (img) img.src = CloseFocusIcon;
                }}
                onBlur={() => {
                  let img = document.getElementById(`CloseIcon`);
                  if (img) img.src = Close;
                }}
              >
                <img src={Close} id="CloseIcon" width="18px" alt="Close icon" />
              </a>
            )}
            <div className="profile-img">
              <Avatar
                src={
                  !isUserCard
                    ? data?.user?.user_profile_url
                    : data?.profile_image_url || NoUserImage
                }
                alt="No user placeholder"
              />
            </div>
            <h2 className="profile-name">{userName}</h2>

            <div className="sub-title">
              {!isUserCard ? data.title : data.intro}
            </div>
            {scheduledAppointment ? (
              <div className="confirmed-booking">
                <p>Confirmed booking</p>
                <div className="item">
                  {moment(scheduledAppointment.date).format("MMM DD, YYYY")}
                </div>
                <div className="item">
                  {scheduledAppointment.start_time} -{" "}
                  {scheduledAppointment.end_time}
                </div>
              </div>
            ) : (
              ""
            )}

            {!scheduledAppointment ? (
              <div className="description">{data.description}</div>
            ) : (
              ""
            )}
            {scheduledAppointment &&
            scheduledAppointment.meeting_type === "in_person" ? (
              <div className="country-detail">
                <div className="country-name">
                  <img src={LocationIcon} alt="Location pin" />
                  <span>{scheduledAppointment.location}</span>
                </div>
              </div>
            ) : (!scheduledAppointment && data.meeting_type) === "in_person" ? (
              <div className="country-detail">
                <div className="country-name">
                  <img src={LocationIcon} alt="Location pin" />
                  <span>{data.location}</span>
                </div>
              </div>
            ) : (
              <div className="meeting-location flex justify-content-center align-items-center">
                <img src={LocationIcon} alt="Location pin" />
                {scheduledAppointment ? (
                  <p>
                    <a
                      href={scheduledAppointment.join_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Meeting Link"
                      className="zoom-link"
                    >
                      Meeting Link
                    </a>
                  </p>
                ) : data.appointment_setting && !scheduledAppointment ? (
                  <p>
                    <a
                      href={data.appointment_setting?.meeting_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Meeting Link"
                      className="zoom-link"
                    >
                      Meeting Link
                    </a>
                  </p>
                ) : (
                  <span>Zoom</span>
                )}
              </div>
            )}
            {showBtn && (
              <ButtonCustom width={238} onClick={onBtnClick}>
                Check Availability
              </ButtonCustom>
            )}
          </CardCustom>
        ) : (
          ""
        )}
      </ViewProfileCardStyles>
    </Grid>
  );
};

export default ViewProfileCard;
