import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import _get from "lodash/get";

import CalendarEventDetailsStyles from "./CalendarEventDetailsStyles";
import Close from "../../../../assets/svg/close.svg";
import LocationIcon from "../../../../assets/svg/f-location-pin.svg";

import { useAppState } from "../../../../context";
import { NoUserImage } from "../../../../helper/helper";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import GoogleMapCustom from "../../../GoogleMapCustom/GoogleMapCustom";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { getMeetingPurposes } from "../../../../services/meetingPurposeServices";

const CalendarEventDetails = ({
  event,
  setSelectedAppointment = () => {},
  setShowDetailsPopup = () => {},
  setIsRescheduled = () => {},
}) => {
  const navigate = useNavigate();
  const { setSelectedEvent, selectedEvent } = useAppState("tools");
  const isEvent = typeof selectedEvent?.type !== "undefined" ? true : false;
  let renderEvent = event?.date ? event : selectedEvent;
  const address = _get(renderEvent, "address", "");
  const meeting_location = _get(renderEvent, "location", "");
  const meeting_type = _get(renderEvent, "meeting_type", "");
  const meeting_date = _get(renderEvent, "date", "");
  const meeting_title = _get(renderEvent, "title", "");
  const admin_user = _get(renderEvent, "admin_user", "");
  const admin_user_name = !isEvent
    ? " " + admin_user.first_name + " " + admin_user.last_name
    : "";
  const [appointmentSettingsId, setAppointmentSettingsId] = useState(null);
  const bookingId = isEvent ? event?.id : selectedEvent?.id;

  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);

  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data()?.data?.appointment_settings;
      if (appointmentSettings?.length > 0) {
        let filteredAppointmentSettingsId = appointmentSettings.filter(
          (settings) => settings?.user?.id === admin_user?.id
        )?.[0]?.id;
        setAppointmentSettingsId(filteredAppointmentSettingsId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetMeetingPurposes.isFetching()]);

  useEffect(() => {
    refreshGetMeetingPurposes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CalendarEventDetailsStyles>
      <div className="mt-10">
        <CardCustom className="cal-event-details-card">
          <div className="btn-close d-flex align-items-center w-100">
            <a
              href="/#"
              className="ml-auto card-close-btn modal-close-icon"
              onClick={(e) => {
                e.preventDefault();
                setSelectedEvent(null);
                setShowDetailsPopup(false);
              }}
            >
              <img
                src={Close}
                id="CloseIcon"
                width="18px"
                alt={`Close ${
                  meeting_title
                    ? meeting_title
                    : "Meeting with " + admin_user_name
                }`}
              />
            </a>
          </div>
          <div className="profile-img">
            <Avatar
              src={admin_user?.user_profile_url || NoUserImage}
              alt="No user placeholder"
            />
          </div>
          <div className="event-title">
            {meeting_title ? meeting_title : "Meeting with" + admin_user_name}
          </div>
          {isEvent && meeting_date ? (
            <div className="modal-field event-date">
              {moment(meeting_date).format("dddd, MMMM Do YYYY")}
            </div>
          ) : (
            <div className="modal-field event-date">
              {moment(_get(renderEvent, "date", "")).isValid()
                ? moment(_get(renderEvent, "date", "")).format(
                    "dddd, MMMM Do YYYY"
                  )
                : moment(
                    _get(renderEvent, "appointment_date_mobile", "")
                  ).isValid() &&
                  moment(
                    _get(renderEvent, "appointment_date_mobile", "")
                  ).format("dddd, MMMM Do YYYY")}
            </div>
          )}

          <div className="modal-field duration">
            {_get(renderEvent, "start_time", "")} –{" "}
            {_get(renderEvent, "end_time", "")}{" "}
          </div>

          {address ? (
            <React.Fragment>
              <div className="address-map">
                <GoogleMapCustom
                  address={address}
                  label={address}
                  zoom={10}
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="location-address">
                {_get(renderEvent, "address", "")}
              </div>
              <hr />
            </React.Fragment>
          ) : null}
          {!isEvent && (
            <div className="modal-field meeting-location">
              <img src={LocationIcon} alt="Location pin" />
              {meeting_type === "in_person" ||
              (!meeting_type && meeting_location) ? (
                <span>{meeting_location}</span>
              ) : meeting_type === "zoom" ||
                (!meeting_type && !meeting_location) ? (
                renderEvent?.join_url ? (
                  <a
                    href={renderEvent?.join_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-a"
                    aria-label="Zoom link"
                  >
                    Zoom link
                  </a>
                ) : (
                  <div className="ml-5 font-bold">
                    Zoom link to be provided via email
                  </div>
                )
              ) : null}
            </div>
          )}

          {!isEvent &&
          !selectedEvent?.hideRescheduleButton &&
          !event?.hideRescheduleButton &&
          appointmentSettingsId &&
          bookingId ? (
            <div className="btn">
              <ButtonCustom
                width="238"
                height={50}
                onClick={() => {
                  setSelectedEvent(null);
                  setSelectedAppointment([]);
                  setIsRescheduled(true);
                  setShowDetailsPopup(false);
                  navigate(
                    "/tools/book-meeting/" +
                      appointmentSettingsId +
                      "?bookingid=" +
                      bookingId,
                    {
                      replace: true,
                    }
                  );
                }}
              >
                Reschedule
              </ButtonCustom>
            </div>
          ) : null}
        </CardCustom>
      </div>
    </CalendarEventDetailsStyles>
  );
};

export default CalendarEventDetails;
