import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-list {
    display: block;
    .title-primary {
      display: flex;
      align-items: center;
    }

    .fixed-companies-wrapper {
      @media (min-width: 992px) {
        max-height: calc(100vh - 270px);
        overflow-y: auto;
        padding: 10px;
        margin: -10px;
        padding-right: 5px;
        margin-right: -5px;
        -ms-overflow-style: auto;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: $color-grey transparent; /* scroll thumb and track */
        transition: all ease-in-out 1000ms;
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          margin-top: 10px;
          background: #ffffff !important; /* color of the tracking area */
          border: 2px solid $color-grey-4;
          border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: $color-grey-4 !important; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
          border: none;
          height: 10px;
        }

        ::-webkit-scrollbar-corner {
          border-radius: 50%;
        }

        ::-webkit-scrollbar-thumb:hover {
          border: 0;
        }
      }
    }

    .search-card {
      overflow: visible;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      .card-content {
        padding: 16px 20px 14px;
      }
      &.active {
        .avtar {
          .MuiAvatar-root {
            &.white-background {
              background: #fff;
            }
          }
        }
        background: ${PRIMARY_COLOR};
        .header-primary,
        .sub-header-primary {
          color: #fff;
        }
        .text-grey {
          color: var(--medium-gray-color);
        }
      }
      .search-title {
        display: flex;
        justify-content: space-between;
      }
      .search-item {
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          padding: 0px;
          margin: 0 10px 0 0;
          border-radius: 100px;
          &.initial-avatar {
            padding-top: 8px;
          }
          img {
            height: auto;
            object-fit: initial;
          }
        }
        .content {
          width: calc(100% - 60px);
          .header-primary {
            display: flex;
            align-items: flex-start;
            margin-bottom: 2px;
            > div {
              margin: -2px 0 0 10px;
            }
            svg {
              width: 18px;
              height: 18px;
              margin-left: 10px;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
`;
