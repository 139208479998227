import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .recommended-wrapper {
    .recommended-network-dialog {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow);
      text-align: center;
      padding: 20px 20px 30px 20px;
      margin-bottom: 15px;
      .recommended-header {
        height: auto;
        align-items: center;
        margin-bottom: 15px;
      }
      .recommended-card-wrapper {
        display: flex;
        flex-direction: column;
        @media (max-width: 991px) {
          max-width: 280px;
          margin: 0 auto;
        }
        .recommended-card {
          align-items: start;
        }
        + .recommended-card-wrapper {
          margin-top: 15px;
        }
        .action-container {
          display: flex;
          align-items: start;
          .nlink-btn {
            width: fit-content;
            margin: 0px;
            font-size: 15px;
            min-height: auto;
            height: 0;
            opacity: 0;
            overflow: hidden;
            transform: scaleY(0);
            transform-origin: top;
            transition: transform 0.3s ease, height 0.3s ease, opacity 0.3s ease;
          }
        }
      }
      .recommended-card-wrapper:hover {
        .nlink-btn {
          margin: 5px 5px 5px 10px;
          padding: 5px 8px 3px;
          height: auto;
          opacity: 1;
          transform: scaleY(1);
        }
      }

      .main-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
      }
      .user-title-container {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 2.5px;
        .user-title {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: left;
          margin-left: 10px;
          margin-bottom: 0px;
          white-space: nowrap;
        }
        .tag-item {
          border-radius: 100px;
          background-color: #f1f4f7;
          color: #000c3d;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          padding: 4px 10px 0;
          display: inline-block;
          white-space: nowrap;
        }
      }
      .user-sub-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        margin-top: auto;
        text-align: left;
        margin-left: 10px;
        line-height: 17px;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        .left-right-space-span {
          @media (max-width: 600px) {
            font-size: 0;
          }
        }
        @media (max-width: 600px) {
          flex-wrap: wrap;
        }
      }
      p {
        color: ${PRIMARY_COLOR};
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 3px auto 25px;
        max-width: 265px;
      }
      button {
        font-size: 22px;
        width: 100%;
        max-width: 290px;
      }
    }
  }
`;
