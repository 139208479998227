import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import CardCustom from "../../ProfileModule/CardCustom/CardCustom";
import styles from "./CoverHeader.module.scss";
const cx = createModuleStyleExtractor(styles);

export const CoverHeader = (props) => {
  const { children } = props;
  return (
    <div className={cx(["profile-wrapper"])}>
      <CardCustom className="info-card">{children}</CardCustom>
    </div>
  );
};
