import React from "react";
import { Tooltip } from "@mui/material";
import ButtonCustomStyles from "./ButtonCustomStyles";
import { CircleSpinner } from "react-spinners-kit";

const ButtonCustom = ({
  onClick = () => {},
  type = "button",
  children,
  width = 325,
  height = 50,
  isSubmitting = false,
  icon,
  color = "secondary",
  variant = "contained",
  className = "",
  isDisabled = false,
  fontSize = 18,
  keepLabelCase = false,
  disableRipple = true,
  rightIcon,
  contentCustomClass = "",
  tooltipText = "",
  ...props
}) => {
  return (
    <Tooltip title={tooltipText || ""} placement="bottom">
      <ButtonCustomStyles
        disableRipple={disableRipple}
        type={type}
        variant={variant}
        color={color}
        width={width}
        height={height}
        fontSize={fontSize}
        onClick={onClick}
        disabled={isDisabled ? isDisabled : isSubmitting}
        className={`${
          color === "secondary" &&
          !className.includes("bg-gray") &&
          !className.includes("bg-white") &&
          !className.includes("bg-widget") &&
          !className.includes("bg-blue")
            ? "bg-secondary"
            : ""
        } ${className}`}
        keepLabelCase={keepLabelCase}
        aria-label={children}
        {...props}
      >
        {icon && <span className="icon">{icon}</span>}
        <div className={`content ${contentCustomClass}`}>
          {children}
          {rightIcon && <img className="ml-10" src={rightIcon} />}
          {isSubmitting && (
            <span className="button-spin">
              <CircleSpinner size={20} />
            </span>
          )}
        </div>
      </ButtonCustomStyles>
    </Tooltip>
  );
};

export default ButtonCustom;
