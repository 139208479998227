import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstLoginProfilePopup: null,
  userProfileAttachement: null,
};

export const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserProfileAttachment: (state, action) => {
      state.userProfileAttachement = action.payload;
    },
    setFirstLoginProfilePopup: (state, action) => {
      state.firstLoginProfilePopup = action.payload;
    },
  },
});

export const { setUserProfileAttachment, setFirstLoginProfilePopup } =
  profileReducer.actions;

export default profileReducer.reducer;
