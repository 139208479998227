import React from "react";
import { useAppState } from "../../../../context";
import { formatOptions } from "../../../../helper/helper";
import { opportunities } from "../../../../utils/common";
import { createModuleStyleExtractor } from "../../../../utils/css";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import styles from "./CareerInformation.module.scss";
const cx = createModuleStyleExtractor(styles);

export const CareerInformation = ({ data }) => {
  const { updateCareerForm, essentials, profile } = useAppState("profile");
  const { looking_for_an_opportunity, job_type } = data || {};
  const { job_types } = essentials || {};
  const { isProfileCompleted } = profile || {};
  return (
    <div
      className={cx([
        isProfileCompleted
          ? "o-career-information-profile"
          : "o-career-information",
      ])}
    >
      <div className={cx(["o-career-information__grid"])}>
        <div>
          <DropdownCustom
            label="Are you currently looking for an opportunity?"
            name="opportunity"
            options={formatOptions(opportunities) || []}
            onChange={(item) =>
              updateCareerForm("looking_for_an_opportunity", item?.value)}
            isSearch
            value={looking_for_an_opportunity ? { label: looking_for_an_opportunity, value: looking_for_an_opportunity} : null}
            containerClassName={
              looking_for_an_opportunity?.value ? "a-dropdown-container__options__valid": ""
            }
            placeholder="Select"
            className={`profile-react-custom-dropdown ${looking_for_an_opportunity?.value ? "border-gray" : ""}`}
            labelClass="justify-left"
            justifyContent="left"
            placeholderColor={true}
            isClearable={true}
          />
        </div>
        <div>
          <DropdownCustom
            label="What job type are you looking for?"
            name="job_type"
            options={formatOptions(job_types) || []}
            onChange={(item) =>
              updateCareerForm("job_type", item?.value)}
            isSearch
            value={job_type ? { label: job_type, value: job_type} : null}
            containerClassName={
              job_type?.value ? "a-dropdown-container__options__valid": ""
            }
            placeholder="Select"
            className={`profile-react-custom-dropdown ${job_type?.value ? "border-gray" : ""}`}
            labelClass="justify-left"
            justifyContent="left"
            placeholderColor={true}
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
};
