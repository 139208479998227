import React from "react";

import BookAppointmentStyles from "./BookAppointmentStyles";
import Calendar from "../Calendar/Calendar";

const BookAppointment = (props) => {
  return (
    <BookAppointmentStyles>
      <div className="book-appointment-wrapper">
        <div className="f-main-title-with-button mb-30">
          <h2 className="f-main-title mb-0 w-100">Meet</h2>
        </div>
        <React.Fragment>
          <Calendar props={props} />
        </React.Fragment>
      </div>
    </BookAppointmentStyles>
  );
};

export default BookAppointment;
