import React from "react";
import _get from "lodash/get";

import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";
import { useAppState } from "../../../../../context";

const BookAppointmentSettings = () => {
  const { notificationSettings } = useAppState("notifications");
  return (
    <>
      <h2 className="title-primary mb-25">Book Appointment Settings</h2>
      <NotificationSettingPanel
        title="New meeting scheduled"
        subTitle="Receive alerts whenever a new meeting is scheduled from University Admins"
        type="appointment"
        appPush={_get(notificationSettings, "appointment_push", false)}
        email={_get(notificationSettings, "appointment_email", false)}
      />
    </>
  );
};

export default BookAppointmentSettings;
