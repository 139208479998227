import React, { Fragment, memo, useEffect, useState, useMemo } from "react";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./Messages.module.scss";
import { SingleMessageItemV2 } from "../SingleMessageItemV2/SingleMessageItemV2";
import { useAppState } from "../../../../context";
import { usePaginateConversation } from "../../../../hooks/usePaginateConversation";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { deliveryTime } from "../../../../helper/helper";

const cx = createModuleStyleExtractor(styles);
const recentMessageInterval = 60000; // Time in milliseconds between two messages which are considered recent messages

const Messages = React.forwardRef(({ messages, loading }, ref) => {
  const {
    activeConversation,
    updateChatsUnreadCount,
    getChatPreviewByConversationId,
    getConversationByConversationID,
    setCurrentSearchMessageItem,
  } = useAppState("chat");
  const conversation = getConversationByConversationID();

  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const { next_page, current_page, chat } = conversation || {};
  const [options, setOptions] = useState(null);
  const [requestMeetingState, setRequestMeetingState] = useState({
    open: false,
    message: null,
  });
  const { pagination } = usePaginateConversation(activeConversation, options);
  const preview = getChatPreviewByConversationId();

  const textDeliveredToday = messages
    ? messages.find(
        (message) =>
          new Date(message.send_at).setHours(0, 0, 0, 0) >=
          new Date().setHours(0, 0, 0, 0)
      )
    : null;

  const scrollToTheBottom = () => {
    /**
     * block: nearest prevents entire page from scrolling and
     * focuses on the last element marked for scrolling inside the chat box
     */
    ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });

    /**
     * if reach to the bottom and conversation is active then update the unread count
     */
    if (!preview) return;
    preview.count > 0 && updateChatsUnreadCount(preview.conversation_id);
  };

  /**
   * Helps to do pagination stuff
   */
  const paginateMessages = () => {
    if (next_page) {
      setCurrentSearchMessageItem(null);
      setOptions({
        id_lt: messages[0].id,
        page: next_page,
      });
    }
  };

  useEffect(() => {
    if (ref.current && current_page === 1 && messages?.length > 0 && !loading)
      scrollToTheBottom();
  }, [messages, loading]);

  useEffect(() => {
    //if conversation Id changes, then we need to reset the current option state
    setOptions(null);
    // Reset request meeting state
    setRequestMeetingState({
      open: false,
      message: null,
    });
  }, [activeConversation]);

  /**
   * Instead of doing computation inside dom
   * We will do it here and use useMemo for performance optimization
   * @returns
   */
  const passthroughChatEngine = () => {
    const _dateCache = {};
    const _timeCache = {};

    return messages.map((message, index) => {
      const group =
        message.sender_id === messages[index > 0 ? index - 1 : index].sender_id;
      const currentDate = new Date(message.send_at)
        .setHours(0, 0, 0, 0)
        .toString();
      const currentTime = new Date(message.send_at).setSeconds(0, 0).toString();

      let groupByDate = false;
      let groupByTime = false;

      if (!_dateCache[currentDate]) {
        groupByDate = true;
        _dateCache[currentDate] = true;
      }

      if (!_timeCache[currentTime]) {
        groupByTime = true;
        _timeCache[currentTime] = true;
      }

      return {
        ...message,
        groupByDate,
        groupByTime,
        groupByUser: group,
      };
    });
  };

  /**
   * Peform computation only if messages change
   */
  const chat_messages = useMemo(() => {
    return passthroughChatEngine();
  }, [messages]);

  /**
   * Calculates if a current message is recent
   * @param {int} index position of current message
   * @returns {boolean} Status
   */
  const isRecentMessage = (index, currentDate) => {
    // Check if previous message exist
    if (chat_messages[index - 1] === undefined) return false;

    // Check if previous message belongs to request meeting
    if (chat_messages[index - 1]?.conversation_type === "request_meeting")
      return false;

    // Check if previous message of the same user
    if (chat_messages[index - 1].sender_id !== chat_messages[index].sender_id)
      return false;

    // Compute recent message status
    return (
      currentDate - new Date(chat_messages[index - 1].send_at) <
      recentMessageInterval
    );
  };

  return (
    <div
      className={cx([
        "messages-container",
        !messages || messages?.length === 0 ? "messages-container__fresh" : "",
      ])}
      ref={ref}
      id={"student-chat-portal__messages-container"}
      style={{
        overflowY: loading || pagination ? "hidden" : "auto",
      }}
    >
      <div className={cx("messages-container__row")}>
        {loading || pagination ? (
          Array.from(Array(3).keys()).map((_, index) => (
            <div
              className={cx("message-preview__item")}
              key={index}
              style={{ marginTop: "10px" }}
            >
              <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={60}
                imageHeight={60}
                text
              />
            </div>
          ))
        ) : (
          <>
            {next_page && (
              <span className={cx(["divider", "show-older-messages"])}>
                <span className={cx("date")} onClick={() => paginateMessages()}>
                  Show older messages
                </span>
              </span>
            )}
            {chat_messages?.map((message, index) => {
              const currentDate = new Date(message.send_at);
              const isRecent = isRecentMessage(index, currentDate);
              const isOwnMessage = userId === message?.sender_id;
              return (
                <Fragment key={message?.id || index}>
                  {
                    <>
                      {textDeliveredToday &&
                      textDeliveredToday?.id === message?.id ? (
                        <div className={cx("divider")}>
                          <span className={cx("date")}>Today</span>
                        </div>
                      ) : (
                        message?.groupByDate && (
                          <div className={cx("divider")}>
                            <span className={cx(["date", "groupByDate"])}>
                              {`${currentDate.toLocaleString("default", {
                                month: "long",
                              })} ${currentDate.toLocaleDateString("default", {
                                day: "2-digit",
                              })}, ${currentDate.getFullYear()}`}
                            </span>
                          </div>
                        )
                      )}
                    </>
                  }
                  {message?.conversation_type === "request_meeting" ? (
                    <div className={cx("messages-container__request-meeting")}>
                      {!isRecent && (
                        <div
                          className={cx([
                            "messages-container__request-meeting__time",
                            userId === message?.sender_id
                              ? "messages-container__request-meeting__time__own"
                              : "",
                          ])}
                        >
                          {userId === message?.sender_id ? (
                            <span>You at {deliveryTime(message?.send_at)}</span>
                          ) : (
                            <span>
                              {message?.sender_name?.split(" ")[0]} at{" "}
                              {deliveryTime(message?.send_at)}
                            </span>
                          )}
                        </div>
                      )}
                      <div
                        className={cx(
                          "messages-container__request-meeting__banner"
                        )}
                      >
                        {isOwnMessage ? (
                          <span>
                            You have requested a meeting
                            {chat?.conversation_window_title?.length &&
                              " with " + chat?.conversation_window_title}
                          </span>
                        ) : (
                          <span>
                            {chat?.conversation_window_title?.length
                              ? chat?.conversation_window_title
                              : "User"}{" "}
                            requested a meeting with you
                          </span>
                        )}
                        <span
                          onClick={() =>
                            setRequestMeetingState({
                              open: true,
                              message: message?.message,
                            })
                          }
                        >
                          View message
                        </span>
                      </div>
                    </div>
                  ) : (
                    <SingleMessageItemV2
                      item={message}
                      isRecent={isRecentMessage(index, currentDate)}
                      conversation={conversation}
                    />
                  )}
                  {/* Use the following empty div to scroll chat into view */}
                  <div ref={ref} />
                </Fragment>
              );
            })}
          </>
        )}
      </div>
      <CustomDialog
        open={requestMeetingState.open}
        handleClose={() =>
          setRequestMeetingState({ open: false, message: null })
        }
        isDisplayCloseButton={true}
      >
        <div className={cx("messages-container__meeting-dialog")}>
          <div className={cx("messages-container__meeting-dialog__title")}>
            <h2>Meeting requested</h2>
          </div>
          <div className={cx("messages-container__meeting-dialog__avatar")}>
            <CircularAvatar
              src={conversation?.user_info?.user_profile}
              name={conversation?.user_info?.full_name}
              size={100}
              round
            />
          </div>
          <div className={cx("messages-container__meeting-dialog__name")}>
            <h3>{conversation?.user_info?.full_name}</h3>
          </div>
          <div className={cx("messages-container__meeting-dialog__message")}>
            <textarea disabled value={requestMeetingState.message} />
          </div>
          <div className={cx("messages-container__meeting-dialog__button")}>
            <ButtonCustom
              onClick={() =>
                setRequestMeetingState({ open: false, message: null })
              }
            >
              Got it
            </ButtonCustom>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
});

export default memo(Messages);
