import React from "react";
import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Box, Tab, Tabs } from "@mui/material";

import { validateWidgetTokenAPI } from "../../services/widgetServices";
import { PRIMARY_COLOR } from "../../styles/variables";
import {
  applyWidgetTheme,
  INTERSTRIDE_DEFAULT_LOGO,
} from "../../helper/helper";
import Jobs from "./Jobs/Jobs";
import Network from "./Network/Network";
import Webinars from "./Webinars/Webinars";
import Resources from "./Resources/Resources";
import Courses from "./Courses/Courses";
import Employment from "./Employment/Employment";
import WidgetStyles from "./WidgetStyles";

import WidgetJobs from "../../assets/Icons/WidgetJobs";
import WidgetNetwork from "../../assets/Icons/WidgetNetwork";
import WidgetWebinars from "../../assets/Icons/WidgetWebinars";
import WidgetResources from "../../assets/Icons/WidgetResources";
import WidgetCourses from "../../assets/Icons/WidgetCourses";
import WidgetEmployment from "../../assets/Icons/WidgetEmployment";

const Widget = () => {
  let query = new URLSearchParams(window.location && window.location.search);

  const token =
    query?.get("token") ?? window.location.pathname.split("/")?.[2] ?? false;

  const [schoolData, setSchoolData] = useState({});
  const [selectedTab, setSelectedTab] = useState();
  const [tabsList, setTabsList] = useState([]);
  const [showLoader, setShowLoader] = useState(token ? true : false);
  const [showInvalidToken, setShowInvalidToken] = useState(
    token ? false : true
  );

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await validateWidgetTokenAPI(token);
        if (response.success && response.data) {
          const { school = {}, school_module_permission = {} } = response.data;

          const themeColor =
            school && school.theme_color ? school.theme_color : PRIMARY_COLOR;
          applyWidgetTheme(themeColor);
          setSchoolData({
            ...school,
            token,
          });

          const TABS = [
            {
              value: "Jobs",
              icon: <WidgetJobs />,
              content: <Jobs token={token} />,
              permission: true, // Jobs feature will be always enabled
            },
            {
              value: "Network",
              icon: <WidgetNetwork />,
              content: <Network token={token} />,
              permission: school_module_permission["chat"],
            },
            {
              value: "Webinars",
              icon: <WidgetWebinars />,
              content: <Webinars token={token} />,
              permission: school_module_permission["webinar"],
            },
            {
              value: school_module_permission["resources"]
                ? "Resources"
                : "Services",
              icon: <WidgetResources />,
              content: (
                <Resources
                  token={token}
                  permissions={school_module_permission}
                />
              ),
              permission:
                school_module_permission["resources"] ||
                school_module_permission["student_services"],
            },
            {
              value: "Courses",
              icon: <WidgetCourses />,
              content: (
                <Courses token={token} permissions={school_module_permission} />
              ),
              permission: school_module_permission["courses"],
            },
            {
              value: "Employment",
              icon: <WidgetEmployment />,
              content: <Employment token={token} />,
              permission:
                school_module_permission["employment_data"] &&
                school_module_permission["opt_upload"],
            },
          ];

          const filteredTabs = TABS.filter((tab) => tab.permission === true);

          setTabsList([...filteredTabs]);
          if (!isEmpty(filteredTabs)) setSelectedTab(filteredTabs[0].value);

          setShowLoader(false);
        } else {
          // Show invalid token page
          setShowInvalidToken(true);
          setShowLoader(false);
        }
      } catch (error) {
        setShowInvalidToken(true);
        setShowLoader(false);
      }
    };

    if (token) validateToken();
    else setShowInvalidToken(true);
  }, [token]);

  // Apply widget's body css
  useEffect(() => {
    document.body.classList.add("widget");
    return () => {
      document.body.classList.remove("widget");
    };
  }, []);

  return (
    <WidgetStyles>
      <div className="career-widget">
        {showLoader ? (
          <div className="career-widget-loader">
            <div className="career-widget-loader-content">
              <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        ) : showInvalidToken ? (
          <div className="career-widget-invalid-ui">
            <div className="career-widget-invalid-ui-content">
              <div className="logo">
                <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
              </div>
              <h2>
                Woops!
                <br /> We couldn't find the resource you are looking for!
              </h2>
              <h6>
                If you have any questions, please reach out to us at{" "}
                <a href="mailto:contact@interstride.com">
                  contact@interstride.com.
                </a>
              </h6>
            </div>
          </div>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="tabs-list">
                <Tabs
                  value={selectedTab}
                  onChange={(e, newValue) => setSelectedTab(newValue)}
                >
                  {tabsList.map(({ value, icon }) => (
                    <Tab
                      value={value}
                      id={`tab-${value}`}
                      aria-controls={`tabpanel-${value}`}
                      label={
                        <div
                          className={`tabs-list-header 
                          ${
                            selectedTab === value
                              ? "tabs-list-header-active"
                              : ""
                          }
                        `}
                        >
                          {icon}
                          <span>{value}</span>
                        </div>
                      }
                    />
                  ))}
                </Tabs>
              </div>
            </Box>

            {tabsList.map(({ value, content = <div></div> }) => (
              <div
                role="tabpanel"
                hidden={selectedTab !== value}
                id={`tab-content-${value}`}
                aria-labelledby={`tab-content-panel-${value}`}
              >
                {selectedTab === value && <div>{content}</div>}

                <div className="widget-footer">
                  <h3>Powered by</h3>
                  <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
                </div>
              </div>
            ))}
          </Box>
        )}
      </div>
    </WidgetStyles>
  );
};

export default Widget;
