import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getEventList = () => {
  return axiosInstance
    .get("network_trackers", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const createEvent = (requestData) => {
  return axiosInstance
    .post("network_trackers", requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getEventById = (eventId) => {
  return axiosInstance
    .get(`network_trackers/${eventId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getAppointments = (month, year) => {
  return axiosInstance
    .get(
      `appointments/appointment_list?month=${month}&year=${year}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data.data;
    });
};
