import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_query-result {
    padding: 0 0 20px 0;
    h2 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin: 0 0 8px 0;
    }
    p {
      margin: 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      padding-top: 2px;
    }
    .warning-message {
      color: var(--secondary-color);
      font-family: "TTCommons-Medium";
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin: 12px 0 0 0;
    }
    add-to-calendar-button {
      display: flex;
      width: 182px;
      margin: 8px auto 0;
    }
  }
  a {
    padding: 13px 15px 12px 20px;
    font-family: "TTCommons-Medium";
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 100px;
    box-shadow: var(--card-box-shadow);
    color: var(--primary-dark-color);
    background: #fff;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    &:focus {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    span {
      padding-top: 4px;
    }
    &.text-white {
      color: #fff;
      background: var(--primary-main-color);
    }
    width: fit-content;
    gap: 10px;
  }
`;
