import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .course-mainpage {
    > .MuiGrid-container {
      .MuiGrid-root {
        @media (max-width: 767px) {
          max-width: 100%;
          flex-basis: 100%;
        }
        &.MuiGrid-grid-xs-3 {
          .title-primary {
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        &.MuiGrid-grid-xs-9 {
          .title-primary {
            @media (max-width: 767px) {
              text-align: center;
            }
          }
        }
      }
    }

    .categories {
      ul {
        margin: 0;
        list-style: none;
        padding: 0;
        @media (max-width: 767px) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
        .category-item {
          a {
            padding: 10px 15px 10px 15px;
            text-decoration: none;
            box-shadow: var(--card-box-shadow);
            background: #fff;
            border-radius: 100px;
            min-height: 50px;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            &:hover {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            &:focus {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
          }
          .header-primary {
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            border-bottom: 1px solid transparent;
            padding-top: 6px;
          }
          + .category-item {
            margin-top: 10px;
            @media (max-width: 767px) {
              margin-top: 0;
              margin-left: 8px;
            }
          }
          &.active {
            a {
              background-color: var(--primary-main-color);
              color: #fff;
            }
            .header-primary {
              color: #fff;
            }
          }
          @media (max-width: 767px) {
            margin: 0 0 8px 0;
          }
        }
      }
    }
  }
  .iframe-content {
    width: 100%;
    @media (max-width: 767px) {
      padding-left: 0px;
    }
    iframe {
      box-shadow: var(--card-box-shadow);
      border-radius: 10px;
      vertical-align: middle;
    }
  }
`;
