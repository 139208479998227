import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .single-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 0 0;
    .single-card {
      width: 100%;
      max-width: 345px;
      display: flex;
      align-items: center;
      .select-card {
        display: inline-block;
        width: 100%;
        border-radius: 100px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        padding: 16px 20px 14px;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        -webkit-text-decoration: none;
        text-decoration: none;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        &.active {
          background-color: var(--primary-main-color);
          color: #fff;
        }
        &.ba-sidebar {
          line-height: 25px;
          min-height: 62px;
        }
        &:focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
      }
      + .single-card {
        margin-top: 10px;
      }
    }
    .block-message {
      color: rgb(0, 4, 133);
    }
  }
  .no-time-slot {
    text-align: center;
    margin-top: 8px;
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      margin: 0 0 4px 0;
    }
    p {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0;
    }
  }
`;
