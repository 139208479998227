import { useEffect } from "react";

// Sometimes you want to run parent effects before those of the children. E.g. when setting
// something up or binding document event listeners. By passing the effect to the first child it
// will run before any effects by later children.
const ParentEffect = ({ effect = () => {} }) => {
  useEffect(() => effect?.(), [effect]);
  return null;
};

export default ParentEffect;
