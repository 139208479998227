import styled from "styled-components";
import {
  SECONDARY_COLOR,
  ACTIVE_BTN_BLUE_COLOR,
} from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .boost-profile-card {
    overflow: visible;
    > .card-content {
      display: flex;
      justify-content: center;
      padding: 20px 30px 30px 30px;

      .status-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 650px;

        .rate-interstride {
          margin: 0 !important;
          .rate-wrapper {
            margin-bottom: -20px;
          }
          .rate-arrow {
            margin: 20px 0 -25px 0;
            position: relative;
          }
        }
        .job-wrapper {
          text-align: center;
          .arrow-icon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
          .steps-img {
            margin: 0px 0px;
            max-width: 325px;
            width: 100%;
            @media (max-width: 767px) {
              margin: 8px 0px 0 0;
            }
          }
        }
        .boost-webinar {
          .arrow-icon {
            position: absolute;
            top: -64px;
            left: 50%;
            transform: translateX(-50%);
            margin-left: 35px;
          }
          .steps-img {
            margin: -45px 0 -40px 0;
            max-width: 100%;
          }
        }

        .status-title {
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          padding-top: 2px;
          margin: 0 0 6px;
        }
        .status-sub-title {
          font-family: "TTCommons-Regular";
          color: var(--primary-dark-color);
          font-size: 18px;
          line-height: 19px;
          text-align: center;
          max-width: 100%;
        }
        .mandetory-note {
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          color: var(--gray-color);
        }
        .status-box {
          margin: 70px 0 28px;
          .img-arrow,
          .network-arrow {
            position: absolute;
            top: -80px;
            left: 50%;
            width: 87px;
            height: 91px;
            transform: translateX(-50%);
            margin-left: 25px;
            + .status-shadow {
              border-color: var(--secondary-color);
            }
          }
          .steps-img {
            width: 100%;
            max-width: 100%;
            &.step1 {
              max-width: 608px;
            }
            &.step2 {
              max-width: 610px;
            }
            &.step3 {
              max-width: 325px;
            }
            &.step4 {
              max-width: 608px;
            }
            &.step5 {
              max-width: 325px;
            }
          }
          &.nt-someone {
            margin: 50px 0px 10px;
            .network-arrow {
              top: -65px;
              margin-left: 85px;
            }
            .steps-img {
              margin-top: -30px;
              margin-bottom: 0px;
              max-width: 100%;
            }
          }

          > .card-wrapper {
            width: 100%;
          }

          button {
            box-shadow: 0 0 10px 0 ${SECONDARY_COLOR};
          }
        }
        button {
          padding: 17px 35.5px 12px 36.87px;
          height: auto;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          border-radius: 45px;
          font-family: "TTCommons-Medium";
          background: ${ACTIVE_BTN_BLUE_COLOR} !important;
        }
      }
    }
  }
`;
