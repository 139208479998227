import React, { useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import VerticalBarChart from "../../../../../components/atoms/VerticalBarChart.jsx/VerticalBarChart";
import DropdownCustom from "../../../../../components/DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../../../utils/css";
import styles from "./AlumniDegreesGraph.module.scss";
import Card from "../../../../../components/CardCustom/CardCustom";
import { getGraphData } from "../../../../../services/employmentDataService";

import NoDataImage from "../../../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function AlumniDegreesGraph({
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter,
  schoolId,
  processGraphClick,
}) {
  // Calls API upon component and/or selected school change
  useEffect(() => {
    (async () => {
      const response = await getGraphData({
        school_id: schoolId,
        graph_type: "degree",
        major: state?.selectedFilters?.major?.value,
        year: state?.selectedFilters?.year?.value,
        nationality: state?.selectedFilters?.nationality?.value,
      });
      if (response?.success && response?.data?.length > 0) {
        let data = response.data;

        // Truncate data
        if (data?.length > 10) data = data.slice(0, 10);

        // Process data
        let _labels = [];
        let _data = [];

        for (let i = 0; i < data.length; i++) {
          _labels.push(data[i]?.degree);
          _data.push(data[i]?.count);
        }

        setState((current) => ({
          ...current,
          loader: false,
          data_set: {
            labels: _labels,
            data: _data,
          },
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        data_set: {
          labels: null,
          data: null,
        },
      }));
    })();
  }, [schoolId, state?.selectedFilters]);

  return (
    <div className={cx("m-alum-deg")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-alum-deg__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-alum-deg__header__title")}>
            Degrees of international graduates with employment
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-alum-deg__header__filters")}
            id="collapsible-section-search-filter"
          >
            <DropdownCustom
              name="major"
              placeholder="Filter by major"
              value={state?.selectedFilters?.major}
              justifyContent="left"
              options={filters?.major}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    major: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="nationality"
              placeholder="Filter by nationality"
              value={state?.selectedFilters?.nationality}
              justifyContent="left"
              options={filters?.nationality}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    nationality: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="year"
              placeholder="Filter by year"
              value={state?.selectedFilters?.year}
              justifyContent="left"
              options={filters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
              isClearable
            />
          </div>
        </div>
      </div>
      <div className={cx("m-alum-deg__body")}>
        <Card className={cx("m-alum-deg__body__card")}>
          {state.loader ? (
            <div className={cx("m-alum-deg__body__card__loader")}>
              <ShimmerThumbnail rounded />
            </div>
          ) : !state.loader &&
            (state.data_set.labels === null || state.data_set.data === null) ? (
            <div className={cx("m-alum-deg__body__card__no-data")}>
              <img src={NoDataImage} alt="No Data Available" />
              <h3>No data available</h3>
            </div>
          ) : !state.loader &&
            state.data_set.labels?.length > 0 &&
            state.data_set.data?.length > 0 ? (
            <div className={cx("m-alum-deg__body__card__graph")}>
              <VerticalBarChart
                labels={state.data_set.labels}
                data={state.data_set.data}
                enableCursorPointer
                onClickHandler={(coordinates) =>
                  processGraphClick(coordinates, "AlumniDegrees")
                }
              />
            </div>
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
