import React from "react";
import { CircleSpinner } from "react-spinners-kit";
import { PRIMARY_COLOR } from "../../styles/variables";

const Loader = (isLoading = true) => {
  return (
    <div className="d-flex w-100 justify-content-center align-items-center spinner-div">
      {isLoading && <CircleSpinner color={PRIMARY_COLOR} size={25} />}
    </div>
  );
};

export default Loader;
