import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
.btn-test {
  margin-bottom: 0;
  display: flex;
  margin-bottom: 35px;

  button {
    background: #fff !important;
    box-shadow: var(--card-box-shadow) !important;
    text-decoration: none;
    color: var(--primary-dark-color);
    width: auto;
    padding: 18px 20px 12px;
    height: auto;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    text-transform: initial;
    margin-left: auto;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }
`;
