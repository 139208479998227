import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .company-list {
    display: block;
    .saved-company-title {
      padding: 0;
      display: flex;
      flex-wrap: inherit;
      width: 100%;
      align-items: flex-end;
      flex-wrap: wrap;
      .title-primary {
        margin-right: 15px;
      }
    }
    .MuiCardContent-root {
      padding: 0;
    }
    a {
      cursor: pointer;
    }

    .company-card {
      .MuiPaper-root {
        padding: 25px 25px;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        .MuiCardContent-root {
          padding: 0;
        }
      }
      .item {
        display: flex;
        flex-wrap: no-wrap;
        padding: 20px;
        align-items: center;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          padding: 0px;
          margin: 0 10px 0 0;
          border-radius: 100px;
          img {
            height: auto;
            object-fit: initial;
          }
        }
        .avtar {
          .initial-avatar {
            padding-top: 8px !important;
          }
        }
        .content {
          width: calc(100% - 60px);
          .header-primary {
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            .link-focus {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              word-break: break-word;
              text-decoration: none;
            }
            .star {
              margin: -4px 0 0 8px;
            }
            svg {
              width: 18px;
              height: 18px;
              margin-left: 10px;
              margin-top: -3px;
            }
          }
          .sub-header-primary {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            color: ${PRIMARY_COLOR};
            margin-bottom: 2px;
          }
          .posted-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0 0 0;
            .text-secondary {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;
