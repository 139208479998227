import React, { useState } from "react";

import ResourcesStyles from "./ResourcesStyles";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import ResourceView from "./ResourceView/ResourceView";
import ServicesView from "./ServicesView/ServicesView";

const Resources = ({ token, permissions = {} }) => {
  const [selectedView, setSelectedView] = useState(
    permissions["resources"] ? "Resources" : "Services"
  );

  return (
    <ResourcesStyles>
      <div className="widget-resources-tab">
        <h2 className="title">Get insider knowledge</h2>
        {permissions["resources"] && permissions["student_services"] ? (
          <div className="buttons-wrapper">
            <ButtonCustom
              width={150}
              onClick={() => setSelectedView("Resources")}
              aria-label={`Resources`}
              className={`${
                selectedView === "Resources"
                  ? "bg-widget"
                  : "bg-white bg-white--widget"
              } ml-10`}
            >
              Resources
            </ButtonCustom>
            <ButtonCustom
              width={150}
              onClick={() => setSelectedView("Services")}
              aria-label={`Services`}
              className={`${
                selectedView === "Services"
                  ? "bg-widget"
                  : "bg-white bg-white--widget"
              } ml-10`}
            >
              Services
            </ButtonCustom>
          </div>
        ) : null}

        <div>
          {selectedView === "Resources" ? (
            <ResourceView token={token} />
          ) : selectedView === "Services" ? (
            <ServicesView token={token} />
          ) : null}
        </div>
      </div>
    </ResourcesStyles>
  );
};

export default Resources;
