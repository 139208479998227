import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { US_VISA_LIST_DOMESTIC_VIEW } from "../../../constant/mockdata";
import RecommendedJobSearchesStyles from "./RecommendedJobSearchesStyles";
import usePromise from "../../../hooks/usePromise/usePromise";
import CardCustom from "../../CardCustom/CardCustom";
import Loader from "../../Loader/Loader";
import { getRecommendedJobSearchesAPI } from "../../../services/JobServices";
import { capitalizeFirstLetter } from "../../../helper/helper";

const RecommendedJobSearches = ({
  applyFilters = () => {},
  setCurrentJobsFilter = () => {},
}) => {
  const { jobsCountryList = [] } = useSelector((store) => store.jobStore);
  const [
    callingGetRecommendedJobSearhesAPI,
    refreshGetRecommendedJobSearchesAPI,
  ] = usePromise(getRecommendedJobSearchesAPI);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const [recommandList, setRecommandList] = useState([]);

  useEffect(() => {
    refreshGetRecommendedJobSearchesAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetRecommendedJobSearhesAPI.hasFetched() &&
      callingGetRecommendedJobSearhesAPI.hasErrors() === false &&
      callingGetRecommendedJobSearhesAPI.data() &&
      callingGetRecommendedJobSearhesAPI.data().data.jobs
    ) {
      setRecommandList(callingGetRecommendedJobSearhesAPI.data().data.jobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedJobSearhesAPI.isFetching()]);

  const onViewClick = (data) => {
    setCurrentJobsFilter({
      search: data?.search_keyword || "",
      country: data?.country || "",
      visa: data?.company || "",
    });
    applyFilters(
      {
        search: data?.search_keyword || "",
        country: data?.country || "",
        visa: data?.company || "",
      },
      false,
      true
    );
  };

  const renderRecommendedJobSearches = (data, i) => {
    let company = data.company;
    if (company === "top_1000_employers") {
      company = "Top 1000 H-1B";
    } else if (company === US_VISA_LIST_DOMESTIC_VIEW[0].value) {
      company = US_VISA_LIST_DOMESTIC_VIEW[0].label;
    } else if (company === US_VISA_LIST_DOMESTIC_VIEW[1].value) {
      company = US_VISA_LIST_DOMESTIC_VIEW[1].label;
    } else if (company === US_VISA_LIST_DOMESTIC_VIEW[2].value) {
      company = US_VISA_LIST_DOMESTIC_VIEW[2].label;
    } else if (
      company === "top_500_employers" ||
      company === "Top_500_employers"
    ) {
      company = "Top 500 H-1B";
    } else if (company === "cap_exempt" || company === "Cap_exempt") {
      company = "Cap-exempt";
    } else if (
      company === "sponsered_last_year" ||
      company === "Sponsered_last_year"
    ) {
      company = "Sponsored last year";
    } else if (
      company === "international_organizations" ||
      company === "International_organizations"
    ) {
      company = "International organizations";
    } else if (company === "all_companies" || company === "All_companies") {
      company = "All Companies";
    }
    const searchKeyword = capitalizeFirstLetter(data.search_keyword);
    //Capitalize string
    company = capitalizeFirstLetter(company);
    return (
      <Grid item xs={12} key={i}>
        <CardCustom>
          <div className="recommand-item">
            <div className="content">
              <div className="header-primary">{searchKeyword}</div>
              <div className="text-grey ">
                {company ? `${company} | ` : ""}
                {data?.country
                  ? `${
                      jobsCountryList.find(
                        (country) => data?.country === country?.value
                      )?.label || data?.country
                    }`
                  : ""}
              </div>
            </div>
            <div
              className={`card-actions-wrapper ${
                accessibilityHelp === "true" ? "show-actions" : ""
              }`}
            >
              <div>
                <a
                  href="/#"
                  className="nlink-btn nbg-secondary"
                  aria-label="Search"
                  onClick={(e) => {
                    e.preventDefault();
                    onViewClick(data);
                  }}
                >
                  Search
                </a>
              </div>
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <RecommendedJobSearchesStyles>
      <div className="recommanded-list">
        <h2 className="title-primary mb-15">Frequent Job Searches</h2>
        <Grid spacing={2} container>
          {callingGetRecommendedJobSearhesAPI.isFetching() ? (
            <Loader />
          ) : (
            <>
              {recommandList.length > 0 &&
                recommandList.map((data, i) =>
                  renderRecommendedJobSearches(data, i)
                )}
            </>
          )}
        </Grid>
      </div>
    </RecommendedJobSearchesStyles>
  );
};
export default RecommendedJobSearches;
