import React from "react";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./GroupParticipant.module.scss";
const cx = createModuleStyleExtractor(styles);
export const GroupParticipant = ({ name, admin, image }) => {
  return (
    <div className={cx("participant__container")}>
      <div className={cx("participant__container-avatar")}>
        <CircularAvatar src={image} round size={40} name={name} />
      </div>
      <div className={cx("participant__container--title")}>
        <span>{name}</span>
      </div>
      {admin && (
        <div className={cx("participant__container--role")}>
          <span>{"Group Admin"}</span>
        </div>
      )}
    </div>
  );
};
