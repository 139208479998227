import React, { useState } from "react";
import moment from "moment";
import CardCustom from "../../../CardCustom/CardCustom";
import DiscoverFilterStyles from "./DiscoverFilterStyles";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { get, orderBy } from "lodash";
import { useAppState } from "../../../../context";
import {
  optionsGenerator,
  schoolOptionsGenerator,
} from "../../../../helper/helper";

const DiscoverFilter = ({
  applyFilter,
  isFetching,
  currentFilters = {},
  setCurrentFilters = () => {},
  countryOptions = [],
}) => {
  const { netwokDiscoverOptions } = useAppState("network");
  const [showAllFilter, setShowAllFilter] = useState(false);

  const [schoolName, setSchoolName] = useState("");

  const handleChange = (obj, name) => {
    setCurrentFilters({
      ...currentFilters,
      [name]: obj.value,
    });
    if (name === "school") {
      setSchoolName(obj.label);
    }
  };

  const {
    country,
    school,
    degree,
    account_type,
    major,
    graduation_year,
    interest,
  } = currentFilters;

  return (
    <DiscoverFilterStyles>
      <CardCustom className="search-card">
        {/* <div className="text-center clear-filter">
          <Link
            to="/#"
            onClick={(e) => {
              e.preventDefault();
              clearFilters();
            }}
            className="text-secondary link-focus"
          >
            Clear all
          </Link>
        </div> */}
        <div className="form-field-group">
          <DropdownCustom
            name="country"
            label="Select country"
            placeholder="Country"
            justifyContent="left"
            className={country !== "" ? "active" : ""}
            value={country !== "" ? { value: country, label: country } : null}
            options={optionsGenerator(
              get(netwokDiscoverOptions, "countries", [])
            )}
            onChange={(obj) => handleChange(obj, "country")}
          />
        </div>
        {netwokDiscoverOptions &&
          netwokDiscoverOptions.schools &&
          netwokDiscoverOptions.schools.length > 0 && (
            <div className="form-field-group">
              <DropdownCustom
                name="school"
                label="Select department"
                placeholder="Department"
                justifyContent="left"
                className={school !== "" ? "active" : ""}
                value={
                  school !== "" ? { value: school, label: schoolName } : null
                }
                options={schoolOptionsGenerator(
                  get(netwokDiscoverOptions, "schools", []),
                  true
                )}
                onChange={(obj) => handleChange(obj, "school")}
              />
            </div>
          )}
        <div className="form-field-group">
          <DropdownCustom
            name="account_type"
            label="Select account type"
            placeholder="Account type"
            justifyContent="left"
            className={account_type !== "" ? "active" : ""}
            value={
              account_type !== ""
                ? { value: account_type, label: account_type }
                : null
            }
            options={optionsGenerator(
              get(netwokDiscoverOptions, "user_types", [])
            )}
            onChange={(obj) => handleChange(obj, "account_type")}
          />
        </div>
        {showAllFilter && (
          <>
            <div className="form-field-group">
              <DropdownCustom
                name="degree"
                label="Select degree"
                placeholder="Degree"
                justifyContent="left"
                className={degree !== "" ? "active" : ""}
                value={degree !== "" ? { value: degree, label: degree } : null}
                options={optionsGenerator(
                  get(netwokDiscoverOptions, "degree", [])
                )}
                onChange={(obj) => handleChange(obj, "degree")}
              />
            </div>
            <div className="form-field-group">
              <DropdownCustom
                name="major"
                label="Select major"
                placeholder="Major"
                justifyContent="left"
                className={major !== "" ? "active" : ""}
                value={major !== "" ? { value: major, label: major } : null}
                // options={optionsGenerator(get(netwokDiscoverOptions, "major", []))}
                options={orderBy(
                  optionsGenerator(get(netwokDiscoverOptions, "major", [])),
                  "label",
                  "asc"
                )}
                onChange={(obj) => handleChange(obj, "major")}
              />
            </div>
            <div className="form-field-group">
              <DropdownCustom
                name="graduation_year"
                label="Select graduation year"
                placeholder="Graduation year"
                justifyContent="left"
                className={graduation_year !== "" ? "active" : ""}
                value={
                  graduation_year !== ""
                    ? {
                        value: graduation_year,
                        label: graduation_year,
                      }
                    : null
                }
                defaultValue={parseInt(moment().format("YYYY"))}
                options={optionsGenerator(
                  get(netwokDiscoverOptions, "gradutaion_year", [])
                )}
                onChange={(obj) => handleChange(obj, "graduation_year")}
              />
            </div>
          </>
        )}
        <div className="text-center mt-20">
          <a
            href="#"
            className={`nlink-secondary-dark`}
            onClick={(e) => {
              e.preventDefault();
              setShowAllFilter(!showAllFilter);
            }}
          >
            {showAllFilter ? "Hide filters" : "Filters"}
          </a>
        </div>
        <ButtonCustom
          isDisabled={
            country === "" &&
            school === "" &&
            degree === "" &&
            major === "" &&
            graduation_year === "" &&
            interest === "" &&
            account_type === ""
          }
          isSubmitting={isFetching}
          onClick={() => applyFilter(currentFilters)}
          width={"100%"}
        >
          Search
        </ButtonCustom>
      </CardCustom>
    </DiscoverFilterStyles>
  );
};

export default DiscoverFilter;
