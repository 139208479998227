import React from "react";

import StarIconStyles from "./StarIconStyles";
import Star from "../../assets/svg/f-saved-star.svg";
import StarBorder from "../../assets/svg/f-unclicked-normal-start.svg";

const StarIcon = ({ variant = "", ...props }) => {
  const id = Math.random();

  return (
    <StarIconStyles>
      {variant === "filled" ? (
        <div
          className={`star ${props.className}`}
          style={{ width: "22px", height: "22px" }}
        >
          <a
            aria-label={
              props["aria-label"]
                ? props["aria-label"]
                : `Remove from favourite`
            }
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onClick && props.onClick(e);
            }}
            onFocus={() => {
              let img = document.getElementById(`Star-${id}`);
              if (img) img.src = Star;
            }}
            onBlur={() => {
              let img = document.getElementById(`Star-${id}`);
              if (img) img.src = Star;
            }}
          >
            <img
              id={`Star-${id}`}
              src={Star}
              alt="Favourite Star"
              className="filled-star"
            />
          </a>
        </div>
      ) : (
        <div
          className={`star ${props.className}`}
          style={{ width: "22px", height: "22px" }}
        >
          <a
            aria-label={
              props["aria-label"] ? props["aria-label"] : `Add to favourite`
            }
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onClick && props.onClick(e);
            }}
            onFocus={() => {
              let img = document.getElementById(`Star-${id}`);
              if (img) img.src = StarBorder;
            }}
            onBlur={() => {
              let img = document.getElementById(`Star-${id}`);
              if (img) img.src = StarBorder;
            }}
          >
            <img
              src={StarBorder}
              id={`Star-${id}`}
              alt="Non-favourite star"
              className="border-star"
            />
          </a>
        </div>
      )}
    </StarIconStyles>
  );
};

export default StarIcon;
