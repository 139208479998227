import React from "react";
import moment from "moment";
import SingleSelectCardStyles from "./SingleSelectCardStyles";
import Loader from "../Loader/Loader";

export default function CardCustom({
  onClick = () => {},
  options = [],
  changeOptionsFormat = false,
  value,
  showEmptyPopup = false,
  selectCardClass = "",
  timeslotsLoading = false,
  ...props
}) {
  let newOptions;
  if (changeOptionsFormat && options.length > 0) {
    newOptions = options.map((t) => {
      const obj = {
        label: moment(t.label, "HH:mm").format("hh:mma"),
        value: t.value,
      };
      return obj;
    });
  } else {
    newOptions = options;
  }
  return (
    <SingleSelectCardStyles>
      <div className="single-select-wrapper">
        {timeslotsLoading && <Loader />}
        {!timeslotsLoading && newOptions.length > 0
          ? newOptions.map((option, i) => (
              <div className="single-card cursor-pointer" key={i}>
                <a
                  href="/#"
                  className={`select-card ${selectCardClass} ${
                    value !== null && value === option.value ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    onClick(option.value);
                  }}
                >
                  {option.label}
                </a>
              </div>
            ))
          : null}
        {!timeslotsLoading && newOptions.length === 0 && showEmptyPopup ? (
          <div className="no-time-slot">
            <h3 className="header-primary">No Times available</h3>
            <p>Please select future date for availbale slots</p>
          </div>
        ) : null}
      </div>
    </SingleSelectCardStyles>
  );
}
