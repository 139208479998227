import { useEffect, useState } from "react";
import { useAppState } from "../context";
import { fetchConversation } from "../services/chat.service";

export const usePaginateConversation = (conversationId, options) => {
  const { updateConversationInformation } = useAppState("chat");
  const [loading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const loadConversations = async () => {
    setIsLoading(true);
    try {
      const response = await fetchConversation(conversationId, options);
      if (response) {
        const { data } = response;
        const { messages, next_page, prev_page, total_pages, current_page } =
          data || {};
        if (data && messages) {
          updateConversationInformation(conversationId, messages, {
            next_page,
            prev_page,
            total_pages,
            current_page,
          });
          setConversation(response.data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!options || Object.keys(options).length === 0) return;
    loadConversations();
  }, [options, conversationId]);

  return {
    pagination: loading,
    conversation,
  };
};
