import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SearchFilterStyles from "./SearchFilterStyles";
import Textbox from "../../../Textbox/TextboxCustom";
import { useAppState } from "../../../../context";
import { searchWebinarAPI } from "../../../../services/webinarServices";
import { cancel_token } from "../../../../helper/helper";

import searchIcon from "../../../../assets/svg/f-search.svg";

const SearchFilter = ({
  categoryOptions = [],
  setUpcomingAndPastWebinars,
  setLoader = () => {},
  tab = "",
}) => {
  const { setWebinars } = useAppState("learn");
  const [trainingCategories, setTrainingCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState(categoryOptions);
  const [host, setHost] = useState("");

  const searchAPIRef = useRef();

  useEffect(() => {
    if (categoryOptions.length > 0) {
      let _categories = [{ value: 0, label: "Training" }];
      let _trainingCategories = [];

      (categoryOptions || []).forEach((category = {}) =>
        ["Admin Training", "Student Training"].includes(category.label)
          ? _trainingCategories.push(category.value)
          : _categories.push(category)
      );

      setCategoriesOptions([..._categories]);
      setTrainingCategories(_trainingCategories);
    } else {
      setCategoriesOptions([]);
      setTrainingCategories([]);
    }
  }, [categoryOptions]);

  const clearFilters = () => {
    setHost("");
    setSearch("");
    setSelectedCategories([]);
    searchWebinarsAPI();
  };

  // Need to avoid using usePromise approach to support cancel API
  const searchWebinarsAPI = async (payload = {}) => {
    setLoader(true);

    if (searchAPIRef?.current?.cancel) {
      searchAPIRef.current.cancel("Operation cancelled by user");
    }

    const cancelToken = cancel_token();
    searchAPIRef.current = cancelToken;

    const response = await searchWebinarAPI({
      ...payload,
      cancelToken: cancelToken.token,
    });

    if (response.success && response.data) {
      setWebinars(response.data);
      setUpcomingAndPastWebinars(response.data);
      setLoader(false);
    }
    searchAPIRef.current = null;
  };

  const handleSearchFilter = (finalCategoryArray = []) => {
    searchWebinarsAPI({
      search,
      host,
      categories: (finalCategoryArray ?? []).includes(0)
        ? [...finalCategoryArray, ...trainingCategories]
        : finalCategoryArray,
    });
  };

  const setCategoriesSelected = (category) => {
    let finalCategoryArray = [...selectedCategories];
    if (!selectedCategories.includes(category)) {
      finalCategoryArray.push(category);
    } else {
      finalCategoryArray.splice(finalCategoryArray.indexOf(category), 1);
    }
    setSelectedCategories(finalCategoryArray);
    handleSearchFilter(finalCategoryArray);
  };

  return (
    <SearchFilterStyles>
      <div className="f-search-wrapper f-search-wrapper-webinars">
        <div className="fsearch-box">
          <Textbox
            name="search_keyword"
            type="text"
            placeholder="Search keyword"
            label=""
            lableaignment="left"
            value={search}
            onChange={(obj) => setSearch(obj.target.value)}
            onEnterKey={() => {
              handleSearchFilter();
            }}
          />
          <a
            className="search-btn"
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              handleSearchFilter();
            }}
          >
            <img src={searchIcon} alt="Search icon" />
          </a>
        </div>
        {categoriesOptions.length > 0 && (
          <div className="filter-item">
            <label>Filter</label>
            <div className="filter-item-content">
              {categoriesOptions.map((item, i) => {
                return (
                  <a
                    href="/#"
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesSelected(item.value);
                    }}
                    className={`item ${
                      selectedCategories.includes(item.value) ? "active" : ""
                    }`}
                    dataId={item.value}
                    aria-label={item.label}
                    disableRipple={true}
                  >
                    {item.label}
                  </a>
                );
              })}
            </div>
          </div>
        )}
        {(selectedCategories.length > 0 || search) && (
          <div className="clear-filter">
            <Link
              to="/#"
              onClick={(e) => {
                e.preventDefault();
                clearFilters();
              }}
              className="ngray-link"
            >
              Clear all
            </Link>
          </div>
        )}
      </div>
    </SearchFilterStyles>
  );
};
export default SearchFilter;
