import React from "react";

import SavedPostWidgetStyles from "./SavedPostWidgetStyles";

import SavedIcon from "../../assets/svg/f-saved.svg";

const SavedPostsWidget = ({
  widgetText = "",
  count,
  onClick = null,
  showIcon = true,
  customClassName = "",
  icon,
  href = "/#",
  hideIfEmpty = false,
  emptyTitle,
  emptySubTitle,
}) => {
  if (hideIfEmpty && (!count || count === 0)) return <></>;

  if ((emptyTitle || emptySubTitle) && (!count || count === 0))
    return (
      <div className="facelift-empty-boxwrap">
        {emptyTitle && <h3 className="header-primary">{emptyTitle}</h3>}
        {emptySubTitle && <p>{emptySubTitle}</p>}
      </div>
    );

  return (
    <SavedPostWidgetStyles>
      <a
        href={href}
        className={`savedNetwork flex align-items-center justify-between ${customClassName}`}
        onClick={onClick}
      >
        <div className="section-content flex align-items-center">
          {showIcon && (
            <div className="icon">
              <img src={icon ? icon : SavedIcon} alt="Saved icon" />
            </div>
          )}

          <div className="text">
            {widgetText}
            <span>{count ? "(" + count + ")" : ""}</span>
          </div>
        </div>
      </a>
    </SavedPostWidgetStyles>
  );
};

export default SavedPostsWidget;
