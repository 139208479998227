import React from "react";

import CardCustom from "../../../../components/CardCustom/CardCustom";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./ContactCareerCenter.module.scss";
import { validateAttribute } from "../../../../helper/helper";
import { FormattedPhoneNumber } from "../../../../components/FormattedPhoneNumber/FormattedPhoneNumber";

const cx = createModuleStyleExtractor(styles);

export default function ContactCareerCenter({
  data = {},
  setStateContactCareer,
}) {
  return (
    <div className={cx("contact-career-center")}>
      <CardCustom>
        <div className={cx("contact-career-center__body")}>
          <p className={cx("contact-career-center__body__title")}>
            Contact your Career Center
          </p>
          <p className={cx("contact-career-center__body__sub-title")}>
            Get answers on school specific questions
          </p>
          {validateAttribute(data?.phone_numbers) && (
            <div className={cx("contact-career-center__body__detail")}>
              <p className={cx("contact-career-center__body__detail__label")}>
                Phone
              </p>
              <p
                className={cx(
                  "contact-career-center__body__detail__description"
                )}
              >
                <FormattedPhoneNumber
                  number={data?.country_code + data?.phone_numbers}
                />
              </p>
            </div>
          )}
          {validateAttribute(data?.career_center_email) && (
            <div className={cx("contact-career-center__body__detail")}>
              <p className={cx("contact-career-center__body__detail__label")}>
                Email
              </p>
              <p
                className={cx(
                  "contact-career-center__body__detail__description"
                )}
              >
                {data?.career_center_email}
              </p>
            </div>
          )}
          {validateAttribute(data?.availability) && (
            <div className={cx("contact-career-center__body__detail")}>
              <p className={cx("contact-career-center__body__detail__label")}>
                Hours
              </p>
              <p
                className={cx(
                  "contact-career-center__body__detail__description"
                )}
              >
                {data?.availability}
              </p>
            </div>
          )}
          <div className={cx("contact-career-center__body__contact")}>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setStateContactCareer((current) => ({
                  ...current,
                  showModel: true,
                }));
              }}
            >
              Contact Career Center
            </a>
          </div>
        </div>
      </CardCustom>
    </div>
  );
}
