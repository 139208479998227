import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .view-profile-card {
    padding: 20px;
    margin-bottom: 16px;

    .card-content {
      padding: 0;
      text-align: center;
      position: relative;
      .card-close-btn {
        position: absolute;
        top: -5px;
        right: -5px;
      }
      .profile-img {
        margin: 0 0 10px 0;
        .MuiAvatar-root {
          width: 70px;
          height: 70px;
          margin: 0 auto;
        }
      }
      .profile-name {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 4px;
        word-break: break-word;
        margin: 0 0 4px 0;
      }
      .tags {
        margin-top: 7px;
        margin-bottom: 20px;
        .tag-item {
          border-radius: 10.5px;
          background-color: #e5eaf7;
          padding: 5.38px 10px 4.62px;
          min-width: 75px;
          text-align: center;
          justify-content: center;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
          font-family: "TTCommons-Regular";
          height: auto;
          min-height: 25px;
          margin: 0;
        }
      }
      .sub-title {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        width: 100%;
        margin-bottom: 8px;
        color: var(--primary-dark-color);
      }
      .confirmed-booking {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 20px 0;
        p {
          margin: 0 0 8px 0;
        }
        .item {
          margin: 0;
          font-size: 18px;
        }
      }
      .description {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-family: "TTCommons-Regular";
        margin: 0 0 10px 0;
      }
      .country-detail {
        .country-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          img {
            width: 25px;
            margin: 0 auto 6px;
          }
        }
      }
      .meeting-location {
        flex-wrap: wrap;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        font-size: 15px;
        img {
          width: 25px;
          margin: 0 auto 6px;
        }
        span {
          width: 100%;
          margin-top: 0 !important;
        }
      }
      .bio-detail {
        margin-top: 30px;
        font-weight: 500;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
      button {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
`;
