import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_query-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .opt_query-container__title + * {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto auto auto;
  }
  input {
    text-align: center;
  }
  .field-group {
    margin-bottom: 0;
  }
  .date-suggestion__container {
    margin-top: 20px;
    border-radius: 20px;
    background-color: var(--icon_background);
    width: 100%;
    text-align: center;
    max-width: 500px;
    padding: 20px;
    @media (max-width: 767px) {
      margin-top: 15px;
      padding: 15px;
    }
    h2 {
      color: var(--primary-dark-color);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      font-family: "TTCommons-Medium";
      margin: 0 0 10px 0;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
        margin: 0 0 5px 0;
      }
    }
    p {
      color: #000c3d;
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  add-to-calendar-button {
    display: flex;
    width: 182px;
    margin: 4px auto 0;
  }

  .date-suggestion__atcb::part(atcb-button) {
    background-color: #fff;
  }
`;
