import styled from "styled-components";

import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({
  className: "",
})`
  .job-dashboard {
    position: relative;

    .extension-card-wrapper {
      margin-top: 15px;
      .extension-card {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        text-align: center;
        padding: 30px;
        margin-bottom: 20px;
      }
    }

    .cityjobguide-wrapper {
      margin-top: 15px;

      .cityjobguide {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        text-align: center;
        padding: 20px 30px 30px;
        margin-bottom: 20px;

        .flag-icon {
          max-width: 38px;
          margin: 0 auto 10px;
          border-radius: 3px;
          background-color: #d8d8d8;
          display: flex;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      h3 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 0 0 4px 0;
      }

      p {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        margin: 0px auto 20px;
      }

      button {
        font-size: 22px;
        max-width: 265px;
      }
    }
  }

  .job-content__jobs-tutorial-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 14px;
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
    a {
      display: inline-block;
      text-decoration: none;
      span {
        &:first-child {
          color: var(--link-color);
          margin-right: 4px;
          border-bottom: 2px solid var(--link-color);
        }
        &:hover {
          opacity: 0.9;
        }
        &:focus {
          opacity: 0.9;
        }
      }
      img {
        vertical-align: middle;
        margin: -2px 0 0 0px;
      }
    }
    @media (max-width: 991px) {
      position: initial;
      float: right;
      margin: 0 0 4px 0;
    }

    /* span {
    display: flex;
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;

    img {
      margin-top: -1px;
    } */

    /* &:first-child {
      border-bottom: 2px solid var(--link-color);
      margin-right: 8px;
    } */

    &:focus {
      border-bottom: 1px solid ${PRIMARY_COLOR};

      span {
        color: ${PRIMARY_COLOR};
      }
    }
  }
`;
