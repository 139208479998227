import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .breadcrumb-div {
    position: absolute;
    left: 40px;
    // top: 50%;
    transform: translateY(-50%);
    ul {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      display: flex;
      align-items: center;
      list-style: none;
      padding: 11px 20px;
      margin: 0;
      li {
        a {
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          text-decoration: none;
          border-bottom: 1px solid transparent;
        }
        span {
          color: ${SECONDARY_COLOR};
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          border-bottom: 1px solid ${SECONDARY_COLOR};
        }
        + li {
          padding-left: 30px;
          position: relative;
          &:before {
            content: " >> ";
            position: absolute;
            left: 10px;
            color: var(--gray-text-color);
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .MuiAvatar-img {
    height: auto !important;
  }
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px 30px;
  }
  .employer-job-wrapper {
    position: relative;
    margin: 30px -30px 0;
    @media (max-width: 1180px) {
      margin: 0 0px;
    }
    .breadcrumb-div {
      position: absolute;
      left: 6px;
      top: 0;
      transform: none !important;
      @media (max-width: 1180px) {
        left: 50%;
        transform: translateX(-50%) !important;
        white-space: nowrap;
      }
    }
    .job-requirement {
      max-width: 670px;
      margin: 0 auto;
      clear: both;
      position: relative;
      margin-top: -40px;
      @media (max-width: 1367px) {
        margin-top: 24px;
      }
      @media (max-width: 767px) {
        margin-top: -16px;
      }
      .jobhead {
        padding-top: 0px;
        margin-bottom: 30px;
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
        h1 {
          color: var(--primary-dark-color);
          width: 100%;
          margin: 0;
          display: flex;
          padding: 0;
          font-size: 28px;
          font-family: "TTCommons-DemiBold";
          font-weight: 600;
          line-height: 28px;
          margin: 0 0 15px 0;
          padding-top: 4px;
        }
        .tag-wrapper {
          > span {
            padding-top: 6px;
          }
        }
        p,
        h2 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 19px;
          margin: 0 0 8px 0;
          display: flex;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          > span {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 19px;
            color: #607790;
            font-family: TTCommons-Regular;
            margin-right: 10px;
            min-width: 110px;
            padding: 0;
            white-space: nowrap;
            @media (max-width: 767px) {
              display: block;
              width: 100%;
              padding: 0;
              font-size: 18px;
              line-height: normal;
              margin: 0 0 4px 0;
            }
          }
          .tag {
            padding-left: 0px;
            span {
              background: var(--icon_background);
              padding: 6px 10px 2px;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              text-align: center;
              color: var(--primary-dark-color);
              border-radius: 100px;
              min-height: 27px;
              display: inline-block;
              margin: 0px 8px 4px 0;
              font-family: TTCommons-Regular;
            }
          }
        }
        .location {
          font-family: TTCommons-Regular;
        }
        .company-name-wrapper {
          display: flex;
          align-items: center;
          @media (max-width: 767px) {
            flex-wrap: wrap;
            margin: 16px 0;
          }
          .company-name {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
              width: 100%;
              margin: 0 0 8px 0;
            }
            .sb-avatar {
              /* width: 50px !important; */
              height: 50px !important;
              border-radius: 100px;
              overflow: hidden;
              img {
                max-width: 100%;
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
                object-position: center;
              }
            }
          }
          img {
            height: 50px;
            width: 50px;
            overflow: hidden;
            min-width: 50px;
            border-radius: 100px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            .MuiAvatar-root {
              height: 50px;
              width: 50px;
              img {
                width: 100%;
                object-fit: scale-down !important;
              }
            }
          }
          .sb-avatar--text {
            width: 50px !important;
            height: 50px !important;
            border-radius: 100px !important;
            overflow: hidden;
            .sb-avatar__text {
              width: 50px !important;
              height: 50px !important;
              span {
                font-size: 22px;
                margin-top: 2px;
                display: block;
              }
            }
          }
          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            line-height: 20px;
            margin: 0 0 0 10px;
            padding-top: 4px;
          }
          .btn-apply {
            margin-left: auto;
            @media (max-width: 767px) {
              width: 100%;
            }
            button {
              width: 156px;
              height: 50px;
              border-radius: 100px;
              @media (max-width: 767px) {
                width: 100%;
              }
            }
            .apply-btn-with-tooltip {
              display: flex;
              img {
                height: 20px;
                width: 20px;
                min-width: 20px;
              }
            }
          }
        }
        h5 {
          color: ${PRIMARY_COLOR};
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin: 0;
        }
      }
      .jobbody {
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        .content {
          h3 {
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-DemiBold" !important;
            font-size: 22px !important;
            font-weight: bold !important;
            letter-spacing: 0 !important;
            line-height: 20px !important;
            margin: 0 0 12px 0 !important;
            padding-top: 4px;
            + p {
              margin-bottom: 25px !important;
              @media (max-width: 767px) {
                margin-bottom: 16px !important;
              }
            }
            span {
              color: var(--primary-dark-color) !important;
              font-family: "TTCommons-Medium" !important;
              font-size: 22px !important;
              font-weight: 500 !important;
              letter-spacing: 0 !important;
              line-height: 24px !important;
              text-decoration: none !important;
              strong {
                font-family: "TTCommons-DemiBold";
              }
            }
          }
          p {
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Regular" !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            letter-spacing: 0 !important;
            line-height: 20px !important;
            text-align: left !important;
            margin: 10px 0 0 0;
            strong {
              font-family: "TTCommons-Medium";
              font-weight: 500;
            }
            + p {
              margin-top: 15px !important;
            }
            em {
            }
            div {
              margin: 0px !important;
              color: var(--primary-dark-color) !important;
              font-family: TTCommons-Regular !important;
              font-size: 18px !important;
              font-weight: 400 !important;
              letter-spacing: 0px !important;
              line-height: 20px !important;
              text-align: left !important;
            }
            span {
              margin: 0px !important;
              color: var(--primary-dark-color) !important;
              font-family: TTCommons-Regular !important;
              font-size: 18px !important;
              font-weight: 400 !important;
              letter-spacing: 0px !important;
              line-height: 20px !important;
              text-align: left !important;
              div {
                margin: 0px !important;
                color: var(--primary-dark-color) !important;
                font-family: TTCommons-Regular !important;
                font-size: 18px !important;
                font-weight: 400 !important;
                letter-spacing: 0px !important;
                line-height: 20px !important;
                text-align: left !important;
                width: 100% !important;
              }
            }
            pre {
              margin: 0px !important;
              color: var(--primary-dark-color) !important;
              font-family: TTCommons-Regular !important;
              font-size: 18px !important;
              font-weight: 400 !important;
              letter-spacing: 0px !important;
              line-height: 20px !important;
              text-align: left !important;
              max-height: initial !important;
              padding: 0 !important;
              + pre {
                margin-top: 20px !important;
              }
            }
          }
          ul {
            margin: 0 !important;
            color: var(--primary-dark-color) !important;
            font-family: "TTCommons-Regular" !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            letter-spacing: 0 !important;
            line-height: 20px !important;
            list-style: none !important;
            margin-bottom: 25px !important;
            padding-left: 0;
            li {
              padding: 0 !important;
              position: relative;
              padding-left: 25px !important;
              &:after {
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                background: ${PRIMARY_COLOR} !important;
                border-radius: 100px;
                left: 6px;
                top: 5px;
              }
              + li {
                margin-top: 2px;
              }
            }
          }
          + .content {
            margin-top: 25px;
            @media (max-width: 767px) {
              margin-top: 16px;
            }
          }
          div.tag {
            display: flex;
            padding: 0;
            margin: 0px 0 0 0 !important;
            span {
              margin: 0 4px 4px 0;
              border-radius: 100px;
              background-color: #e5eaf7;
              display: flex;
              min-width: 72px;
              padding: 6px 10px 3px;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 15px;
              text-align: center;
            }
          }
        }
      }
      .posted-time {
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 20px;
        margin: 0;
        + .btn-apply {
          width: 100%;
          max-width: 210px;
          button {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .employer-job-wrapper {
      .job-requirement {
        .jobbody {
          .content {
            h3 {
              font-size: 18px;
              margin: 0 0 10px 0;
            }
            p {
              font-size: 15px;
            }
            ul.tag {
              flex-wrap: wrap;
              margin: 0px 0px !important;
              li {
                padding: 0px 0px !important;
                margin: 0 10px 10px 0;
              }
            }
          }
          + .flex {
            justify-content: center;
            flex-wrap: wrap;
            .btn-apply {
              max-width: 100%;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
`;
