import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import BoostProfileProgressBarStyles from "./BoostProfileProgressBarStyles";
import CustomProgressBar from "../../CustomProgressBar/CustomProgressBar";
import { useAppState } from "../../../context";
import { routes } from "../../../routes";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";

const BoostProfileProgressBar = ({ currentIndex }) => {
  const navigate = useNavigate();
  const { totalCompletedStatus, statusList } = useAppState("boostProfile");
  const { users = {} } = useSelector((store) => store.userStore);
  let username = "newbie";

  if (Object.keys(users).length > 0 && currentIndex === 0) {
    username = users?.first_name;
  }

  return (
    <BoostProfileProgressBarStyles>
      <div className="relative custom-container">
        <div className="f-main-title-with-button position-absolute mt-20 mb-20">
          <div className="back-btn-wrapper">
            <a
              className="shadow-btn"
              onClick={(e) => {
                e.preventDefault();
                navigate(routes.DASHBOARD);
              }}
              href="#"
              aria-label="Back to Main Dashboard"
            >
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>Back to Main Dashboard</span>
            </a>
          </div>
        </div>
        <h1 className="progress-main-title text-primary">Boost your profile</h1>
        <h2 className="progress-title text-primary">
          {currentIndex === 0
            ? "Hey there, " + username + "!"
            : statusList[currentIndex].subText}
        </h2>
        <div className="boost-profile-progressbar">
          <CustomProgressBar
            percent={(totalCompletedStatus + 1) * (100 / statusList.length)}
            totalStep={statusList.length}
            currentStep={totalCompletedStatus + 1}
          />
        </div>
      </div>
    </BoostProfileProgressBarStyles>
  );
};

export default BoostProfileProgressBar;
