import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .chat-container__deactivated {
    scrollbar-width: thin !important;
    overflow: auto;
    height: calc(100% - 70px) !important;
  }
  .init-chat-container {
    .chats-section-wrapper {
      box-shadow: var(--card-box-shadow);
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
    }

    .nmessage-profile-section {
      display: flex;
      justify-content: center;
      max-height: 665px;
      > div {
        width: 100%;
      }
    }

    .saved-network-widget {
      margin-top: 15px;
      margin-bottom: 65px;
      > div {
        width: 100%;
        a {
          width: 100% !important;
        }
      }
    }

    .divider {
      height: 1px;
      background-color: #bfced8;
      position: relative;
      margin: 7px 0;
      display: inline-block;
      width: 100%;
      .date {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        width: 200px;
        font-size: 16px;
        text-align: center;
        font-family: "TTCommons-Regular";
        color: var(--secondary-color) !important;
        min-width: 90px;
        font-weight: 400;
      }
    }
    .deactivated_info {
      background-color: #ffffff;
      position: relative;
      padding: 10px 0;
    }
    .message-section {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .loading-indicator-container {
        display: block;
        padding-left: 10px;
        &__img {
          width: 30px;
          height: 30px;
        }
      }
      .message-section-title {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        h4 {
          color: var(--primary-dark-color);
          font-size: 22px;
          font-weight: 500;
          font-family: "TTCommons-Medium";
          margin: 4px 0 0 0;
        }
        .message-section-badge {
          background: rgba(247, 100, 95, 0.17) !important;
          height: 25px;
          border-radius: 12.5px;
          font-family: "TTCommons-Medium";
          font-size: 15px;
          line-height: 29px;
          font-weight: 500;
          text-align: center;
          color: #a80c06;
          letter-spacing: 0;
          padding-inline: 7.5px;
          display: block;
        }
      }
      .chat-container {
        height: 50vh;
        max-height: 50vh;
        padding-bottom: 12px;
        width: 100%;
        @media screen and (min-height: 500px) {
          height: 33vh;
          max-height: 33vh;
        }
        @media screen and (min-height: 550px) {
          height: 38vh;
          max-height: 38vh;
        }
        @media screen and (min-height: 600px) {
          height: 43vh;
          max-height: 43vh;
        }
        @media screen and (min-height: 700px) {
          height: 50vh;
          max-height: 50vh;
        }
      }
      > div {
        &:last-child {
          width: 100%;
          margin-top: auto;
        }
      }
    }
    .start-chat-textbox:focus-within {
      border-color: var(--gray-color) !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .init-chat-container {
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;
