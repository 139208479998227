import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .info-panel {
    .card-wrapper {
      .info-panel-card {
        padding: 15px !important;
        min-height: auto;
        .card-content {
          .MuiAvatar-root {
            background: #f1f4f7;
            width: 50px;
            height: 50px;
            min-width: 50px;
          }
          .panel-header {
            width: calc(100% - 335px);
            .panel-sub-title {
              color: var(--gray-text-color);
              margin: 0;
              line-height: 15px;
            }
          }
          .panel-actions {
            max-width: 265px;
            justify-content: flex-end;
            button:first-child {
              min-width: 110px;
              text-transform: initial;
            }
            button {
              padding: 6px 10px 2px 10px;
              min-width: 74px;
              width: auto;
              font-family: "TTCommons-Medium";
              .content {
                min-width: auto;
                display: flex;
              }
            }
            .btn-delete {
              background: var(--icon_background) !important;
              display: flex;
              align-items: center;
              width: 35px;
              height: 35px;
              padding: 0 !important;
              min-width: 35px;
              .content {
                img {
                  margin: 0;
                }
              }
              &:hover {
                background: var(--icon_background_hover) !important;
              }
            }
          }
        }
      }
    }
    &.noti-icon {
      .card-wrapper {
        .info-panel-card {
          .card-content {
            .MuiAvatar-root {
              padding: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100% !important;
                height: auto !important;
                object-fit: initial !important;
              }
            }
            .panel-header {
              .panel-title {
                + .panel-title {
                  display: flex;
                  align-items: center;
                  padding: 0;
                  margin: 2px 0 0 0;
                  .tags {
                    margin: 0;
                    .tag-item {
                      white-space: nowrap;
                      margin-right: 8px;
                      padding: 4px 9px 0;
                      min-width: 50px;
                      line-height: 21px;
                    }
                  }
                  .panel-sub-title {
                    padding: 0;
                    margin: 5px 0 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main-noti {
    display: flex;
    .noti-clear {
      margin-bottom: 8px;
      margin-top: 16px;
      margin-left: auto;
      button {
        background: #fff !important;
        box-shadow: var(--card-box-shadow) !important;
        color: var(--primary-dark-color);
        width: auto;
        padding: 18px 20px 12px;
        height: auto;
        min-height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        border-radius: 100px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
    }
  }
`;
