import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .save-list {
    display: block;
    margin-bottom: 20px;
    .job-title {
      padding: 0;
      display: flex;
      flex-wrap: inherit;
      width: 100%;
      align-items: flex-end;
      flex-wrap: wrap;
      .title-primary {
        margin-right: 15px;
      }
    }
    .save-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        border-radius: 100px;
      }
      .content {
        width: calc(100% - 50px);
        padding-left: 15px;
        .header-primary {
          display: flex;
          align-items: flex-start;
          > div {
            margin-left: auto;
          }
          svg {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            margin-top: -3px;
          }
          .star {
            margin: -2px 0 0 10px;
          }
        }
        .text-grey {
          display: flex;
          align-items: center;
          line-height: 16px;
          span {
            padding: 2px 0 0 0;
            white-space: nowrap;
            &:first-child {
              max-width: 75px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
        .posted-date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 4px 0 0 0;
          .text-secondary {
            text-decoration: none;
          }
        }
      }
    }
    .card-wrapper {
      .MuiPaper-root {
        padding: 15px 15px 15px 15px;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        .MuiCardContent-root {
          padding: 0;
        }
      }
    }
  }
`;
