import React from "react";
import InformationIcon from "../../assets/svg/InformationIcon";
import { createModuleStyleExtractor } from "../../utils/css";
import styles from "./ProfileModule.module.scss";
const cx = createModuleStyleExtractor(styles);
export const MiniHeading = ({ heading, showIcon = false, onClickIcon }) => {
  return (
    <h1 className={cx("section__top-heading")}>
      {heading}
      {showIcon && (
        <a
          href="/#"
          onClick={
            onClickIcon ??
            ((e) => {
              e.preventDefault();
            })
          }
        >
          <InformationIcon />
        </a>
      )}
    </h1>
  );
};
