import React, { useState } from "react";
import CategoryStyles from "../../HelpCenter/Category/CategoryStyles";
import ArrowIcon from "../../../assets/svg/down-arrow.svg";

const PolicyCategory = ({ catList = [], ActiveID, handleCategory }) => {
  const [openFilter, toggleFilter] = useState(false);

  const renderCategory = (data, i) => {
    return (
      <li
        className={`category-item cursor-pointer ${
          data.id === ActiveID && "active"
        }`}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            handleCategory(data.id);
            toggleFilter(false);
          }}
          aria-label={data.category}
        >
          <div className="item">
            <div className="cat-name">
              <div className="header-primary">{data.category}</div>
              {/* {props.subHeader && <div className="sub-text">{data.url}</div>} */}
            </div>
          </div>
        </a>
      </li>
    );
  };

  return (
    <CategoryStyles>
      <div
        className={`category-list collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-25">
          Categories
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon arrow"
          >
            <img src={ArrowIcon} alt="Arrow Icon" />
          </a>
        </h2>
        <div>
          <div className="categories">
            <ul>{catList.map((data, i) => renderCategory(data, i))}</ul>
          </div>
        </div>
      </div>
    </CategoryStyles>
  );
};

export default PolicyCategory;
