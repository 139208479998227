import React from "react";
import { Avatar } from "@mui/material";

import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";

const PendingRequest = ({ list = [], acceptRejectRequest = () => {} }) => {
  const handleAction = (data) => {
    acceptRejectRequest(data);
  };

  const renderRequest = (item, index) => {
    return (
      <div className="request">
        {item.image && item.image !== null && item.image.length > 0 ? (
          <Avatar src={item.image} alt={`${item.name}'s picture`} />
        ) : (
          <InitialAvatar
            title={item.name}
            alt={`${item.name}'s picture`}
            index={index}
          />
        )}
        <div className="request-header">
          <div className="user-title">{item.name}</div>
          <div className="panel-sub-title">{`${item.major} | ${item.graduation_year}`}</div>
        </div>
        <div className="request-actions">
          <ButtonCustom
            onClick={() =>
              handleAction({
                request_state: "decline",
                topic_id: item.topic_id,
                topic_follower_id: item.id,
              })
            }
            className="bg-gray"
            width="120"
            height="30"
          >
            Decline
          </ButtonCustom>
          <ButtonCustom
            onClick={() =>
              handleAction({
                request_state: "accept",
                topic_id: item.topic_id,
                topic_follower_id: item.id,
              })
            }
            className="bg-light-blue"
            width="120"
            height="30"
          >
            Accept
          </ButtonCustom>
        </div>
      </div>
    );
  };

  return (
    list?.length > 0 && (
      <CardCustom className="request-box">
        {list.map((item, index) => renderRequest(item, index))}
      </CardCustom>
    )
  );
};

export default PendingRequest;
