import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .book-appointment-wrapper {
    .book-meeting-btn-wrap {
      text-align: right;
      margin-top: -75px;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        margin-top: -50px;
        margin-bottom: 15px;
      }
      @media (max-width: 480px) {
        text-align: center;
        margin: 0 0 15px 0;
      }
      button {
        background-color: var(--link-color) !important;
      }
    }
    .tools-calendar {
      .MuiGrid-container {
        .MuiGrid-grid-xs-5 {
          padding-top: 51px;
          @media (max-width: 1367px) {
            max-width: 50%;
            flex-basis: 50%;
          }
          @media (max-width: 1024px) {
            max-width: 40%;
            flex-basis: 40%;
          }
          @media (max-width: 767px) {
            max-width: 100%;
            flex-basis: 100%;
            padding-top: 16px;
          }
        }
        .MuiGrid-grid-xs-7 {
          @media (max-width: 1367px) {
            max-width: 50%;
            flex-basis: 50%;
          }
          @media (max-width: 1024px) {
            max-width: 60%;
            flex-basis: 60%;
          }
          @media (max-width: 767px) {
            max-width: 100%;
            flex-basis: 100%;
          }
          .calendar-card {
            @media (max-width: 1367px) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`;
