import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Grid } from "@mui/material";
import { filter, isNull, isEmpty } from "lodash";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import Overview from "../../../assets/svg/icon-overview.svg";
import Travel from "../../../assets/svg/icon-travel.svg";
import Jobs from "../../../assets/svg/icon-jobs.svg";
import { BACKEND_FLAG_URL } from "../../../constant/mockdata";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import GoogleMapCustom from "../../../components/GoogleMapCustom/GoogleMapCustom";
import EmptyMiddleBox from "../../../components/EmptyModdleBox/EmptyMiddleBox";

const CountryView = ({
  selectedCountry = {},
  countryDetails = {},
  selectedCity = {},
  cityDetails = {},
  onBack = () => {},
  handleCityClick = () => {},
}) => {
  const [filterResult, setFilterResult] = useState([]);

  const selectedCountryName = !isNull(selectedCountry)
    ? selectedCountry.name
    : "";

  const selectedCityName = !isNull(selectedCity) ? selectedCity.city_name : "";

  useEffect(() => {
    setFilterResult([...countryDetails.cities]);
  }, [countryDetails]);

  const renderLink = (name, link, desc, icon) => (
    <>
      {isNull(link) || isEmpty(link) || link === "NA" ? (
        <></>
      ) : (
        <a
          onClick={() => window.open(link)}
          target="_blank"
          className="cursor-pointer"
          tabIndex={0}
        >
          <div className="wraper">
            <div>
              <div className="icon">
                <img src={icon} alt="link" />
              </div>
              <h3>{name}</h3>
              <p>{desc}</p>
            </div>
          </div>
        </a>
      )}
    </>
  );

  const showOverview =
    isNull(cityDetails.overview) ||
    isEmpty(cityDetails.overview) ||
    cityDetails.overview === "NA"
      ? false
      : true;

  return (
    <>
      <div className="selected-contry-content">
        <div className="country-heading">
          <div className="serach-country mb-5">
            <div className="form-group">
              <TextboxCustom
                name="search"
                placeholder={"Search city"}
                label={""}
                lableaignment="left"
                onChange={(e) => {
                  let data = filter(countryDetails.cities, function (o) {
                    return o.city_name.match(
                      new RegExp("^" + e.target.value, "i")
                    );
                  });
                  setFilterResult(data);
                }}
              />
            </div>
            <h2 className="title-primary">
              {selectedCountryName}
              <div className="flag-icon">
                <img
                  src={`${BACKEND_FLAG_URL}${
                    selectedCountry ? selectedCountry.code : ""
                  }.svg`}
                  alt="Country Flag"
                />
              </div>
            </h2>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item md={2}>
            <div className="cities_wrapper">
              <a
                href="#"
                className="pages-back-btn mb-15"
                onClick={(e) => {
                  e.preventDefault();
                  onBack();
                }}
              >
                <img src={BackArrow} width="21" alt="Arrow" />
                Back to {selectedCountryName}
              </a>
              <h3 className="title-primary mb-15">
                Cities in {selectedCountryName}
              </h3>
              <ul>
                {filterResult.length > 0 ? (
                  <>
                    {filterResult.map((city) => (
                      <li key={city.city_name}>
                        <a
                          href="#"
                          onClick={(e) => handleCityClick(e, city)}
                          className={`${
                            city.id === selectedCity.id ? "active" : ""
                          }`}
                          aria-selected={
                            city.id === selectedCity.id ? true : false
                          }
                          aria-label={city.city_name}
                        >
                          {city.city_name}
                        </a>
                      </li>
                    ))}
                  </>
                ) : (
                  <EmptyMiddleBox title="There aren’t any cities with this name. Please try again." />
                )}
              </ul>
            </div>
          </Grid>
          <Grid item md={10}>
            <Grid container spacing={3}>
              {showOverview && (
                <Grid item md={6}>
                  <div className="overview-wrapper">
                    <h2 className="title-primary mb-15">
                      {selectedCityName} Overview
                    </h2>
                    <div className="Scrollbars-wrapper">
                      <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={"100%"}
                      >
                        <div className="overview-content overview-spacing">
                          <div
                            className="country-start-paragraph-margin city-overview-align"
                            dangerouslySetInnerHTML={{
                              __html: cityDetails.overview,
                            }}
                          ></div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item md={showOverview ? 6 : 12}>
                <div className="location-wrapper">
                  <h2 className="title-primary mb-15">Location on Map</h2>
                  <div className="map-wrapper">
                    <GoogleMapCustom
                      address={selectedCityName}
                      label={selectedCityName}
                      zoom={4}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="country-services-wrapper">
              <h2 className="title-primary mt-0 mb-15">Guides</h2>
              <ul>
                {renderLink(
                  "Moving",
                  cityDetails.moving_link,
                  `What to know if you are moving to ${selectedCityName}`,
                  Overview
                )}

                {renderLink(
                  "Working",
                  cityDetails.working_link,
                  `Find out how to get a job and work in ${selectedCityName}`,
                  Jobs
                )}
                {renderLink(
                  "Living",
                  cityDetails.living_link,
                  `A comprehensive guide about living well in ${selectedCityName}`,
                  Travel
                )}
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CountryView;
