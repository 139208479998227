import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px 30px;
  }
  .create-topic {
    .create-topic-dialog {
      .MuiDialogContent-root {
        padding: 50px;
        .MuiTypography-root {
          padding: 0 !important;
          margin: 0;
        }
      }
      .upload-icon {
        margin: 10px 0 40px 0;
        .dropzone {
          .btn-upload {
            color: var(--secondary-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            margin-left: 10px;
          }
        }
      }
    }
  }
`;
