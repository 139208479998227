import React from "react";
import { useSelector } from "react-redux";

import CardCustom from "../../../CardCustom/CardCustom";
import StarIcon from "../../../StarIcon/StarIcon";
import styles from "./ServiceCard.module.scss";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { Mixpanel } from "../../../../mixpanel";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { trackClickForAnalyticsAPI } from "../../../../services/commonServices";

const cx = createModuleStyleExtractor(styles);

export default function ServiceCard({
  data = {},
  isFavourite = false,
  onClickStar = () => {},
  key = 0,
  label = false,
  widget = false,
  onActionClick = () => {},
}) {
  const { users = {} } = useSelector((store) => store.userStore);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [, refreshTrackClickForAnalyticsAPI] = usePromise(
    trackClickForAnalyticsAPI
  );

  return (
    <div className={cx("service-card")} key={key}>
      <div className={cx("service-card__wrapper")}>
        <CardCustom>
          <div className={cx("service-card__wrapper__content")}>
            <div className={cx("service-card__wrapper__content__header")}>
              {label && data?.deal_category?.name && (
                <div
                  className={cx(
                    "service-card__wrapper__content__header__label"
                  )}
                >
                  <span>{data.deal_category.name}</span>
                </div>
              )}
            </div>
            <div className={cx("service-card__wrapper__content__image")}>
              <img src={data.logo_url} alt={data.name} />
            </div>
            <div className={cx("service-card__wrapper__content__title")}>
              <h4>{data.name}</h4>
            </div>
            <div
              className={cx("service-card__wrapper__content__description")}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        </CardCustom>
      </div>
      {!widget && (
        <div
          className={cx([
            "service-card__favorite",
            isFavourite ? "service-card__favorite--active" : "",
            accessibilityHelp == "true" ? "service-card__showActions" : "",
          ])}
        >
          {isFavourite ? (
            <StarIcon
              aria-label={`Remove ${data.name} from saved services`}
              variant="filled"
              onClick={() => onClickStar({ deal_id: data.id, like: false })}
            />
          ) : (
            <StarIcon
              aria-label={`Add ${data.name} to saved services`}
              onClick={() => onClickStar({ deal_id: data.id, like: true })}
            />
          )}
        </div>
      )}

      <div
        className={cx([
          "service-card__learn-more",
          accessibilityHelp == "true" ? "service-card__showActions" : "",
          widget ? "service-card__learn-more--widget" : "",
        ])}
      >
        <a
          href
          onClick={(e) => {
            e.preventDefault();

            if (widget) {
              onActionClick();
            } else {
              refreshTrackClickForAnalyticsAPI(
                "deal",
                data.id,
                users.school_id
              );
              Mixpanel.track("Student Service clicked", {
                id: data.id,
              });

              window.open(data.url, "_blank");
            }
          }}
        >
          Learn More
        </a>
      </div>
    </div>
  );
}
