import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import { Grid, Popover, Typography } from "@mui/material";

import BoostProfileLeftSidebar from "../../../components/BoostProfile/BoostProfileLeftSidebar/BoostProfileLeftSidebar";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import OPTCalculatorMiddle from "./OPTCalculatorMiddle/OPTCalculatorMiddle";
import OPTCalculatorRightSidebar from "./OPTCalculatorRightSidebar/OPTCalculatorRightSidebar";
import {
  resetOPTInitialState,
  updateOPTIndex,
  updateOPTRightSidebarStatus,
} from "../../../redux/reducers/optReducer";
import { getDateDiffInDays } from "../../../utils/common";
import OPTCalculatorStyles from "./OPTCalculatorStyles";

import Notealert from "../../../assets/svg/note-alert.svg";
import btnArrow from "../../../assets/svg/f-btn-arrow.svg";

const syncFullTimeCPTUsed = (
  full_time_cpt_used = {},
  optRightSidebarStatus = []
) => {
  if (optRightSidebarStatus[0][0]) {
    const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
    const total = full_time_cpt_used?.value?.reduce(getDateDiffInDays, 0);
    clonedRightSidebarStatus[0][0].value = total;
    return clonedRightSidebarStatus;
  }
  return optRightSidebarStatus;
};

const syncAvailablePostCompletionOPT = (
  available_post_completion_opt = {},
  optRightSidebarStatus = []
) => {
  const fullTimeTotal = available_post_completion_opt?.value
    ?.filter((val) => !val?.isPartTime)
    .reduce(getDateDiffInDays, 0);

  const partTimeTotal = available_post_completion_opt?.value
    ?.filter((val) => val?.isPartTime)
    .reduce(getDateDiffInDays, 0);

  const total =
    (partTimeTotal ? Number(partTimeTotal) / 2 : 0) +
    (fullTimeTotal ? Number(fullTimeTotal) : 0);

  const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
  let available_opt = 0;
  if (clonedRightSidebarStatus[1][0]) {
    available_opt = clonedRightSidebarStatus[1][0].value - total;
  }

  if (clonedRightSidebarStatus[1][1]) {
    clonedRightSidebarStatus[1][1].value = total;
  }

  if (clonedRightSidebarStatus[1][2]) {
    clonedRightSidebarStatus[1][2].value =
      available_opt > 0 ? available_opt : 0;
  }

  return clonedRightSidebarStatus;
};

const syncDeadlinesPostCompletionOPT = (
  deadlines_post_completion_opt = {},
  optRightSidebarStatus = []
) => {
  const add60Days = moment(deadlines_post_completion_opt?.value)
    .add(60, "d")
    .format("MMMM DD, YYYY");
  const earliestI765USCISDate = moment(deadlines_post_completion_opt?.value)
    .subtract(90, "d")
    .format("MMMM DD, YYYY");
  const postCompletionOPTRange = `${moment(deadlines_post_completion_opt?.value)
    .add(1, "d")
    .format("MMMM DD, YYYY")} and ${add60Days}`;
  const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);

  if (clonedRightSidebarStatus[2][0]) {
    clonedRightSidebarStatus[2][0].value = earliestI765USCISDate;
  }

  if (clonedRightSidebarStatus[2][1]) {
    clonedRightSidebarStatus[2][1].value = add60Days; // sevisDeadline
  }

  if (clonedRightSidebarStatus[2][2]) {
    clonedRightSidebarStatus[2][2].value = add60Days; // I765USCISDeadline
  }

  if (clonedRightSidebarStatus[2][3]) {
    clonedRightSidebarStatus[2][3].value = postCompletionOPTRange;
  }

  return clonedRightSidebarStatus;
};

const syncDSOUSCISProcessingTimes = (
  dso_uscis_processing_times = {},
  optRightSidebarStatus = []
) => {
  if (optRightSidebarStatus[3][0]) {
    const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
    const {
      value: {
        dso,
        uscis: { months, weeks, days },
      },
    } = dso_uscis_processing_times;
    clonedRightSidebarStatus[3][0].value =
      Number(dso) + Number(months) * 30 + Number(weeks) * 7 + Number(days);
    return clonedRightSidebarStatus;
  }
  return optRightSidebarStatus;
};

const syncPreferredOPTStartDate = (
  dso_uscis_processing_times = {},
  preferred_opt_start_date = {},
  optRightSidebarStatus = []
) => {
  const {
    value: {
      dso,
      uscis: { months, weeks, days },
    },
  } = dso_uscis_processing_times;
  const USCISDays = Number(months) * 30 + Number(weeks) * 7 + Number(days);
  const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);

  const {
    value: { startDate = null }, // null value is intended to keep the input clear and show placeholder
  } = preferred_opt_start_date;
  const employmentAuthorizationDate = moment(startDate).isValid()
    ? moment(startDate).subtract(1, "d").format("MMMM DD, YYYY")
    : "";
  const estimatedUSCISProcessingDate = moment(
    employmentAuthorizationDate
  ).isValid()
    ? moment(employmentAuthorizationDate)
        .subtract(7, "d")
        .format("MMMM DD, YYYY")
    : "";
  const estimatedI765USCISReceiptDate = moment(
    estimatedUSCISProcessingDate
  ).isValid()
    ? moment(estimatedUSCISProcessingDate)
        .subtract(USCISDays, "d")
        .format("MMMM DD, YYYY")
    : "";
  const optSEVISEntryDate = moment(estimatedI765USCISReceiptDate).isValid()
    ? moment(estimatedI765USCISReceiptDate)
        .subtract(10, "d")
        .format("MMMM DD, YYYY")
    : "";
  const requestOPTFromDSODeadline = moment(optSEVISEntryDate).isValid()
    ? moment(optSEVISEntryDate).subtract(dso, "d").format("MMMM DD, YYYY")
    : "";
  if (clonedRightSidebarStatus[4][0]) {
    clonedRightSidebarStatus[4][0].value = requestOPTFromDSODeadline;
  }

  if (clonedRightSidebarStatus[4][1]) {
    clonedRightSidebarStatus[4][1].value = optSEVISEntryDate;
  }

  if (clonedRightSidebarStatus[4][2]) {
    clonedRightSidebarStatus[4][2].value = estimatedI765USCISReceiptDate;
  }

  if (clonedRightSidebarStatus[4][3]) {
    clonedRightSidebarStatus[4][3].value = estimatedUSCISProcessingDate;
  }

  if (clonedRightSidebarStatus[4][4]) {
    clonedRightSidebarStatus[4][4].value = employmentAuthorizationDate;
  }
  return clonedRightSidebarStatus;
};

const syncFormI765FilingDeadline = (
  form_i765_filing_deadline = {},
  optRightSidebarStatus = []
) => {
  if (optRightSidebarStatus[5][0]) {
    const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
    const i765Deadline = moment(form_i765_filing_deadline?.value).isValid()
      ? moment(form_i765_filing_deadline?.value)
          .add(30, "d")
          .format("MMMM DD, YYYY")
      : "";
    clonedRightSidebarStatus[5][0].value = i765Deadline;
    return clonedRightSidebarStatus;
  }
  return optRightSidebarStatus;
};

const OPTCalculator = () => {
  window.document.title = "OPT Calculator"; // Assigns page title
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [restoringState, setRestoringState] = useState(false);
  const dispatch = useDispatch();

  const {
    optCalculatorState = {},
    optTotalCompletedStatus = 0,
    optStatusList = [],
    optRightSidebarStatus = [],
    optIndex = null,
  } = useSelector((store) => store.optStore);

  const [showPopover, setPopover] = useState(null);
  const openPopover = Boolean(showPopover);
  const idPopover = openPopover ? "opt-title-popover" : undefined;

  const {
    full_time_cpt_used = {},
    available_post_completion_opt = {},
    deadlines_post_completion_opt = {},
    dso_uscis_processing_times = {},
    preferred_opt_start_date = {},
    form_i765_filing_deadline = {},
  } = optCalculatorState;

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      setRestoringState(true);
      dispatch(resetOPTInitialState());
      setRestoringState(false);
    }
  }, []);

  useEffect(() => {
    if (
      full_time_cpt_used &&
      !isEmpty(full_time_cpt_used) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncFullTimeCPTUsed(full_time_cpt_used, optRightSidebarStatus)
        )
      );
    }
  }, [full_time_cpt_used]);

  useEffect(() => {
    if (
      available_post_completion_opt &&
      !isEmpty(available_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncAvailablePostCompletionOPT(
            available_post_completion_opt,
            optRightSidebarStatus
          )
        )
      );
    }
  }, [available_post_completion_opt]);

  useEffect(() => {
    if (
      deadlines_post_completion_opt &&
      !isEmpty(deadlines_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncDeadlinesPostCompletionOPT(
            deadlines_post_completion_opt,
            optRightSidebarStatus
          )
        )
      );
    }
  }, [deadlines_post_completion_opt]);

  useEffect(() => {
    if (
      dso_uscis_processing_times &&
      !isEmpty(dso_uscis_processing_times) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncDSOUSCISProcessingTimes(
            dso_uscis_processing_times,
            optRightSidebarStatus
          )
        )
      );
    }
  }, [dso_uscis_processing_times]);

  useEffect(() => {
    if (
      preferred_opt_start_date &&
      !isEmpty(preferred_opt_start_date) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncPreferredOPTStartDate(
            dso_uscis_processing_times,
            preferred_opt_start_date,
            optRightSidebarStatus
          )
        )
      );
    }
  }, [preferred_opt_start_date]);

  useEffect(() => {
    if (
      form_i765_filing_deadline &&
      !isEmpty(form_i765_filing_deadline) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncFormI765FilingDeadline(
            form_i765_filing_deadline,
            optRightSidebarStatus
          )
        )
      );
    }
  }, [form_i765_filing_deadline]);

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      dispatch(resetOPTInitialState());
    }
  }, []);

  const handleInfoClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  return (
    <OPTCalculatorStyles>
      <div className="custom-container">
        <div
          className={`opt-calculator-content ${
            optIndex === null ? "opt-intro-page" : ""
          }`}
        >
          <h2 className="f-main-title">
            OPT Calculator
            <a
              href="/#"
              onClick={handleInfoClick}
              className="alert-icon information-icon"
              aria-label="Find Job Note"
            >
              <img src={Notealert} alt="Note" />
            </a>
          </h2>

          <Popover
            id={idPopover}
            open={openPopover}
            anchorEl={showPopover}
            onClose={handlePopoverClose}
            className="popover-wrapper"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography>
              This planning tool for post-competition OPT is not a substitute
              for applicable legal requirements, nor is it itself a rule or
              final action by SEVP. Learn more about the OPT calculator:{" "}
              <a
                href="https://studyinthestates.dhs.gov/sevis-help-hub/student-records/fm-student-employment/planning-tool-for-post-completion-opt"
                target="_blank"
                rel="noopener noreferrer"
                className="popover-link"
              >
                Planning Tool for Post Completion OPT
              </a>
            </Typography>
            {accessibilityHelp === "true" && (
              <div className="close-button">
                <ButtonCustom onClick={handlePopoverClose}>
                  Got It!
                </ButtonCustom>
              </div>
            )}
          </Popover>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 ? (
                <BoostProfileLeftSidebar
                  sidebarTitle={false}
                  statusType={"opt"}
                  currentIndex={optIndex}
                  handleClickStatus={(idx) => dispatch(updateOPTIndex(idx))}
                  statusList={optStatusList}
                  totalCompletedStatus={optTotalCompletedStatus}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <OPTCalculatorMiddle />
            </Grid>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 && optIndex < 6 ? (
                <OPTCalculatorRightSidebar />
              ) : optIndex === 6 ? (
                <div className="btn-wrapper">
                  <ButtonCustom
                    width={173}
                    className="shadow-btn"
                    onClick={() => dispatch(resetOPTInitialState())}
                  >
                    Retake questionnaire
                    <img src={btnArrow} alt="btn-arrow" />
                  </ButtonCustom>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </OPTCalculatorStyles>
  );
};

export default OPTCalculator;
