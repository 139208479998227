import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .tools-checklist {
    .checklist-items {
      .checklist-card {
        .card-content {
          .sub-tasks {
            li {
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      & &.tools-dashboard img {
        display: none;
      }
      li
        .checklist-card
        .card-content
        .checklist-task
        .checklist-control
        span.Mui-checked
        + div {
        -webkit-text-decoration-color: ${PRIMARY_COLOR};
        text-decoration-color: ${PRIMARY_COLOR};
      }
    }
    .duo-text {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      div.title-primary {
        margin-bottom: 0;
      }
      button {
        margin-right: 39px;
        height: auto;
        line-height: normal;
        padding: 6px 0 3px 0;
      }
    }
    .category-list {
      margin-top: 60px;
      @media (max-width: 991px) {
        margin-top: 0;
      }
      .title-primary {
        display: none;
        @media (max-width: 991px) {
          display: flex;
        }
      }
    }
  }
`;
