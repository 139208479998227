import React from "react";
import HeaderContainerStyles from "./HeaderContainerStyles";

const HeaderContainer = ({ children, padding = "0 35px" }) => {
  return (
    <HeaderContainerStyles padding={padding}>
      <div className="custom-header-container">{children}</div>
    </HeaderContainerStyles>
  );
};

export default HeaderContainer;
