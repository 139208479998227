import React, { useEffect, useState } from "react";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { useSelector, useDispatch } from "react-redux";

import btnArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import usePromise from "../../../../hooks/usePromise/usePromise";
import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import CustomProgressBar from "../../../../components/CustomProgressBar/CustomProgressBar";
import CustomOptionTabs from "../../../../components/CustomOptionTabs/CustomOptionTabs";
import { getTestQuestionsAPI } from "../../../../services/careerGameplanServices";
import {
  resumePreviousTest,
  setPreTestStep,
  setTestQuestions,
  updateTestData,
} from "../../../../redux/reducers/gameplanReducer";

const TestQuestions = ({ onBack = () => {} }) => {
  const dispatch = useDispatch();
  const {
    testType,
    testQuestionsList = [],
    testQuestionsLabels = [],
    testAnswersList = [],
    currentTestStep,
  } = useSelector((store) => store.gameplanStore);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  const [callingGetTestQuestionsAPI, refreshGetTestQuestionsAPI] =
    usePromise(getTestQuestionsAPI);

  useEffect(() => {
    if (testQuestionsList.length === 0)
      refreshGetTestQuestionsAPI(testType.value);
    else dispatch(resumePreviousTest());
  }, []);

  useEffect(() => {
    if (
      callingGetTestQuestionsAPI.hasFetched() &&
      callingGetTestQuestionsAPI.hasErrors() === false &&
      callingGetTestQuestionsAPI.data() &&
      callingGetTestQuestionsAPI.data().data
    ) {
      const { gameplan_questions = [] } =
        callingGetTestQuestionsAPI.data().data;
      dispatch(setTestQuestions(gameplan_questions));
    }
  }, [callingGetTestQuestionsAPI.isFetching()]);

  useEffect(() => {
    if (
      testQuestionsList &&
      testQuestionsList.length > 0 &&
      currentTestStep < testQuestionsList.length
    ) {
      setCurrentQuestion(testQuestionsList[currentTestStep]);
    }
  }, [currentTestStep]);

  useEffect(() => {
    setCurrentAnswer(
      testAnswersList &&
        testAnswersList[currentTestStep] &&
        testAnswersList[currentTestStep].answers
        ? testAnswersList[currentTestStep].answers
        : null
    );
  }, [currentTestStep]);

  const { questions = [] } =
    currentQuestion && currentQuestion !== null ? currentQuestion : {};

  const setAnswer = (operation) => {
    const answersCopy = [...testAnswersList];

    answersCopy[currentTestStep] = {
      category_id: currentQuestion.category_id,
      answers: currentAnswer,
    };
    if (operation === "next") {
      if (currentTestStep === testQuestionsList.length - 1) {
        dispatch(
          updateTestData({
            testAnswersList: answersCopy,
            isCompletedTest: true,
          })
        );
      } else {
        dispatch(
          updateTestData({
            testAnswersList: answersCopy,
            currentTestStep: currentTestStep + 1,
          })
        );
      }
    } else {
      if (currentTestStep === 0) {
        dispatch(setPreTestStep(2));
        onBack();
        dispatch(updateTestData({ testAnswersList: answersCopy }));
      } else {
        dispatch(
          updateTestData({
            testAnswersList: answersCopy,
            currentTestStep: currentTestStep - 1,
          })
        );
      }
    }
  };

  const isValid = () => {
    if (!questions || questions.length === 0) return true;
    if (!currentAnswer || currentAnswer === null) return false;
    if (currentAnswer !== null) {
      let valid = true;
      questions.forEach((question) => {
        if (
          !currentAnswer[question.id] ||
          currentAnswer[question.id] === undefined
        )
          valid = false;
      });
      if (!valid) return false;
    }
    return true;
  };

  return (
    <>
      {callingGetTestQuestionsAPI.isFetching() || currentTestStep === -1 ? (
        <div className="gameplan-test-loader">
          <ShimmerPostDetails card cta variant="SIMPLE" />
        </div>
      ) : (
        <div className="game-questionnaries text-center">
          <div className="steps-wrapper mb-20">
            <CustomProgressBar
              stepLabels={testQuestionsLabels}
              totalStep={testQuestionsList.length}
              width={685}
              percent={
                ((currentTestStep + 1) * 100) /
                (testQuestionsList.length === 0 ? 1 : testQuestionsList.length)
              }
              currentStep={currentTestStep + 1}
            />
          </div>
          {currentQuestion && currentQuestion !== null && (
            <>
              <h3 className="test-title mb-25">
                {currentQuestion.category_name}:
              </h3>

              <p
                className="test-sub-title"
                style={{ maxWidth: "440px" }}
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.category_label,
                }}
              />

              <div className="test-controls">
                {questions.map((question, i) => (
                  <>
                    <div className="mb-20 align-items-center">
                      <CustomOptionTabs
                        id={`question-${currentTestStep + 1}`}
                        keyField={"id"}
                        valueField={"answer"}
                        value={
                          currentAnswer && currentAnswer[question.id]
                            ? currentAnswer[question.id]
                            : null
                        }
                        options={question.options}
                        onClick={(value) => {
                          setCurrentAnswer({
                            ...currentAnswer,
                            [question.id]: value,
                          });
                        }}
                      />
                    </div>
                    <label htmlFor={`question-${currentTestStep + 1}`}>
                      <div
                        className="blue-bg-card mb-30"
                        dangerouslySetInnerHTML={{ __html: question.question }}
                      />
                    </label>
                  </>
                ))}
              </div>
            </>
          )}
          <div className="test-action-btns-wrapper mt-30">
            <ButtonCustom
              width={248}
              className="shadow-btn"
              onClick={(e) => {
                e.preventDefault();
                setAnswer("back");
              }}
            >
              <img src={btnArrow} alt="btn-arrow" />
              Previous
            </ButtonCustom>
            <ButtonCustom
              width={248}
              isDisabled={!isValid()}
              onClick={() => {
                setAnswer("next");
                window.scrollTo(0, 0);
              }}
            >
              {currentTestStep === testQuestionsList.length - 1
                ? "Finish"
                : "Next Question"}
            </ButtonCustom>
          </div>
        </div>
      )}
    </>
  );
};

export default TestQuestions;
