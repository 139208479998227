import React, { Fragment } from "react";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./GetStarted.module.scss";

import ProfileInfo from "../../../assets/svg/profileInfo.svg";

const cx = createModuleStyleExtractor(styles);

export default function GetStarted() {
  const { updateProfileState } = useAppState("profile");
  const handleGetStarted = () => {
    updateProfileState({ getStarted: false });
  };
  return (
    <Fragment>
      <div className={cx("profile-info")}>
        <div className={cx("profile-info__wrapper")}>
          <div className={cx("profile-info__wrapper--img-left")}>
            <img src={ProfileInfo} alt="profile info" />
          </div>
          <div className={cx("description")}>
            <h1>Finalize your profile</h1>
            <p>Complete your profile to unlock jobs, network, and more.</p>
            <ButtonCustom
              width={205}
              onClick={handleGetStarted}
              className="btn-primary"
            >
              Get started
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
