import { useEffect, useRef, useState } from "react";

export const useComponentVisible = (initialIsVisible = false, allowedIds = []) => {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  useEffect(() => {
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        setIsComponentVisible(false);
      }
      return event;
    };
  
    const handleClickOutside = event => {
      if (![...allowedIds, ref?.current?.id].includes(event?.target?.id)) {
        setIsComponentVisible(false);
      } else {
        setIsComponentVisible(true);
      }
      return event;
    };
    
    document.addEventListener("keydown", handleHideDropdown);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown);
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

  