import styled from "styled-components";
import { FIELD_LABEL_COLOR, PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  :focus {
    outline: 0;
  }
  .react-datepicker {
    border: 2px solid var(--gray-outline-color);
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 14px 3px rgba(209, 221, 229, 0.4);
    width: 100%;
    vertical-align: top;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${PRIMARY_COLOR};
    font-size: 12px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 100px;
    background-color: #0030b5;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 31px;
    font-weight: normal;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-bottom: transparent;
    border-radius: 10px;
    padding: 1rem 0.5rem 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: ${PRIMARY_COLOR};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: normal;
    font-family: "TTCommons-Regular";
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: inherit;
    color: var(--gray-text-color);
    pointer-events: none;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    cursor: inherit;
    color: var(--gray-text-color) !important;
    pointer-events: none;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    display: none;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    display: none;
  }
  .react-datepicker-popper[data-placement^="auto"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="auto"]
    .react-datepicker__triangle::before {
    display: none;
  }
  .react-datepicker__navigation--previous {
    left: 46px;
    border: 0;
    background: url("${(props) => props.leftArrowImg}") no-repeat 0 0 / 10px
      10px;
    width: 10px;
    height: 10px;
  }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 0;
  }
  .react-datepicker__month-container,
  .eXzIce .react-datepicker__header {
    border-radius: 10px;
    width: 100%;
  }
  .react-datepicker__navigation--next {
    border: 0;
    background: url("${(props) => props.rightArrowImg}") no-repeat 0 0 / 10px
      10px;
    width: 10px;
    height: 10px;
    right: 46px;
  }
  .react-datepicker__navigation {
    top: 20px;
  }
  .react-datepicker__day-names {
    padding: 0.8rem 0 0;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #0030b5;
    color: #fff;
    border-radius: 130px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    color: ${PRIMARY_COLOR};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    height: auto;
    padding: 16px 10px 10px;
    font-family: "TTCommons-Regular";
  }
  .react-datepicker__time-container,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: auto;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${PRIMARY_COLOR} !important;
    color: white !important;
    font-weight: 400 !important;
  }
  .react-datepicker__header.react-datepicker__header--time {
    display: none;
  }
  .dp-dropdown-custom-header {
    display: flex;
    align-items: start;
  }
  .react-datepicker__close-icon {
    padding: 0 15px 0 0;
    &::after {
      background-color: transparent;
      height: 30px;
      width: 30px;
      font-size: 25px;
      color: ${FIELD_LABEL_COLOR};
    }
  }
  .clearable-date-picker {
    input {
      padding: 16.65px 45px 12.65px 20px;
      text-overflow: ellipsis;
    }
  }
`;
