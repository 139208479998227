import React from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import { cloneDeep } from "lodash";
import { Tooltip } from "@mui/material";

import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import { LineDivider } from "../../ProfileModule/Divider/Divider";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";

import OPTPeriodCalculatorStyles from "./OPTPeriodCalculatorStyles";

import { isFormattedDateValid } from "../../../utils/common";

import { optPeriodPlaceholder } from "../../../constant/mockdata";

import RemoveIcon from "../../../assets/svg/RemoveIcon";

const durationOptions = [
  { value: false, label: "Full-time" },
  { value: true, label: "Part-time" },
];

const OPTPeriodEntry = ({
  type = "",
  period = {},
  idx = 0,
  optPeriodList = [],
  setOPTPeriodList = () => {},
  isTypeEnabled = false,
}) => {
  const calculatedDays = moment(period?.endDate).diff(
    moment(period?.startDate),
    "days"
  );

  const dayCount =
    period?.isPartTime && calculatedDays
      ? Number(calculatedDays) / 2
      : calculatedDays ?? null;

  const handleDateChange = (date, dateType) => {
    const updatedOPTPeriodList = cloneDeep(optPeriodList);
    if (updatedOPTPeriodList[idx]) {
      if (dateType === "isPartTime") {
        updatedOPTPeriodList[idx][dateType] = date;
      } else {
        // optional chaining was not a success with babel plugins
        updatedOPTPeriodList[idx][dateType] = isFormattedDateValid(date, "LL")
          ? moment(date).format("LL")
          : null;
      }
      setOPTPeriodList({ value: updatedOPTPeriodList });
    }
  };

  const handleDateDelete = (e, idx) => {
    e.preventDefault();
    setOPTPeriodList({ value: optPeriodList?.filter((v, i) => i !== idx) });
  };

  return (
    <div className="opt-period-entry">
      <CustomDatePicker
        dateFormat={"MMMM dd, yyyy"}
        width={200}
        maxDate={period?.endDate ? new Date(period?.endDate) : null}
        type="date"
        onChange={(date) => handleDateChange(date, "startDate")}
        value={period?.startDate !== null ? new Date(period?.startDate) : null}
        label="Start date"
        placeholder="Enter date"
        isClearable
        name={`${type}_start_${idx}`}
      />
      <CustomDatePicker
        dateFormat={"MMMM dd, yyyy"}
        width={200}
        minDate={period?.startDate ? new Date(period?.startDate) : null}
        type="date"
        onChange={(date) => handleDateChange(date, "endDate")}
        value={period?.endDate !== null ? new Date(period?.endDate) : null}
        label="End date"
        placeholder="Enter date"
        isClearable
        name={`${type}_end_${idx}`}
      />
      {isTypeEnabled && (
        <div className="duration-dropdown">
          <DropdownCustom
            name="type"
            title="type"
            value={
              durationOptions.filter(
                (opt) => opt.value === period?.isPartTime
              ) || durationOptions[0]
            }
            options={durationOptions}
            onChange={(data) => handleDateChange(data.value, "isPartTime")}
            ariaLabel="type"
          />
        </div>
      )}
      <div class="actions">
        <label>Days</label>
        <p className="day-count">{dayCount ? dayCount : 0}</p>
        <Tooltip title="Remove" aria-label="Remove date entries">
          <a
            href={"#"}
            className={`opt-period-entry__delete ${idx === 0 ? "hidden" : ""}`}
            onClick={(e) => {
              handleDateDelete(e, idx);
            }}
          >
            <RemoveIcon color={"#607790"} />
          </a>
        </Tooltip>
      </div>
    </div>
  );
};
const OPTPeriodEntryHeader = ({ isTypeEnabled }) => {
  return (
    <div className="opt-period-entry__header">
      <label className="opt-date-label">Start date</label>
      <label className="opt-date-label">End date</label>
      {isTypeEnabled && <label className="opt-date-label">Duration</label>}
      <label className="opt-date-label">Days</label>
    </div>
  );
};

const OPTPeriodCalculator = ({
  optPeriodList,
  setOPTPeriodList,
  isTypeEnabled,
  type = "",
}) => {
  const { isNextDisabled = true } = useSelector((store) => store.optStore);
  return (
    <OPTPeriodCalculatorStyles>
      <OPTPeriodEntryHeader {...{ isTypeEnabled }} />
      {optPeriodList?.length > 0
        ? optPeriodList.map((period, idx) => (
            <OPTPeriodEntry
              type={type}
              key={`Period_${idx}`}
              {...{
                period,
                idx,
                optPeriodList,
                setOPTPeriodList,
                isTypeEnabled,
              }}
            />
          ))
        : ""}
      <LineDivider />
      <a
        href="#"
        className={`add-another-action ${
          isNextDisabled ? "cursor-notallowed" : ""
        }`}
        onClick={() => {
          !isNextDisabled &&
            setOPTPeriodList({
              value: [
                ...optPeriodList,
                isTypeEnabled
                  ? { ...cloneDeep(optPeriodPlaceholder), isPartTime: false }
                  : cloneDeep(optPeriodPlaceholder),
              ],
            });
        }}
      >
        Add another date +
      </a>
    </OPTPeriodCalculatorStyles>
  );
};

export default OPTPeriodCalculator;
