import React, { useEffect, useState, useRef } from "react";

import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Textarea.module.scss";

const cx = createModuleStyleExtractor(styles);

const Textarea = ({
  className,
  onChange,
  placeholder,
  defaultValue = "",
  props = {},
  error = "",
  label,
  name,
  maxLength = 524288, // Default length as per html spec
}) => {
  const [value, setValue] = useState(defaultValue);
  const [cursor, setCursor] = useState(null);
  const { profile } = useAppState("profile");
  const { accessibility_help } = profile || {};

  const handleOnChange = (e) => {
    if (e.target.value && e.target?.value?.trim() === "") return;
    //setValue(e.target.value);
    setCursor(e.target.selectionStart);
    onChange(e.target.value);
  };

  const ref = useRef(null);

  // Fix cursor position upon on change
  useEffect(() => {
    const textArea = ref.current;
    if (textArea) textArea.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <div>
      {label && name && (
        <label htmlFor={name} className="hide-element">
          {label}
        </label>
      )}
      <textarea
        {...props}
        ref={ref}
        className={cx(["a-textarea", ...(className ? [className] : [])])}
        onChange={handleOnChange}
        value={value ?? ""}
        placeholder={placeholder}
        style={{
          ...(accessibility_help && { border: "2px solid #7491aa" }),
        }}
        id={name ? name : "textarea"}
        maxLength={maxLength}
      />
      {error && <div className={cx("a-textarea__error")}>{error}</div>}
    </div>
  );
};

export default Textarea;
