import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-list {
    display: block;
    .title-primary {
      display: flex;
      align-items: center;
      font-family: "TTCommons-DemiBold";
    }

    .search-card {
      overflow: visible;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      .card-content {
        padding: 16px 20px 14px;
      }
      &.active {
        .avtar {
          .MuiAvatar-root {
            &.white-background {
              background: #fff;
            }
          }
        }
        background: ${PRIMARY_COLOR};
        .header-primary,
        .sub-header-primary {
          color: #fff;
        }
        .text-grey {
          color: var(--medium-gray-color);
        }
      }
      .search-title {
        display: flex;
        justify-content: space-between;
      }
      .search-item {
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          padding: 0px;
          margin: 0 10px 0 0;
          border-radius: 100px;
          &.initial-avatar {
            padding-top: 8px;
          }
          img {
            height: auto;
            object-fit: initial;
          }
        }
        .content {
          width: calc(100% - 60px);
          .header-primary {
            display: flex;
            align-items: flex-start;
            margin-bottom: 2px;
            > div {
              margin: -2px 0 0 10px;
            }
            svg {
              width: 18px;
              height: 18px;
              margin-left: 10px;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
`;
