import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";
export default styled.div.attrs({ className: "" })`
  ul.submenu {
    list-style: none;
    box-shadow: var(--card-box-shadow);
    border-radius: 10px 1px 10px 10px;
    padding: 0 20px;
    margin: 8px 0 0 0;
    width: 265px;
    position: absolute;
    z-index: 2;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    li {
      margin: 0px;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      color: var(--primary-dark-color);
      text-align: center;
      padding: 22px 0 18px;

      a {
        color: var(--primary-dark-color);
        border-bottom: 2px solid transparent;
        text-decoration: none;
        &:focus,
        &:hover {
          border-bottom-color: var(--primary-dark-color);
        }
      }

      + li {
        border-top: 2px solid #eaeef3;
      }
    }
  }
`;
