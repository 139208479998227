import React from "react";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerTitle,
} from "react-shimmer-effects";
import { createModuleStyleExtractor } from "../../utils/css";
import styles from "./ProfileModule.module.scss";
const cx = createModuleStyleExtractor(styles);
export const Loading = () => {
  return (
    <>
      <div className={cx("loading-container")}>
        <div className={cx("loading-container__header")}>
          <div>
            <ShimmerCategoryItem
              hasImage
              imageType="circular"
              imageWidth={100}
              imageHeight={100}
              title
              contentCenter={true}
              cta
            />
          </div>
          {/* <div>
            <FlexRow justifyContent="flex-end">
              <ShimmerButton size={"lg"} />
              <span style={{ width: "100px" }}></span>
              <ShimmerButton size={"lg"} />
            </FlexRow>
          </div> */}
        </div>
      </div>

      <div className={cx("loading-container")}>
        <div className={cx("loading-container__header")}>
          <div>
            <ShimmerTitle line={4} gap={10} variant="primary" />
          </div>
        </div>
      </div>
      <div className={cx("loading-container")}>
        <div className={cx("loading-container__header")}>
          <div>
            <ShimmerText line={4} gap={10} variant="primary" />
          </div>
        </div>
      </div>
      <div className={cx("loading-container")}>
        <div className={cx("loading-container__header")}>
          <div>
            <ShimmerCategoryItem />
          </div>
        </div>
      </div>
      <div className={cx("loading-container")}>
        <div className={cx("loading-container__header")}>
          <div>
            <ShimmerText line={4} gap={10} variant="primary" />
          </div>
        </div>
      </div>
    </>
  );
};
