import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getCountriesAPI = () => {
  return axiosInstance.get(`countries`, headers_with_token()).then((res) => {
    return res.data;
  });
};

/**
 * This API is used to track click events of various resources
 * @param {*} category user_post, video, video_category, deal, deal_category
 * @param {*} id
 * @param {*} school_id
 * @returns
 */
export const trackClickForAnalyticsAPI = (category, id, school_id) => {
  return axiosInstance
    .post(`visits/track/${category}/${id}`, { school_id }, headers_with_token())
    .then((res) => {
      return res.data;
    });
};
