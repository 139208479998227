import React, { useRef } from "react";
import DatePicker from "react-datepicker";

import { setHours, setMinutes, getYear, getMonth } from "date-fns";

import CustomDatePickerStyles from "./CustomDatePickerStyles";
import TextboxCustom from "../Textbox/TextboxCustom";

import { months } from "../../utils/common";

import LeftArrow from "../../assets/svg/leftArrowIcon.svg";
import RightArrow from "../../assets/svg/rightArrowIcon.svg";

const CustomDatePicker = ({
  onChange = (e) => {},
  type = "date",
  width = "100%",
  name,
  label = "",
  value = new Date(),
  placeholder = "",
  timeIntervals = 15,
  minDate,
  maxDate,
  minTime = null,
  maxTime = null,
  dateFormat,
  isClearable = false,
  disabled = false,
}) => {
  const today = new Date();
  const picker = useRef();

  const ExampleCustomInput = ({ value, onClick, disabled }, ref) => (
    <TextboxCustom
      name={name}
      placeholder={placeholder}
      label={label}
      width={`${width}px`}
      onClick={onClick}
      value={value}
      required={true}
      inputProps={{ "aria-label": label }}
      ref={ref}
      isDisable={disabled}
    />
  );
  const renderDayContents = (day, date) => {
    const tooltipText = `Tooltip`;
    return <span title={tooltipText}>{day}</span>;
  };

  return (
    <CustomDatePickerStyles leftArrowImg={LeftArrow} rightArrowImg={RightArrow}>
      {type === "date" ? (
        <div
          className={`datepicker-wrapper ${
            isClearable ? "clearable-date-picker" : ""
          }`}
        >
          <DatePicker
            dateFormat={dateFormat ?? "MM/DD/YYYY"}
            ref={picker}
            popperPlacement="auto"
            name={name}
            selected={value}
            onChange={(date) => onChange(date)}
            customInput={<ExampleCustomInput />}
            minDate={minDate ? minDate : null}
            maxDate={maxDate ? maxDate : null}
            className="date-picker"
            ariaRequired={true}
            onKeyDown={(e) => {
              if (e.keyCode === 9 || e.keyCode === 13)
                picker.current.setOpen(false);
            }}
            renderDayContents={renderDayContents}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={"dp-dropdown-custom-header"}>
                <div className={"dp-dropdown-custom-title"}>
                  {months[getMonth(date)]} {getYear(date)}
                </div>
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={"dp-dropdown-custom-nav-btn"}
                >
                  {"<"}
                </button>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className={"dp-dropdown-custom-nav-btn"}
                >
                  {">"}
                </button>
              </div>
            )}
            disabled={disabled}
            isClearable={isClearable}
          />
        </div>
      ) : (
        <div className="timepicker-wrapper">
          <label
            className="form-label textbox-label text-primary text-center"
            style={{ width: "100%", margin: "0px 0px 8px 12px" }}
          >
            {label}*
          </label>
          <DatePicker
            ref={picker}
            popperPlacement="auto"
            ariaRequired={true}
            name={name}
            selected={value}
            customInput={<ExampleCustomInput />}
            onChange={(date) => onChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            timeCaption="Time"
            dateFormat="h:mm aa"
            minDate={today}
            minTime={
              minTime && minTime !== null
                ? setHours(
                    setMinutes(today, minTime.getMinutes()),
                    minTime.getHours()
                  )
                : setHours(setMinutes(today, 0), 0)
            }
            maxTime={
              maxTime && maxTime !== null
                ? setHours(
                    setMinutes(today, maxTime.getMinutes()),
                    maxTime.getHours()
                  )
                : setHours(setMinutes(today, 59), 23)
            }
            maxDate={today}
            className="time-picker"
            onKeyDown={(e) => {
              if (e.keyCode === 9 || e.keyCode === 13)
                picker.current.setOpen(false);
            }}
            disabled={disabled}
            isClearable={isClearable}
          />
        </div>
      )}
    </CustomDatePickerStyles>
  );
};

export default CustomDatePicker;
