import { useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  ambassadorBio: "",
  ambassadorHobbies: [],
  ambassadorClubs: [],
  infoPopup: false,
  ambassadorPopup: false,
};

const useAmbassadorProfile = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const resetAmbassadorProfileState = () => {
    setState(initialArgs);
  };

  const updateAmbassadorProfileState = (payload) => {
    setState({
      ...payload,
    });
  };

  const removeHobbyItem = (payload) => {
    setState({
      ambassadorHobbies: state.ambassadorHobbies.filter(
        (item) => item !== payload
      ),
    });
  };

  const removeClubItem = (payload) => {
    setState({
      ambassadorClubs: state.ambassadorClubs.filter((item) => item !== payload),
    });
  };

  return {
    ...state,
    resetAmbassadorProfileState,
    updateAmbassadorProfileState,
    removeHobbyItem,
    removeClubItem,
  };
};

export default useAmbassadorProfile;
