import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .search-filter {
    margin-top: 7px;
    display: block;
    .textbox-label {
      margin: 0 !important;
      padding: 0 0 6px 8px;
    }
    .search-card {
      padding: 40px 30px;
      .MuiCardContent-root {
        padding: 0;
      }
      .searchBtn {
        display: block;
        margin-top: 25px;
        button {
          padding: 19.85px 20px 15.85px;
          height: auto;
          border-radius: 45px;
          font-size: 22px;
          font-weight: 500;
          -webkit-letter-spacing: 0;
          -moz-letter-spacing: 0;
          -ms-letter-spacing: 0;
          letter-spacing: 0;
          line-height: 24.3px;
          text-align: center;
          margin: 15px 0 0 0;
          width: 100%;
        }
      }
      .clear-all {
        cursor: pointer;
        margin-top: 30px;
        text-align: center;
        .text-secondary {
          display: inline-block;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          border-bottom: 1px solid var(--secondary-color);
        }
      }
    }
  }
`;
