import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .tools-calendar {
    .mobile-calendar-event-popup {
      .MuiPaper-root {
        width: 100%;
        margin: 0;
        max-width: 480px;
        box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
        .MuiDialogContent-root {
          width: 100% !important;
          padding: 30px;
        }
      }
    }
    .calender-side-events {
      .event-card {
        .card-content {
          padding: 20px;
          @media (max-width: 1024px) {
            padding: 16px;
          }
          .event-item {
            display: flex;
            @media (max-width: 1024px) {
              flex-wrap: wrap;
            }
            .left {
              width: 50%;
              @media (max-width: 1024px) {
                width: 100%;
              }
              h3 {
                margin: 0;
                color: var(--primary-dark-color);
                font-family: "TTCommons-DemiBold";
                font-size: 18px;
                font-weight: 600;
                -webkit-letter-spacing: 0;
                -moz-letter-spacing: 0;
                -ms-letter-spacing: 0;
                letter-spacing: 0;
                line-height: 19px;
              }
              .webinarCategory {
                background: var(--icon_background);
                font-size: 15px;
                -webkit-letter-spacing: 0;
                -moz-letter-spacing: 0;
                -ms-letter-spacing: 0;
                letter-spacing: 0;
                line-height: 17px;
                text-align: center;
                color: var(--primary-dark-color);
                padding: 6px 16px 3px;
                border-radius: 100px;
                display: inline-block;
                margin: 8px 0 0 0;
              }
              h5 {
                display: flex;
                align-items: center;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--primary-dark-color);
                margin: 8px 0;
                font-weight: 400;
                img {
                  margin-right: 8px;
                  margin-top: -2px;
                }
              }
              .user-info-wrap {
                margin-top: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .avtar {
                  width: 50px;
                  height: 50px;
                  margin-right: 8px;
                  .initial-avatar {
                    width: 50px;
                    height: 50px;
                    margin-right: 0;
                    padding: 8px 0 0 0;
                  }
                  .MuiAvatar-root {
                    width: 50px;
                    height: 50px;
                    margin-right: 0;
                  }
                }
                .user-info-details {
                  h4 {
                    margin: 0 0 2px 0;
                  }
                  p {
                    margin: 0;
                    color: var(--primary-dark-color);
                    font-family: "TTCommons-Regular";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 17px;
                  }
                }
              }
            }
            .right {
              width: 50%;
              margin-left: 20px;
              padding-left: 20px;
              border-left: 2px solid var(--gray-outline-color);
              @media (max-width: 1024px) {
                width: 100%;
                margin-left: 0px;
                margin-top: 16px;
                padding-left: 0;
                padding-top: 16px;
                border-left: 0;
                border-top: 2px solid var(--gray-outline-color);
              }
              .purpose-tag {
                border-radius: 100px;
                background-color: var(--icon_background);
                color: #000c3d;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                padding: 5px 16px 0;
                text-align: left;
                display: inline-block;
              }
              .meeting-description {
                display: flex;
                align-items: center;
                margin-top: 10px;
                img {
                  width: 25px;
                  height: 25px;
                  margin: -2px 8px 0 0;
                  min-width: 25px;
                }
                .location {
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Medium";
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 16px;
                  margin-top: 2px;
                }
                .email {
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Medium";
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 16px;
                  margin-top: 4px;
                }
                a {
                  color: var(--primary-dark-color);
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 15px;
                  font-family: "TTCommons-Medium";
                  border-bottom: 2px solid var(--primary-dark-color);
                  cursor: pointer;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
    .part-seperation {
      margin-bottom: 24px;
    }

    div.title-primary {
      margin-bottom: 23px;
    }

    .calendar-icons {
      display: flex;
      align-items: center;
      img {
        margin: 0 5px;
      }
      .gray-linktext {
        margin-left: 5px;
      }
    }
    .cal-event-details-card {
      .card-content {
        padding: 30px 20px 40px;
      }
    }
  }
`;
