import styled from "styled-components";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
  SECONDARY_COLOR,
} from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .cal-event-details-card {
    .card-content {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      position: relative;
      .modal-close-icon {
        position: absolute;
        right: -5px;
        top: -5px;
      }
      .event-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        + svg {
          top: -5px;
          right: -5px;
        }
      }

      /* .event-date {
        margin: 0 0 5px;
        font-size: 18px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
      }
      .meeting-location {
        font-size: 18px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 5px;
        .underline-a {
          color: ${SECONDARY_COLOR}
          text-decoration: none;
          display: inline-block;
          border-bottom: 1px solid var(--secondary-color);
          margin: 0 0 0 5px;
        }
      }
      .btn {
        margin-bottom: 20px;
        button {
          width: 140px;
          height: 40px;
          border-radius: 25px;
        }
      }
      .duration {
        font-size: 18px;
        font-family: "TTCommons-Regular";
        color: ${GRAY_COLOR};
        margin: 0 0 25px;
      } */
      /* .address-map {
        margin: 0 0 13px;
        position: relative;
        height: 171px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        * {
          border-bottom-left-radius: 0 !important;
          box-shadow: none !important;
        }
      }
      .location-address {
        margin: 3px 0;
        font-size: 15px;
        font-family: "TTCommons-Regular";
        color: ${GRAY_COLOR};
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 225px;
      }
      hr {
        width: 225px;
        border: 1px solid var(--light-gray-color);
        margin: 15px 0;
      }
      button {
        margin: 20px 0;
      } */
      .profile-img {
        .MuiAvatar-root {
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
        }
      }

      .modal-field {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        max-width: 260px;
        &.event-date {
          margin: 0 0 4px 0;
        }
        &.duration {
          font-size: 18px;
          line-height: 19px;
        }
        &.meeting-location {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          margin-bottom: 24px;
          margin-top: 8px;
          img {
            width: 25px;
            margin: 0 auto 6px;
          }
          .zoom-link {
            color: var(--primary-dark-color);
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            font-family: "TTCommons-Medium";
            border-bottom: 2px solid var(--primary-dark-color);
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
`;
