import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .started-middle-page {
    .started-card {
      padding: 30px 20px;
      .MuiCardContent-root {
        padding: 0;
      }
    }

    .faq-post {
      .MuiPaper-root {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        &:hover,
        &focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
      }
      .card-content {
        padding: 20px;
        .header-primary {
          margin: 0 0 8px 0;
          font-size: 22px;
          line-height: 24px;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 18px;
          }
        }
        .sub-header {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 0 8px 0;
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 16px;
          }
          strong {
            font-family: "TTCommons-DemiBold";
            font-weight: 600;
          }
          a {
            color: var(--primary-dark-color);
          }
        }
        .media {
          display: flex;
          video {
            width: 100%;
            verticle-align: middle;
            border-radius: 4px;
          }
        }
      }
    }
    .faq-section {
      .MuiPaper-root {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        padding: 22px 20px 18px 20px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        + .MuiPaper-root {
          margin-top: 15px;
          @media (max-width: 767px) {
            margin-top: 8px;
          }
        }
        .MuiButtonBase-root {
          padding: 0;
          min-height: auto;
          .MuiAccordionSummary-content {
            margin: 0 !important;
            p {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              line-height: 19px;
            }
          }
          .MuiAccordionSummary-expandIcon {
            margin-right: 6px;
            svg.MuiSvgIcon-root {
              fill: var(--primary-dark-color);
            }
          }
        }
        &:before {
          display: none;
        }
        .MuiAccordionDetails-root {
          padding: 0 35px 0 0;
          margin: 8px 0 0 0;
          flex-wrap: wrap;
          div {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            a {
              color: var(--primary-dark-color);
            }
          }
          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            margin: 0;
          }
          strong,
          b {
            font-family: "TTCommons-DemiBold";
            font-weight: 600;
          }
          a {
            color: var(--primary-dark-color);
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              + li {
                margin-top: 8px;
              }
            }
          }
          .media {
            margin: 15px 0 0 0;
            video {
              width: 100%;
            }
          }
        }
      }
    }
    .policy-section {
      .MuiPaper-root {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      }
      .card-content {
        padding: 40px 30px 40px 30px;
        p {
          margin: 0;
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          + p {
            margin-top: 20px;
          }
        }
      }
    }
  }
`;
