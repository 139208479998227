import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .event-card {
    margin-bottom: 12px;
  }
  .empty-events-box {
    .card-content {
      padding: 39px 18px 30px 18px;
      box-sizing: border-box;
    }
    .empty-item {
      text-align: center;
      .header-primary {
        font-size: 22px;
        line-height: 21px;
        margin: 0 0 10px 0;
      }
      .text-grey {
        font-size: 18px;
        line-height: 17px;
        font-family: "TTCommons-Medium";
      }
    }
  }
`;
