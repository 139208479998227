import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_query-container__title + div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto auto auto;
  }

  .react-datepicker__input-container {
    .field-group {
      margin-bottom: 0;
    }
  }
`;
