import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .field-group {
    max-width: 202px;
    text-align: center;
    margin: 20px auto 0;
    input {
      text-align: center;
      padding-left: 30px;
      @media (max-width: 767px) {
        padding-left: 20px;
      }
    }
  }
  .view-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 0 0;
    justify-content: center;
    a {
      padding: 16px 20px 12px 20px;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      text-decoration: none;
      border-radius: 100px;
      box-shadow: var(--card-box-shadow);
      color: var(--primary-dark-color);
      background: #fff;
      transition: all 0.3s;
      min-height: 50px;

      img {
        margin: 0px 0 0 8px;
        position: relative;
        top: 2px;
      }
      &:hover {
        box-shadow: var(--card-box-shadow-hover);
      }
    }
  }

  .processing_input {
    &__container {
      display: flex;
      flex-direction: column;
      @media (max-width: 480px) {
        flex-direction: row;
        margin-top: 12px;
      }
    }
    &__header {
      display: flex;
      margin: 18px 0 12px 0;
      @media (max-width: 480px) {
        flex-wrap: wrap;
        width: 40%;
        margin: 0;
      }
      label {
        padding: 0 6px;
        width: 33.33%;
        text-align: center;
        font-size: 18px;
        line-height: 19px;
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        @media (max-width: 480px) {
          width: 100%;
          text-align: left;
          align-items: center;
          display: flex;
        }
      }
    }
    &__value-container {
      display: flex;
      @media (max-width: 480px) {
        flex-wrap: wrap;
        width: 60%;
      }
      > div {
        width: 33.33%;
        padding: 0 6px;
        position: relative;
        margin: 0;
        max-width: 100%;
        @media (max-width: 480px) {
          width: 100%;
          margin: 4px 0;
        }
      }
    }
  }
`;
