import styled from "styled-components";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .filter-list {
    .filters-shimmer-container {
      background-color: ${WHITE_COLOR};
      padding: 40px 38px;
      border-radius: 10px;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    }

    .job-alert-list {
      .job-alert-card {
        cursor: pointer;
        margin-bottom: 10px;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        .card-content {
          padding: 15px;
        }
        .title {
          font-size: 18px;
          line-height: 20px;
          font-family: "TTCommons-Medium";
          margin: 0 0 2px 0;
          color: var(--primary-dark-color);
        }
        .sub-title {
          font-size: 15px;
          line-height: 17px;
          font-family: "TTCommons-Regular";
          color: var(--field-label-color);
        }
      }

      .active {
        background-color: ${PRIMARY_COLOR};
        .title {
          color: #fff;
        }
        .sub-title {
          color: #fff;
        }
      }
    }
  }
`;
