import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { COURSES_LIST } from "../../../constant/mockdata";
import CoursesStyles from "./CoursesStyles";
import CourseIframe from "../../../components/Learn/Courses/CourseIframe/CourseIframe";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";

const Courses = ({ permissions = {} }) => {
  let query = new URLSearchParams(useLocation().search);
  const courseParam = query.get("course");
  const [allowedCourses, setAllowedCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState({});

  useEffect(() => {
    const filteredCourses = COURSES_LIST.filter(
      (course) => course.permission === true || permissions[course.permission]
    );

    setAllowedCourses([...filteredCourses]);

    if (!isEmpty(filteredCourses)) {
      let selectedCourse = filteredCourses[0];
      if (courseParam) {
        const course = filteredCourses.find(
          (course) => course.id === courseParam
        );
        if (course) selectedCourse = course;
      }
      setActiveCourse(selectedCourse);
    }
    document.body.classList.add("course-page-body");

    return () => {
      document.body.classList.remove("course-page-body");
    };
  }, [permissions]);

  return (
    <CoursesStyles>
      <div className="course-mainpage">
        <Grid container spacing={3}>
          {allowedCourses && !isEmpty(allowedCourses) ? (
            <>
              <Grid item xs={3}>
                <h2 className="title-primary text-left mb-15">Categories</h2>
                <div>
                  <div className="categories">
                    <ul>
                      {allowedCourses.map((course) => (
                        <li
                          key={course.id}
                          className={`category-item cursor-pointer ${
                            course.id === activeCourse.id && "active"
                          }`}
                        >
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveCourse(course);
                            }}
                          >
                            <div className="item">
                              <div className="cat-name">
                                <div className="header-primary">
                                  {course.name}
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className={`${activeCourse.id} iframe-content`}>
                  <h2 className="title-primary text-left mb-15">
                    {activeCourse.name}
                  </h2>
                  <CourseIframe
                    coursesList={allowedCourses}
                    activeCourse={activeCourse.id}
                  />
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <EmptyListWithImage
                title={`Looks like there are no courses for you.`}
                className={"default-empty-cover"}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </CoursesStyles>
  );
};

export default Courses;
