import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000485",
    },
    secondary: {
      main: "#F7645F",
      // main: deepOrange[500],
    },
    secondaryDisable: {
      main: "#FBAFAA",
    },
    info: {
      main: "#fff",
    },
    success: {
      main: "#8094AB",
    },
  },
  typography: {
    fontFamily: [
      '"TTCommons"',
      '"TTCommons-Thin"',
      '"TTCommons-ThinItalic"',
      '"TTCommons-Light"',
      '"TTCommons-LightItalic"',
      '"TTCommons-ExtraLight"',
      '"TTCommons-ExtraLightItalic"',
      '"TTCommons-Regular"',
      '"TTCommons-Italic"',
      '"TTCommons-Medium"',
      '"TTCommons-MediumItalic"',
      '"TTCommons-DemiBold"',
      '"TTCommons-DemiBoldItalic"',
      '"TTCommons-Bold"',
      '"TTCommons-BoldItalic"',
      '"TTCommons-ExtraBold"',
      '"TTCommons-ExtraBoldItalic"',
      '"TTCommons-Black"',
      '"TTCommons-BlackItalic"',
      '"TTCommons-Regular"',
    ].join(","),
  },
});
