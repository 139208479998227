import React, { Fragment, useMemo, memo, useEffect, useState } from "react";
import moment from "moment/moment";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./LeadershipExperience.module.scss";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import { SectionFooter } from "../../SectionFooter/SectionFooter";
import { LineDivider } from "../../Divider/Divider";
import { generateYears, months, uniqueId } from "../../../../utils/common";
import { ProfileCheckbox } from "../../Checkbox/Checkbox";
import { useAppState } from "../../../../context";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { formatOptions } from "../../../../helper/helper";

import { PRIMARY_COLOR } from "../../../../styles/variables";

const cx = createModuleStyleExtractor(styles);

const currentYear = new Date().getFullYear();
const LeadershipExperience = ({ experiences }) => {
  const {
    profile,
    setLocalExperienceFormInfo,
    removeExperience,
    addExperience,
  } = useAppState("profile");

  const { experience } = profile || {};
  const { extra_experiences: items } = experience || {};
  const experienceIds = useMemo(() => {
    if (items) return items?.map((item) => item.id);
    return [];
  }, [items]);

  const experienceCount = useMemo(() => {
    return experiences.filter((item) => !item?.remove)?.length;
  }, [experiences]);

  //extra_experiences
  const handleClick = (flag, experienceId) => {
    if (!flag) {
      //add expeerience
      const draftId = uniqueId();
      addExperience("extra_experiences", { draftId });
      return;
    }
    const draft = experienceIds.includes(experienceId);
    if (!draft) {
      removeExperience(experienceId, "extra_experiences");
      return;
    }

    setLocalExperienceFormInfo(
      experienceId,
      "remove",
      true,
      false,
      "extra_experiences"
    );
  };

  return (
    <>
      {experiences
        ?.filter((item) => !item?.remove)
        .map((experience, index) => (
          <Fragment
            key={`id-${
              experience?.id || !experience?.draftId
            }-extra-experience`}
          >
            <LeadershipExperienceItem experience={experience} index={index} />
            {experienceCount > 1 && (
              <>
                <SectionFooter
                  isRemove
                  label={"Remove experience"}
                  handleClick={(flag) =>
                    handleClick(flag, experience?.draftId ?? experience?.id)
                  }
                />
              </>
            )}
            <br />
            <LineDivider />
          </Fragment>
        ))}
      <SectionFooter
        label={"Add another experience"}
        handleClick={handleClick}
      />
      {/* <br />
      <Divider /> */}
    </>
  );
};
export default memo(LeadershipExperience);
const LeadershipExperienceItem = ({ experience, index }) => {
  const { setLocalExperienceFormInfo  } = useAppState("profile");
  const [error, setError] = useState({
    startEndDateError: null,
  });
  const {
    draftId,
    end_date,
    id,
    location,
    organization,
    remote_work,
    role,
    start_date,
    still_active_with_this_activity,
    start_month,
    start_year,
    end_month,
    end_year,
  } = experience || {};

  // Form Field Error Generator
  useEffect(() => {
    // Check if start date is before end date
    if (start_month && start_year && end_month && end_year) {
      const startDate = new Date(start_month + start_year);
      const endDate = new Date(end_month + end_year);
      if (startDate > endDate) {
        setError((current) => ({
          ...current,
          startEndDateError: "Invalid Date",
        }));
      } else {
        setError((current) => ({
          ...current,
          startEndDateError: null,
        }));
      }
    }
  }, [experience]);

  const getDefaultMonthValue = () => {
    if (start_month) return {label: start_month, value: start_month};
    // if we have graduation date
    if (start_date) {
      //if it is valid. means full formatted
      if (moment(start_date).isValid()) {
        let month = moment(start_date).format("MMMM")
        return {label: month, value: month}
      }
    }
    return {label: "", value: ""};
  };

  const getDefaultYearValue = () => {
    if (start_year) return {label: start_year, value: start_year};
    // if we have graduation date
    if (start_date) {
      //if it is valid. means full formatted
      if (moment(start_date).isValid()) {
        let year = moment(start_date).format("YYYY")
        return {label: year, value: year};
      }
    }
    return "";
  };

  const getDefaultEndMonthValue = () => {
    if (end_month) return {label: end_month, value: end_month};
    // if we have graduation date
    if (end_date) {
      //if it is valid. means full formatted
      if (moment(end_date).isValid()) {
        let endMonth = moment(end_date).format("MMMM")
        return  {label: endMonth, value: endMonth};
      }
    }
    return "";
  };

  const getDefaultEndYearValue = () => {
    if (end_year) return {label: end_year, value: end_year};
    // if we have graduation date
    if (end_date) {
      //if it is valid. means full formatted
      if (moment(end_date).isValid()) {
        let endYear = moment(end_date).format("YYYY");
        return {label: endYear, value: endYear}
      }
    }
    return "";
  };

  const updateState = (key, value) => {
    setLocalExperienceFormInfo(
      draftId ?? id,
      key,
      value,
      draftId ? true : false,
      "extra_experiences"
    );
  };

  const Checkbox = ({ handleClick, text, state, className = "" }) => {
    return (
      <div
        className={cx([
          "o-leadership-experience__check",
          state ? "o-leadership-experience__check__filled": "",
          state ? className : ""
        ])}
      >
        <div>
          <ProfileCheckbox defaultState={state} text={text} handleClick={handleClick} />
        </div>
        <div>
          <span
            className={cx(
              "o-leadership-experience__check__text"
            )}
            style={{
              ...(state && {
                color: PRIMARY_COLOR,
              }),
            }}
          >
            {text}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={cx("o-leadership-experience__grid")}>
      <div className={cx("o-leadership-experience__grid__item")}>
        <TextboxCustom
          value={organization || ""}
          label={"Organization"}
          placeholder="Enter name"
          className={
            organization
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          lableaignment="left"
          name={`Leadership Organization-${index}`}
          onChange={(e) => updateState("organization", e.target.value)}
        />
      </div>
      <div className={cx("o-leadership-experience__grid__item")}>
        <TextboxCustom
          value={role || ""}
          label={"Role"}
          placeholder="Enter your role"
          className={
            role
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          lableaignment="left"
          name={`Leadership Role-${index}`}
          onChange={(e) => updateState("role", e.target.value)}
        />
      </div>
      <div className={cx("o-leadership-experience__grid__item")}>
        <div className={cx("o-leadership-experience__grid__item__row")}>
          <div className={cx("o-leadership-experience__grid__item__row__left")}>
            <DropdownCustom
              label="Start date"
              name={`Leadership Start date-${index}`}
              options={formatOptions(months) || []}
              onChange={(item) => {
                updateState("start_month", item.value);
              }}
              isSearch={false}
              value={getDefaultMonthValue()}
              containerClassName={
                getDefaultMonthValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              placeholder="Month"
              /* noOptionsFoundLabel={"No months found..."} */
              className={`profile-react-custom-dropdown ${
                getDefaultMonthValue().value ? "border-gray" : ""
              }`}
              labelClass="justify-left"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
          <div
            className={cx("o-leadership-experience__grid__item__row__right")}
          >
            <DropdownCustom
              label="Leadership Start Year"
              name={`Leadership Start Year-${index}`}
              options={
                formatOptions(generateYears(1998, currentYear + 1)) || []
              }
              onChange={(item) => {
                updateState("start_year", item.value);
              }}
              isSearch={false}
              value={getDefaultYearValue()}
              containerClassName={
                getDefaultYearValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              placeholder="Year"
              className={`profile-react-custom-dropdown ${
                getDefaultYearValue().value ? "border-gray" : ""
              }`}
              labelClass="label-hidden"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
        </div>
      </div>
      <div className={cx("o-leadership-experience__grid__item")}>
        {!still_active_with_this_activity && (
          <div className={cx("o-leadership-experience__grid__item__row")}>
            <div
              className={cx("o-leadership-experience__grid__item__row__left")}
            >
              <DropdownCustom
                label="End date"
                name={`Leadership End date-${index}`}
                options={formatOptions(months) || []}
                onChange={(item) => {
                  updateState("end_month", item.value);
                }}
                isSearch={false}
                value={getDefaultEndMonthValue()}
                containerClassName={
                  getDefaultEndMonthValue().value
                    ? "a-dropdown-container__options__valid"
                    : ""
                }
                placeholder="Month"
                className={`profile-react-custom-dropdown ${
                  getDefaultEndMonthValue().value ? "border-gray" : ""
                }`}
                labelClass="justify-left"
                justifyContent="left"
                placeholderColor={true}
              />
            </div>
            <div
              className={cx("o-leadership-experience__grid__item__row__right")}
            >
              <DropdownCustom
                label="Leadership End Year"
                name={`Leadership End Year-${index}`}
                options={
                  formatOptions(
                    generateYears(
                      Number(start_year) ? Number(start_year) : currentYear,
                      currentYear + 1
                    )
                  ) || []
                }
                onChange={(item) => {
                  updateState("end_year", item.value);
                }}
                isSearch={false}
                value={getDefaultEndYearValue()}
                containerClassName={
                  getDefaultEndYearValue().value
                    ? "a-dropdown-container__options__valid"
                    : ""
                }
                error={error.startEndDateError}
                touched={error.startEndDateError}
                placeholder="Year"
                className={`profile-react-custom-dropdown ${
                  getDefaultEndYearValue().value ? "border-gray" : ""
                }`}
                labelClass="label-hidden"
                justifyContent="left"
                placeholderColor={true}
              />
            </div>
          </div>
        )}
        <div className={cx("o-leadership-experience__grid__item__row")}>
          <Checkbox
            text=" I currently work here"
            state={still_active_with_this_activity}
            handleClick={() =>
              updateState(
                "still_active_with_this_activity",
                !still_active_with_this_activity
              )
            }
          />
        </div>
      </div>
      <div
        className={cx([
          "o-leadership-experience__grid__item",
          remote_work ? "o-leadership-experience__grid__height0" : "",
        ])}
      >
        {!remote_work && (
          <TextboxCustom
            value={location || ""}
            label={"Location"}
            placeholder="Enter location"
            className={
              location
                ? "a-input-profile__completed custom-textbox"
                : "a-input-profile custom-textbox"
            }
            lableaignment="left"
            name={`Leadership Location-${index}`}
            onChange={(e) => updateState("location", e.target.value)}
          />
        )}
        <Checkbox
          state={remote_work}
          text="Remote work"
          handleClick={() => updateState("remote_work", !remote_work)}
          className="o-leadership-experience__check__remote"
        />
      </div>
    </div>
  );
};
