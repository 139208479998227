import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clone } from "lodash";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import ButtonCustom from "../../../../../components/ButtonCustom/ButtonCustom";
import OPTPeriodCalculator from "../../../../../components/OPTCalculator/OPTPeriodCalculator/OPTPeriodCalculator";

import {
  disableNext,
  updateAvailablePostCompletionOPT,
} from "../../../../../redux/reducers/optReducer";

import { hasNull } from "../../../../../utils/common";

import { optPeriodPlaceholder } from "../../../../../constant/mockdata";

const AvailablePostCompletionOPT = () => {
  const dispatch = useDispatch();
  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const { available_post_completion_opt = {} } = optCalculatorState;
  const { isAuthorized = false, value = null } = available_post_completion_opt; // null value is intended to keep the input clear and show placeholder
  useEffect(() => {
    if (!isAuthorized) {
      dispatch(disableNext(false));
    } else if (
      available_post_completion_opt?.value &&
      [
        ...available_post_completion_opt?.value?.map((val) => hasNull(val)),
      ]?.includes(true)
    ) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
  }, [available_post_completion_opt]);
  return (
    <>
      <OPTQueryContainer
        title={
          "Have you been authorized for any OPT at the current level of study?"
        }
      >
        <div className="test-action-btns-wrapper">
          <ButtonCustom
            color={isAuthorized ? "primary" : ""}
            className={isAuthorized ? "" : "shadow-btn"}
            onClick={() =>
              dispatch(
                updateAvailablePostCompletionOPT({
                  isAuthorized: true,
                  value: [
                    clone({ ...optPeriodPlaceholder, isPartTime: false }),
                  ],
                })
              )
            }
          >
            Yes
          </ButtonCustom>
          <ButtonCustom
            color={isAuthorized ? "" : "primary"}
            className={isAuthorized ? "shadow-btn" : ""}
            onClick={() =>
              dispatch(
                updateAvailablePostCompletionOPT({
                  isAuthorized: false,
                  value: [
                    clone({ ...optPeriodPlaceholder, isPartTime: false }),
                  ],
                })
              )
            }
          >
            No
          </ButtonCustom>
        </div>
      </OPTQueryContainer>
      {isAuthorized ? (
        <>
          <OPTQueryContainer title={"Periods of approved Full-time OPT"}>
            <OPTPeriodCalculator
              type={"approved_fulltime_opt"}
              isTypeEnabled={true}
              optPeriodList={value}
              setOPTPeriodList={({ value: updatedValue }) => {
                dispatch(
                  updateAvailablePostCompletionOPT({
                    value: updatedValue,
                  })
                );
              }}
            />
          </OPTQueryContainer>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AvailablePostCompletionOPT;
