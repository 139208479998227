import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .getstarted-wrapper {
    background: var(--primary-main-color);
    border-radius: 10px;
    max-width: 710px;
    margin: 50px auto 50px;
    display: flex;
    align-items: center;
    padding: 46px 40px 52px;
    color: #ffffff;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 30px;
    }
    .getstarted-image {
      max-width: 128px;
      img {
        max-width: 100%;
      }
    }
    .getstarted-description {
      padding-left: 35px;
      @media (max-width: 767px) {
        padding-left: 0;
        text-align: center;
        margin-top: 24px;
      }
      h1 {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        font-family: "TTCommons-Demibold";
        margin: 0 0 4px 0;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        margin: 0 0 15px 0;
      }
    }
  }
  .gameplan-loading-container {
    &__item {
      margin-bottom: 12px;
      background-color: #fff;
      padding: 35px;
    }
  }

  .text-center {
    text-align: center;
  }
  .btn-next {
    button {
      font-size: 22px;
      height: 60px;
      border-radius: 50px;
    }
    &.getstartedbtn {
      margin-top: 100px;
    }
  }
  .test-title {
    font-size: 30px;
    font-family: "TTCommons-Medium";
    color: ${PRIMARY_COLOR};
    text-align: center;
  }
  .test-sub-title {
    font-size: 22px;
    font-family: "TTCommons-Medium";
    color: ${PRIMARY_COLOR};
    text-align: center;
  }
  .test-controls {
    margin-top: 30px;
  }
  .btn-next {
    margin: 25px 0 30px 0;
  }
  .CareerGameplanDashboard {
    .MuiAccordion-root {
      padding: 28px 20px 26px;
      + .MuiAccordion-root {
        margin-top: 10px;
      }
      @media (max-width: 767px) {
        padding: 16px 15px 12px;
      }
    }
  }
`;
