import styled from "styled-components";
import {
  PRIMARY_COLOR,
  INACTIVE_BTN_GRAY_COLOR,
  SECONDARY_COLOR,
} from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .MuiAvatar-img {
    height: auto !important;
  }
  .employer-jobs-list {
    display: flex;
    .search-top {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      margin-bottom: 12px;
      .sort-by {
        span.sortby {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .react-custom-dropdown {
          color: ${PRIMARY_COLOR};
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          > div:nth-child(3) {
            margin: 0 !important;
            > div {
              margin: 0 !important;
              > div {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                margin: 0 !important;
                overflow: visible !important;
                > div {
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin: 0 !important;
                  > &:hover {
                    background: ${PRIMARY_COLOR};
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      &.employer-job {
        .content {
          .header-primary {
            align-items: center;
          }
        }
      }
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        // padding: 10px 8px 8px;
        margin: 0 10px 0 0;
      }
      .apply-btn {
        margin-left: 10px;
        .nlink-btn {
          min-width: 80px;
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          padding: 2px 8px 0;
        }
        .bg-gray {
          color: #ffffff;
          background: var(--gray-color);
          &:hover {
            background: var(--gray-color-btn-hover);
          }
          &:disabled {
            background: ${INACTIVE_BTN_GRAY_COLOR};
            color: #ffff;
          }
        }
      }
      .content {
        margin-right: auto;
        .header-primary {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0px;
          span {
            display: inline-block;
            white-space: normal;
            word-break: break-word;
          }
          .star {
            margin: -3px 0 0 10px;
          }
          .tag-item {
            background: #e5eaf7;
            border-radius: 10.5px;
            background-color: #e5eaf7;
            color: ${PRIMARY_COLOR};
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            display: inline-block;
            padding: 3px 8px 0px;
            margin: -3px 0 0 10px;
            white-space: nowrap;
          }
        }
        .sub-header-primary {
          font-size: 15px;
          -webkit-letter-spacing: 0;
          -moz-letter-spacing: 0;
          -ms-letter-spacing: 0;
          letter-spacing: 0;
          line-height: 17px;
          color: ${PRIMARY_COLOR};
          margin: 0 0 3px 0;
        }
      }
      .apply-btn {
        button {
          border-radius: 45px;
          background-color: ${SECONDARY_COLOR};
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 74px;
          line-height: 30px;
          height: auto;
          padding: 0;
          box-shadow: none;
          font-family: "TTCommons-Regular";
          a {
            text-decoration: none;
            color: #fff;
            font-family: "TTCommons-Regular";
          }
        }
      }
    }
    .card-wrapper {
      .MuiPaper-root {
        padding: 25px 25px !important;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        .MuiCardContent-root {
          padding: 0;
        }
      }
    }
  }
`;
