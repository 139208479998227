import React, { memo } from "react";

function WidgetNetwork() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke="#000C3D" stroke-width="1.2">
          <path
            d="M19.3613376,25.7883931 C20.2530643,28.9087453 23.1804621,31.1967254 26.6550001,31.1967254 L34.2269471,31.1967254 L34.2269471,23.789624 C34.2269471,19.6983679 30.8366818,16.3819106 26.6550001,16.3819106 C25.9355306,16.3819106 25.2392093,16.4804434 24.5804255,16.6634331"
            id="Stroke-1"
          ></path>
          <path
            d="M14.8118559,26.4271906 L5,26.4271906 L5,16.7135953 C5,11.3485998 9.3931824,7 14.8118559,7 C20.2305293,7 24.6237117,11.3485998 24.6237117,16.7135953 C24.6237117,22.0780036 20.2305293,26.4271906 14.8118559,26.4271906 Z"
            id="Stroke-7"
          ></path>
          <path
            d="M25.5,24.462963 L29.396875,24.462963"
            id="Path-9"
            fill="#000C3D"
          ></path>
          <path
            d="M9.6875,14.635299 L18.684428,14.635299"
            id="Path-10"
            fill="#000C3D"
          ></path>
          <path
            d="M9.6875,19.462963 L14.8118559,19.462963"
            id="Path-10"
            fill="#000C3D"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetNetwork);
