import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import SaveJobList from "../../../components/Jobs/SaveJobsList/SaveJobsList";
import FilterList from "../../../components/Jobs/FilterList/FilterList";
import SearchList from "../../../components/Jobs/SearchResult/SearchList";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import { US_VISA_LIST_DOMESTIC_VIEW } from "../../../constant/mockdata";
import {
  getSavedSearchesAPI,
  searchJobsAPI,
  searchTopEmployerJobsAPI,
  getFeaturedJobsAPI,
} from "../../../services/JobServices";
import { getFilteredEmployerJobsAPI } from "../../../services/EmployerJobServices";
import { US_STATE_OPTIONS } from "../../../constant/mockdata";

const Alert = () => {
  const [savedJobSearchesList, setSavedJobSearchesList] = useState(null);

  const [callSavedJobSearchesAPI, refreshSavedJobSearchesAPI] =
    usePromise(getSavedSearchesAPI);
  const [selectedSearch, setSelectedSearch] = useState(null);

  const { jobsCountryList = [] } = useSelector((store) => store.jobStore);
  const { users = {} } = useSelector((store) => store.userStore);
  const { jobs = [], setJobs } = useAppState("job");
  const [sortBy, setSortBy] = useState("date");
  const [savedJobsFilter, setSavedJobsFilter] = useState({});
  const [savedFeaturedJobsFilter, setSavedFeaturedJobsFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [callSearchJobsAPI, refreshSearchJobsAPI] = usePromise(searchJobsAPI);
  const [callSearchTopEmployerJobsAPI, refreshSearchTopEmployerJobsAPI] =
    usePromise(searchTopEmployerJobsAPI);
  const [showLoader, setLoader] = useState(true);
  const [adminJobsList, setAdminJobsList] = useState([]);
  const [callAdminJobsAPI, refreshAdminJobsAPI] =
    usePromise(getFeaturedJobsAPI);
  const [employerJobsList, setEmployerJobsList] = useState([]);
  const [callEmployerJobsAPI, refreshEmployerJobsAPI] = usePromise(
    getFilteredEmployerJobsAPI
  );

  let query = new URLSearchParams(useLocation().search);
  let backPage = query.get("from");

  useEffect(() => {
    setSortBy("date");
    setJobs([]);
    refreshSavedJobSearchesAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callSavedJobSearchesAPI.hasFetched() &&
      callSavedJobSearchesAPI.hasErrors() === false
    ) {
      if (
        callSavedJobSearchesAPI.data() &&
        callSavedJobSearchesAPI.data().data &&
        callSavedJobSearchesAPI.data().data.saved_jobs
      ) {
        setSavedJobSearchesList(callSavedJobSearchesAPI.data().data.saved_jobs);
        if (isEmpty(callSavedJobSearchesAPI.data().data.saved_jobs))
          setLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSavedJobSearchesAPI.isFetching()]);

  useEffect(() => {
    if (selectedSearch && selectedSearch !== null) {
      applyFilters(selectedSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch]);

  const applyFilters = (params = {}) => {
    setJobs([]);
    setLoader(true);
    const {
      keyword,
      country,
      state,
      city,
      job_type,
      publish_date,
      search_type,
      company,
      position,
      experience,
      degree,
      job_search_type,
      sort,
    } = params;

    const defaultParams = {
      job_region: country && country === "us" ? "us" : "international",
      search: keyword === null ? "" : keyword,
      country,
      visa:
        country !== null && country === "us" && search_type !== null
          ? search_type
          : "",
      state:
        country !== null && country === "us" && state !== null ? state : "",
      city,
      job_type,
      publish_date,
      degree,
      experience,
      job_search_type,
      company,
      position,
      sort,
      page: 1,
    };

    /* Featured Job **/
    const featuredJobsParams = {
      search: defaultParams.search !== "" ? defaultParams.search : null,
      city: defaultParams.city !== "" ? defaultParams.city : null,
      publish_date:
        defaultParams.publish_date && defaultParams.publish_date !== ""
          ? publish_date
          : null,
      job_type: defaultParams.job_type,
    };

    if (!isNull(defaultParams.country) && jobsCountryList.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.value === defaultParams.country
      );
      if (selectedCountry) featuredJobsParams.country = selectedCountry.label;
    }

    if (country && country === "us") {
      const selectedState =
        state !== null
          ? US_STATE_OPTIONS.find((rec) => rec.value === defaultParams.state)
          : null;

      if (selectedState && selectedState !== null)
        featuredJobsParams.state = selectedState.value;

      if (
        selectedState &&
        selectedState !== null &&
        (defaultParams.visa === "top_500_employers" ||
          defaultParams.visa === "top_1000_employers" ||
          defaultParams.visa === "cap_exempt" ||
          defaultParams.visa === "sponsered_last_year" ||
          defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[0].value ||
          defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[1].value ||
          defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[2].value)
      ) {
        defaultParams.state = selectedState.value;
      } else if (defaultParams.visa === "all_companies") {
        if (selectedState && selectedState !== null) {
          defaultParams.location = `${
            city && city !== null && city.length > 0 ? city : ""
          }${
            city &&
            city !== null &&
            city.length > 0 &&
            selectedState &&
            selectedState !== null &&
            selectedState.label
              ? ", "
              : ""
          }${
            selectedState && selectedState.label ? `${selectedState.label}` : ""
          }`;
        } else {
          defaultParams.location = `${
            city && city !== null && city.length > 0 ? city : ""
          }`;
        }
      }
    } else {
      defaultParams.location = `${
        city && city !== null && city.length > 0 ? city : ""
      }`;
    }

    setCurrentPage(1);

    /* Featured Jobs**/
    setEmployerJobsList([]);
    setAdminJobsList([]);

    // No featured jobs for sponsered_last_year, cap_exempt visa type + experience, degree and position filter
    if (
      !(
        defaultParams.visa === "sponsered_last_year" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.experience ||
        defaultParams.degree ||
        defaultParams.job_type
      )
    ) {
      refreshEmployerJobsAPI(users?.id, {
        ...featuredJobsParams,
        sort: sortBy,
      });
      refreshAdminJobsAPI({
        ...featuredJobsParams,
        sort: sortBy,
      });
    }

    if (
      defaultParams.country === "us" &&
      (defaultParams.visa === "top_1000_employers" ||
        defaultParams.visa === "top_500_employers" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.visa === "sponsered_last_year" ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[0].value ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[1].value ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[2].value)
    ) {
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: sortBy });
    } else {
      refreshSearchJobsAPI({ ...defaultParams, sort: sortBy });
    }

    setSavedJobsFilter(defaultParams);
    setSavedFeaturedJobsFilter(featuredJobsParams);
  };

  useEffect(() => {
    if (
      callEmployerJobsAPI.hasFetched() &&
      callEmployerJobsAPI.hasErrors() === false
    ) {
      if (callEmployerJobsAPI.data() && callEmployerJobsAPI.data().data) {
        const { employer_jobs = [] } = callEmployerJobsAPI.data().data;
        setEmployerJobsList(employer_jobs);
      } else {
        setEmployerJobsList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEmployerJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callAdminJobsAPI.hasFetched() &&
      callAdminJobsAPI.hasErrors() === false
    ) {
      if (
        callAdminJobsAPI.data() &&
        callAdminJobsAPI.data().data &&
        callAdminJobsAPI.data().data.jobs
      ) {
        setAdminJobsList(callAdminJobsAPI.data().data.jobs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAdminJobsAPI.isFetching()]);

  const getPaginationData = () => {
    const defaultParams = {
      ...savedJobsFilter,
      page: currentPage + 1,
    };

    setCurrentPage(currentPage + 1);
    if (
      (defaultParams.country === "us" &&
        defaultParams.visa === "top_500_employers") ||
      defaultParams.visa === "sponsered_last_year"
    )
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: sortBy });
    else refreshSearchJobsAPI({ ...defaultParams, sort: sortBy });
  };

  const updateSortBy = (newSortBy) => {
    setJobs([]);
    const defaultParams = {
      ...savedJobsFilter,
      page: 1,
    };
    setLoader(true);
    setSortBy(newSortBy);

    /* Featured Jobs**/
    refreshEmployerJobsAPI(users?.id, {
      ...savedFeaturedJobsFilter,
      sort: newSortBy,
    });
    refreshAdminJobsAPI({
      ...savedFeaturedJobsFilter,
      sort: newSortBy,
    });

    if (
      (defaultParams.country === "us" &&
        defaultParams.visa === "top_500_employers") ||
      defaultParams.visa === "sponsered_last_year"
    )
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: newSortBy });
    else refreshSearchJobsAPI({ ...defaultParams, sort: newSortBy });
  };

  useEffect(() => {
    if (
      callSearchJobsAPI.hasFetched() &&
      callSearchJobsAPI.hasErrors() === false &&
      callSearchJobsAPI.data() &&
      callSearchJobsAPI.data().data
    ) {
      setTotalPages(callSearchJobsAPI.data().data.total_pages);
      setJobs([...jobs, ...callSearchJobsAPI.data().data.jobs]);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callSearchTopEmployerJobsAPI.hasFetched() &&
      callSearchTopEmployerJobsAPI.hasErrors() === false &&
      callSearchTopEmployerJobsAPI.data() &&
      callSearchTopEmployerJobsAPI.data().data
    ) {
      setTotalPages(callSearchTopEmployerJobsAPI.data().data.total_pages);
      const formattedJobs = callSearchTopEmployerJobsAPI
        .data()
        .data.jobs.map((rec) => {
          const formattedRec = { ...rec, ...rec.api_response };
          delete formattedRec.api_response;
          return formattedRec;
        });

      setJobs([...jobs, ...formattedJobs]);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchTopEmployerJobsAPI.isFetching()]);

  const fetchMoreData = () => {
    getPaginationData();
  };

  return (
    <div className="alert-mainpage">
      <div className="job-alert">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FilterList
              searchesList={savedJobSearchesList}
              applySearch={setSelectedSearch}
              loading={callSavedJobSearchesAPI.isFetching()}
              backPage={backPage}
            />
            <div className="ShowResponsiveDiv">
              <SaveJobList />
            </div>
          </Grid>
          <Grid item xs={6}>
            {showLoader ||
            callEmployerJobsAPI.isFetching() ||
            callAdminJobsAPI.isFetching() ? (
              <JobsListingEffect count={5} />
            ) : (
              <SearchList
                showFeaturedJobs={true}
                source="alert"
                subTitle="New Job listing"
                showSortBy={false}
                fetchMoreData={fetchMoreData}
                totalPages={totalPages}
                currentPage={currentPage}
                sortBy={sortBy}
                updateSortBy={updateSortBy}
                employerJobsList={employerJobsList}
                adminJobsList={adminJobsList}
                showPaginationLoader={
                  callEmployerJobsAPI.isFetching() ||
                  callAdminJobsAPI.isFetching() ||
                  callSearchJobsAPI.isFetching() ||
                  callSearchTopEmployerJobsAPI.isFetching()
                }
                isJobAlertList={true}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <SaveJobList />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Alert;
