import styled from "styled-components";
import { PRIMARY_COLOR, GRAY_COLOR } from "../../../../../styles/variables";

// notification-switch-container => minor padding added to have tooltip interactions work smoothly
export default styled.div.attrs({ className: "" })`
  .info-card {
    margin-bottom: 20px;
    min-height: 103px;
    display: flex;
    width: 100%;
    align-items: center;
    .card-content {
      padding: 20px 25px;
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 50px;

      .panel-info {
        display: flex;
        align-items: center;
        .notification-switch-container {
          padding: 2px;
        }
        .panel-avatar {
          display: flex;
          align-items: center;
          height: 50px;
          width: 50px;
          border-radius: 100px;
          overflow: hidden;
          min-width: 50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .info-description {
          margin-left: 10px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          .d-flex {
            @media (max-width: 600px) {
              flex-wrap: wrap;
            }
          }
          .title {
            font-size: 18px;
            font-family: "TTCommons-Medium";
            color: var(--primary-dark-color);
            @media (max-width: 600px) {
              margin-bottom: 4px;
            }
          }
          .sub-title {
            margin-top: 2px;
            font-size: 15px;
            font-family: "TTCommons-Regular";
            color: ${GRAY_COLOR};
          }
        }
        .panel-tabs {
          margin-left: 10px;
          @media (max-width: 600px) {
            margin-left: 0;
          }
          .tabs-items {
            border-radius: 10.5px;
            background-color: #e5eaf7;
            padding: 0;
            color: ${PRIMARY_COLOR};
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
            padding: 4px 10px 1px 10px;
            box-sizing: border-box;
            margin: -4px 0 0 0;
            text-transform: capitalize;
          }
        }
      }
      .panel-action {
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .MuiTypography-root {
          .MuiSwitch-root {
            + .text-gray,
            + .text-primary {
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              padding: 3px 0 0 0;
            }
          }
        }
        .ncustom-switch {
          .MuiSwitch-root {
            margin: 0px 0 0 0;
          }
        }
        button {
          height: auto;
          padding: 6px 0 3px 0;
          line-height: normal;
        }
      }
    }
  }
`;
