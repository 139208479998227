import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const listCountriesAPI = async () => {
  return axiosInstance
    .get(`country_insights/search`, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const countryDetailsAPI = async (id) => {
  return axiosInstance
    .get(`country_insights/${id}`, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const cityDetailsAPI = async (id) => {
  return axiosInstance
    .get(`city_guides/${id}`, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const getVisaRequirementsAPI = async (citizen_of, travelling_to) => {
  return axiosInstance
    .get(
      `country_insights/visa_hq?to=${travelling_to}&passport=${citizen_of}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data.data;
    });
};
