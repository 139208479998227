import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
    .empty-save-box{
      padding: 0;
      text-align: start;
      .empty-item {
        padding: 32px 15px 28px;
        text-align: center;
      }
      .header-primary {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          margin: 0 0 10px 0;
          &:last-child{
            margin-bottom: 0;
          }
      }
      .text-grey {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          font-family: "TTCommons-Medium";
      }
`;
