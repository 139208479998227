import React, { useState, useEffect } from "react";
import { Avatar, Tooltip } from "@mui/material";

import usePromise from "../../../hooks/usePromise/usePromise";
import { followUnfollowTopicAPI } from "../../../services/networkServices";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { capitalizeFirstLetter, toastify } from "../../../helper/helper";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";
import CardCustom from "../../CardCustom/CardCustom";
import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SuggestedTopics.module.scss";

import arrowIcon from "../../../assets/svg/f-go-arrow.svg";

const cx = createModuleStyleExtractor(styles);

export default function SuggestedTopics({ refreshGetMyTopicsAPI = () => {} }) {
  const { suggestedTopics, postViewSuggestedTopic, activeFilterTopics } =
    useAppState("topic");
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const [callingFollowUnfollowTopicAPI, refreshFollowUnfollowTopicAPI] =
    usePromise(followUnfollowTopicAPI);

  useEffect(() => {
    if (callingFollowUnfollowTopicAPI.hasFetched()) {
      if (callingFollowUnfollowTopicAPI.hasErrors() === false) {
        if (
          callingFollowUnfollowTopicAPI.data() &&
          callingFollowUnfollowTopicAPI.data().message
        ) {
          toastify("success", callingFollowUnfollowTopicAPI.data().message);
          refreshGetMyTopicsAPI(activeFilterTopics);
        }
      } else {
        if (
          callingFollowUnfollowTopicAPI.data() &&
          callingFollowUnfollowTopicAPI.data().message
        ) {
          toastify("error", callingFollowUnfollowTopicAPI.data().message);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFollowUnfollowTopicAPI.isFetching()]);

  const handleViewTopic = (topic) => {
    postViewSuggestedTopic(topic);
  };

  const followTopic = (topicId) => {
    refreshFollowUnfollowTopicAPI(topicId);
  };

  return (
    <>
      {suggestedTopics?.length > 0 && (
        <div className={cx("suggested-topics")}>
          <h3 className={cx("suggested-topics__title")}>
            Suggested Communities for you
          </h3>
          <div className={cx("suggested-topics__list")}>
            {suggestedTopics?.map((topic, index) => (
              <div
                className={cx([
                  "suggested-topics__list__item",
                  selectedCardIndex === index
                    ? "suggested-topics__list__item--selected"
                    : "",
                ])}
                onClick={() =>
                  setSelectedCardIndex((current) =>
                    current === index ? null : index
                  )
                }
              >
                <CardCustom>
                  <div className={cx("suggested-topics__list__item__body")}>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__title"
                      )}
                    >
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__title__avatar"
                        )}
                      >
                        {topic?.topic_icon?.length > 0 ? (
                          <Avatar src={topic?.topic_icon} />
                        ) : (
                          <InitialAvatar
                            index={index}
                            title={topic?.topic_name}
                          />
                        )}
                      </div>
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__title__text"
                        )}
                      >
                        <Tooltip title={topic?.topic_name}>
                          <div
                            className={cx(
                              "suggested-topics__list__item__body__title__text__line-one"
                            )}
                          >
                            {topic?.topic_name?.length > 60
                              ? topic?.topic_name?.slice(0, 60) + "..."
                              : topic?.topic_name}
                          </div>
                        </Tooltip>
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__title__text__line-two"
                          )}
                        >
                          {`${topic.total_participants} members${
                            topic?.participants_from_school > 0
                              ? ` (${topic?.participants_from_school} from your school)`
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__labels"
                      )}
                    >
                      <span
                        className={cx(
                          "suggested-topics__list__item__body__labels__type"
                        )}
                      >
                        {capitalizeFirstLetter(topic?.topic_type)}
                        {" | "}
                        {topic?.global ? "All schools" : "My school"}
                      </span>
                      {topic?.topic_portal_type === "admissions" && (
                        <div
                          className={cx([
                            "suggested-topics__list__item__body__labels__tag",
                            "suggested-topics__list__item__body__labels__tag--ambassador",
                          ])}
                        >
                          Admissions
                        </div>
                      )}
                    </div>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__description"
                      )}
                    >
                      <Tooltip title={topic?.topic_description}>
                        <p>
                          {topic?.topic_description?.length > 80
                            ? topic?.topic_description?.slice(0, 80) + "..."
                            : topic?.topic_description}
                        </p>
                      </Tooltip>
                    </div>
                    {selectedCardIndex === index && (
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__actions"
                        )}
                      >
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__actions__view-topic"
                          )}
                        >
                          <ButtonCustom
                            width={130}
                            height={50}
                            onClick={() => handleViewTopic(topic)}
                          >
                            <div
                              className={cx(
                                "suggested-topics__list__item__body__actions__view-topic__body"
                              )}
                            >
                              <span>View</span>
                              <img src={arrowIcon} alt="arrow" />
                            </div>
                          </ButtonCustom>
                        </div>
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__actions__request"
                          )}
                        >
                          <ButtonCustom
                            width={130}
                            height={50}
                            isDisabled={
                              topic.following_status === "requested"
                                ? true
                                : false
                            }
                            onClick={() => followTopic(topic.id)}
                            isSubmitting={callingFollowUnfollowTopicAPI.isFetching()}
                          >
                            <span>
                              {capitalizeFirstLetter(topic.following_status)}
                            </span>
                          </ButtonCustom>
                        </div>
                      </div>
                    )}
                  </div>
                </CardCustom>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
