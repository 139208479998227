import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .company-mainpage {
    .search-list {
      .search-title {
        .title-primary {
          display: flex;
          align-items: center;
          svg {
            width: 21px;
            height: 21px;
            margin: -5px 0 0 0;
          }
        }
      }
    }
    .back-btn-wrapper {
      margin-top: -14px;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        margin-bottom: 16px;
      }
    }
  }
`;
