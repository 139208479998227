import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="18"
      viewBox="0 0 13 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#BFCED8" fillRule="nonzero" transform="translate(-706 -424)">
          <g transform="translate(629 423)">
            <g transform="translate(77)">
              <g transform="translate(0 1)">
                <path d="M5.698 17.584C3.93 15.323 0 9.842 0 6.764 0 3.027 2.91 0 6.5 0 10.089 0 13 3.028 13 6.763c0 3.079-3.96 8.56-5.698 10.821a.998.998 0 01-1.604 0zM6.5 9.018c1.195 0 2.167-1.011 2.167-2.255 0-1.243-.972-2.254-2.167-2.254-1.195 0-2.167 1.01-2.167 2.254S5.305 9.018 6.5 9.018z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
