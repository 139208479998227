import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .RSPBprogressBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    width: auto !important;
    height: auto !important;
    background: transparent !important;
    margin-top: 0;
    .RSPBstep {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      transform: none !important;
      position: relative !important;
      left: initial !important;
      .transitionStep {
        .progress-item {
          position: relative;
          z-index: 5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 25px;
          border-radius: 12.5px;
          background: #eaeef3;
          margin-bottom: 6px;
          @media (max-width: 767px) {
            margin-bottom: 0;
          }
          &:after {
            content: "";
            width: 15px;
            height: 15px;
            background: #fff;
            border-radius: 100px;
          }
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          left: 50%;
          z-index: 3;
          height: 25px;
          top: 0;
          background: #eaeef3;
        }
        &.completed {
          .progress-item {
            background-color: var(--primary-main-color);
            &:before {
              content: "";
              position: absolute;
              left: 14px;
              top: 8px;
              width: 4px;
              height: 8px;
              border: solid var(--primary-main-color);
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &:after {
            background: var(--primary-main-color);
          }
        }
        &.active {
          .progress-item {
            background-color: var(--primary-main-color);
          }
        }
      }
      .progress-label {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-family: "TTCommons-Regular";
        min-height: 34px;
        word-break: initial;
        color: var(--primary-dark-color);
        @media (max-width: 767px) {
          font-size: 0;
          min-height: auto;
          display: none;
        }
      }
      &:first-child {
        .transitionStep.active {
          .progress-item {
            width: 35px;
            padding: 0 13px 0 7px;
          }
        }
      }
    }
    .RSPBprogression {
      display: none;
    }
  }
`;
