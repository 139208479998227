import styled from "styled-components";
import {
  PRIMARY_COLOR,
  BLUE_COLOR_02,
  MEDIUM_GRAY_COLOR,
} from "../../../../styles/variables";
export default styled.div.attrs({ className: "" })`
  .chat-search-box-container {
    display: flex;
    flex-direction: column;
    position: relative;
    &__input {
      display: flex;
      align-items: flex-start;
      &__clear {
        position: absolute;
        right: 10px;
        top: 14px;
        svg {
          cursor: pointer;
          width: 18px !important;
          fill: ${PRIMARY_COLOR};
        }
      }
    }
  }
  .loading-container {
    background: white;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 55px;
    border-radius: 6px;
  }
  .dropdown-container {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    position: absolute;
    margin-top: 8px;
    top: 100%;
    width: 100%;
    z-index: 1;
    &__content {
      position: initial !important;
      max-height: calc(100vh - 180px);
      overflow-y: scroll;
      -webkit-scrollbar-width: thin;
      -moz-scrollbar-width: thin;
      -ms-scrollbar-width: thin;
      scrollbar-width: thin;
      display: block;
      overflow-y: auto;
      margin: auto;
      width: 100%;
      overflow-x: hidden;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
      }
      &::-webkit-scrollbar-track {
        background: ${BLUE_COLOR_02} !important; /* color of the tracking area */
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${MEDIUM_GRAY_COLOR} !important; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: none;
        height: 150px !important;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
      }
      .load-more-search-messages {
        padding: 15px;
        text-align: center;
        cursor: pointer;
        color: ${PRIMARY_COLOR};
        font-family: "TTCommons-Regular";
        font-weight: 600;
      }
      .content-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d1dde5;
        &:hover {
          background-color: #ececff;
          cursor: pointer;
        }
        &__message {
          font-size: 14px;
          font-style: normal;
          color: ${PRIMARY_COLOR};
          font-family: "TTCommons-Regular";
        }
        &__title {
          margin-top: 8px;
          text-align: right;
          .divider {
            color: ${PRIMARY_COLOR} !important;
            font-family: "TTCommons-Medium" !important;
            font-weight: 500 !important;
            margin: 0px 3px 0 2px;
            position: relative;
            top: -1px;
            &::before {
              content: "";
            }
            &::after {
              content: "";
            }
          }
          span {
            font-size: 14px;
            color: var(--secondary-color);
            font-style: italic;
            font-family: "TTCommons-Medium" !important;
            font-weight: 500;
            &::before {
              content: open-quote;
            }
            &::after {
              content: close-quote;
            }
          }
        }
        .chat-user-list-item {
          display: flex;
          align-content: space-between;
          align-items: center;
          gap: 1rem;
          &.__avatar-wrapper {
            height: 30px;
          }
          h4 {
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
      }
      .people-container {
        padding: 6.5px;
      }
      .content-title-container {
        padding: 10px 10px 7.5px 10px;
        border-bottom: 1px solid #d1dde5;
        font-size: 16px;
        font-style: bold;
        color: var(--gray-color);
        font-family: "TTCommons-Medium";
      }
    }
  }
`;
