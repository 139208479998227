import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import CustomDatePicker from "../../../../../components/CustomDatePicker/CustomDatePicker";

import FormI765DeadlineStyles from "./FormI765DeadlineStyles";

import {
  disableNext,
  updateFormI765Deadline,
} from "../../../../../redux/reducers/optReducer";

import { isFormattedDateValid } from "../../../../../utils/common";

const FormI765Deadline = () => {
  const dispatch = useDispatch();

  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const { form_i765_filing_deadline = {} } = optCalculatorState;
  const { value = null } = form_i765_filing_deadline; // null value is intended to keep the input clear and show placeholder

  useEffect(() => {
    if (!value) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
  }, [value]);
  const handleDateChange = (date) => {
    dispatch(
      updateFormI765Deadline({
        value: isFormattedDateValid(date, "LL") ? date : null,
      })
    );
  };
  return (
    <FormI765DeadlineStyles>
      <OPTQueryContainer title={" Enter the date the DSO recommended OPT:"}>
        <CustomDatePicker
          dateFormat={"MMMM dd, yyyy"}
          width={200}
          type="date"
          onChange={handleDateChange}
          value={value !== null ? new Date(value) : null}
          placeholder="Enter date"
          isClearable
          name={"opt_dso_recommended_date"}
        />
      </OPTQueryContainer>
    </FormI765DeadlineStyles>
  );
};

export default FormI765Deadline;
