import React, { useCallback, useState } from "react";

import { createModuleStyleExtractor } from "../../../../utils/css";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import styles from "./CareerIntro.module.scss";
import Textarea from "../../../atoms/Textarea/Textarea";
import CustomUploadV2 from "../../../CustomUploadV2/CustomUpload";
import { toastify } from "../../../../helper/helper";
import { useAppState } from "../../../../context";
import {
  notEmpty,
  validateLinkedinURL,
  validateResume,
} from "../../../../utils/common";
import { updateResume, removeResume } from "../../../../services/profile.service";

import cvIcon from "../../../../assets/svg/profile-upload-cv.svg";
import { isUndefined } from "lodash";

const cx = createModuleStyleExtractor(styles);

export const CareerIntro = ({ data }) => {
  const {
    profile,
    updateCareerForm,
    updateBulkCareerForm,
    setProfile,
    careerForm,
  } = useAppState("profile");
  const { isProfileCompleted } = profile || {};
  const {
    resume_file_name,
    linkedin_public_url,
    resume_bio,
    resume_url,
  } = data || {};
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);

  const uploadResume = async (data) => {
    try {
      setUpdating(true);
      const response = await updateResume(data);
      if (response?.success) {
        setProfile({
          ...profile,
          career: {
            ...careerForm,
            resume_file_name: response?.resume_title,
            resume_url: response?.resume_url,
          },
        });
        const _date = {
          ...careerForm,
          resume_file_name: response?.resume_title,
          resume_url: response?.resume_url,
        };

        updateBulkCareerForm(_date);
        toastify("success", "Resume updated successfully");
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  };
  const handleCallback = useCallback(
    (file) => {
      if (file?.length < 1) return;
      const item = file[0];
      const response = validateResume(item);
      if (response !== 200) {
        toastify("error", response);
        return;
      }

      const formData = new FormData();
      formData.append("resume", item);
      uploadResume(formData);
    },
    [careerForm]
  );

  const removeUploadFile = async (e) => {
    if(!isUndefined(e)) e.preventDefault();
    try{
      setRemoving(true);
      const response = await removeResume();
      if (response?.success) {
        setProfile({
          ...profile,
          career: {
            ...careerForm,
            resume_file_name: "",
            resume_url: "",
          },
        });
        const _date = {
          ...careerForm,
          resume_file_name: "",
          resume_url: "",
        };

        updateBulkCareerForm(_date);
        toastify("success", "Resume removed successfully");
        setRemoving(false);
      }

    } catch (error) {
      setUpdating(false);
    }

  };
  return (
    <div
      className={cx([
        isProfileCompleted
          ? "o-career-containter-profile"
          : "o-career-container",
      ])}
    >
      <div className={cx("o-career-container__top")}>
        <div>
          <div className={cx("o-career-container__profile")}>
            <span className={cx("o-career-container__profile__heading")}>
              Upload resume
            </span>

            <div className={cx("o-career-container__profile__upload")}>
              <div className={cx("o-career-container__profile__upload__icon")}>
                <img src={cvIcon} alt="PDF"/>
              </div>
              <div
                className={cx("o-career-container__profile__upload__inside")}
              >
                <CustomUploadV2
                  handleUploadFile={handleCallback}
                  handleRemoveFile={removeUploadFile}
                  uploadText="Upload resume"
                  removeText="Remove resume"
                  vallidFiles="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  preview={resume_file_name}
                />
              </div>
            </div>
            {resume_file_name && (
              <div className={cx("o-career-container__profile-resume-name")}>
                <a target="_blank" rel="noopener noreferrer" href={resume_url} aria-label="Resume file">
                  <span>{resume_file_name}</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className={cx(["o-career-container__top__right"])}>
          <TextboxCustom
            label={"Connect your LinkedIn profile"}
            name={"LinkedIn profile"}
            className={
              linkedin_public_url ? "a-input-profile__completed custom-textbox" : "a-input-profile custom-textbox"
            }
            placeholder={"Enter LinkedIn URL"}
            lableaignment="left"
            value={linkedin_public_url || ""}
            onChange={(e) =>
              updateCareerForm("linkedin_public_url", e.target.value)
            }
            required
            error={
              linkedin_public_url && !validateLinkedinURL(linkedin_public_url)
            }
            errorText={linkedin_public_url && !validateLinkedinURL(linkedin_public_url) ? "Invalid Profile URL" : ""}
          />
        </div>
      </div>
      <div className={cx("o-career-container__top-footer")}>
        <div className={cx("o-career-container__top-footer__inside")}>
          <span
            className={cx("o-career-container__top-footer__inside__heading")}
          >
            Professional summary
          </span>

          <Textarea
            className={resume_bio ? "a-textarea__valid": ""}
            name="Professional summary"
            label="Professional summary"
            defaultValue={resume_bio}
            placeholder="What are you interested in academically and professionally? Highlight a top skill or achievement, and inject some personality! Think of this as your elevator pitch to your peers and potential employers!"
            onChange={(summary) => updateCareerForm("resume_bio", summary)}
            validator={notEmpty}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};
