import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .label {
    display: flex;
    justify-content: center;
    font-size: 15px;
    color: ${PRIMARY_COLOR};
    font-family: "TTCommons-Regular";
    margin: 3px;
  }
  .custom-options-list {
    margin: 0;
    padding: 0;
    width: 100%;

    a {
      box-shadow: var(--card-box-shadow);
      border-radius: 40px;
      background-color: #ffffff;
      padding: 18px 15px 13px;
      text-decoration: none;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      color: var(--primary-dark-color);
      min-height: 50px;
      display: inline-block;
      width: 100%;
      text-align: center;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      &.active {
        background: var(--primary-main-color);
        color: #fff;
      }
    }
    > .MuiGrid-grid-xs-12 {
      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
`;
