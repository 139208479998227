import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const createAppointment = async (
  userId,
  adminId,
  title,
  description,
  meetingLocation,
  meetingLink,
  startTime,
  endTime,
  appointmentDate,
  /* amount, */
  timezone,
  timezoneId
) => {
  let appointment = {};
  appointment["title"] = title;
  appointment["description"] = description;
  appointment["status"] = "pending";
  appointment["meeting_location"] = meetingLocation;
  appointment["meeting_link"] = meetingLink;
  appointment["start_time"] = startTime;
  appointment["end_time"] = endTime;
  appointment["appointment_date"] = appointmentDate;
  let requestData = {
    admin_id: adminId,
    "appointed_user_ids": [userId],
    /* amount: amount, */
    timezone: timezone,
    timezone_id: timezoneId,
    appointment
  };
  return await axiosInstance
    .post("/appointments", requestData, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const updateAppointment = async (
  userId,
  adminId,
  startTime,
  endTime,
  appointmentDate,
  timezone,
  id,
  timezoneId
) => {

  let appointment = {};
  appointment["start_time"] = startTime;
  appointment["end_time"] = endTime;
  appointment["appointment_date"] = appointmentDate;

  let requestData = {
    admin_id: adminId,
    "appointed_user_ids": [userId],
    timezone: timezone,
    timezone_id: timezoneId,
    appointment
  };
  return await axiosInstance
    .put(`/appointments/${id}`, requestData, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const getAppointmentById = async (id, timezone, timezone_id) => {
  let requestData = { timezone, timezone_id };
  return await axiosInstance
    .post(`/appointments/${id}`, requestData, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const getAppointments = async (status) => {
  if (status) {
    return await axiosInstance
      .get(`/appointments?status=${status}`, headers_with_token())
      .then((res) => {
        return res.data.data;
      });
  } else {
    return await axiosInstance
      .get("/appointments", headers_with_token())
      .then((res) => {
        return res.data.data;
      });
  }
};

export const deleteAppointment = async () => {
  return await axiosInstance
    .delete("/appointments", headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const getTimezones = async () => {
  return await axiosInstance
    .get("hours_settings/get_rails_timezone", headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};


export const getCode = async (code, clientId, clientSecret, redirectUrl) => {
  let requestData = {
    code: code,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUrl,
    grant_type: "authorization_code"
  };
  let formBody = [];
  for (let property in requestData) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(requestData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return await axiosInstance
    .post(`https://www.googleapis.com/oauth2/v4/token`, formBody, {headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Origin': '*',
      'Access-Control-Allow-Origin': window.location.origin
    }})
    .then((res) => {
      return res;
    });

};


export const calenderIntegration = async (data) => {
  return await axiosInstance
    .post(`/book_appointments/create_calendar_settings`, data, headers_with_token())
    .then((res) => {
      console.log(res)
      return res.data;
    });
};


export const GetConnectedPlatforms = async (data) => {
  return await axiosInstance
    .post(`/external_user_accounts/fetch_external_accounts`, data, headers_with_token())
    .then((res) => {
      console.log(res)
      return res.data;
    });
};


export const DisconnectPlatform = async (id) => {
  return await axiosInstance
    .delete(`external_user_accounts/disconnect_external_user_account/${id}`, headers_with_token())
    .then((res) => {
      console.log(res)
      return res.data;
    });
};

