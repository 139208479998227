import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-jobs-tab {
    position: relative;
    padding-bottom: 215px;
    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 5px 0 0 0;
      @media (max-width: 906px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 16px 0;
      }
    }
    .collapsible-view {
      box-shadow: none !important;
      max-width: 600px;
      margin: 0 auto;
      @media (max-width: 767px) {
        background: #fff;
        margin-bottom: 16px;
        box-shadow: var(--card-box-shadow) !important;
      }
      .title {
        @media (max-width: 767px) {
          text-align: left;
          padding: 10px 16px 6px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          font-size: 18px;
        }
        .collapse-icon {
          @media (max-width: 767px) {
            display: flex;
            margin-top: -2px;
          }
        }
      }
      &.open {
        .jobs-filter-wrapper {
          @media (max-width: 767px) {
            display: block;
            padding: 15px 15px 1px 15px;
            border-top: 1px solid var(--gray-outline-color);
          }
        }
      }
    }
    .jobs-filter-wrapper {
      display: flex;
      max-width: 600px;
      margin: 0 auto 14px;
      padding: 0 10px;
      justify-content: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        display: none;
      }
      @media (max-width: 600px) {
        margin: 0 auto 0px;
      }
      .field-group {
        width: 33.33%;
        padding: 0 5px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .jobs-list-wrapper {
      max-width: 600px;
      margin: 0 auto;
      > .MuiGrid-root {
        margin: 0 0 15px 0;
        @media (max-width: 600px) {
          margin: 0 0 10px 0;
        }
      }
      .card-content {
        padding: 18px 15px;
        min-height: 100px;
        @media (max-width: 600px) {
          padding: 15px;
        }
        &:hover {
          .card-actions-wrapper {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .search-item {
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
        @media (max-width: 600px) {
          flex-wrap: wrap;
          align-items: flex-start;
        }
        &.featured-job {
          .content {
            .header-primary {
              align-items: center;
            }
          }
        }
        .custom-rss-icon {
          height: 15px;
          width: 15px;
          position: absolute;
          margin-top: -49px;
          margin-left: -20px;
        }
        .avtar {
          margin-right: 15px;
          @media (max-width: 600px) {
            margin-right: 10px;
          }
          .MuiAvatar-root {
            width: 50px;
            height: 50px;
            border-radius: 100px;
          }
        }

        .apply-btn {
          margin-left: 10px;
          .nlink-btn {
            min-width: 80px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            padding: 2px 8px 0;
          }
        }
        .content {
          margin-right: auto;
          max-width: calc(100% - 220px);
          @media (max-width: 600px) {
            max-width: calc(100% - 60px);
          }
          .header-primary {
            padding-top: 0px;
            margin-bottom: 2px;
            span {
              white-space: normal;
              word-break: break-word;
            }

            span.inline-title {
              overflow: hidden;
              line-height: 16px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding: 3px 0 2px 0;
            }
            span.inline-title-flex {
              width: max-content;
            }
          }
          .star {
            margin: -3px 0 0 10px;
          }
          .tag-item {
            border-radius: 100px;
            background-color: #f1f4f7;
            color: #000c3d;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            padding: 4px 8px 0;
            margin-left: 10px;
            margin-top: -4px;
            display: inline-block;
            white-space: nowrap !important;
          }
        }
        .sub-header-primary {
          margin-bottom: 0px;
          padding: 0px 0 0 0;
          line-height: 17px;
        }
        .text-grey {
          padding-top: 4px;
          line-height: 16px;
        }
        > .flex {
          @media (max-width: 600px) {
            margin-left: 60px;
            margin-top: 4px;
          }
        }
        .card-actions-wrapper {
          display: flex;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          @media (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
          }
          .nlink-btn {
            width: auto;
            min-width: 80px;
            white-space: nowrap;
            margin-right: 12px;
            @media (max-width: 600px) {
              height: 28px;
              min-height: auto;
              padding: 5px 8px 3px;
            }
          }
          div {
            + div {
              margin-left: 12px;
              @media (max-width: 991px) {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
`;
