import axios from "axios";
import Bugsnag from "@bugsnag/js";

import { config } from "../constant/config";
import { store } from "../redux/store";
import { signOutCall } from "../utils/common";

let axiosInstance = axios.create({
  baseURL: config().API_URL, // API URL
  // timeout: 8000,
});

axiosInstance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
axiosInstance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosInstance.interceptors.response.use(
  function (response) {
    // console.log("response", response);
    return response;
  },
  function (error) {
    //console.log("--------------------------", error);
    const responseData = error.response
      ? error.response.data
      : {
          code: 0,
          message: error.message,
        };
    // console.log("error", error.response);

    const statusData = error.response ? error.response.status : 500;

    if (statusData === 401) {
      const isLogin = sessionStorage.getItem("isLogin");
      if (isLogin === "1") {
        // Leave breadcrumb
        Bugsnag.leaveBreadcrumb(
          "Session storage clear",
          {
            instance: "API failure 401 code",
          },
          "state"
        );
      }

      // Fetch user's school from redux state
      let state = store.getState();
      const school_id = state?.userStore?.users?.school_id ?? null;

      signOutCall(school_id);
    }

    /** Bugsnag error reporting for failed API - Start */

    // Leave breadcrumb
    Bugsnag.leaveBreadcrumb(
      "XMLHttpRequest failed",
      {
        status: statusData || 500,
        request: error?.request?.responseURL ?? error?.config?.url ?? "",
      },
      "request"
    );

    // Log custom error event with all API details
    Bugsnag.notify(
      new Error(responseData?.message ?? responseData?.errors ?? "API Failure"),
      function (event) {
        event.handled = true;
        event.severity = "info";
        event.context = "Handled API Failure";
        event.addMetadata("response", {
          status: statusData || 500,
          ...(responseData ?? {}),
        });
      }
    );
    /** Bugsnag error reporting for failed API - end */

    return Promise.reject({
      status: statusData || 500,
      data: responseData ?? {},
      handled: true,
    });
  }
);

export default axiosInstance;
