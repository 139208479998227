import React, { useState, useEffect } from "react";
import { Popover, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";

import DashboardMenusStyles from "./DashboardMenusStyles";
import { useAppState } from "../../../../context";
import { routes } from "../../../../routes";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog";
import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import MaskedTextboxCustom from "../../../MaskedTextbox/MaskedTextboxCustom";
import { sendGetAppCodeSMS } from "../../../../services/dashboardServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../../utils/common";

import AppStoreImg from "../../../../assets/svg/apple-store.svg";
import GooglePlayImg from "../../../../assets/svg/play-store.svg";

const DashboardMenus = () => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { handleOpenFooter } = useAppState("app");
  const [showGetAppPopup, setGetAppPopup] = useState(false);
  const [showGetAppSuccessPopup, setGetAppSuccessPopup] = useState(false);
  const { users = {} } = useSelector((store) => store.userStore);

  const [callSendGetAppCodeSMS, refreshSendGetAppCodeSMS] =
    usePromise(sendGetAppCodeSMS);

  useEffect(() => {
    if (callSendGetAppCodeSMS.hasFetched()) {
      if (callSendGetAppCodeSMS.hasErrors()) {
        toastify("error", "Unable to send link!");
      } else {
        setGetAppPopup(false);
        setGetAppSuccessPopup(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSendGetAppCodeSMS.isFetching()]);

  const [showPopover, setPopover] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  return (
    <DashboardMenusStyles>
      <div className="dashboard-menus">
        <ul>
          <li>
            <a
              href="https://www.interstride.com/our-mission/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="about interstride"
              className="link-focus"
            >
              About
            </a>
          </li>
          <li>
            <Link to={routes.HELP.DASHBOARD} className="link-focus">
              Help Center
            </Link>
          </li>
          {users?.student_view_preference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL && (
            <li>
              <Link
                to="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setGetAppPopup(true);
                }}
                className="link-focus"
              >
                Get the App
              </Link>
            </li>
          )}
          <li>
            <a
              onClick={handleClick}
              href="/#"
              className="link-focus"
              aria-label="Privacy & Terms"
            >
              Privacy & Terms
            </a>
          </li>
          <li>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleOpenFooter();
              }}
              className="link-focus"
              aria-label="Expand more footer menus"
            >
              More <ExpandMore htmlColor="var(--gray-text-color)" />
            </a>
          </li>
        </ul>

        {showGetAppPopup && (
          <CustomDialog
            open={showGetAppPopup}
            title={"Get the Interstride App"}
            subTitle={
              "Click on the links below or enter your number to receive a link to the app store."
            }
            handleClose={() => setGetAppPopup(false)}
            className="get-app-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <div className="text-note">
                Note: Our mobile applications supplement our web application
                experience. They are not an alternative to our web application.
              </div>
              <div className="Appimg d-flex">
                <a
                  href="https://play.google.com/store/apps/details?id=com.interstride&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Google play"
                >
                  <img
                    src={GooglePlayImg}
                    className="google-play-img"
                    alt="Google Play"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/interstride/id1254437834"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="App store"
                >
                  <img
                    src={AppStoreImg}
                    className="app-store-img"
                    alt="App store"
                  />
                </a>
              </div>
              <Formik
                initialValues={{ code: "+1", phone: "" }}
                isInitialValid={false}
                validate={(values) => {
                  let errors = {};

                  if (!values.code || values.code.trim() === "") {
                    errors.code = "Country code is required";
                  }
                  if (!values.phone || values.phone.trim() === "") {
                    errors.phone = "Phone number is required";
                  }
                  if (
                    values.phone &&
                    values.phone.trim() !== "" &&
                    !/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/.test(values.phone)
                  ) {
                    errors.phone =
                      "Looks like the number you entered is incorrect. Please try again.";
                  }
                  // console.log("values error", values);
                  return errors;
                }}
                onSubmit={(values, actions) => {
                  refreshSendGetAppCodeSMS(values.code, values.phone);
                }}
              >
                {({ isSubmitting, isValid, ...props }) => (
                  <Form>
                    <div className="ReceiveLink">
                      <label>Or receive a link via text message</label>
                      <div className="d-flex">
                        <Field {...props} name="code">
                          {({
                            field,
                            form: {
                              touched,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                            },
                            meta,
                          }) => (
                            <TextboxCustom
                              {...field}
                              label=""
                              name="code"
                              placeholder="Code"
                              type="text"
                              lableaignment="left"
                              onChange={(e) => setFieldValue("code", "+1")}
                            />
                          )}
                        </Field>

                        <Field {...props} name="phone">
                          {({ field, form: { setFieldValue }, meta }) => (
                            <MaskedTextboxCustom
                              {...field}
                              name="phone"
                              type="number"
                              placeholder="(  )    -    "
                              label={""}
                              lableaignment="left"
                              onChange={(e) => {
                                setFieldValue("phone", e.target.value);
                              }}
                              value={field.value}
                              error={meta.touched && !isEmpty(meta.error)}
                              errorText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="send-code-button">
                      <ButtonCustom isDisabled={!isValid} type="submit">
                        Send Code
                      </ButtonCustom>
                    </div>
                    <div className="text-center">
                      <a
                        href="/#"
                        className="ngray-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setGetAppPopup(false);
                        }}
                        aria-label="Cancel"
                      >
                        Cancel
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </CustomDialog>
        )}

        {showGetAppSuccessPopup && (
          <CustomDialog
            open={showGetAppSuccessPopup}
            title={"We've just sent you a code to download the app!"}
            subTitle={"This can take a few minutes to reach your phone."}
            handleClose={() => setGetAppSuccessPopup(false)}
            className="get-app-success-popup"
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
              <div className="got-it-button">
                <ButtonCustom onClick={() => setGetAppSuccessPopup(false)}>
                  Got It!
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={showPopover}
          onClose={handleClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="terms-popover-div">
            <Typography>
              <Link
                to={routes.TERMS_AND_CONDITIONS}
                className="nlink-secondary link-focus"
              >
                Terms & Conditions
              </Link>
            </Typography>
            <Typography>
              <Link
                to={routes.PRIVACY_POLICY}
                className="nlink-secondary link-focus"
              >
                Privacy Policy
              </Link>
            </Typography>
            {accessibilityHelp && accessibilityHelp === "true" && (
              <div className="close-button">
                <ButtonCustom onClick={handleClose}>Close</ButtonCustom>
              </div>
            )}
          </div>
        </Popover>
      </div>
    </DashboardMenusStyles>
  );
};

export default DashboardMenus;
