import React, { useState } from "react";

/** This barebones utility component provides text with truncate options.
 * Use parent wrapper to style this component.
 * @param {String} text to display
 * @param {Number} maxTextLength maximum numbers of characters to display
 * @param {Number} minTextLength minimum numbers of characters to display
 * @returns {Component} renderComponent
 */
export default function TextTruncate({
  text = "",
  maxTextLength = 500,
  minTextLength = 100,
}) {
  const [expandedText, setExpandedText] = useState(false);

  return (
    <div>
      <p>
        {text.slice(0, expandedText ? maxTextLength : minTextLength)}
        {text?.length > minTextLength && !expandedText && ". . ."}
      </p>
      {text.length > minTextLength && (
        <span onClick={() => setExpandedText((current) => !current)}>
          {expandedText ? "See less" : "See more"}
        </span>
      )}
    </div>
  );
}
