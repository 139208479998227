import { useState, useEffect } from "react";

import { useAppState } from "../../context";
import { fetchEssentials, fetchSchools } from "../../services/profile.service";

export const useEssentials = () => {
  const { setEssentials, essentials, schools, setSchools } = useAppState("profile");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (schools) return;
      const response = await fetchSchools();
      setSchools(response || []);
    })();
  }, []);

  useEffect(() => {
    if (essentials || loading) return;
    (async () => {
      setLoading(true);
      const response = await fetchEssentials();
      setEssentials(response);
      setLoading(false);
    })();
  }, []);

  return essentials;
};
