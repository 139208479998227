import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g strokeWidth="1.2" transform="translate(-587 -333)">
          <g transform="translate(588 332)">
            <g transform="translate(0 1.929)">
              <path
                fill="#BFCED8"
                stroke="#BFCED8"
                d="M15.817 15.071H2.183A1.19 1.19 0 011 13.883V4.26a1.19 1.19 0 011.183-1.189h13.634A1.19 1.19 0 0117 4.261v9.622a1.19 1.19 0 01-1.183 1.188z"
              ></path>
              <path
                stroke="#FFF"
                d="M0 3.69642857L9.00066568 10.4464286 18 3.69642857"
              ></path>
              <path
                stroke="#BFCED8"
                d="M6 3.071c0-1.649 1.35-3 3-3s3 1.351 3 3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
