import React from "react";
import { useNavigate } from "react-router-dom";

import profileArrowIcon from "../../../../assets/svg/f-arrow-profile-arrow-icon.svg";
import completeYourProfile from "../../../../assets/png/f-complete-your-profile-step1.png";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { routes } from "../../../../routes";

const CompleteProfile = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="status-wrapper">
        <h3 className="status-title text-primary">How to do it</h3>
        <div className="status-sub-title">
          Go to your Account page by clicking on the profile icon on the top
          bar.
        </div>
        <div className="status-box d-flex justify-content-center align-items-center pos-relative">
          <img className="img-arrow" src={profileArrowIcon} alt="Down Arrow" />
          <img
            className="steps-img step1"
            src={completeYourProfile}
            alt="Complete Your Profile"
          />
        </div>
        <div className="mandetory-note">
          Fill out all mandatory fields to complete your profile! You can skip
          the optional sections.
        </div>
        <ButtonCustom
          onClick={() => navigate(routes.PROFILE.PATH)}
          width={250}
          height={60}
          style={{ marginTop: "20px" }}
        >
          Set It Up
        </ButtonCustom>
      </div>
    </>
  );
};

export default CompleteProfile;
