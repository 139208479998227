import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .user-profile__container {
    box-shadow: var(--card-box-shadow);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    .profile-shimmer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__title {
        width: 200px;
        height: 20px;
      }
    }
    &__profile {
      position: relative;
      &__actions {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      &__not-available {
        font-family: "TTCommons-Medium";
        color: #000c3d;
        font-weight: 500;
      }
    }
  }
`;
