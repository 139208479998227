import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-overlay {
    background: linear-gradient(
      180deg,
      rgba(249, 250, 252, 0.2) 0%,
      #f8faff 15%
    );
    position: absolute;
    height: 300px;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 -15px;
    .close {
      display: flex;
      margin: -20px 20px 20px auto;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: flex-end;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 5px 0 0 0;
      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 15px 0;
      }
    }
    button {
      width: auto;
      min-width: 214px;
    }
  }
`;
