import React, { useState } from "react";
import { useSelector } from "react-redux";

import CategoryStyles from "./CategoryStyles";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";

import ArrowIcon from "../../../assets/svg/down-arrow.svg";

const HelpCategory = ({ catList = [], ActiveFAQID, handleFAQ }) => {
  const { users = {} } = useSelector((store) => store.userStore);
  const [openFilter, toggleFilter] = useState(false);

  const renderCategory = (data) => {
    if (
      users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC &&
      data.category === "US Visa Insights"
    ) {
      return <></>;
    }
    return (
      <li
        className={`category-item cursor-pointer ${
          data.id === ActiveFAQID && "active"
        }`}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            handleFAQ(data);
          }}
          aria-label={data.category}
        >
          <div className="item">
            <div className="image">{data?.image}</div>
            <div className="cat-name">
              <div className="header-primary">{data.category}</div>
            </div>
          </div>
        </a>
      </li>
    );
  };

  return (
    <CategoryStyles>
      <div
        className={`category-list collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary hide-title mb-15">
          <span>FAQ</span>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon arrow"
            aria-label="Open filter"
          >
            <img src={ArrowIcon} alt="Arrow Icon" />
          </a>
        </h2>
        <div>
          <div className="categories">
            <ul>{catList.map((data, i) => renderCategory(data, i))}</ul>
          </div>
        </div>
      </div>
    </CategoryStyles>
  );
};

export default HelpCategory;
