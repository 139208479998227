import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-services-view {
    margin: 30px 0px 0 0;
    .service-categories-wrapper {
      display: flex;
      align-items: flex-start;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      .service-categories-loader {
        width: 100%;
        display: flex;
        margin-right: 8px;
        max-width: 210px;
        flex-wrap: wrap;
        @media (max-width: 767px) {
          max-width: 100%;
          margin-right: 0;
        }
        > div {
          width: 100%;
          display: flex;

          > div {
            margin-bottom: 10px;
            border-radius: 100px;
          }
        }
      }
      .service-listing-loader {
        width: 100%;
        position: relative;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        > div {
          padding: 0 8px;
          width: 50%;
          > div {
            width: 100% !important;
            border-radius: 10px;
          }
        }
      }
      .student-services-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img {
          width: 100%;
          max-width: 500px;
        }
        h2 {
          margin: 18px 0 0 0;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
        }
      }
      .student-services-categories-wrapper {
        width: 100%;
        display: flex;
        margin-right: 8px;
        max-width: 210px;
        @media (max-width: 767px) {
          max-width: 100%;
          margin-right: 0;
        }
        &.collapsible-view {
          box-shadow: none !important;
          @media (max-width: 767px) {
            flex-wrap: wrap;
            background: #fff;
            margin-bottom: 16px;
            box-shadow: var(--card-box-shadow) !important;
          }
          &.open {
            .student-services-categories {
              @media (max-width: 767px) {
                display: block;
              }
            }
          }
        }
        .title-primary {
          @media (min-width: 767px) {
            display: none;
          }
          @media (max-width: 767px) {
            font-family: "TTCommons-Medium";
            font-weight: 500;
            width: 100%;
            padding: 10px 16px 6px 16px;
            font-size: 18px !important;
          }
        }
        .student-services-categories {
          width: 100%;
          @media (max-width: 767px) {
            background: #fff;
            padding: 0px;
            border-radius: 0 0 8px 7px;
            display: none;
          }
          .student-services-category-item {
            cursor: pointer;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            height: 50px;
            border-radius: 25px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            padding: 10px 15px;
            text-decoration: none;
            transition: all 0.3s;
            &:hover {
              box-shadow: var(--card-box-shadow-hover);
            }
            @media (max-width: 767px) {
              margin: 0;
              border-radius: 0;
              box-shadow: none;
              border-top: 1px solid var(--gray-outline-color);
            }
            .student-services-category-image {
              width: 30px;
              height: 30px;
              min-width: 30px;
              margin-right: 10px;
              background-color: #f1f4f7;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              img {
                width: 100%;
              }
            }
            span {
              height: 16px;
              width: 80%;
              color: #000c3d;
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .student-services-category-item-active {
            background-color: var(--widget-theme-color);
            span {
              color: #ffffff;
            }
          }
        }
      }
      .student-services-list {
        width: 100%;
        position: relative;
        padding: 0 15px;
        padding-bottom: 255px;
        @media (max-width: 767px) {
          padding-left: 0;
          padding-right: 0;
        }
        > div:first-child {
          > div {
            > div {
              > div {
                > div {
                  gap: 0;
                  margin: 0 -8px;
                  @media (max-width: 480px) {
                    margin: 0 0px;
                  }
                  > div {
                    width: 50%;
                    padding: 0 8px;
                    margin-bottom: 25px;
                    @media (max-width: 480px) {
                      width: 100%;
                      padding: 0px;
                    }
                  }
                }
              }
            }
          }
        }
        .widget-overlay {
          margin: 0;
          left: 8px;
          @media (max-width: 767px) {
            left: 0;
            margin: 0 -15px;
          }
        }
      }
    }
  }
`;
