import { useEffect, useState } from "react";
import { useAppState } from "../context";
import { fetchConversation } from "../services/chat.service";

export const useFetchConversation = (redirectUserId) => {
  const {
    activeConversation,
    setConversationInformation,
    getConversationByConversationID,
    currentSearchMessageItem,
    reloadChats,
  } = useAppState("chat");
  const conversation = getConversationByConversationID(activeConversation);
  const fresh = !conversation ? true : false;
  const [loading, setIsLoading] = useState(false);
  const [useId, setUseID] = useState(redirectUserId ? true : false);

  ///// in order to avoid same api call for same conversation consectivly
  const [apiCache, setApiCache] = useState({});

  const loadConversations = async (userId) => {
    setIsLoading(true);
    try {
      let response;
      if (userId) {
        response = await fetchConversation(activeConversation, {}, userId);
      } else {
        response = await fetchConversation(activeConversation);
      }

      if (response) {
        setConversationInformation(activeConversation, response.data, true);
        setIsLoading(false);
        setApiCache({
          ...apiCache,
          activeConversation: false,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Monitor chat use case
    if (reloadChats) {
      (async () => {
        await loadConversations();
      })();
      return;
    }
    if (
      activeConversation === -1 ||
      activeConversation === currentSearchMessageItem?.conversation_id
    )
      return;
    if (apiCache[activeConversation]) return;
    (async () => {
      if (useId) {
        await loadConversations(redirectUserId);
        setUseID(false);
      } else loadConversations();
    })();
  }, [activeConversation]);

  return {
    loading,
    conversation,
    fresh,
  };
};
