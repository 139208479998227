import React, { memo } from "react";

function WidgetCourses() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke="#000C3D" stroke-width="1.2">
          <path
            d="M4,10.3965265 L4,29 C4,29 15.4783463,25.9459152 19,29 L19,10.7016444 C19,10.7016444 13.9532317,7.08527096 4,10.3965265 Z"
            id="Stroke-3"
          ></path>
          <path
            d="M34,10.3965265 L34,29 C34,29 22.5230568,25.9459152 19,29 L19,10.7016444 C19,10.7016444 24.0467683,7.08527096 34,10.3965265 Z"
            id="Stroke-7"
          ></path>
          <path d="M22.5625,14.5 L30.2431203,14.5" id="Line-5"></path>
          <path d="M7.5625,14.5 L15.2431203,14.5" id="Line-5"></path>
          <path d="M22.5625,18.5 L30.2431203,18.5" id="Line-5"></path>
          <path d="M7.5625,18.5 L15.2431203,18.5" id="Line-5"></path>
          <path d="M22.5625,22.5 L30.2431203,22.5" id="Line-5"></path>
          <path d="M7.5625,22.5 L15.2431203,22.5" id="Line-5"></path>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetCourses);
