import React from "react";

import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";

const TestGuidelines = ({ onNext = () => {} }) => {
  return (
    <>
      <div className="game-start-content text-center">
        <h3 className="test-title">
          Pre and Post Self-Assessment of NACE’s 8 Career Readiness Competencies
        </h3>

        <p className="test-sub-title mb-30">
          The National Association of Colleges and Employers (NACE) surveyed
          recruiters from different industries about common characteristics they
          look for in new hires. The study revealed a list of 8 common
          competencies. Mastery of all the competencies makes one career ready.
          These competencies are:
        </p>

        <div className="blue-bg-card mb-30">
          <h4 className="title">NACE’s 8 Career Readiness Competencies </h4>

          <ul className="ol-listing">
            <li>1) Career & Self-Development</li>
            <li>2) Communication</li>
            <li>3) Critical Thinking</li>
            <li>4) Equity & Inclusion</li>
            <li>5) Leadership</li>
            <li>6) Professionalism</li>
            <li>7) Teamwork</li>
            <li>8) Technology</li>
          </ul>
        </div>

        <div className="btn-next">
          <ButtonCustom
            width={205}
            onClick={() => {
              onNext();
            }}
          >
            {" "}
            Get Started
          </ButtonCustom>
        </div>
      </div>
    </>
  );
};

export default TestGuidelines;
