import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  appliedVisaFilters: {},
  selectedCompany: null,
  jobsCountryList: [],
  jobsCountryListVersion: null,
  selectedJob: null,
  appliedFromJobAlerts: false,
};

export const jobReducer = createSlice({
  name: "job",
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
    setAppliedVisaFilters: (state, action) => {
      state.appliedVisaFilters = { ...action.payload };
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = { ...action.payload };
    },
    setJobsCountryList: (state, action) => {
      const { data = [], version } = action.payload;

      const optionData = [];
      data.forEach((option) => {
        optionData.push({ value: option.code, label: option.name });
      });
      optionData.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );

      state.jobsCountryList = optionData;
      state.jobsCountryListVersion = version;
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = { ...action.payload };
    },
    setAppliedFromJobAlerts: (state, action) => {
      state.appliedFromJobAlerts = { ...action.payload };
    },
  },
});

export const {
  setAppliedVisaFilters,
  setSelectedCompany,
  setCompanies,
  setJobsCountryList,
  setSelectedJob,
  setAppliedFromJobAlerts,
} = jobReducer.actions;

export default jobReducer.reducer;
