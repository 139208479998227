import React, { memo } from "react";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import SmartbannerStyles from "./SmartbannerStyles";

const SmartBannerInterstride = () => {
  return (
    <SmartbannerStyles>
      <SmartBanner
        title={"Interstride"}
        author="International student mobile app"
      />
    </SmartbannerStyles>
  );
};
export default memo(SmartBannerInterstride);
