import React from "react";
import { ClickAwayListener } from "@mui/material";
import Picker from "emoji-picker-react";

import TextboxCustom from "../../Textbox/TextboxCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

import emojiIcon from "../../../assets/svg/f-emoji-icon.svg";

export default function CreateComment({
  commentText = "",
  setCommentText = () => {},
  maxCommentLength = 500,
  handleSubmitComment = () => {},
  emojiContainer = false,
  setEmojiContainer = () => {},
  edit = false,
  commentMenuState = {},
  setCommentMenuState = () => {},
  apiFetching = false,
}) {
  const onEmojiClick = (emojiObject) => {
    if (emojiObject?.emoji) {
      if (edit) {
        setCommentMenuState((current) => ({
          ...current,
          comment: {
            ...current?.comment,
            comment: current?.comment?.comment + emojiObject.emoji,
          },
        }));
      } else {
        setCommentText((current) => current + emojiObject.emoji);
      }
    }
    setEmojiContainer(false);
  };

  const handleChangeText = (text = "") => {
    if (edit) {
      setCommentMenuState((current) => ({
        ...current,
        comment: {
          ...current?.comment,
          comment: text,
        },
      }));
    } else {
      setCommentText(text);
    }
  };

  return (
    <div className="comment-box">
      <div className="comment-box__text">
        <TextboxCustom
          name="comment"
          placeholder="Write your reply"
          value={edit ? commentMenuState?.comment?.comment : commentText}
          onChange={(e) => handleChangeText(e.target.value)}
          isMultiline={true}
          rows={1}
          maxLength={maxCommentLength}
        />
      </div>
      <div className="comment-box__action">
        <div className="comment-box__action__left">
          <div className="emoji-picker-wrapper">
            <img
              src={emojiIcon}
              alt="emoji"
              onClick={() => setEmojiContainer((current) => !current)}
              className="emoji-icon"
            />
            {emojiContainer && (
              <ClickAwayListener onClickAway={() => setEmojiContainer(false)}>
                <div className="emoji-picker">
                  <Picker
                    onEmojiClick={onEmojiClick}
                    skinTonesDisabled
                    style={{ width: "100%" }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div className="comment-box__action__post">
          <ButtonCustom
            isSubmitting={apiFetching}
            isDisabled={
              edit
                ? commentMenuState?.comment?.comment?.trim() === ""
                : commentText.trim() === ""
            }
            onClick={() => handleSubmitComment()}
          >
            {apiFetching ? "" : "Post"}
          </ButtonCustom>
        </div>
      </div>
    </div>
  );
}
