import React, { memo } from "react";

function WidgetJobs() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke={"#000C3D"} stroke-width="1.2">
          <path
            d="M14.6819,10.3384 C14.6819,7.8154 16.7469,5.7494 19.2709,5.7494 C21.7939,5.7494 23.8589,7.8154 23.8589,10.3384"
            id="Stroke-3"
          ></path>
          <path
            d="M30.7903,31.4986 L7.7503,31.4986 C6.6493,31.4986 5.7503,30.5986 5.7503,29.4986 L5.7503,13.3116 C5.7503,12.2126 6.6493,11.3116 7.7503,11.3116 L30.7903,11.3116 C31.8913,11.3116 32.7903,12.2126 32.7903,13.3116 L32.7903,29.4986 C32.7903,30.5986 31.8913,31.4986 30.7903,31.4986 Z"
            id="Stroke-7"
          ></path>
          <polyline
            id="Stroke-11"
            points="5.7502 13.312 19.2712 22.351 32.7902 13.312"
          ></polyline>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetJobs);
