import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    .MuiGrid-spacing-xs-3 {
      justify-content: center;
    }

    .MuiCardContent-root {
      padding: 20px 20px;
    }


    .title-boxs {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-end;
      .nlink-secondary {
        font-family: "TTCommons-Regular";
        font-size: 18px;
      }
      .view-all{
        text-decoration:none;
        border-bottom:1px solid ${SECONDARY_COLOR};
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
    .search-list-card {
      margin: 0 0 15px 0;
      padding: 18px 25px 14px 25px;
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        padding-left: 8px;
        opacity: 0;
        visibility: hidden;
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 650px) {
          padding-left: 0px;
        }
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        .card-actions-wrapper {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s;
        }
      }
      &.active{
        background-color: ${PRIMARY_COLOR};
        .content{
          .text-grey,
          .header-primary{
            color:#fff;
          }
        }
      }

      .MuiCardContent-root {
        padding: 0;
      }

      .search-item {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        align-items: center;

        .btn-wrap {
            display: flex;
            align-items: center;
            margin-left: auto;
            .apply-btn {
                button {
                  height: 35px;
                  padding: 4px 12px 0 12px;
                  width: auto;
                  min-width: 94px;
                  span.MuiButton-label {
                      width: auto;
                      min-width: auto;
                      .content {
                          min-width: auto;
                          white-space: nowrap;
                          width: auto !important;
                          display: flex;
                          text-transform: none;
                      }
                  }
              }
              &.edit-btn{
                button{
                  background:var(--link-color);
                }
              }
              &.delete-btn{
                button{
                  background: var(--icon_background);
                  display: flex;
                  align-items: center;
                  width: 35px;
                  height: 35px;
                  padding: 0 !important;
                  min-width: 35px;
                  &:hover{
                    background:var(--icon_background_hover);
                  }
                }
              }
              + .apply-btn {
                  margin-left: 20px;
              }
            }
        }
      }
    }
  }

  .empty-search-box {
    padding: 40px 40px 35px;
    text-align: center;
    .card-content {
      padding:0;
    }
    .header-primary {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      margin: 0 0 10px 0;
    }
    .text-grey {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-family: "TTCommons-Medium";
      margin: 0 0 25px 0;
    }
    .suggestion {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      max-width: 245px;
      margin: 0 auto;
      padding: 20px;
      button {
        width: 100%;
        padding: 11.55px 0 6.15px;
        height: auto;
        border-radius: 100px;
        font-size: 22px;
        font-weight: 500;
        line-height: 21px;
        font-family: "TTCommons-Regular";
        min-height: 42px;
        box-shadow: none;
        width: 180px;
        margin: 0 0 20px 0px;
      }
      .save {
        display: block;
        font-size: 12.6px;
        letter-spacing: 0;
        line-height: 13.3px;
      }
      .apply-btn {
        width: auto;
        position:relative;
        button {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          text-align: center;
          line-height: 35px;
          height: auto;
          padding: 0;
          box-shadow: none;
          .content {
            margin: 0;
          }
        }
        &:before{
          content:'';
          background:url('${(props) => props.arrowImg}') no-repeat 0 0;
          width:120px;
          height:115px;
          display:block;
          position:absolute;
          top: -30px;
          left: -40px;
        }
    }
`;
