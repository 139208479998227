import React from "react";

import ButtonCustom from "../ButtonCustom/ButtonCustom";
import CustomDialog from "../CustomDialog/CustomDialog";

export const SecondaryEmailConfirmDialog = ({
  isShow,
  setIsShow,
  onSuccess,
}) => {
  const handleSuccess = () => {
    onSuccess();
  };

  return (
    <CustomDialog
      open={isShow}
      title={"Are you sure to switch secondary to primary email?"}
      handleClose={() => setIsShow(false)}
      className="sign-out-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
        <div className="btn mt-20 mb-20">
          <ButtonCustom
            width="200"
            height={50}
            onClick={(e) => {
              e.preventDefault();
              handleSuccess();
            }}
          >
            Confirm
          </ButtonCustom>
        </div>
        <div className="make-primary-email-modal-footnotes">
          <p>
            Changing primary email will sign you out from all active sessions
          </p>
          <p>You will be able to sign-in again with the new primary email</p>
        </div>
      </div>
    </CustomDialog>
  );
};
