import styled from "styled-components";

export const InitialAvatarStyles = styled.div.attrs({ className: "" })`
  .initial-avatar-root {
    color: "#fff";
    font-size: "26px";
    padding-top: "5px";
  }
  .initial-avatar-accessibility {
    background-color: "#5C728A";
  }
  .initial-avatar-color-default {
    background-color: "#d1dde5";
  }
  .initial-avatar-color1 {
    background-color: "#ABC1D";
  }
  .initial-avatar-color2 {
    background-color: "#FFE69A";
  }
  .initial-avatar-color3 {
    background-color: "#A3C3FD";
  }
  .initial-avatar-color4 {
    background-color: "#FCA6A0";
  }
  .initial-avatar-color5 {
    background-color: "#F7BE8D";
  }
`;
