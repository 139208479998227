export const INITIAL_FILTERS = {
  country: { value: null, label: null },
  account_type: { value: null, label: null },
  degree: { value: null, label: null },
  major: { value: null, label: null },
  graduation_year: { value: null, label: null },
  department: { value: null, label: null, school_name: null },
};

export const INITIAL_FILTERS_OPTIONS = {
  country: [],
  account_type: [],
  degree: [],
  major: [],
  graduation_year: [],
  departments: [],
};

export const INITIAL_SEARCH_STATE = {
  text: "",
  appliedText: "",
};

export const INITIAL_PAGINATION_DATA = {
  current_page: 1,
  per_page: 10,
  total_pages: 1,
  total_participants: 0,
  pagination_loader: false,
};
