import React, { useEffect } from "react";

import Container from "../../../components/Container/Container";
import MTopics from "../MTopics/MTopics";
import TopicDiscover from "../TopicDiscover/TopicDiscover";
import { useAppState } from "../../../context";
import { communitiesTabOptions } from "../../../utils/common";

const AllTopics = () => {
  const { tab, resetStateTopics } = useAppState("topic");

  useEffect(() => {
    return () => {
      // On component unmount
      resetStateTopics();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="no-padding-container relative">
      {tab === communitiesTabOptions[0] ? <MTopics /> : <TopicDiscover />}
    </Container>
  );
};

export default AllTopics;
