import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import { widgetRedirection } from "../../../utils/common";
import CoursesStyles from "./CoursesStyles";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

import WorkAuthorizationCourseImage from "../../../assets/png/work_authorization_course.png";
import OrientationCourseImage from "../../../assets/png/orientation_course.png";
import H1BCourseImage from "../../../assets/png/h1b_course.png";
import SponsorshipCourseImage from "../../../assets/png/sponsorship_course.png";
import BlueArrowImage from "../../../assets/png/Blue-Arrow.png";

// reference : https://interstride.com/courses/
const Courses = ({ permissions = {} }) => {
  const [allowedCourses, setAllowedCourses] = useState([]);

  useEffect(() => {
    const COURSES_LIST = [
      {
        id: "work_authorization",
        permission: "work_authorization_course",
        image: WorkAuthorizationCourseImage,
        title: "US work authorizations for international students",
        description:
          "International F-1 students are allowed to obtain work authorization under specific circumstances, whether during their studies or after graduation. In this course, you'll learn about:",
        key_points: [
          "Curricular Practical Training (CPT) work authorization",
          "Optional Practical Training (OPT) work authorization",
          "STEM OPT extension",
          "H-1B visa",
          "TN-1 visa",
          "L-1 visa",
          "O-1 visa",
        ],
        tag: "Best for students & alumni",
      },
      {
        id: "orientation",
        permission: "orientation_course",
        image: OrientationCourseImage,
        title: "Job search strategy for international students",
        description:
          "It's important to understand the unique career challenges you'll face as an international student. Some employers may be reluctant to interview and hire you, but with the right framework and job search strategies, you can overcome these challenges. In this course, you'll learn to:",
        key_points: [
          "Understand the challenges and the process to execute a successful job search",
          "Assess your profile and tailor it to the opportunities on the job market",
          "Prepare to execute a strategic job search process",
        ],
        tag: "Best for students & alumni",
      },
      {
        id: "h1b",
        permission: "h1b_course",
        image: H1BCourseImage,
        title: "Ultimate guide to the H-1B visa",
        description:
          "Although there are many immigration paths to stay in the US after graduation, the H-1B remains one of the most popular options among international students. Learning about the H-1B visa and preparing in advance can provide an edge as you explore your post-graduation employment options.",
        key_points: [
          "What is the H-1B visa",
          "H-1B visa eligibility",
          "H-1B application and process",
          "Planning for the H-1B visa application process",
          "What if you aren't selected for the lottery or if your H-1B is denied?",
          "Life after your H-1B is approved",
        ],
        tag: "Best for students & alumni",
      },
      {
        id: "sponsorship_response_strategy",
        permission: "sponsorship_response_strategy",
        image: SponsorshipCourseImage,
        title: "Navigating work authorization and sponsorship questions",
        description:
          "It’s common for US employers to ask about work authorization on job applications. International students may be nervous about answering truthfully and fear that this will hurt their chances of getting the job. This course will help you learn how to answer sponsorship questions honestly, completely, and confidently during your job search.",
        key_points: [
          "Factors to consider before answering sponsorship questions",
          "How to answer work authorization and sponsorship questions",
          "Strategic approaches for answering sponsorship questions",
          "How to find companies that sponsor international students",
        ],
        tag: "Best for students & alumni",
      },
    ];

    const filteredCourses = COURSES_LIST.filter(
      (course) => course.permission === true || permissions[course.permission]
    );
    setAllowedCourses([...filteredCourses.slice(0, 3)]);
  }, [permissions]);

  const onCourseClick = () => {
    widgetRedirection("course");
  };

  return (
    <CoursesStyles>
      <div className="widget-course-tab">
        <h2 className="title">
          Courses to kickstart your international journey.
        </h2>
        {allowedCourses && !isEmpty(allowedCourses) ? (
          <div className="courses-list-wrapper">
            {allowedCourses.map((course) => (
              <div key={course.id} className="course-card">
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    onCourseClick();
                  }}
                >
                  <div className="image-wrapper">
                    <img
                      className="course-image"
                      src={course.image}
                      alt={course.title}
                    />
                  </div>
                  <div className="course-title">{course.title}</div>
                  <div className="course-description">{course.description}</div>
                  <ul className="course-key-points">
                    {course.key_points.map((point) => (
                      <li>{point}</li>
                    ))}
                  </ul>
                  <div className="course-tag">{course.tag}</div>
                  <div className="course-link">
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Register Now <img src={BlueArrowImage} alt="arrow" />
                    </a>
                  </div>
                </a>
              </div>
            ))}
            <WidgetOverlay
              title="Get access to all our courses"
              route_type="course"
            />
          </div>
        ) : (
          <EmptyListWithImage
            title={`Looks like there are no courses for you.`}
            className={"default-empty-cover"}
          />
        )}
      </div>
    </CoursesStyles>
  );
};

export default Courses;
