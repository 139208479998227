import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .MuiCard-root {
    border-radius: ${(props) => props.borderRadius}px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    overflow: initial;
  }
`;
