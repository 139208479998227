import React from "react";
import cls from "classnames";

import BoostProfileLeftSidebarStyles from "./BoostProfileLeftSidebarStyles";
import complete from "../../../assets/svg/f-arrow-path.svg";

const BoostProfileLeftSidebar = ({
  currentIndex,
  statusList,
  statusType,
  handleClickStatus,
  sidebarTitle,
  totalCompletedStatus,
}) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  return (
    <BoostProfileLeftSidebarStyles>
      {sidebarTitle && <h2 className="sidebar-title">{sidebarTitle}</h2>}
      <div
        className={`profile-status-card ${
          sidebarTitle ? "" : sidebarTitle === false ? "mt-0" : "mt-40"
        }`}
      >
        <ul>
          {statusList.map((item, index) => (
            <li
              key={index}
              onClick={() =>
                statusType === "opt"
                  ? index <= totalCompletedStatus && handleClickStatus(index)
                  : item.isCompleted === false && handleClickStatus(index)
              }
            >
              <a
                href="/#"
                aria-label={`${item.title} ${
                  item.isCompleted === true ? "Completed" : "Incomplete"
                } ${accessibilityHelp ? "step" : ""}`}
                onClick={(e) => e.preventDefault()}
                className={cls("text-primary", {
                  "bg-primary":
                    statusType === "opt"
                      ? index === currentIndex
                      : index === currentIndex && item.isCompleted === false,
                  "text-white":
                    statusType === "opt"
                      ? index === currentIndex
                      : index === currentIndex && item.isCompleted === false,
                  "text-gray": item.isCompleted,
                  "cursor-pointer":
                    statusType === "opt"
                      ? index <= totalCompletedStatus
                      : item.isCompleted === false && index <= currentIndex + 1,
                  disabled:
                    statusType === "opt" && index > totalCompletedStatus,
                })}
              >
                <span>{item.title}</span>
                {statusType === "opt" ? (
                  <div
                    className={cls("ml-auto", {
                      "bg-gray": item.isCompleted === true,
                    })}
                    alt={`${
                      item.isCompleted === true ? "Completed" : "Incomplete"
                    } ${accessibilityHelp ? "step" : ""}`}
                  >
                    {item.isCompleted ? (
                      <div className="completed-profile completed-only-icon">
                        <img src={complete} alt="Completed" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div
                    className={cls("ml-auto", {
                      "bg-gray": item.isCompleted === true,
                    })}
                    alt={`${
                      item.isCompleted === true ? "Completed" : "Incomplete"
                    } ${accessibilityHelp ? "step" : ""}`}
                  >
                    {item.isCompleted ? (
                      <div className="completed-profile">
                        Completed
                        <img src={complete} alt="Completed" />
                      </div>
                    ) : (
                      <div className="incompleted-profile">Incomplete</div>
                    )}
                  </div>
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </BoostProfileLeftSidebarStyles>
  );
};

export default BoostProfileLeftSidebar;
