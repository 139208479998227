import React from "react";
import { Routes, Route } from "react-router-dom";
import TermsAndConditionsDB from "./TermsAndConditionsDB/TermsAndConditionsDB";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";

const TermsAndConditions = () => {
  return (
    <MainLayout title="Terms and Conditions" withSubHeader={false}>
      <Container>
        <Routes>
          <Route path="/" element={<TermsAndConditionsDB />} />
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default TermsAndConditions;
