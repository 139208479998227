import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
.country-insights__items{
  padding: 15px;
}
  .country-mainpage {
    .serach-country {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      @media(max-width:767px){
        flex-wrap:wrap;
      }
      .form-field-group{
        width:100%;
        max-width:220px;
        @media(max-width:480px){
          max-width:100%;
        }
        .field-group{
          margin-bottom:0;
        }
      }
      .nlink-secondary{
        margin-left:auto;
      }
    }
    .country-heading {
      position: relative;
      .city-align {
        padding-bottom: 3px !important;
      }
      .title-primary {
        width: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "TTCommons-DemiBold";
        font-weight: bold;
        @media(max-width:767px){
          position: initial;
          width: 100%;
          transform: none;
        }
        .flag-icon {
          border-radius: 3px;
          background-color: #d8d8d8;
          display: flex;
          align-items: center;
          overflow: hidden;
          vertical-align: middle;
          max-width: 41px;
          margin-left: 10px;
          margin-top: -4px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            }
          }
        }
      }
    }
    .networking-link {
      margin-left: auto;
      display: flex;
      align-items: center;
      @media(max-width:767px){
        margin: 8px auto 0;
      }
      a{
        margin-right:8px;
      }
    }
    .hide-city {
      .selected-contry-content {
        .overview-wrapper,
        .location-wrapper {
          @media(max-width:991px){
            margin-top: 16px !important;
          }
        }
      }
    }
    .country_listing {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        > div {
          width: 100%;
          padding: 0 10px;
        }
        li {
          padding: 0 10px;
          width: 33.33%;
          margin: 0 0 10px 0;
          display: flex;
          a {
            display: inline-block;
            width: 100%;
            border-radius: 100px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            padding: 16px 20px 14px;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            transition: all 0.3s;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
             box-shadow:0 1px 14px 3px var(--gray-outline-color);
            }
            &:focus {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            &.active {
              background-color: var(--primary-main-color);
              color: #fff;
              transition: all 0.3s;
            }
          }
        }
      }
    }
    .mapcontent-wrapper {
      .map-wrapper {
        border-radius: 15px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        height: 330px;
        > div {
          position: relative;
          padding-top: 56.56%;
          > div {
            top: 0;
          }
        }
      }
    }
    .cities_wrapper {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: flex;
          a {
            display: inline-block;
            width: 100%;
            border-radius: 100px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            padding: 16px 20px 14px;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            transition: all 0.3s;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-decoration: none;
            &.active {
              background-color: var(--primary-main-color);
              color: #fff;
              transition: all 0.3s;
            }
            &:hover {
              box-shadow:0 1px 14px 3px var(--gray-outline-color);
             }
            &:focus {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
              transition: all 0.3s;
            }
          }
          + li {
            margin-top:10px;
          }
        }
      }
    }
    .overview-wrapper {
      height: 100%;
      .overview-content {
        height: 295px;
        div {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 23px;
          margin: 0;
        }
        div.country-start-paragraph-margin {
          margin-block-start: 0px !important;
          font-size: 18px !important;
        }
        div.city-overview-align {
          margin-block-start: -5px !important;
        }
      }
      .city-hide-overview {
        padding: 0 15px;
        height: 290px;
        div {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 23px;
          margin: 0;
        }
        div.country-start-paragraph-margin {
          margin-block-start: 0px !important;
          font-size: 18px !important;
        }
      }
    }
    .location-wrapper {
      .map-wrapper {
        border-radius: 15px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        height: 330px;
        > div {
          position: relative;
          height:330px;
          > div {
            top: 0;
            height:330px !important;
          }
        }
      }
    }
    .country-advisory-section {
      margin-top: 30px;
      margin-bottom: -15px;
      @media(max-width:767px){
        margin-top: 24px;
      }
      ul {
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;
        li,a {
          width: 25%;
          padding: 0 10px;
          margin-bottom: 15px;
          text-decoration: none;
          @media(max-width:1367px){
            width: 33.33%;
          }
          @media(max-width:991px){
            width: 50%;
          }
          .wrapper {
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            display: flex;
            align-items: center;
            padding: 12px 16px 8px;
            flex-wrap: wrap;
            position: relative;
            height: 78px;
            padding-left: 88px;
            transition:all .3s;
            &:hover{
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            @media(max-width:767px){
              padding: 10px 8px;
              height: auto;
              justify-content: center;
              text-align: center;
              height: 100%;
            }
            .icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
              width: 58px;
              text-align: center;
              img {
                vertical-align: middle;
              }
              @media(max-width:767px){
                position:initial;
                transform:none;
                margin-bottom:8px;
                width:100%;
              }
            }
            h3 {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              line-height: 19px;
              margin: 0;
              width: 100%;
            }
            p {
              color: var(--field-label-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              line-height: 19px;
              margin: 0;
              width: 100%;
              font-weight: 400;
            }
          }
          @media(max-width:767px){
            width:50%;
          }
        }
      }
    }
    .country-services-wrapper {
      margin-top: 30px;
      ul {
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;

        a, li {
          padding: 0 10px;
          width: 20%;
          margin-bottom: 15px;
          text-decoration:none;
          @media(max-width:1366px){
            width: 25%;
          }
          @media(max-width:991px){
            width: 33.33%;
          }
          @media(max-width:767px){
            width: 50%;
          }
          .wraper {
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
            padding: 15px;
            display: flex;
            justify-content: center;
            text-align: center;
            height: 100%;
            transition:all .3s;
            &:hover{
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            .icon {
              height: 50px;
              width: 50px;
              margin: 0 auto 8px;
            }
            h3 {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              margin-bottom: 2px;
              margin-top: 0;
            }
            p {
              color: var(--field-label-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              text-align: center;
              max-width: 180px;
              margin: 0 auto;
            }
          }
          .canada-banner {
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
  .selected-contry-content {
    .MuiGrid-grid-md-12 {
      .location-wrapper {
        .title-primary {
          img {
            margin-right: 10px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
  .Scrollbars-wrapper {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
    padding: 15px;
    max-height: 330px;
    height: 100%;
    .overview-content{
      padding: 15px;
    }
  }
  .country-view-wrapper {
    @media(max-width:767px){
      position: relative;
      padding-top: 70px;
    }
    .selected-contry-content{
      @media(max-width:767px){
        padding-top: 0;
        position: initial;
      }
    }
    .title-primary{
      @media(max-width:767px){
        order: 1;
      }
    }
    .networking-link{
      @media(max-width:767px){
        order: 2;
        width: 100%;
        justify-content: center;
      }
      .nlink-secondary{
        @media(max-width:767px){
          margin:0 4px 0 0;
        }
      }
    }
    .form-field-group{
      @media(max-width:767px){
        margin:15px auto 0;
        order: 3;
      }
    }
    .cities_wrapper{
      .pages-back-btn{
        cursor:pointer;
        @media(max-width:767px){
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }
    }
    &.hide-city{
      .country-heading{
        @media(max-width:767px){
          position: initial;
        }
      }
      .pages-back-btn{
        cursor:pointer;
        @media(max-width:767px){
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }
    }
  }
  .selected-contry-content{
    @media(max-width:767px){
      position: relative;
      padding-top: 70px;
    }
    .country-heading{
      > .serach-country{
        @media(max-width:767px){
          justify-content: center;
        }
        .form-group{
          @media(max-width:767px){
            margin:15px auto 0;
            order: 3;
          }
        }
        .title-primary{
          @media(max-width:767px){
            order: 1;
          }
        }
      }
    }
    .cities_wrapper{
      cursor:pointer;
      .pages-back-btn{
        @media(max-width:767px){
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }

      }
    }
  }
`;
