import React, { useEffect, useState } from "react";
import _get from "lodash/get";

import { useAppState } from "../../../../../context";
import {
  getMyTopicsAPI,
  closeTopicAPI,
  muteUnmuteTopicAPI,
} from "../../../../../services/topicServices";
import { toastify } from "../../../../../helper/helper";
import { followUnfollowTopicAPI } from "../../../../../services/networkServices";
import usePromise from "../../../../../hooks/usePromise/usePromise";
import MenuDialog from "../../../../Topics/TopicRight/MenuDialog";
import EmptyMiddleBox from "../../../../EmptyModdleBox/EmptyMiddleBox";
import JobsListingEffect from "../../../../effects/JobsListingEffect/JobsListingEffect";
import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";
import NotificationInfoPanel from "../NotificationInfoPanel/NotificationInfoPanel";

const TopicsSettings = ({ isFetching }) => {
  const [topicId, setActiveTopicId] = useState(null);

  const [state, setMenuModel] = useState({
    isOpen: false,
  });
  const [switchKey, setSwitchToggle] = useState({
    id: null,
    key: null,
  });

  const {
    notificationSettings,
    setTopicsInNotificationSettings,
    iFollowingTopics,
    iCreatedTopics,
    closeTopic,
    unFollow,
    setTopicsMuteUnmuteNotificationSettings,
  } = useAppState("notifications");

  const [callingGetMyTopicsAPI, refreshGetMyTopicsAPI] =
    usePromise(getMyTopicsAPI);
  const [callingCloseTopicAPI, refreshCloseTopicAPI] =
    usePromise(closeTopicAPI);
  const [callingMuteUnmuteTopicAPI, refreshMuteUnmuteTopicAPI] =
    usePromise(muteUnmuteTopicAPI);
  const [callingFollowUnfollowTopicAPI, refreshFollowUnfollowTopicAPI] =
    usePromise(followUnfollowTopicAPI);

  const handleClose = (id) => {
    setActiveTopicId(id);
    menuModelHandler(1, id);
  };

  const handleUnFollow = (id) => {
    setActiveTopicId(id);
    menuModelHandler(2, id);
  };
  const menuModelHandler = (id, topic) => {
    switch (id) {
      case 1:
        setMenuModel({
          ...state,
          isOpen: true,
          title: "Are you sure you want to close this Community?",
          subTitle:
            "If you close this Community, others will no longer be able to access the content.",
          btnText: "Close Community for All",
          bottomText: "Keep Community open",
          showTextBox: false,
          callAction: () => callCloseAPI(1, topic),
        });
        break;
      case 2:
        setMenuModel({
          ...state,
          id,
          isOpen: true,
          title: `Are you sure you want to unfollow this Community?`,
          btnText: `Unfollow`,
          bottomText: "Cancel",
          showTextBox: false,
          callAction: () => callCloseAPI(2, topic),
        });
        break;
      default:
        break;
    }
  };

  const callCloseAPI = (id, topic = null) => {
    switch (id) {
      case 1:
        refreshCloseTopicAPI(topic);
        break;
      case 2:
        refreshFollowUnfollowTopicAPI(topic);
        break;
      default:
        break;
    }
  };

  const closeMenuModel = () => {
    setMenuModel({ isOpen: false });
  };

  const muteAndUnmuteTopic = async (id, topic_follower_id, key) => {
    setSwitchToggle({
      id: id,
      key: key,
    });
    let response = await refreshMuteUnmuteTopicAPI(topic_follower_id, null);
    if (response.success) {
      toastify("success", response.message);
    } else {
      toastify("error", response.message);
    }
  };

  useEffect(() => {
    refreshGetMyTopicsAPI();
  }, []);

  useEffect(() => {
    if (
      callingGetMyTopicsAPI.hasFetched() &&
      callingGetMyTopicsAPI.hasErrors() === false
    ) {
      setTopicsInNotificationSettings(callingGetMyTopicsAPI.data().data);
    }
  }, [callingGetMyTopicsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingCloseTopicAPI.hasFetched() &&
      callingCloseTopicAPI.hasErrors() === false
    ) {
      closeTopic(topicId);
      setActiveTopicId(null);
      closeMenuModel();
      toastify("success", callingCloseTopicAPI.data().message);
    }
  }, [callingCloseTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFollowUnfollowTopicAPI.hasFetched() &&
      callingFollowUnfollowTopicAPI.hasErrors() === false
    ) {
      unFollow(callingFollowUnfollowTopicAPI.data().data, topicId);
      setActiveTopicId(null);
      closeMenuModel();
      if (callingFollowUnfollowTopicAPI.data().data === "") {
        toastify("success", callingFollowUnfollowTopicAPI.data().message);
      } else {
        toastify("error", callingFollowUnfollowTopicAPI.data().message);
      }
    }
  }, [callingFollowUnfollowTopicAPI.isFetching()]);

  useEffect(() => {
    if (
      callingMuteUnmuteTopicAPI.hasFetched() &&
      callingMuteUnmuteTopicAPI.hasErrors() === false
    ) {
      if (callingMuteUnmuteTopicAPI.data().data.mute_topic) {
        setTopicsMuteUnmuteNotificationSettings(true, switchKey);
      } else {
        setTopicsMuteUnmuteNotificationSettings(false, switchKey);
      }
    }
  }, [callingMuteUnmuteTopicAPI.isFetching()]);

  return isFetching ? (
    <JobsListingEffect count={3} />
  ) : (
    <>
      <h2 className="title-primary mb-15">Community Settings</h2>
      <NotificationSettingPanel
        title="Comments from Communities"
        subTitle="Receive alerts whenever someone adds a comment to a post you have created or commented on."
        type="followed_topics"
        appPush={_get(notificationSettings, "followed_topic_push", false)}
        email={_get(notificationSettings, "followed_topic_email", false)}
      />
      <NotificationSettingPanel
        title="New Community"
        subTitle="Receive alerts whenever a new Community is created."
        type="new_topics"
        appPush={_get(notificationSettings, "new_topic_push", false)}
        email={_get(notificationSettings, "new_topic_email", false)}
      />
      <h2 className="title-primary mt-30 mb-15">Communities You Follow</h2>
      {iFollowingTopics && iFollowingTopics.length > 0 ? (
        iFollowingTopics.map((item, i) => {
          return (
            <>
              <NotificationInfoPanel
                topicShowDefault={item.topic_show_default}
                notificationType={"community"}
                key={i}
                avatarImg={item && item.topic_icon}
                title={item && item.topic_name}
                tabs={[{ text: item && item.topic_type }]}
                onClick={() => handleUnFollow(item && item.id)}
                checked={item && !item.mute_topic}
                handleChange={() =>
                  muteAndUnmuteTopic(
                    item && item.id,
                    item.topic_follower_id,
                    "following"
                  )
                }
              />
            </>
          );
        })
      ) : (
        <EmptyMiddleBox title="Looks like you haven't followed any Community." />
      )}

      <h2 className="title-primary mt-30 mb-15">Communities You Created</h2>
      {iCreatedTopics && iCreatedTopics.length > 0 ? (
        iCreatedTopics.map((item, i) => (
          <NotificationInfoPanel
            notificationType={"community"}
            key={i}
            avatarImg={item && item.topic_icon}
            title={item && item.topic_name}
            tabs={[{ text: item && item.topic_type }]}
            actionName="Close"
            onClick={() => handleClose(item && item.id)}
            checked={item && item.mute_topic}
            handleChange={() =>
              muteAndUnmuteTopic(
                item && item.id,
                item.topic_follower_id,
                "created"
              )
            }
          />
        ))
      ) : (
        <EmptyMiddleBox title="Looks like you haven't created any Community." />
      )}
      <MenuDialog
        isOpen={state.isOpen}
        data={state}
        handleClose={closeMenuModel}
        callAPI={state.callAction}
        loader={callingCloseTopicAPI.isFetching()}
      />
    </>
  );
};

export default TopicsSettings;
