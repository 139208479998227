import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .pos-relative {
    cursor: pointer;
  }
  .responsive {
    width: 100%;
    height: auto;
  }

  .dashboard-card {
    padding: 20px;
    margin-bottom: 15px;
    overflow: visible;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    .MuiCardHeader-root {
      padding: 0;
      .MuiCardHeader-avatar {
        margin: 0 8px 0 0;
      }
      + .card-content {
        margin-top: 10px;
      }
      .MuiCardHeader-content {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .MuiCardHeader-title {
          font-family: "TTCommons-Medium";
          color: var(--primary-dark-color);
          line-height: 19px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin: 0 0 0px 0;
          padding-top: 0;
        }
        .MuiCardHeader-subheader {
          font-size: 15px;
          font-family: "TTCommons-Regular";
          color: var(--f-light-gray-color);
          letter-spacing: 0;
          line-height: 12px;
          margin-left: 6px;
          @media (max-width: 767px) {
            margin-left: 0px;
            width: 100%;
            margin-top: 0px;
            line-height: 15px;
          }
        }
      }
      .MuiCardHeader-action {
        margin-top: 0;
        margin-right: 0;
        position: relative;
        align-self: initial;

        button {
          box-shadow: none;
          height: 40px;
          width: 40px;
          border-radius: 100px;
          background-color: var(--icon_background);
          z-index: 1;
          &:hover,
          &:focus {
            background-color: var(--icon_background_hover);
          }
          svg {
            fill: var(--primary-dark-color);
          }
        }
      }
    }
    .card-content {
      padding: 0;
      .dashboard-post {
        .post-wrapper {
          align-items: center;
          position: relative;
        }
        img {
          width: 100% !important;
          height: 100%;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
          &.play-icon {
            width: 180px !important;
            height: 180px;
            position: absolute;
            cursor: pointer;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            object-fit: initial;
            box-shadow: none !important;
          }
        }
        img {
          margin: 0px 0 20px;
          border-radius: 5px;
          vertical-align: middle;
        }
        .slick-list {
          margin-bottom: 20px;
        }
        video {
          width: 100%;
          margin: 0px 0 15px;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
          vertical-align: middle;
        }
        .img-blur {
          filter: blur(8px);
        }

        .post-tag {
          border: 2px solid #f1f4f7;
          border-radius: 100px;
          background-color: #ffffff;
          color: #000c3d;
          padding: 6px 16px 1px;
          display: flex;
          align-items: center;
          min-height: 29px;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          position: absolute;
          top: 15px;
          left: 15px;
        }

        .post-title {
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          line-height: 24px;
          padding-top: 5px;
          margin: 0;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 16px;
            padding-top: 4px;
          }
        }
        .post-body {
          margin: 10px 0 0 0;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 400;
          white-space: break-spaces;
          &.font-size-large {
            font-family: "TTCommons-Regular";
            font-size: 20px;
            line-height: 22px;
            @media (max-width: 767px) {
              font-size: 18px;
              line-height: 16px;
              padding-top: 4px;
            }
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 15px;
          }
        }
        .read-more-less {
          margin: 10px 0px;
          padding: 10px 12px;
          height: 35px !important;
          cursor: pointer;
          height: 35px;
          border-radius: 45px;
          background-color: #e8eef2;
          min-width: fit-content;
          height: 19px;
          width: 76px;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }
  }
`;
