import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_query-container__title + * {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto auto auto;
    @media (max-width: 767px) {
      margin: 15px auto auto auto;
    }
  }
  input {
    text-align: center;
  }
  .field-group {
    margin-bottom: 0;
  }
`;
