import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_query-container {
    margin-bottom: 15px;
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 30px 15px;
    @media (max-width: 767px) {
      padding: 24px 15px;
    }
    &__title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      max-width: 536px;
      margin: 0 auto;
    }
    .test-action-btns-wrapper {
      button {
        max-width: 175px;
        min-width: auto;
        width: 100%;
        &.shadow-btn {
          color: var(--primary-dark-color) !important;
        }
      }
    }
    .warning-message {
      color: var(--secondary-color);
      font-family: "TTCommons-Medium";
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin: 12px 0 0 0;
    }
  }
`;
