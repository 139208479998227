import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .dashboard-menus {
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;

      li {
        margin: 10px;
        a,
        p {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--gray-text-color);
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
`;
