import React from "react";
import _get from "lodash/get";

import BoostProfileRightSidebarStyles from "./BoostProfileRightSidebarStyles";
import CardCustom from "../../CardCustom/CardCustom";


const BoostProfileRightSidebar = ({ status }) => {
  return (
    <BoostProfileRightSidebarStyles>
      <h2 className="content-title text-primary">Why it matters</h2>
      <div className="matters-content">
        {status.matters &&
          status.matters.map((matter, index) => (
            <CardCustom className="matters-card" key={index}>
              <div className="card-left-side">
                {_get(matter, "image", "Not found")}
              </div>
              <div className="card-right-side">
                <h3 className="matter-title text-primary">
                  {_get(matter, "title", "")}
                </h3>
                <div className="matter-content text-gray">
                  {_get(matter, "description", "")}
                </div>
              </div>
            </CardCustom>
          ))}
      </div>
    </BoostProfileRightSidebarStyles>
  );
};

export default BoostProfileRightSidebar;
