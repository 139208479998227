import React from "react";
import _get from "lodash/get";
import { useSelector } from "react-redux";

import { useAppState } from "../../../../../context";
import JobsListingEffect from "../../../../effects/JobsListingEffect/JobsListingEffect";
import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";

const WebinarSettings = ({ isFetching }) => {
  const { notificationSettings } = useAppState("notifications");
  const { permissions = {} } = useSelector((store) => store.userStore);

  const title =
    (permissions["webinar"] &&
      permissions["pastOrUpcomingWebinarsAvailable"]) ||
    permissions["resources"] ||
    permissions["student_services"]
      ? permissions["webinar"] &&
        permissions["pastOrUpcomingWebinarsAvailable"] &&
        permissions["resources"]
        ? "Webinar & Resources"
        : permissions["webinar"] &&
          permissions["pastOrUpcomingWebinarsAvailable"] &&
          permissions["student_services"]
        ? "Webinar & Student services"
        : permissions["resources"] && permissions["student_services"]
        ? "Resources & Student services"
        : permissions["webinar"] &&
          permissions["pastOrUpcomingWebinarsAvailable"]
        ? "Webinar & Posts"
        : permissions["resources"]
        ? "Resources & Posts"
        : "Student services & Posts"
      : "Posts";

  return isFetching ? (
    <JobsListingEffect count={3} />
  ) : (
    <>
      <h2 className="title-primary mb-15">{title} Settings</h2>
      {permissions["webinar"] &&
        permissions["pastOrUpcomingWebinarsAvailable"] && (
          <NotificationSettingPanel
            title="New webinars"
            subTitle="Receive alerts whenever a new webinar is posted."
            type="webinars"
            appPush={_get(notificationSettings, "webinar_push", false)}
            email={_get(notificationSettings, "webinar_email", false)}
          />
        )}

      {permissions["resources"] && (
        <NotificationSettingPanel
          title="New resources"
          subTitle="Receive alerts whenever a new resource is posted."
          type="resources"
          appPush={_get(notificationSettings, "resource_push", false)}
          email={_get(notificationSettings, "resource_email", false)}
        />
      )}

      {permissions["student_services"] && (
        <NotificationSettingPanel
          title="New services"
          subTitle="Receive alerts whenever a new service is posted."
          type="services"
          appPush={_get(notificationSettings, "service_push", false)}
          email={_get(notificationSettings, "service_email", false)}
        />
      )}

      <NotificationSettingPanel
        title="New posts"
        subTitle="Receive alerts whenever a new post is created."
        type="user_posts"
        appPush={_get(notificationSettings, "user_post_push", false)}
        email={_get(notificationSettings, "user_post_email", false)}
      />
    </>
  );
};

export default WebinarSettings;
