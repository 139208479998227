import React, { memo } from "react";
import { Grid } from "@mui/material";
import { ShimmerCategoryItem } from "react-shimmer-effects";

const JobsListingEffect = ({
  count = 3,
  styles = {},
  hasImage = true,
  imageWidth = 90,
  imageHeight = 90,
  cta = false,
}) => {
  const cssStyles = {
    backgroundColor: "#fff",
    marginBottom: "10px",
    borderRadius: "8px",
    padding: "20px",
    ...styles,
  };
  return (
    <Grid container spacing={2}>
      {Array.from(Array(count).keys()).map((_, index) => (
        <Grid item xs={12} key={index}>
          <div style={cssStyles}>
            <ShimmerCategoryItem
              hasImage={hasImage}
              imageType="thumbnail"
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              text
              cta={cta}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
export default memo(JobsListingEffect);
