import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImpulseSpinner } from "react-spinners-kit";

import { useAppState } from "../../../context";
import { resetOPTInitialState } from "../../../redux/reducers/optReducer";
import { signOutCall } from "../../../utils/common";
import CustomDialog from "../../CustomDialog/CustomDialog";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import { useFetchProfile } from "../../../hooks/Profile/useFetchProfile";
import { useUpdateProfile } from "../../../hooks/Profile/useUpdateProfile";
import {
  useFetchAndUpdateAmbassadorProfile,
  useUpdateAmbassadorProfile,
} from "../../../hooks/Profile/useCommonAmbassadorProfile";
import styles from "./ProfileHeaderV2.module.scss";

import farrow from "../../../assets/svg/f-dark-right-arrow.svg";

const cx = createModuleStyleExtractor(styles);

export default function ProfileHeaderV2() {
  const dispatch = useDispatch();
  const { users = {} } = useSelector((store) => store.userStore);
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const { resetChatStateOnLogout } = useAppState("chat");
  const { resetProfileState, setTouchedSections, tab } = useAppState("profile");
  const { handleSave, loading: profileUpdating } = useUpdateProfile();
  const { handleAmbassadorProfileUpdate, loadingAmbassadorPatchAPI } =
    useUpdateAmbassadorProfile();

  // Custom Hooks call
  const state = useFetchProfile();
  const { profile, loading } = state || {};
  const { isProfileCompleted } = profile || {};
  useFetchAndUpdateAmbassadorProfile();

  const handleSignout = () => {
    dispatch(resetOPTInitialState());
    resetProfileState();
    resetChatStateOnLogout();
    signOutCall(users.school_id);
  };

  const handleProfileSave = async () => {
    if (tab === "ambassador") {
      await handleAmbassadorProfileUpdate();
    } else {
      setTouchedSections({ personal: true, education: true });
      handleSave();
    }
  };

  return (
    <div className={cx("profile-header")}>
      <div className={cx("profile-header__title")}>
        <h2>My Profile</h2>
        <div className={cx("profile-header__title__button")}>
          {!loading && isProfileCompleted && (
            <div className={cx("profile-header__title__button__save")}>
              <Button
                onClick={handleProfileSave}
                disabled={profileUpdating || loadingAmbassadorPatchAPI}
              >
                {profileUpdating || loadingAmbassadorPatchAPI ? (
                  <ImpulseSpinner
                    size={30}
                    backColor="#FFFFFF"
                    frontColor="#FFFFFF"
                  />
                ) : (
                  <span className={cx("profile-header__title__button__save")}>
                    Save
                  </span>
                )}
              </Button>
            </div>
          )}
          <Button onClick={() => setShowSignOutModal(true)}>
            <span>Sign out</span>
            <img src={farrow} className="arrow-icon" alt={"Sign out"} />
          </Button>
        </div>
      </div>
      {/* Signout Modal */}
      <div className={cx("profile-header__signout-modal")}>
        <CustomDialog
          open={showSignOutModal}
          title={"Are you sure you want to sign out?"}
          handleClose={() => setShowSignOutModal(false)}
          className="sign-out-popup"
        >
          <div className={cx("profile-header__signout-modal__body")}>
            <Button onClick={handleSignout}>Sign out</Button>
            <a
              href="/#"
              className={cx("profile-header__signout-modal__body__cancel")}
              onClick={(e) => {
                e.preventDefault();
                setShowSignOutModal(false);
              }}
              aria-label="cancel"
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      </div>
    </div>
  );
}
