import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getCategoryByServiceId = (serviceId) => {
  if (!serviceId || serviceId === -1) return -1;
  return axiosInstance
    .get(
      `deals/deal_category_from_deal?deal_id=${serviceId}`,
      headers_with_token()
    )
    .then((response) => response.data);
};
export const getCategoriesAPI = (search = "") => {
  return axiosInstance
    .get(`deals/categories?search=${search}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getStudentServicesAPI = (
  id,
  page = 1,
  per_page = 10,
  options = {}
) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  let link = `deals/${id}/category?page=${page}&per_page=${per_page}`;
  link = Object.keys(options).length > 0 ? `${link}&${queryString}` : link;
  return (
    axiosInstance
      // .get(`deals/${id}/category`, headers_with_token())
      .get(link, headers_with_token())
      .then((res) => {
        return res.data;
      })
  );
};

export const getSavedServicesAPI = () => {
  return axiosInstance
    .get(`deals/favorite_deals`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getServiceById = dealId => {
  return axiosInstance
    .get(`deals/${dealId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateFavouriteServiceAPI = ({ deal_id, like = false }) => {
  return axiosInstance
    .post(`deals/favorite_deal`, { deal_id, like }, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const subscribeCategoryAlertAPI = (deal_category_id) => {
  return axiosInstance
    .post(
      `deals/deal_category_subscribe_alert`,
      { deal_category_id },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};
