import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getMeetingPurposeById = async (id) => {
  return await axiosInstance
    .get(`/meeting_purposes/${id}`, headers_with_token())
    .then((res) => {
      return res.data.data;
    });
};

export const getMeetingPurposes = async () => {
  return await axiosInstance
    .get("/appointment_settings/fetch_meeting_purpose", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getUsersByMeetingPurposeId = async (id, search = "") => {
  return await axiosInstance
  .get(
    `appointment_settings/fetch_users_by_purpose?meeting_purpose_id=${id}&search=${search}`,
    headers_with_token()
  )
  .then((res) => {
    return res.data.data;
  });
};

export const getUsersAvailibility = async (month, year, appointed_user_ids, timezone, timezone_id) => {
  const requestData = {
    month, year, appointed_user_ids, timezone, timezone_id
  }
  return await axiosInstance
    .post(
      `hours_settings/check_availibility`, requestData, headers_with_token()
    )
    .then((res) => {
      return res.data.data;
    });
};

export const getUserTimeslots = async (date, admin_id, timezone, hour_type, timezone_id, start_time) => {
  let requestData = {
    date,
    admin_id,
    timezone,
    hour_type,
    timezone_id,
    start_time
  }
  return await axiosInstance
    .post(`hours_settings/fetch_available_slots`, requestData, headers_with_token())
    .then((res) => {
      let data = res.data.data;
      let finalData = []
      if(res.data.success && data.length > 0) {
        data.map((i) => {
          let formatTime = i.substring(0, i.length - 3)
          finalData.push({label: formatTime, value: formatTime})
        })
      } else if(!res.data.success) {
        return res.data.message
      }
      return finalData;
    }).catch((error) => {
      return error
    });
};
