import React from "react";
import OPTQueryContainerStyles from "./OPTQueryContainerStyles";

const OPTQueryContainer = ({ title, children }) => {
  return (
    <OPTQueryContainerStyles>
      <div className="opt_query-container">
        <div className="opt_query-container__title">{title}</div>
        {children}
      </div>
    </OPTQueryContainerStyles>
  );
};

export default OPTQueryContainer;
