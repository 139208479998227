import React, { useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import HorizontalBarChart from "../../../../../components/atoms/HorizontalBarChart/HorizontalBarChart";
import DropdownCustom from "../../../../../components/DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../../../utils/css";
import styles from "./TopEmployerGraph.module.scss";
import Card from "../../../../../components/CardCustom/CardCustom";
import { getGraphData } from "../../../../../services/employmentDataService";
import { seedGraphWrapperHeightTopEmp } from "../../Utils/EmploymentDataConstants";
import { widgetEmploymentGraphAPI } from "../../../../../services/widgetServices";

import NoDataImage from "../../../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function TopEmployerGraph({
  widget = false,
  token,
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter = () => {},
  schoolId,
  processGraphClick = () => {},
}) {
  // Calls API upon component mount
  useEffect(() => {
    (async () => {
      let response = {};
      if (widget) {
        response = await widgetEmploymentGraphAPI(token);
      } else {
        const { major, year, nationality, degree, employment_type, country } =
          state?.selectedFilters || {};

        response = await getGraphData({
          school_id: schoolId,
          graph_type: "employer",
          degree: degree?.value,
          major: major?.value,
          year: year?.value,
          nationality: nationality?.value,
          employment_type: employment_type?.value,
          country: country?.value,
        });
      }

      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _labels = [];
        let _data = [];
        let _graphHeight = seedGraphWrapperHeightTopEmp;

        for (let i = 0; i < response.data.length; i++) {
          _labels.push(response.data[i]?.company);
          _data.push(response.data[i]?.count);
          // Increase graph height per record dynamically if more than 10 records
          if (i > 9) _graphHeight += 45; // Pixels
        }
        setState((current) => ({
          ...current,
          loader: false,
          graphHeight: _graphHeight,
          data_set: {
            labels: _labels,
            data: _data,
          },
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        graphHeight: seedGraphWrapperHeightTopEmp,
        data_set: {
          labels: null,
          data: null,
        },
      }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedFilters]);

  //  CPT, OPT and STEM OPT are only relevant for US
  const onEmploymentTypeChange = (option) => {
    const isUSSpecific =
      option?.value &&
      (option.value === "CPT" ||
        option.value === "OPT" ||
        option.value === "STEM OPT");
    // clearing residue country value conditionally
    setState((current) => ({
      ...current,
      selectedFilters: {
        ...current.selectedFilters,
        country: isUSSpecific ? null : current.selectedFilters.country,
        employment_type: option,
      },
    }));
  };

  return (
    <div
      className={cx(["m-top-emp", ...(widget ? ["m-top-emp--widget"] : [])])}
    >
      {!widget && (
        <div
          className={`collapsible-section ${
            openFilter ? "collapsible-section--open" : ""
          }`}
        >
          <div
            className={cx("m-top-emp__header")}
            id={"collapsible-section-header"}
          >
            <h2 className={cx("m-top-emp__header__title")}>
              Top employers for international graduates
            </h2>

            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleFilter(!openFilter);
              }}
              id="collapsible-section-header-icon"
            >
              <FilterIcon fill={openFilter} />
            </a>
            <div
              className={cx("m-top-emp__header__filters")}
              id="collapsible-section-search-filter"
            >
              <DropdownCustom
                name="degree"
                placeholder="Degree"
                value={state?.selectedFilters?.degree}
                justifyContent="left"
                options={filters?.degree}
                onChange={(option) =>
                  setState((current) => ({
                    ...current,
                    selectedFilters: {
                      ...current.selectedFilters,
                      degree: option,
                    },
                  }))
                }
                isClearable
              />
              <DropdownCustom
                name="major"
                placeholder="Major"
                value={state?.selectedFilters?.major}
                justifyContent="left"
                options={filters?.major}
                onChange={(option) =>
                  setState((current) => ({
                    ...current,
                    selectedFilters: {
                      ...current.selectedFilters,
                      major: option,
                    },
                  }))
                }
                isClearable
              />
              <DropdownCustom
                name="nationality"
                placeholder="Nationality"
                value={state?.selectedFilters?.nationality}
                justifyContent="left"
                options={filters?.nationality}
                onChange={(option) =>
                  setState((current) => ({
                    ...current,
                    selectedFilters: {
                      ...current.selectedFilters,
                      nationality: option,
                    },
                  }))
                }
                isClearable
              />
              <DropdownCustom
                name="year"
                placeholder="Year"
                value={state?.selectedFilters?.year}
                justifyContent="left"
                options={filters?.year}
                onChange={(option) =>
                  setState((current) => ({
                    ...current,
                    selectedFilters: {
                      ...current.selectedFilters,
                      year: option,
                    },
                  }))
                }
                isClearable
              />
              <DropdownCustom
                name="Employment Type"
                placeholder="Type"
                value={state?.selectedFilters?.employment_type}
                justifyContent="left"
                options={filters?.employment_type}
                onChange={onEmploymentTypeChange}
                isClearable
              />
              {state?.selectedFilters?.employment_type?.value === "CPT" ||
              state?.selectedFilters?.employment_type?.value === "OPT" ||
              state?.selectedFilters?.employment_type?.value === "STEM OPT" ? (
                <></>
              ) : (
                <DropdownCustom
                  name="country"
                  placeholder="Job Location"
                  value={state?.selectedFilters?.country}
                  justifyContent="left"
                  options={filters?.country}
                  onChange={(option) =>
                    setState((current) => ({
                      ...current,
                      selectedFilters: {
                        ...current.selectedFilters,
                        country: option,
                      },
                    }))
                  }
                  isClearable
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className={cx("m-top-emp__body")}>
        <Card className={cx("m-top-emp__body__card")}>
          {state.loader ? (
            <div className={cx("m-top-emp__body__card__loader")}>
              <ShimmerThumbnail rounded />
            </div>
          ) : !state.loader &&
            (state.data_set.labels === null || state.data_set.data === null) ? (
            <div className={cx("m-top-emp__body__card__no-data")}>
              <img src={NoDataImage} alt="No Data Available" />
              <h3>No data available</h3>
            </div>
          ) : !state.loader &&
            state.data_set.labels?.length > 0 &&
            state.data_set.data?.length > 0 ? (
            <div className={cx("m-top-emp__body__card__graph")}>
              <div
                // Dynamically adjust graph height. Helps with scroll
                style={{ height: state?.graphHeight + "px" }}
              >
                <HorizontalBarChart
                  labels={state.data_set.labels}
                  data={state.data_set.data}
                  enableCursorPointer
                  onClickHandler={(coordinates) =>
                    processGraphClick(coordinates, "TopEmployer")
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
