import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .find-job-section {
    display: block;
    .title-primary {
      display: flex;
      align-items: center;
    }
    .search-card {
      padding: 20px 20px;
      overflow: visible;
      .MuiCardContent-root {
        padding: 0;
      }
      .searchBtn {
        display: block;
        margin-top: 30px;
        @media (max-width: 991px) {
          margin-top: 16px;
        }
        button {
          width: 100%;
          background-color: var(--link-color) !important;
        }
      }
    }
  }
`;
