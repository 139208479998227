import CustomDialog from "../../../CustomDialog/CustomDialog";
import styled from "styled-components";

export const CustomDialogBox = styled(CustomDialog)`
  .checkbox-wrap {
    margin: 20px 0;
  }
  .form-label {
    margin: 20px 0 8px 0 !important;
    justify-content: center;
  }
  .form-control {
    input {
      text-align: center !important;
    }
    min-width: 332px;
  }
`;
