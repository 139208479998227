import React, { useEffect } from "react";

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    const getPreferredLanguage = () => {
      const languages = navigator.languages || [
        navigator.language || navigator.userLanguage,
      ];
      return languages[0].split("-")[0];
    };

    const autoTranslate = () => {
      const lang = getPreferredLanguage();
      setTimeout(() => {
        const select = document.querySelector("select.goog-te-combo");
        if (select) {
          select.value = lang;
          select.dispatchEvent(new Event("change"));
        }

        // Reset body to top
        document.body.style.top = 0;
      }, 1000);

      setTimeout(() => {
        // Reset body to top
        document.body.style.top = 0;
      }, 2000);
    };

    autoTranslate();
  }, []);

  return <div id="google_translate_element" style={{ display: "none" }} />;
};

export default GoogleTranslate;
