import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preTestStep: 1,
  currentTestStep: -1,
  testType: null,
  testQuestionsList: [],
  testQuestionsLabels: [],
  testAnswersList: [],
  isCompletedTest: false,
};

export const gameplanReducer = createSlice({
  name: "gameplan",
  initialState,
  reducers: {
    setPreTestStep: (state, action) => {
      state.preTestStep = action.payload;
      state.currentTestStep = action.payload <= 2 ? -1 : state.currentTestStep;
    },
    setTestType: (state, action) => {
      state.testType = action.payload;
      state.currentTestStep = -1;
      state.testQuestionsList = [];
      state.testQuestionsLabels = [];
      state.testAnswersList = [];
      state.isCompletedTest = false;
    },
    setTestQuestions: (state, action) => {
      state.testQuestionsList = action.payload;
      state.testQuestionsLabels = action.payload.map(
        ({ category_name }) => category_name
      );
      state.isCompletedTest = false;
      state.currentTestStep = 0;
    },
    updateTestData: (state, action) => {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
    resumePreviousTest: (state) => {
      state.isCompletedTest = false;
      state.currentTestStep =
        state.currentTestStep === -1 ? 0 : state.currentTestStep;
    },
    resetTestState: (state) => {
      state.preTestStep = 1;
      state.testType = null;
      state.currentTestStep = -1;
      state.testQuestionsList = [];
      state.testQuestionsLabels = [];
      state.testAnswersList = [];
      state.isCompletedTest = false;
    },
  },
});

export const {
  updateTestData,
  setPreTestStep,
  setTestType,
  setTestQuestions,
  resetTestState,
  resumePreviousTest,
} = gameplanReducer.actions;

export default gameplanReducer.reducer;
