import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import moment from "moment";
import _find from "lodash/find";
import isNull from "underscore/modules/isNull";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import MyWebinarStyles from "./WebinarListStyles";
import webinarIcon from "../../../../assets/png/Others.png";
import RSS from "../../../../assets/png/handshake_rss_logo.png";
import Image from "../../../../assets/svg/discover_no_people.svg";
import CircleCheck from "../../../../assets/svg/f-circle-check.svg";

import { useQueryParams } from "../../../../hooks/useQueryParams";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../../helper/helper";
import ReadMoreReadLess from "../../../atoms/ReadMoreLess/ReadMoreLess";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import Badge from "../../../Badge/Badge";
import EmptyListWithImage from "../../../EmptyListWithImage/EmptyListWithImage";
import userAvtar from "../../../../assets/svg/f-user-avatar.svg";

const MAX_LINES_WEBINAR = 5;

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const WebinarList = ({ id = "webinars-list", webinars, type, myWebinars }) => {
  const params = useQueryParams();
  const navigate = useNavigate();

  const [scrolled, setIsScrolled] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(-1);

  const { users = {} } = useSelector((store) => store.userStore);
  const isAdmin = users ? users.user_type === "school_admin" || "school" : null;

  const handleClick = (link) => {
    window.open(link);
  };

  useEffect(() => {
    try {
      if (params.has("notificationId")) {
        const notificationId = params.get("notificationId");
        const element = document.getElementById(`${notificationId}`);

        if (element && !scrolled.includes(notificationId)) {
          element.scrollIntoView({ behavior: "smooth" });
          setIsScrolled([...scrolled, notificationId]);
          setCurrentNotification(notificationId);
        }
      }
    } catch (error) {
      console.log("error", error?.message);
    }
  }, [params, webinars]);

  useEffect(() => {
    if (!currentNotification || currentNotification == -1) return;
    if (scrolled.includes(currentNotification)) {
      params.delete("notificationId");
      navigate(window.location.pathname, { replace: true });
    }
  }, [currentNotification, scrolled]);

  const renderWebinars = (data, i) => {
    const btn = {};
    if (type === "upcoming") {
      if (
        _find(myWebinars, (o) => {
          return o.id === data.id;
        })
      ) {
        btn.disabled = true;
        btn.text = "Registered";
        btn.className = "bg-registered";
        btn.showIcon = true;
      } else {
        btn.disabled = false;
        btn.text = "Register";
        btn.className = "";
        btn.showIcon = false;
      }
    } else {
      btn.disabled = false;
      btn.text = "View Recording";
      btn.className = "";
    }

    return (
      <Grid item xs={12} key={i} id={`${data.id}`}>
        <CardCustom
          className={
            currentNotification !== -1 && currentNotification == data.id
              ? "webinar-cards webinar-cards--active"
              : "webinar-cards "
          }
        >
          <div className="item">
            <div className="img-wrapper">
              {data.webinar_image && data.webinar_image.length > 0 ? (
                <img src={data.webinar_image} alt="Webinar Display" />
              ) : (
                <img src={webinarIcon} alt="Webinar Icon" />
              )}
              {data.webinar_category_name && (
                <div className="webinarCategory">
                  {data.webinar_category_name}
                </div>
              )}
            </div>
            <div className="webinar-description">
              <div className="header-primary">{data.name}</div>
              {data.webinar_category_name === "Admin Training" && (
                <div className="tag">
                  <Badge
                    tooltipText={"Created for Admins only"}
                    title={"For Admins Only"}
                  />
                </div>
              )}{" "}
              <div className="description">
                {data.rss_feed_id && data.rss_feed_id != null ? (
                  <img
                    src={RSS}
                    className="custom-rss-icon"
                    alt="Webinar-Rss-Image"
                  />
                ) : (
                  ""
                )}
                <ReadMoreReadLess
                  maxLines={MAX_LINES_WEBINAR}
                  moreComp={
                    <a href="#" className="cursor-pointer nlink-secondary">
                      Read more
                    </a>
                  }
                  lessComp={
                    <a href="#" className="cursor-pointer nlink-secondary">
                      Read less
                    </a>
                  }
                >
                  {data.description}
                </ReadMoreReadLess>
              </div>
            </div>
            <div className="webinar-schedule-info">
              <div className="sub-header-primary">
                {moment
                  .unix(data?.epoch_time)
                  .local()
                  .format("ddd MMMM Do, YYYY")}
              </div>
              <div className="sub-header-primary">
                {`${moment
                  .unix(data?.epoch_time)
                  .local()
                  .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                  data?.epoch_time,
                  data && data.duration && !isNull(data.duration)
                    ? data.duration.split(" ")[0]
                    : 0,
                  "minutes"
                )}  (${getLocalTzAbbr()})`}{" "}
              </div>
              {data.presenter && data.presenter.length > 0 && (
                <div className="text-grey">
                  <span>
                    <img src={userAvtar} alt="User avatar" />
                  </span>
                  {data.presenter}
                </div>
              )}

              {data && data.attachment && data.attachment != "" ? (
                <a
                  href={data.attachment}
                  download
                  target="blank"
                  aria-label="Open attachment"
                  className="text-secondary link-focus"
                >
                  <div className="download-btn">
                    <span>attachment</span>
                  </div>
                </a>
              ) : (
                ""
              )}
              <div className="btn">
                {type === "upcoming" && data.register_link !== "" && (
                  <ButtonCustom
                    className={`up-btn ${btn.className}`}
                    onClick={() => handleClick(data.register_link)}
                    isDisabled={
                      btn.disabled ||
                      (data.webinar_category_name === "Admin Training" &&
                        !isAdmin)
                    }
                    aria-label={`${btn.text} ${
                      btn.text && btn.text.includes("Register") ? "for" : "of"
                    } ${data.name}`}
                    tooltipText="Use your university email to register for webinars."
                  >
                    {btn.showIcon ? (
                      <div className="flex justify-between align-items-center capitalize">
                        <span className="pt-3">{btn.text}</span>

                        <img src={CircleCheck} className="button-image" />
                      </div>
                    ) : (
                      <div>{btn.text}</div>
                    )}
                  </ButtonCustom>
                )}
                {type === "past" && data.recording_link !== "" && (
                  <ButtonCustom
                    className="past-btn"
                    onClick={() => handleClick(data.recording_link)}
                    aria-label={`${btn.text} ${
                      btn.text && btn.text.includes("Register") ? "for" : "of"
                    } ${data.name}`}
                  >
                    {btn.text}
                  </ButtonCustom>
                )}
              </div>
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <MyWebinarStyles>
      <div className="webinar-list" id={id}>
        <Grid container spacing={3}>
          {webinars.length > 0
            ? getUniqueListBy(webinars, "id").map((data, i) =>
                renderWebinars(data, i)
              )
            : type !== "past" && (
                <Grid item xs={12}>
                  <EmptyListWithImage
                    Image={Image}
                    title="Looks like there are no webinars with this search."
                    subTitle="Try expanding your search to discover other webinars!"
                    image
                  />
                </Grid>
              )}
        </Grid>
      </div>
    </MyWebinarStyles>
  );
};

export default WebinarList;
