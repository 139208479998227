import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicyDB from "./PrivacyPolicyDB/PrivacyPolicyDB";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";

const PrivacyPolicy = () => {
  return (
    <MainLayout title="Privacy Policy" withSubHeader={false}>
      <Container>
        <Routes>
          <Route path="/" element={<PrivacyPolicyDB />} />
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default PrivacyPolicy;
