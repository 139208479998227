import { useEffect } from "react";
import { useState } from "react";
import { useAppState } from "../../context";
import { fetchEducationInformation } from "../../services/profile.service";
export const useFetchEducation = () => {
  const [loading, setIsLoading] = useState(false);
  const { educationsForm, updateProfileState } = useAppState("profile");

  const fetchInfo = async () => {
    setIsLoading(true);
    try {
      const response = await fetchEducationInformation();
      if (response?.success) {
        updateProfileState({
          educationsForm: response?.data?.educations ?? [],
          isEducationChanged: false,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (educationsForm) return;
    fetchInfo();
  }, []);

  return { loading, educationsForm };
};
