import React from "react";
import CardCustomStyles from "./CardCustomStyles";
import { Card, CardContent } from "@mui/material";

const CardCustom = ({
  children,

  className,
  borderRadius = 10,
  noTabIndex = false,
  onClick = () => {},
}) => {
  return (
    <CardCustomStyles borderRadius={borderRadius} className="card-wrapper">
      <Card
        className={className}
        onClick={onClick}
        {...(noTabIndex ? { tabIndex: 0 } : {})}
      >
        <CardContent className={`card-content profile-module-card-content`}>
          {children}
        </CardContent>
      </Card>
    </CardCustomStyles>
  );
};

export default CardCustom;
