import React from "react";

import SavedJobsStyles from "./SavedJobsStyles";
import SavedJobs from "../../../../components/Jobs/SavedJobs/SavedJobs";

const SavedJobsList = () => {
  return (
    <SavedJobsStyles>
      <div className="saved-jobs">
        <SavedJobs />
      </div>
    </SavedJobsStyles>
  );
};
export default SavedJobsList;
