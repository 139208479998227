import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px 30px;
  }
  .saved-posts-wrapper {
    max-width: 670px;
    margin: 0 auto;
  }
`;
