import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";
import onEnter from "react-onenterkeydown";
import { Radio, Tooltip } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { isUndefined } from "lodash";
import isNull from "underscore/modules/isNull";
import isEmpty from "underscore/modules/isEmpty";

import deleteIcon from "../../../../assets/svg/f-delete-icon.svg";
import editIcon from "../../../../assets/svg/f-edit-icon.svg";
import plusIcon from "../../../../assets/svg/f-plus.svg";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";

const EnhancedInput = onEnter("input");

const ChecklistTaskItemsList = ({
  setAddNewSubTask,
  taskItem,
  taskIndex,
  createNewTask,
  editTask,
  editSubtask,
  taskMark,
  removeTask,
  removeStaticTask,
  showAddEditDeleteButton = true,
  activeEditTask,
  setActiveEditTask,
  activeEditSubTask,
  hideSubtaskButton,
  setHideSubtaskButton,
}) => {
  const [taskName, setTaskName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);
  const searchInput = useRef(null);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  useEffect(() => {
    taskItem.task_name && setTaskName(taskItem.task_name);
  }, [taskItem]);

  useEffect(() => {
    if (!activeEditTask) {
      setIsEdit(false);
    }
  }, [activeEditTask]);

  useEffect(() => {
    if (isEdit) {
      searchInput.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (taskItem.task_name !== taskName) {
      setActiveEditTask({ id: taskItem.id, taskName: taskName });
    } else {
      setActiveEditTask(null);
    }
  }, [taskName]);

  return (
    <>
      <div className="checklist-task" id={taskItem.sort_order}>
        <div className="checklist-control task-control">
          <div className="checklist-title-wrapper">
            <div className="ncheckbox-wrapper">
              <Radio
                disableRipple={true}
                id={`task-${taskItem.id}`}
                type="checkbox"
                defaultChecked={taskItem.is_mark == "true"}
                checkedIcon={<CheckCircle color={"primary"} fontSize="small" />}
                inputProps={{
                  "aria-label": taskItem.task_name
                    ? taskItem.task_name
                    : "task",
                }}
                color="primary"
                size="small"
                onChange={(e) => {
                  if (!isUndefined(taskItem.id)) {
                    setIsEdit(false);
                    taskMark(taskItem.id, e.target.checked);
                  }
                }}
              />
            </div>
            <div
              className={cls("title", {
                completed: taskItem.is_mark == "true",
                "text-primary": taskItem.task_name !== undefined,
                "text-gray": taskItem.task_name === undefined,
              })}
            >
              {isEdit ? (
                <EnhancedInput
                  ref={searchInput}
                  type="text"
                  value={taskName}
                  onChange={(e) => {
                    setTaskName(e.target.value);
                  }}
                  placeholder="Add a new task"
                />
              ) : taskItem.task_name === undefined ? (
                <EnhancedInput
                  ref={searchInput}
                  type="text"
                  value={taskName}
                  onChange={(e) => {
                    setTaskName(e.target.value);
                  }}
                  placeholder="Add a new task"
                />
              ) : (
                <div className="primaryTask">{taskName}</div>
              )}
              {taskItem &&
                taskItem.link_name &&
                (!isNull(taskItem.link_name) ||
                  !isEmpty(taskItem.link_name)) && (
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(taskItem.link_url);
                    }}
                    className="nlink-secondary view-all link-focus"
                  >
                    {taskItem.link_name}
                  </Link>
                )}
            </div>
          </div>

          {isEdit ? (
            <ButtonCustom
              width="94"
              height="40"
              color="primary"
              className="btn-save"
              onClick={() => {
                setIsEdit(false);
                setActiveEditTask({ id: taskItem.id, taskName: taskName });
                taskName &&
                  taskName.trim().length > 0 &&
                  editTask(taskItem.id, taskName);
              }}
            >
              Save task
            </ButtonCustom>
          ) : taskItem.task_name === undefined ? (
            <ButtonCustom
              width="94"
              height="40"
              color="primary"
              className="btn-save"
              onClick={() => {
                setIsEdit(false);
                taskName &&
                  taskName.trim().length > 0 &&
                  createNewTask(taskItem.category_id, taskName);
              }}
            >
              Save task
            </ButtonCustom>
          ) : null}
          {taskItem &&
            taskItem.task_name &&
            taskItem.user_id == localStorage.getItem("userId") &&
            showAddEditDeleteButton && (
              <>
                <Tooltip
                  title="Edit task"
                  placement="bottom"
                  open={!isNull(showTooltip) && showTooltip === "main-edit"}
                  disableHoverListener
                  onMouseEnter={() => setShowTooltip("main-edit")}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEdit(true);
                      setActiveEditTask({
                        id: taskItem.id,
                        taskName: taskName,
                      });
                      if (activeEditSubTask) {
                        editSubtask(
                          activeEditSubTask.id,
                          activeEditSubTask.title,
                          activeEditSubTask.task_id,
                          true
                        );
                      }
                    }}
                    className={`btn-edit link-focus ${
                      accessibilityHelp == "true" ? "show-actions" : ""
                    }`}
                  >
                    <img src={editIcon} alt="Edit button" />
                  </Link>
                </Tooltip>
                {taskItem.id ? (
                  <Tooltip
                    title="Delete task"
                    placement="bottom"
                    open={!isNull(showTooltip) && showTooltip === "main-delete"}
                    disableHoverListener
                    onMouseEnter={() => setShowTooltip("main-delete")}
                    onMouseLeave={() => setShowTooltip(null)}
                  >
                    <button
                      className={`btn-close ${
                        accessibilityHelp == "true" ? "show-actions" : ""
                      }`}
                      onClick={() => removeTask(taskItem.id)}
                      aria-label={`Close task ${taskItem.task_name}`}
                      aria-describedby={`task-${taskItem.id}`}
                    >
                      <img src={deleteIcon} alt="Close icon" />
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Delete task"
                    placement="bottom"
                    open={!isNull(showTooltip) && showTooltip === "main-delete"}
                    disableHoverListener
                    onMouseEnter={() => setShowTooltip("main-delete")}
                    onMouseLeave={() => setShowTooltip(null)}
                  >
                    <button
                      className={`btn-close ${
                        accessibilityHelp == "true" ? "show-actions" : ""
                      }`}
                      onClick={() => removeStaticTask(taskIndex)}
                      aria-label={`Remove task ${taskItem.task_name}`}
                      aria-describedby={`task-${taskItem.id}`}
                    >
                      <img src={deleteIcon} alt="Close icon" />
                    </button>
                  </Tooltip>
                )}
              </>
            )}

          {showAddEditDeleteButton && !taskItem.id && (
            <Tooltip
              title="Delete task"
              placement="bottom"
              open={!isNull(showTooltip) && showTooltip === "main-delete"}
              disableHoverListener
              onMouseEnter={() => setShowTooltip("main-delete")}
              onMouseLeave={() => setShowTooltip(null)}
            >
              <button
                className={`btn-close ${
                  accessibilityHelp == "true" ? "show-actions" : ""
                }`}
                onClick={() => removeStaticTask(taskIndex)}
                aria-label={`Close task ${taskItem.task_name}`}
                aria-describedby={`task-${taskItem.id}`}
              >
                <img src={deleteIcon} alt="Close icon" />
              </button>
            </Tooltip>
          )}
          {showAddEditDeleteButton && !hideSubtaskButton && (
            <ButtonCustom
              className={`shadow-btn ${
                accessibilityHelp == "true" ? "show-actions" : ""
              }`}
              onClick={() => {
                setAddNewSubTask(taskIndex);
                setHideSubtaskButton(true);
              }}
              isDisabled={hideSubtaskButton || !taskItem.task_name}
              aria-label={`Add Subtasks of task ${taskItem.task_name}`}
            >
              Add subtask
              <img src={plusIcon} className="plus-icon" alt="Plus icon" />
            </ButtonCustom>
          )}
        </div>
      </div>
    </>
  );
};

export default ChecklistTaskItemsList;
