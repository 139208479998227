import React from "react";
import _get from "lodash/get";

import { useAppState } from "../../../../../context";
import NotificationSettingPanel from "../NotificationSettingPanel/NotificationSettingPanel";
import JobsListingEffect from "../../../../effects/JobsListingEffect/JobsListingEffect";

const CalendarEventSettings = ({ isFetching }) => {
  const eventList = [
    { event: "On time", checked: false },
    { event: "1 hour before", checked: false },
    { event: "1 day before", checked: false },
  ];
  const { notificationSettings } = useAppState("notifications");

  return isFetching ? (
    <JobsListingEffect count={3} />
  ) : (
    <>
      <h2 className="title-primary mb-25">Calendar Events Settings</h2>
      <NotificationSettingPanel
        title="Upcoming events"
        subTitle="Receive an alert for your upcoming events created in the Calendar."
        type="events"
        appPush={_get(notificationSettings, "event_push", false)}
        email={_get(notificationSettings, "event_email", false)}
        events={eventList}
      />
      <NotificationSettingPanel
        title="Upcoming webinars"
        subTitle="Receive an alert for upcoming webinars you registered for."
        type="user_posts"
        appPush={_get(notificationSettings, "user_post_push", false)}
        email={_get(notificationSettings, "user_post_email", false)}
        events={eventList}
      />
    </>
  );
};

export default CalendarEventSettings;
