import React, { useState } from "react";
import styles from "./Experience.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import InformationIcon from "../../../assets/svg/InformationIcon";
import { ProfessionalExperience } from "./ProfessionalExperience/ProfessionalExperience";
import LeadershipExperience from "./LeadershipExperience/LeadershipExperience";
import { ExperienceLanguages } from "./Languages/Language";
import { ProfileExperience } from ".";
import ProfilePopup from "../ProfilePopup/ProfilePopup";
const cx = createModuleStyleExtractor(styles);
export const Experience = () => {
  const [element, setElement] = useState(null);
  const [text, setText] = useState("");
  const handleClick = (e, text) => {
    try {
      setElement(e.currentTarget);
      setText(text);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setElement(null);
    setText("");
  };
  return (
    <ProfileExperience>
      {({ experiences }) => (
        <>
          <ProfilePopup
            open={Boolean(element)}
            onClose={handleClose}
            anchorEl={element}
            text={text}
          />
          <h1 className={cx(["o-experience-heading"])}>Experience</h1>
          <div className={cx(["o-experience-container"])}>
            <h1 className={cx("o-experience-container__top-heading")}>
              Professional experience
              <span>
                <InformationIcon
                  onClick={(e) =>
                    handleClick(
                      e,
                      "This could be a job, internship, or freelance work that contributes directly to your career interests."
                    )
                  }
                />
              </span>
            </h1>
            <CoverHeader>
              <ProfessionalExperience
                experiences={experiences?.experiences || []}
              />
            </CoverHeader>
          </div>

          <div className={cx(["o-experience-container"])}>
            <h1 className={cx("o-experience-containers__top-heading")}>
              Extracurricular & leadership experience
              <span>
                <InformationIcon
                  onClick={(e) =>
                    handleClick(
                      e,
                      `Think about your experience outside of the classroom and workplace that highlight your interests and abilities. Maybe you tutor students, lead a student organization, or organize events – think about experiences that demonstrate valuable and transferrable skills!`
                    )
                  }
                />
              </span>
            </h1>
            <CoverHeader>
              <LeadershipExperience
                experiences={experiences?.extra_experiences || []}
              />
            </CoverHeader>
          </div>

          <div className={cx(["o-laguage-container"])}>
            <h1 className={cx("o-laguage-container__top-heading")}>
              Languages
            </h1>
            <CoverHeader>
              <ExperienceLanguages languages={experiences?.languages || []} />
            </CoverHeader>
          </div>
        </>
      )}
    </ProfileExperience>
  );
};
