import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: var(--card-box-shadow);
  margin-bottom: 15px;
  padding: 20px 20px;
  padding-bottom: 0;
`;
