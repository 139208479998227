import React from "react";

function CheckMark({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#000C3D" strokeWidth="3" transform="translate(-323 -242)">
          <g transform="translate(205 229)">
            <path d="M119 19.5947785L122.780152 23 131 14"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckMark;
