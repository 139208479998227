import React, { useEffect } from "react";
import cls from "classnames";

import ChecklistLeftSidebarStyles from "./ChecklistLeftSidebarStyles";
import CardCustom from "../../../CardCustom/CardCustom";
import { useAppState } from "../../../../context";
import { getChecklistByCategoryId } from "../../../../services/ChecklistServices";

const ChecklistLeftSidebar = () => {
  const {
    checkListCategories,
    selectedCategoriesIndex,
    setSelectedChecklistCategory,
    setChecklist,
    setLoader,
  } = useAppState("tools");

  const getListByCategory = async (id) => {
    const response = await getChecklistByCategoryId(id);
    if (response.success) {
      setChecklist(
        checkListCategories[selectedCategoriesIndex].id,
        response.data
      );
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getListByCategory(checkListCategories[selectedCategoriesIndex].id, 0);
  }, []);

  return (
    <ChecklistLeftSidebarStyles>
      <div className="categories">
        <CardCustom className="checklist-card">
          <div className="checklist-items">
            <ul>
              {checkListCategories.map((item, k) => (
                <li
                  key={k}
                  className={cls({ active: selectedCategoriesIndex === k })}
                  onClick={() => {
                    setSelectedChecklistCategory(k);
                    setLoader(true);
                    setChecklist(
                      checkListCategories[selectedCategoriesIndex].id,
                      []
                    );
                    getListByCategory(checkListCategories[k].id, k);
                  }}
                >
                  <a
                    className="link"
                    href="/#"
                    aria-label={item.category_name}
                    onClick={(e) => e.preventDefault()}
                  >
                    <span>{item.category_name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </CardCustom>
      </div>
    </ChecklistLeftSidebarStyles>
  );
};

export default ChecklistLeftSidebar;
