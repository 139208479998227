import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

/** Get Resource Categories */
export const getVideoCategoriesListAPI = (search) => {
  return axiosInstance
    .get(`videos/video_categories?search=${search}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** Get Save Resources List */
export const getSavedResourcesAPI = (search = null, group_by = null) => {
  return axiosInstance
    .get("videos/saved_resources_list", {
      headers: headers_with_token().headers,
      params: {
        search,
        group_by,
      },
    })
    .then((res) => {
      return res.data;
    });
};

/** Save or Un-save any resource by resource id */
export const saveUnSaveResourceAPI = (payload) => {
  return axiosInstance
    .post("videos/save_resource", payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** Get resource by passing resource id */
export const getResourceById = (resourceId) => {
  return axiosInstance
    .get(`videos/${resourceId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getResourcesListAPI = ({
  video_category_id,
  sort_by = "type",
  group_by = null,
}) => {
  let params = {
    video_category_id,
    sort_by,
  };
  if (group_by) params["group_by"] = group_by;
  return axiosInstance
    .get("videos", {
      headers: headers_with_token().headers,
      params,
    })
    .then((res) => res.data);
};

export const subscribeCategoryAlertAPI = (video_category_id) => {
  return axiosInstance
    .post(
      `videos/video_category_subscribe_alert`,
      { video_category_id },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getResourceCategoryById = (resourceId) => {
  const url = `videos/video_category_from_video?video_id=${resourceId}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, headers_with_token())
      .then((response) => {
        //resolve(223);
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
};
