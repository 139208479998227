import React from "react";

function RemoveIcon({color}) {
  const customColor = color ? color : "#0030b5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke={customColor}>
          <g
            strokeLinecap="square"
            strokeWidth="1.2"
            transform="matrix(0 1 1 0 6 9)"
          >
            <path d="M1 8V.5"></path>
          </g>
          <circle cx="10" cy="10" r="8"></circle>
        </g>
      </g>
    </svg>
  );
}

export default RemoveIcon;
