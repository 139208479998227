import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt-calculator-content {
    .f-main-title {
      margin: 0 0 30px 0;
      @media (max-width: 767px) {
        margin: 0 0 16px 0;
      }
    }
    .profile-status-card {
      ul {
        li {
          a {
            padding: 13px 15px 12px 15px;
            font-size: 17px;
            .completed-profile {
              padding-top: 0px;
              img {
                width: 12px;
              }
            }
          }
        }
      }
    }
    &.opt-intro-page {
      > .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          display: none;
        }
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          max-width: 686px;
          margin: 0 auto;
        }
      }
    }
    > .MuiGrid-container {
      @media (max-width: 1366px) {
        flex-wrap: wrap;
      }
      > .MuiGrid-grid-xs-3 {
        @media (max-width: 1366px) {
          max-width: 32%;
          flex-basis: 32%;
        }
        &:last-child {
          @media (max-width: 1366px) {
            max-width: 68%;
            flex-basis: 68%;
            margin-left: auto;
          }
          @media (max-width: 991px) {
            max-width: 100%;
            flex-basis: 100%;
            margin-top: 15px;
          }
        }
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      > .MuiGrid-grid-xs-6 {
        @media (max-width: 1366px) {
          max-width: 68%;
          flex-basis: 68%;
        }
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
          margin-top: 15px;
        }
      }
    }
    .alert-icon {
      position: relative;
      top: -1px;
      img {
        vertical-align: middle;
      }
    }
  }
  input {
    text-align: center;
  }
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px 30px;
  }
  .boost-profile-content {
    margin: 0;
    .MuiGrid-spacing-xs-3 {
      margin-top: 0;
      margin-bottom: 0;
      .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .content-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      font-family: "TTCommons-Medium";
      margin: 0 0 15px 0;
      padding-top: 4px;
    }
    .dialogDescription {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: center;
      margin-bottom: 60px;
      width: 336px;
    }
  }
  .test-action-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .shadow-btn {
      background: #fff !important;
      box-shadow: var(--card-box-shadow) !important;
      color: var(--field-label-color) !important;
      width: auto;
      padding: 18px 20px 13px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      img {
        vertical-align: middle;
        margin: -2px 8px 0 8px;
        width: 8px;
        transform: rotate(180deg);
      }
    }
    button {
      max-width: 205px;
      min-width: 205px;
      @media (max-width: 767px) {
        max-width: 100%;
        min-width: auto;
        width: 50% !important;
      }
      + button {
        margin-left: 15px;
      }
    }
  }
  .estimate-condition {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin: 0;
  }
  .datepicker-wrapper {
    .react-datepicker-popper {
      z-index: 2;
      left: 0 !important;
      top: 100% !important;
      margin-top: 10px;
      width: auto;
      @media (max-width: 767px) {
        left: 50% !important;
        transform: translateX(-50%) !important;
        z-index: 3;
      }
      .react-datepicker {
        border: 0;
        box-shadow: var(--card-box-shadow);
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        .react-datepicker__header {
          padding: 0;
          text-align: initial;
          background: transparent;
          border-radius: initial;
          .dp-dropdown-custom-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .dp-dropdown-custom-title {
              color: var(--primary-dark-color);
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              font-family: "TTCommons-Medium";
              padding-top: 6px;
              margin-right: auto;
            }
            button {
              background: transparent;
              border: 0;
              width: 24px;
              height: 24px;
              font-size: 24px;
              font-family: "TTCommons-Medium";
              cursor: pointer;
              margin-top: -4px;
              outline: 0 !important;
              box-shadow: none !important;
            }
          }
          .react-datepicker__day-names {
            padding: 0;
            background-color: #f1f4f7;
            border-radius: 10px 10px 0 0;
            .react-datepicker__day-name {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 12px;
              text-align: center;
              padding: 14px 8px 12px;
              min-width: 54px;
              margin: 0;
              border-right: 1px solid #fff;
              @media (max-width: 600px) {
                min-width: 36px;
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
        }
        .react-datepicker__month {
          margin: 0;
          padding: 0;
          background-color: #f1f4f7;
          border-radius: 0 0 10px 10px;
          .react-datepicker__week {
            > div {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              text-align: center;
              min-width: 54px;
              margin: 0;
              position: relative;
              border-right: 1px solid #fff;
              transition: all 0.3s;
              background: transparent !important;
              border-radius: 0;
              padding: 4px 0;
              @media (max-width: 600px) {
                min-width: 36px;
              }
              span {
                width: 26px;
                height: 26px;
                border-radius: 100px;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 14px;
                font-weight: 500;
                padding-top: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
              }
              &.react-datepicker__day--today {
                span {
                  background-color: #d1dde5;
                }
              }
              &.react-datepicker__day--disabled {
                color: var(--field-label-color);
              }
              &.react-datepicker__day--selected {
                span {
                  background-color: var(--primary-main-color);
                  color: #fff;
                }
              }
              &:hover {
                span {
                  background-color: #d1dde5;
                }
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .btn-wrapper {
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
      position: initial;
      margin: 0 0 16px 0;
      transform: none;
    }
    .shadow-btn {
      background: #fff !important;
      box-shadow: var(--card-box-shadow) !important;
      color: var(--primary-dark-color) !important;
      width: auto;
      padding: 18px 20px 13px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      img {
        vertical-align: middle;
        margin: -2px 8px 0 8px;
        width: 8px;
      }
    }
  }
`;
