import React from "react";
import CheckMark from "../../../assets/svg/CheckMark";
import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Badge.module.scss";
const cx = createModuleStyleExtractor(styles);
export const Badge = ({ complete = true }) => {
  const { profile } = useAppState("profile");
  const { accessibility_help } = profile || {};
  return (
    <div
      className={cx([
        "profile-module-badge",
        ...(!complete ? ["profile-module-badge__incomplete"] : []),

        ...(accessibility_help && !complete
          ? ["incomplete-badge-accessibility"]
          : []),
      ])}
      aria-label={complete ? "Completed" : "Incomplete"}
    >
      <div className={cx(["profile-module-badge__text"])}>
        {complete ? "Completed" : "Incomplete"}{" "}
      </div>
      {complete && (
        <div className={cx("profile-module-badge__icon")}>
          <CheckMark />
        </div>
      )}
    </div>
  );
};
