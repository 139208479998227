import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .test-action-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
    .shadow-btn {
      background: #fff !important;
      box-shadow: var(--card-box-shadow) !important;
      color: var(--field-label-color) !important;
      width: auto;
      padding: 18px 20px 13px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      img {
        vertical-align: middle;
        margin: -2px 8px 0 8px;
        width: 8px;
        transform: rotate(180deg);
      }
    }
    button {
      max-width: 205px;
      min-width: 205px;
      @media (max-width: 767px) {
        max-width: 100%;
        min-width: auto;
        width: 50% !important;
      }
      + button {
        margin-left: 15px;
      }
    }
  }
`;
