import React, { Fragment, useMemo } from "react";

import EditableDropdown from "../../../../components/DropdownCustom/EditableDropdown";
import { useAppState } from "../../../../context";
import { formatOptions } from "../../../../helper/helper";
import { createFriendlyTitle, uniqueId } from "../../../../utils/common";
import { createModuleStyleExtractor } from "../../../../utils/css";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { LineDivider } from "../../Divider/Divider";
import { SectionFooter } from "../../SectionFooter/SectionFooter";
import styles from "./Language.module.scss";
import languagesJSON from "./languages.json";

const cx = createModuleStyleExtractor(styles);

export const ExperienceLanguages = ({ languages }) => {
  const {
    profile,
    setLocalExperienceFormInfo,
    removeExperience,
    addExperience,
  } = useAppState("profile");

  const { experience } = profile || {};
  const { languages: items } = experience || {};
  const languagesIds = useMemo(() => {
    if (items) return items?.map((item) => item.id);
    return [];
  }, [items]);

  const experienceCount = useMemo(() => {
    return languages.filter((item) => !item?.remove)?.length;
  }, [languages]);

  //extra_expereinces
  const handleClick = (flag, languageId) => {
    if (!flag) {

      const draftId = uniqueId();
      addExperience("languages", { draftId });
      return;
    }
    const draft = languagesIds.includes(languageId);
    if (!draft) {
      removeExperience(languageId, "languages");
      return;
    }

    setLocalExperienceFormInfo(languageId, "remove", true, false, "languages");
  };

  return (
    <div className={cx("o-professional-language")}>
      {languages
        ?.filter((item) => !item?.remove)
        .map((language, index) => (
          <Fragment
            key={`language-${index}-${language?.draftId ?? language?.id}`}
          >
            <LanguageItem language={language} index={index} />
            {
              experienceCount > 1 &&
              <SectionFooter
                isRemove
                label="Remove language"
                handleClick={(flag) =>
                  handleClick(flag, language?.draftId ?? language?.id)
                }
              />
            }
            <LineDivider />
          </Fragment>
        ))}
      <SectionFooter label="Add another language" handleClick={handleClick} />
    </div>
  );
};

const LanguageItem = ({ language, index }) => {
  const { draftId, id, name, level } = language || {};
  const { essentials, setLocalExperienceFormInfo } = useAppState("profile");
  const { proficiencies } = essentials || {};
  const updateInfo = (key, value) => {
    setLocalExperienceFormInfo(
      draftId ?? id,
      key,
      value,
      draftId ? true : false,
      "languages"
    );
  };
  return (
    <div className={cx("o-professional-language__grid")}>
      <div className={cx("o-professional-language__grid__item")}>
        <EditableDropdown
          className={name ? "react-custom-dropdown__valid": ""}
          name={`Languages-${index}`}
          label="Languages"
          placeholder="Select language"
          addText="Add Language"
          justifyContent="left"
          error={level && !name ? "Language required" : ""}
          touched={level && !name}
          options={languagesJSON}
          value={
            languagesJSON
              ? languagesJSON.find((option) => option.value === name)
                ? languagesJSON.find((option) => option.value === name)
                : { label: name, value: name }
              : ""
          }
          onChange={(item) =>
            updateInfo(
              "name",
              item?.__isNew__
                ? createFriendlyTitle(item?.value)
                : item?.value
            )
          }
        />
      </div>
      <div className={cx("o-professional-language__grid__item")}>
        <DropdownCustom
          label="Level"
          name={`Level-${index}`}
          options={formatOptions(proficiencies) || []}
          onChange={(item) =>
            updateInfo("level", item?.value)}
          isSearch
          value={level ? { label: level, value: level} :  null}
          containerClassName={
            level?.value ? "a-dropdown-container__options__valid": ""
          }
          placeholder="Select level"
          className={`profile-react-custom-dropdown ${level?.value ? "border-gray" : ""}`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
          isClearable={true}
        />
        {/* <Dropdown
          containerClassName={level ? "a-dropdown-container__options__valid": ""}
          name={`Level-${index}`}
          options={proficiencies}
          label="Level"
          placeholder={"Select level"}
          value={level}
          onSelect={(item) => updateInfo("level", item)}
          error={!level && name ? "Proficiency required" : ""}
        /> */}
      </div>
    </div>
  );
};
