import React, { memo, useState } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useDispatch } from "react-redux";

import { config } from "../../constant/config";
import { useAppState } from "../../context";
import { toastify } from "../../helper/helper";
import {
  authenticateGoogleSignin,
  disconnectAccountGoogle,
} from "../../services/profile.service";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { CustomDialogBox } from "../Profile/ProfileInfo/ProfileInfoStyles";
import { updateUsersPersistState } from "../../redux/reducers/userReducer";

import googleDashboardIcon from "../../assets/svg/f-google-logo.svg";
import googleDashboardIconTick from "../../assets/svg/f-google-synced.svg";
import googleWithoutBgIcon from "../../assets/svg/googleIcon.svg";

const AuthGoogle = ({
  isGoogleConnected = false,
  isDashboardWidget = false,
  setIsUserConnected = () => {},
  isUserConnected = {},
  isDisabled = false,
  ariaLabelledby = "",
}) => {
  const dispatch = useDispatch();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const { updateIsGoogleConnected } = useAppState("profile");

  const GOOGLE_CLIENT_ID = config().GOOGLE_CLIENT_ID;

  const url = new URL(window.location.href);

  const onSuccessLogin = async (response) => {
    const { code } = response ?? {};

    // Send code to backend
    try {
      const response = await authenticateGoogleSignin(code, url.origin);
      if (response.success) {
        toastify("success", "Successfully connected to google");
        setIsUserConnected({ ...isUserConnected, google: true });
        updateIsGoogleConnected(response.data.is_google_signed_in);
        dispatch(updateUsersPersistState({ is_google_connected: true }));
      }
    } catch (error) {
      console.error(error);
      setIsUserConnected({ ...isUserConnected, google: true });
      dispatch(updateUsersPersistState({ is_google_connected: true }));
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
      dispatch(updateUsersPersistState({ is_google_connected: false }));
    }
  };

  const onFailLogin = (response) => {
    const { error } = response ?? {};

    let message = "";
    switch (error) {
      case "popup_closed_by_user":
        message = "Authentication process rejected by user";
        break;
      case "access_denied":
        message = "User denied the permission to the scopes required";
        break;
      case "immediate_failed":
        message =
          "No user could be automatically selected without prompting the consent flow.";
        break;
      case "idpiframe_initialization_failed":
        message = response?.details
          ? response.details
          : "Oops! something went wrong";
        break;
      default:
        message = "Oops! something went wrong";
    }
    if (error) {
      toastify("error", message);
    }
  };

  const onSuccessLogout = async () => {
    try {
      const response = await disconnectAccountGoogle();
      if (response.success) {
        toastify("success", response.message);
        setShowDisconnectModal(false);
        updateIsGoogleConnected(false);
        dispatch(updateUsersPersistState({ is_google_connected: false }));
      }
    } catch (error) {
      setShowDisconnectModal(false);
      console.error(error);
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
    }
  };

  const onFailLogout = (response) => {
    toastify("error", "Oops! Something went wrong");
    console.error(response);
    setShowDisconnectModal(false);
  };

  return (
    <>
      {isDashboardWidget ? (
        isGoogleConnected ? (
          <>
            <div className="widgets-item">
              <button aria-labelledby={ariaLabelledby}>
                <div
                  style={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src={googleDashboardIconTick}
                    alt="Linked In"
                    className="icon"
                  />
                </div>
              </button>
            </div>
          </>
        ) : (
          <>
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={onSuccessLogin}
              onFailure={onFailLogin}
              cookiePolicy={"single_host_origin"}
              isSignedIn={false}
              buttonText="Login"
              uxMode="popup"
              responseType="code"
              prompt="consent"
              autoLoad={false}
              redirectUri={url.origin}
              aria-labelledby={ariaLabelledby}
              render={(renderProps) => (
                <div className="widgets-item" onClick={renderProps.onClick}>
                  <button
                    style={{
                      background: "none",
                      color: "inherit",
                      border: "none",
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        // style={{padding: '2.5px'}}
                        src={googleDashboardIcon}
                        alt="Linked In"
                        className="icon"
                      />
                    </div>
                  </button>
                </div>
              )}
            />
          </>
        )
      ) : isGoogleConnected ? (
        <a
          href="#"
          className="google-sso-connect-v1"
          onClick={(e) => {
            e.preventDefault();
            setShowDisconnectModal(true);
          }}
        >
          <div className="google-sso-connect-v1-img">
            <img src={googleWithoutBgIcon} alt="Google" />
          </div>
          <span className="google-sso-connect-v1-text">Disconnect Google</span>
        </a>
      ) : (
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          onSuccess={onSuccessLogin}
          onFailure={onFailLogin}
          cookiePolicy={"single_host_origin"}
          isSignedIn={false}
          buttonText="Login"
          uxMode="popup"
          responseType="code"
          prompt="consent"
          autoLoad={false}
          redirectUri={url.origin}
          render={(renderProps) => (
            <a
              href="#"
              className="google-sso-connect-v1"
              onClick={renderProps.onClick}
            >
              <div className="google-sso-connect-v1-img">
                <img src={googleWithoutBgIcon} alt="Google" />
              </div>
              <span className="google-sso-connect-v1-text">Connect Google</span>
            </a>
          )}
        />
      )}
      <CustomDialogBox
        open={showDisconnectModal}
        title={"Are you sure you want to disconnect google account?"}
        handleClose={() => setShowDisconnectModal(false)}
        isDisplayCloseButton={true}
        className="profile-accessibility-popup"
        dialogTitleClass="modal-title"
        dialogSubTitleClass="text-para"
      >
        <div className="modal-disconnect-google-button-wrapper">
          <GoogleLogout
            clientId={GOOGLE_CLIENT_ID}
            onLogoutSuccess={onSuccessLogout}
            onFailure={onFailLogout}
            buttonText="Logout"
            render={(renderProps) => (
              <div className="modal-disconnect-google-button-wrapper__button">
                <ButtonCustom onClick={renderProps.onClick}>
                  Disconnect
                </ButtonCustom>
              </div>
            )}
          />
        </div>
      </CustomDialogBox>
    </>
  );
};

export default memo(AuthGoogle);
