import React, { Fragment, useEffect, useState, useMemo } from "react";
import moment from "moment/moment";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./ProfessionalExperience.module.scss";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import { generateYears, months, uniqueId } from "../../../../utils/common";
import { SectionFooter } from "../../SectionFooter/SectionFooter";
import { LineDivider } from "../../Divider/Divider";
import { ProfileCheckbox } from "../../Checkbox/Checkbox";
import { useAppState } from "../../../../context";
import { PRIMARY_COLOR } from "../../../../styles/variables";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { formatOptions } from "../../../../helper/helper";

const cx = createModuleStyleExtractor(styles);
const currentYear = new Date().getFullYear();

export const ProfessionalExperience = ({ experiences }) => {
  const {
    profile,
    setLocalExperienceFormInfo,
    removeExperience,
    addExperience,
  } = useAppState("profile");

  const { experience } = profile || {};
  const { experiences: items } = experience || {};
  const experienceIds = useMemo(() => {
    if (items) return items?.map((item) => item.id);
    return [];
  }, [items]);

  const experienceCount = useMemo(() => {
    return experiences.filter((item) => !item?.remove)?.length;
  }, [experiences]);

  const handleClick = (flag, experienceId) => {
    if (!flag) {
      //add expeerience
      const draftId = uniqueId();

      addExperience("experiences", { draftId });
      return;
    }
    const draft = experienceIds.includes(experienceId);
    if (!draft) {
      removeExperience(experienceId, "experiences");
      return;
    }

    setLocalExperienceFormInfo(
      experienceId,
      "remove",
      true,
      false,
      "experiences"
    );
  };

  return (
    <div className={cx("o-professional-experience")}>
      {experiences
        .filter((item) => !item?.remove)
        .map((experience, index) => (
          <Fragment key={`item-${experience?.id ?? experience?.draftId}`}>
            <ExperienceItem experience={experience} index={index} />
            {experienceCount > 1 && (
              <>
                <SectionFooter
                  label="Remove experience"
                  isRemove
                  handleClick={(flag) =>
                    handleClick(flag, experience?.draftId ?? experience?.id)
                  }
                />
              </>
            )}
            <br />
            <LineDivider />
          </Fragment>
        ))}

      <SectionFooter
        label={"Add another experience"}
        handleClick={handleClick}
      />
    </div>
  );
};

const ExperienceItem = ({ experience, index }) => {
  const { setLocalExperienceFormInfo } = useAppState("profile");
  const [error, setError] = useState({
    startEndDateError: null,
  });

  const {
    draftId,
    id,
    organization,
    role,
    location,
    start_date,
    end_date,
    currently_working,
    remote_work,
    start_month,
    start_year,
    end_month,
    end_year,
  } = experience || {};

  // Form Field Error Generator
  useEffect(() => {
    // Check if start date is before end date
    if (start_month && start_year && end_month && end_year) {
      const startDate = new Date(start_month + start_year);
      const endDate = new Date(end_month + end_year);
      if (startDate > endDate) {
        setError((current) => ({
          ...current,
          startEndDateError: "Invalid Date",
        }));
      } else {
        setError((current) => ({
          ...current,
          startEndDateError: null,
        }));
      }
    }
  }, [experience]);

  const getDefaultMonthValue = () => {
    if (start_month) return {label: start_month, value: start_month};
    // if we have graduation date
    if (start_date) {
      //if it is valid. means full formatted
      if (moment(start_date).isValid()) {
        let month = moment(start_date).format("MMMM")
        return {label: month, value: month}
      }
    }
    return {label: "", value: ""};
  };

  const getDefaultYearValue = () => {
    if (start_year) return {label: start_year, value: start_year};
    // if we have graduation date
    if (start_date) {
      //if it is valid. means full formatted
      if (moment(start_date).isValid()) {
        let year = moment(start_date).format("YYYY")
        return {label: year, value: year};
      }
    }
    return "";
  };

  const getDefaultEndMonthValue = () => {
    if (end_month) return {label: end_month, value: end_month};
    // if we have graduation date
    if (end_date) {
      //if it is valid. means full formatted
      if (moment(end_date).isValid()) {
        let endMonth = moment(end_date).format("MMMM")
        return  {label: endMonth, value: endMonth};
      }
    }
    return "";
  };

  const getDefaultEndYearValue = () => {
    if (end_year) return {label: end_year, value: end_year};
    // if we have graduation date
    if (end_date) {
      //if it is valid. means full formatted
      if (moment(end_date).isValid()) {
        let endYear = moment(end_date).format("YYYY");
        return {label: endYear, value: endYear}
      }
    }
    return "";
  };

  const updateState = (key, value) => {
    setLocalExperienceFormInfo(
      draftId ?? id,
      key,
      value,
      draftId ? true : false,
      "experiences"
    );
  };

  const Checkbox = ({ handleClick, text, state, className = "" }) => {
    return (
      <div
        className={cx([
          "o-professional-experience__check",
          state ? "o-professional-experience__check__filled": "",
          state ? className : ""
        ])}
      >
        <div>
          <ProfileCheckbox defaultState={state} text={text} handleClick={handleClick} />
        </div>
        <div>
          <span className={cx("o-professional-experience__check__text")}
            style={{
              ...(state && {
                color: PRIMARY_COLOR,
                lineHeight: "10px",
                paddingTop: "0px",
              }),
            }}
          >
            {text}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className={cx("o-professional-experience__grid")}>
      <div className={cx("o-professional-experience__grid__item")}>
        <TextboxCustom
          value={organization || ""}
          label={"Organization"}
          placeholder="Enter name"
          className={
            organization
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          lableaignment="left"
          name={`Organization-${index}`}
          onChange={(e) => updateState("organization", e.target.value)}
        />
      </div>
      <div className={cx("o-professional-experience__grid__item")}>
        <TextboxCustom
          value={role || ""}
          label={"Role"}
          placeholder="Enter your role"
          className={
            role
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          lableaignment="left"
          name={`Role-${index}`}
          onChange={(e) => updateState("role", e.target.value)}
        />
      </div>
      <div className={cx("o-professional-experience__grid__item")}>
        <div className={cx("o-professional-experience__grid__item__row")}>
          <div
            className={cx("o-professional-experience__grid__item__row__left")}
          >
            <DropdownCustom
              label="Start date"
              name={`Start date-${index}`}
              options={formatOptions(months) || []}
              onChange={(item) => {
                updateState("start_month", item.value);
              }}
              isSearch={false}
              value={getDefaultMonthValue()}
              containerClassName={
                getDefaultMonthValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              placeholder="Month"
              /* noOptionsFoundLabel={"No months found..."} */
              className={`profile-react-custom-dropdown ${
                getDefaultMonthValue().value ? "border-gray" : ""
              }`}
              labelClass="justify-left"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
          <div
            className={cx("o-professional-experience__grid__item__row__right")}
          >
            <DropdownCustom
              label="Start Year"
              name={`Start Year-${index}`}
              options={
                formatOptions(generateYears(1998, currentYear + 1)) || []
              }
              onChange={(item) => {
                updateState("start_year", item.value);
              }}
              isSearch={false}
              value={getDefaultYearValue()}
              containerClassName={
                getDefaultYearValue().value
                  ? "a-dropdown-container__options__valid"
                  : ""
              }
              placeholder="Year"
              className={`profile-react-custom-dropdown ${
                getDefaultYearValue().value ? "border-gray" : ""
              }`}
              labelClass="label-hidden"
              justifyContent="left"
              placeholderColor={true}
            />
          </div>
        </div>
      </div>
      <div className={cx("o-professional-experience__grid__item")}>
        <div className={cx("o-professional-experience__grid__item__row")}>
          {!currently_working && (
            <>
              <div
                className={cx(
                  "o-professional-experience__grid__item__row__left"
                )}
              >
                <DropdownCustom
                  label="End date"
                  name={`End date-${index}`}
                  options={formatOptions(months) || []}
                  onChange={(item) => {
                    updateState("end_month", item.value);
                  }}
                  isSearch={false}
                  value={getDefaultEndMonthValue()}
                  containerClassName={
                    getDefaultEndMonthValue().value
                      ? "a-dropdown-container__options__valid"
                      : ""
                  }
                  placeholder="Month"
                  /* noOptionsFoundLabel={"No months found..."} */
                  className={`profile-react-custom-dropdown ${
                    getDefaultEndMonthValue().value ? "border-gray" : ""
                  }`}
                  labelClass="justify-left"
                  justifyContent="left"
                  placeholderColor={true}
                />
              </div>
              <div
                className={cx(
                  "o-professional-experience__grid__item__row__right"
                )}
              >
                <DropdownCustom
                  label="End Year"
                  name={`End Year-${index}`}
                  options={
                    formatOptions(
                      generateYears(
                        Number(start_year) ? Number(start_year) : currentYear,
                        currentYear + 1
                      )
                    ) || []
                  }
                  onChange={(item) => {
                    updateState("end_year", item.value);
                  }}
                  isSearch={false}
                  value={getDefaultEndYearValue()}
                  containerClassName={
                    getDefaultEndYearValue().value
                      ? "a-dropdown-container__options__valid"
                      : ""
                  }
                  error={error.startEndDateError}
                  touched={error.startEndDateError}
                  placeholder="Year"
                  className={`profile-react-custom-dropdown ${
                    getDefaultEndYearValue().value ? "border-gray" : ""
                  }`}
                  labelClass="label-hidden"
                  justifyContent="left"
                  placeholderColor={true}
                />
              </div>
            </>
          )}
        </div>
        <div className={cx("o-professional-experience__grid__item__row")}>
          <Checkbox
            state={currently_working}
            text="I currently work here"
            handleClick={() =>
              updateState("currently_working", !currently_working)
            }
          />
        </div>
      </div>
      <div
        className={cx([
          "o-professional-experience__grid__item",
          remote_work ? "o-professional-experience__grid__height0" : "",
        ])}
      >
        {!remote_work && (
          <TextboxCustom
            value={location || ""}
            label={"Location"}
            placeholder="Enter location"
            className={
              location
                ? "a-input-profile__completed custom-textbox"
                : "a-input-profile custom-textbox"
            }
            lableaignment="left"
            name={`Location-${index}`}
            onChange={(e) => updateState("location", e.target.value)}
          />
        )}
        <Checkbox
          text="Remote work"
          handleClick={() => updateState("remote_work", !remote_work)}
          state={remote_work}
          className="o-professional-experience__check__remote"
        />
      </div>
    </div>
  );
};
