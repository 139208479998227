import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoriteUsers: [],
};

export const networkReducer = createSlice({
  name: "network",
  initialState,
  reducers: {
    setFavoriteUsers: (state, action) => {
      state.favoriteUsers = [...action.payload];
    },
  },
});

export const { setFavoriteUsers } = networkReducer.actions;

export default networkReducer.reducer;
