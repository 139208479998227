import React, { useState, useEffect } from "react";
import SavedResourcesStyles from "./SavedResourcesStyles";
import CardCustom from "../../../CardCustom/CardCustom";
import { Grid } from "@mui/material";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../../context";
import {
  getSavedResourcesAPI,
  saveUnSaveResourceAPI,
} from "../../../../services/VideoServices";
import { toastify, textLabel } from "../../../../helper/helper";
import StarIcon from "../../../StarIcon/StarIcon";
import { ShimmerThumbnail } from "react-shimmer-effects";

const EmptySavedResources = () => {
  return (
    <Grid item xs={12}>
      <CardCustom>
        <div className="empty-item">
          <h3 className="header-primary">Check out our resources!</h3>
          <div className="description">
            We have curated amazing content to help you in your journey. Click
            on the star next to the resource title to pin the content here for
            quick access.
          </div>
        </div>
      </CardCustom>
    </Grid>
  );
};

const SavedResources = () => {
  // const [resourcesList, setResourcesList] = useState([
  //   { name: "Communication Startegies for students", type: "Interstide Video" },
  //   { name: "Linkdin top 10 companies", type: "PDF" }
  // ]);

  const [resourceId, setResourceId] = useState(null);
  const [callingGetSavedResourcesAPI, refreshGetSavedResourcesAPI] =
    usePromise(getSavedResourcesAPI);
  const [callingSaveUnSaveResourceAPI, refreshSaveUnSaveResourceAPI] =
    usePromise(saveUnSaveResourceAPI);

  const { setSavedResources, saveResourceList, setSaveUnSaveResource } =
    useAppState("learn");

  const unSaveTheResource = (resourceId) => {
    setResourceId(resourceId);
    const payload = { resource_id: resourceId };
    refreshSaveUnSaveResourceAPI(payload);
  };

  useEffect(() => {
    refreshGetSavedResourcesAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetSavedResourcesAPI.hasFetched() &&
      callingGetSavedResourcesAPI.hasErrors() === false &&
      callingGetSavedResourcesAPI.data() &&
      callingGetSavedResourcesAPI.data().data
    ) {
      setSavedResources(callingGetSavedResourcesAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetSavedResourcesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingSaveUnSaveResourceAPI.hasFetched() &&
      callingSaveUnSaveResourceAPI.hasErrors() === false
    ) {
      toastify("success", callingSaveUnSaveResourceAPI.data().message);
      // setSaveUnSaveResource(resourceId, "unsave");
      refreshGetSavedResourcesAPI();
      setSaveUnSaveResource(
        resourceId,
        callingSaveUnSaveResourceAPI.data().data,
        "right"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingSaveUnSaveResourceAPI.isFetching()]);

  const renderResources = (data, i) => {
    return (
      <Grid item xs={12} key={i}>
        <CardCustom className="resouces-cards">
          <div className="header-primary">
            <span>{data.title}</span>{" "}
            <StarIcon
              aria-label={`Remove ${data.title} from saved resources`}
              variant="filled"
              onClick={() => unSaveTheResource(data.id)}
            />
          </div>
          <div className="content">
            <div className="text-grey">{data.link_type}</div>
            <a
              href="/#"
              className={`text-secondary cursor-pointer link-focus`}
              aria-label={textLabel(data.link_type)}
              onClick={(e) => {
                e.preventDefault();
                ["YouTube Video", "Website Link"].includes(data.link_type)
                  ? data.link && window.open(data.link)
                  : ["Interstride Video", "PDF", "Image"].includes(
                      data.link_type
                    ) &&
                    data.image &&
                    window.open(data.image);
              }}
            >
              {textLabel(data.link_type)}
            </a>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <SavedResourcesStyles>
      <div className="resource-list">
        <div className="title">
          <h2 className="title-primary mb-25">Saved Resources</h2>
        </div>
        {callingGetSavedResourcesAPI.isFetching() ? (
          Array.from(Array(5).keys()).map((_, index) => (
            <div className="saved-resource-loading-item">
              <ShimmerThumbnail
                height={100}
                key={index}
                className="m-0"
                center={true}
              />
            </div>
          ))
        ) : (
          <Grid container spacing={3}>
            {saveResourceList.length > 0 ? (
              saveResourceList.map((data, i) => renderResources(data, i))
            ) : (
              <EmptySavedResources />
            )}
          </Grid>
        )}
      </div>
    </SavedResourcesStyles>
  );
};

export default SavedResources;
