import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { ShimmerThumbnail } from "react-shimmer-effects";

import {
  widgetServicesAPI,
  widgetServicesCategoriesAPI,
} from "../../../../services/widgetServices";
import { widgetRedirection } from "../../../../utils/common";
import ServicesViewStyles from "./ServicesViewStyles";
import ServiceMiddle from "../../../../components/Learn/Service/ServiceMiddle/ServiceMiddle";
import WidgetOverlay from "../../WidgetOverlay/WidgetOverlay";

import IllustrationNoUser from "../../../../assets/svg/Illustration-no-user.svg";
import ArrowIcon from "../../../../assets/svg/down-arrow.svg";

const ServicesView = ({ token }) => {
  const [categories, setCategories] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openFilter, toggleFilter] = useState(false);
  const [showListLoader, setShowListLoader] = useState(true);

  useEffect(() => {
    const categoriesAPICall = async () => {
      try {
        setShowLoader(true);
        const response = await widgetServicesCategoriesAPI(token);
        if (response?.success && response?.data) {
          const { categories = [] } = response.data;
          setCategories(categories);
          if (!isEmpty(categories)) {
            setSelectedCategory(categories[0]);
          }
          setShowLoader(false);
        }
      } catch (error) {
        setCategories([]);
        setShowLoader(false);
      }
    };
    categoriesAPICall();
  }, [token]);

  useEffect(() => {
    const servicesListAPICall = async () => {
      try {
        setShowListLoader(true);
        const response = await widgetServicesAPI(token, selectedCategory.id);
        if (response?.success && response?.data) {
          const { deals = [] } = response.data;
          setServiceList((deals ?? []).slice(0, 2));
          setShowListLoader(false);
        } else {
          setServiceList([]);
          setShowListLoader(false);
        }
      } catch (error) {
        setServiceList([]);
        setShowListLoader(false);
      }
    };

    if (selectedCategory) servicesListAPICall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const onServiceClick = () => {
    widgetRedirection("services");
  };

  const ListLoader = () => (
    <div className="service-listing-loader">
      {[...Array(2)].map((_, index) => (
        <ShimmerThumbnail width={267} height={345} key={index} />
      ))}
    </div>
  );

  return (
    <ServicesViewStyles>
      <div className="widget-services-view">
        <div className="service-categories-wrapper">
          {showLoader ? (
            <>
              <div className="service-categories-loader">
                {[...Array(8)].map((_, index) => (
                  <ShimmerThumbnail height={50} key={index} />
                ))}
              </div>
              <ListLoader />
            </>
          ) : categories && !isEmpty(categories) ? (
            <>
              <div
                className={`student-services-categories-wrapper collapsible-view ${
                  openFilter ? "open" : ""
                }`}
              >
                <h2 className="title-primary mb-25">
                  Categories
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleFilter(!openFilter);
                    }}
                    className="collapse-icon arrow"
                  >
                    <img src={ArrowIcon} alt="Arrow Icon" />
                  </a>
                </h2>
                <div className="student-services-categories">
                  {categories.map((data, index) => (
                    <a
                      href
                      className={`student-services-category-item ${
                        data.id === selectedCategory.id
                          ? "student-services-category-item-active"
                          : ""
                      }`}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedCategory(data);
                      }}
                    >
                      <div className="student-services-category-image">
                        <img src={data.logo_url} alt="logo" />
                      </div>
                      <span>{data.name}</span>
                    </a>
                  ))}
                </div>
              </div>
              <div className="student-services-list">
                {showListLoader ? (
                  <ListLoader />
                ) : (
                  <>
                    <ServiceMiddle
                      serviceList={serviceList}
                      refreshSavedServices={() => {}}
                      savedServiceList={[]}
                      showLoader={showLoader}
                      currentPage={1}
                      totalPages={1}
                      fetchMoreData={() => {}}
                      showPaginationLoader={false}
                      widget
                      onActionClick={onServiceClick}
                    />
                    {serviceList.length > 0 && (
                      <WidgetOverlay
                        title="Get access to view all services"
                        route_type="services"
                      />
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="student-services-empty">
              <img src={IllustrationNoUser} alt="no-services" />
              <h2>Looks like there are no services with this search.</h2>
            </div>
          )}
        </div>
      </div>
    </ServicesViewStyles>
  );
};

export default ServicesView;
