import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .started-mainpage {
    display: block;
    padding: 0 0px;

    .MuiDialog-container {
      .MuiPaper-root {
        .MuiDialogContent-root {
          .MuiTypography-root {
            .model-body {
              .rating-box {
                text-align: center;
                background: green;
              }
            }
          }
        }
      }
    }
  }
  .resource-section {
    .category-list {
      .title-primary {
        @media (max-width: 991px) {
          height: auto;
        }
        span {
          display: none;
          @media (max-width: 991px) {
            display: block;
          }
        }
      }
    }
    &__right-section-top-spacer {
      margin-top: 39px;
    }
  }
`;
