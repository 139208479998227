import React from "react";

import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Button.module.scss";

const cx = createModuleStyleExtractor(styles);

export const ButtonModifier = {
  PRIMARY: "a-button--primary",
  SECONDARY: "a-button--secondary",
  DANGER: "a-button--danger",
  WARNING: "a-button--warning",
  SUCCESS: "a-button--success",
  INVERTED: "a-button--inverted",
  CHAT_SEND_BUTTON: "a-button--chat-send",
  CHAT_SEND_BUTTON_DISABLED: "a-button--chat-send--disabled",
  CHAT_LIST: "a-button--chat-list",
  CHAT_LIST_ADDED: "a-button--chat-list-added",
  FILTER_NETWORK_BUTTON: "a-button--filter-network",
  PROFILE_NEXT_BUTTON: "a-button-profile-next",
  PROFILE_PREVIOUS_BUTTON: "a-button-profile-previous",
};

export const Button = ({
  onClick,
  children,
  modifier,
  type,
  buttonRef,
  disabled = false,
  accessiblityClass = "",
}) => {
  const { profile } = useAppState("profile");
  const { accessibility_help } = profile || {};

  return (
    <button
      ref={buttonRef}
      disabled={disabled}
      className={cx(
        [
          `a-button`,
          ...(accessibility_help && accessiblityClass
            ? [accessiblityClass]
            : []),
        ],

        modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
      type={type}
      role="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
