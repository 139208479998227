import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Switch.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function Switch({
  leftTitle = "",
  leftValue = "left",
  rightTitle = "",
  rightValue = "right",
  activeSide = "left",
  setActiveSide = () => {},
  variant,
}) {
  return (
    <div className={cx(["a-switch", variant ? `a-switch--${variant}` : ""])}>
      <div
        onClick={() => {
          if (activeSide !== leftValue) setActiveSide(leftValue);
        }}
        className={
          activeSide === leftValue
            ? cx(["a-switch-item", "a-switch-selected"])
            : cx("a-switch-item")
        }
      >
        {leftTitle}
      </div>
      <div
        onClick={() => {
          if (activeSide !== rightValue) setActiveSide(rightValue);
        }}
        className={
          activeSide === rightValue
            ? cx(["a-switch-item", "a-switch-selected"])
            : cx("a-switch-item")
        }
      >
        {rightTitle}
      </div>
    </div>
  );
}
