import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .company-list {
    display: block;
    .company-title {
      display: flex;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .mode {
        margin-left: 12px;
        button {
          background: transparent;
          width: 35px;
          height: 35px;
          padding: 0;
          min-width: auto;
          .MuiButton-label {
          }
          .content {
            min-width: auto;
            display: flex;
          }
        }
      }
      .export-dropdown-container {
        margin-left: auto;
      }
    }
    .card-wrapper {
      width: 100%;
      .MuiPaper-root {
        padding: 20px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          .card-actions-wrapper {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
        }
        .MuiCardContent-root {
          padding: 0;
        }
      }
    }
    .company-item {
      display: flex;
      align-items: center;
      @media (max-width: 600px) {
        position: relative;
      }
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        margin: 0 10px 0 0;
        border-radius: 100px;
        &.initial-avatar {
          padding-top: 8px;
        }
        img {
          height: auto;
          object-fit: initial;
        }
      }
      .content {
        margin-right: auto;
        max-width: 355px;
        .header-primary {
          padding: 2px 0 0px;
        }
      }
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 600px) {
          flex-wrap: wrap;
          width: 100%;
          margin-left: 60px;
          margin-top: -6px;
        }
        .pentition {
          font-size: 15px;
          line-height: 15px;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          color: var(--primary-dark-color);
          margin-top: 4px;
          min-width: 60px;
          @media (max-width: 600px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
          }
        }
        .view {
          button {
            height: 30px;
            width: 83px;
            padding: 6px 0 0px;
          }
          @media (max-width: 600px) {
            justify-content: flex-start;
            margin: 4px 0 0 0;
            width: auto;
          }
        }
        > div {
          + div {
            margin-left: 16px;
            @media (max-width: 991px) {
              margin-left: 8px;
            }
          }
        }
        + div {
          @media (max-width: 600px) {
            position: absolute;
            bottom: 5px;
            left: 136px;
          }
        }
      }
    }
    .company-map {
      position: relative;
      width: 100%;
      height: 840px;
      border-radius: 10px;
      overflow: hidden;
      * {
        border-bottom-left-radius: 0 !important;
        box-shadow: none !important;
      }
      #map {
        width: 100% !important;
      }
      .gm-style-iw-tc {
        display: none;
      }
      .infowindow-title {
        color: #ffffff;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        -webkit-letter-spacing: 0;
        -moz-letter-spacing: 0;
        -ms-letter-spacing: 0;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        border-radius: 45px 45px 45px 1px !important;
        background-color: ${PRIMARY_COLOR};
        padding: 5px 12px 1px 12px;
        display: block;
        line-height: 24px;
        min-width: 106px;
      }
      .multiple-employers-infowindow {
        text-align: left;
        padding: 15px 20px 15px 40px;
        border-radius: 15px 15px 15px 1px !important;
        max-height: 200px;
        overflow-y: auto;

        scrollbar-width: thin !important;
        -ms-overflow-style: auto;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: #d1dde5 transparent; /* scroll thumb and track */
        transition: all ease-in-out 500ms;
        &:hover {
          overflow-y: auto;
          &::-webkit-scrollbar {
            height: 50px;
            transition: all ease-in-out 500ms;
          }
        }
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          height: 50px;
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d1dde5 !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 150px !important;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: #607790 !important; /* color of the scroll thumb */
        }
      }
    }
    .gm-style .gm-style-iw-c {
      transform: translate(-0%, -100%) !important;
      padding: 0;
      background: transparent !important;
      height: auto;
      max-height: initial !important;
    }

    .gm-style .gm-style-iw-c button {
      display: none !important;
    }

    .ceOdbb .company-list .gm-style .gm-style-iw-c .gm-style-iw-d {
      background: transparent !important;
      height: auto !important;
      overflow: hidden !important;
      max-height: initial !important;
    }
    .gm-style .gm-style-iw-d {
      overflow: hidden !important;
      max-height: initial !important;
    }

    .gm-style .gm-style-iw-t::after {
      display: none !important;
    }
    .n-empty-list {
      width: 100%;
      margin-top: 8px;
    }
  }
`;
