import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .registered-webinar-list-wrapper {
    .registered-webinar-list {
      .card-wrapper {
        margin-bottom: 15px;
        .card-content {
          padding: 20px;
          @media (max-width: 767px) {
            padding: 16px;
          }
          .webinar-item {
            display: flex;
            @media (max-width: 600px) {
              flex-wrap: wrap;
            }
            .left {
              width: 50%;
              @media (max-width: 991px) {
                width: 35%;
                min-width: 220px;
              }
              @media (max-width: 600px) {
                width: 100%;
                min-width: auto;
              }
              h3 {
                margin: 0;
                color: var(--primary-dark-color);
                font-family: "TTCommons-DemiBold";
                font-size: 18px;
                font-weight: 600;
                -webkit-letter-spacing: 0;
                -moz-letter-spacing: 0;
                -ms-letter-spacing: 0;
                letter-spacing: 0;
                line-height: 19px;
              }
              .webinarCategory {
                background: var(--icon_background);
                font-size: 15px;
                -webkit-letter-spacing: 0;
                -moz-letter-spacing: 0;
                -ms-letter-spacing: 0;
                letter-spacing: 0;
                line-height: 17px;
                text-align: center;
                color: var(--primary-dark-color);
                padding: 6px 16px 3px;
                border-radius: 100px;
                display: inline-block;
                margin: 8px 0 0 0;
                @media (max-width: 767px) {
                  top: 8px;
                  left: 8px;
                }
              }
              h5 {
                display: flex;
                align-items: center;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--primary-dark-color);
                margin: 8px 0;
                font-weight: 400;
                img {
                  margin-right: 8px;
                  margin-top: -2px;
                }
              }
            }
            .right {
              width: 50%;
              margin-left: 20px;
              padding-left: 20px;
              border-left: 2px solid var(--gray-outline-color);
              @media (max-width: 991px) {
                width: 65%;
              }
              @media (max-width: 600px) {
                width: 100%;
                margin-left: 0px;
                margin-top: 16px;
                padding-left: 0px;
                padding-top: 16px;
                border-left: 0;
                border-top: 2px solid var(--gray-outline-color);
              }
              .decsription {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--gray-text-color);
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
`;
