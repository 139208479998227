import { useEffect } from "react";

import { useAppState } from "../context";
import { fetchChatsList } from "../services/chat.service";

export const useChatsList = (update = false) => {
  const {
    chats,
    setChats,
    isChatsLoaded,
    setIsChatsLoaded,
    isChatsLoading,
    setIsChatsLoading,
    setCurrentConversationId,
    setReloadChats,
    activeConversation,
    chatFilterTab,
  } = useAppState("chat");

  const loadChats = async () => {
    try {
      const response = await fetchChatsList({ filter: chatFilterTab });
      if (response.success) {
        setChats(response.data.direct_chats);
        if (response.data.direct_chats.length > 0) {
          !update &&
            setCurrentConversationId(
              response.data.direct_chats[0].conversation_id
            );
          const currentConversation = response.data.direct_chats.find(
            (chat) => chat.conversation_id === activeConversation
          );
          //this is the case when user remove the chat
          // chat conversation id exists in the state
          //but orignaly it is removed from database
          //since actions are asynchrounous
          //therefor need to update the state conversation id from here
          if (!currentConversation) {
            setCurrentConversationId(
              response.data.direct_chats[0].conversation_id
            );
          }
        }
      }
      update && setReloadChats(false);
      setIsChatsLoaded(true);
      setIsChatsLoading(false);
    } catch (error) {
      setIsChatsLoaded(true);
      setIsChatsLoading(false);
      update && setReloadChats(false);
    }
  };
  useEffect(() => {
    if (update) {
      loadChats();
    }
    //if loaded or chats retrieved or isloading
    if (chats || (!chats && isChatsLoading)) return;
    if ((!isChatsLoading && isChatsLoaded) || isChatsLoaded) return;
    loadChats();
    setIsChatsLoading(true);
  }, [update]);

  return {
    chats,
    isChatsLoaded,
    isChatsLoading,
  };
};
