import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Spinner.module.scss";
const cx = createModuleStyleExtractor(styles);
export const Spinner = () => {
  return (
    <div className={cx("spinner-container")}>
      <span className={cx("loader")}></span>
    </div>
  );
};
