import { useReducer } from "react";

import moment from "moment";
import { find, findIndex, isEmpty } from "lodash";
import _map from "lodash/map";

import { existsMulti, sortDateTimeArray } from "../helper/helper";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  activeEventDetails: null,
  calendarEvents: [], // CALENDAR_EVENTS_LIST,
  upCommingEvents: [],
  pastEvents: [],
  selectedEvent: null,
  checklist: null,
  checkListSortableArray: [],
  checkListCategories: [],
  selectedCategoriesIndex: 0,
  loader: false,
};

const useTools = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const resetToolsState = () => {
    setState({
      activeEventDetails: null,
      calendarEvents: [], // CALENDAR_EVENTS_LIST,
      upCommingEvents: [],
      pastEvents: [],
      selectedEvent: null,
    });
  };

  const activeCalendarEvent = (event) => {
    setState({ activeEventDetails: event });
  };

  const setCalendarEvents = (eventObj) => {
    const calendarData = [...state.calendarEvents];
    const upCommingEventsData = [...state.upCommingEvents];
    const pastEventsData = [...state.pastEvents];
    _map(
      eventObj.past_events
        .map(({ date: date, ...rec }) => ({ ...rec, date, type: "past" }))
        .concat(
          eventObj.upcoming_events.map(({ date: date, ...rec }) => ({
            ...rec,
            date,
            type: "upcoming",
          }))
        ),
      (data) => {
        if (!existsMulti(calendarData, data.id, data.title, "id", "title")) {
          calendarData.push({
            id: data.id,
            title: data.title,
            address: data.address,
            start: new Date(
              moment(data.appointment_date_mobile).format("YYYY-MM-DD")
            ),
            end: new Date(
              moment(data.appointment_date_mobile).format("YYYY-MM-DD")
            ),
            type: data.type,
          });
        }
        if (
          !existsMulti(
            upCommingEventsData,
            data.id,
            data.title,
            "id",
            "title"
          ) &&
          data.type === "upcoming"
        ) {
          upCommingEventsData.push(data);
        }
        if (
          !existsMulti(pastEventsData, data.id, data.title, "id", "title") &&
          data.type === "past"
        ) {
          pastEventsData.push(data);
        }
      }
    );
    sortDateTimeArray(upCommingEventsData, "date");
    sortDateTimeArray(pastEventsData, "date");

    setState({
      upCommingEvents: upCommingEventsData,
      pastEvents: pastEventsData,
      calendarEvents: calendarData,
    });
  };

  const setCalendarAppointments = (eventObj) => {
    const calendarData = [...state.calendarEvents];
    const upCommingEventsData = [...state.upCommingEvents];
    const pastEventsData = [...state.pastEvents];
    _map(eventObj.appointments, (data) => {
      data.map((i) => {
        if (!existsMulti(calendarData, i.id, i.title, "id", "title")) {
          calendarData.push({
            id: i.id,
            title: i.title,
            address: i.address,
            start: new Date(moment(i.date).format("YYYY-MM-DD")),
            end: new Date(moment(i.date).format("YYYY-MM-DD")),
            type: i.meeting_type,
          });
        }
      });
    });
    if (eventObj.past_events.length > 0) {
      _map(eventObj.past_events, (data) => {
        if (!existsMulti(pastEventsData, data.id, data.title, "id", "title")) {
          pastEventsData.push(data);
        }
      });
    }

    if (eventObj.upcoming_events.length > 0) {
      _map(eventObj.upcoming_events, (data) => {
        if (
          !existsMulti(upCommingEventsData, data.id, data.title, "id", "title")
        ) {
          upCommingEventsData.push(data);
        }
      });
    }
    sortDateTimeArray(upCommingEventsData, "date");
    sortDateTimeArray(pastEventsData, "date");
    setState({
      upCommingEvents: upCommingEventsData,
      pastEvents: pastEventsData,
      calendarEvents: calendarData,
    });
  };

  const setSelectedEvent = (eventObj) => {
    setState({ selectedEvent: eventObj });
  };

  const updateCalendarAppointment = (id, event) => {
    let calendarData = [...state.calendarEvents];
    let upCommingEventsData = [...state.upCommingEvents];
    if (calendarData.length > 0) {
      _map(calendarData, (data, i) => {
        if (parseInt(data.id) === parseInt(id)) {
          let changedDate = new Date(event.date);
          calendarData[i].start = changedDate;
          calendarData[i].end = changedDate;
        }
      });
    }
    if (upCommingEventsData.length > 0) {
      _map(upCommingEventsData, (data, i) => {
        if (parseInt(data.id) === parseInt(id)) {
          upCommingEventsData[i] = event;
        }
      });
    }

    setState({
      upCommingEvents: upCommingEventsData,
      calendarEvents: calendarData,
    });
  };

  const setAddNewChecklist = () => {
    let newChecklist = state.checklist;
    newChecklist.unshift({ title: undefined, is_mark: false });
    setState({ checklist: newChecklist });
  };

  const setAddNewSubTask = (taskIndex) => {
    console.log("TT -> taskIndex", taskIndex);
    let newChecklist = state.checklist;
    console.log("TT -> Checklist", newChecklist);
    console.log("TT -> Checklist[]", newChecklist[taskIndex]);
    let newSubTaskList = newChecklist[taskIndex].sub_tasks;
    newSubTaskList.push({ title: undefined, is_mark: false });
    newChecklist[taskIndex].sub_tasks = newSubTaskList;
    setState({ checklist: newChecklist });
  };

  const setChecklistCategories = (obj) => {
    setState({
      checkListCategories: isEmpty(obj) ? [] : obj.checklist,
    });
  };

  const setSelectedChecklistCategory = (index) => {
    setState({ selectedCategoriesIndex: index });
  };

  const setLoader = (data) => {
    setState({ loader: data });
  };

  const setChecklist = (categoryId, obj) => {
    if (obj != undefined) {
      let newTasks = obj.tasks ? obj.tasks : [];
      const sortArray = [];
      newTasks.map((item) => {
        sortArray.push({
          subcategory_id: item.id,
          sort_order: item.sort_order,
        });
        return { ...item, categoryId: categoryId };
      });
      setState({ checklist: newTasks, checkListSortableArray: sortArray });
    } else {
      setState({ checklist: [], checkListSortableArray: [] });
    }
  };

  /** If Primary Task mark then mark all its sub tasks */
  const setMarkingForTask = (task) => {
    const { is_mark, task_id } = task;
    let checkList = state.checklist;
    let index = findIndex(checkList, (o) => {
      return o.id === task_id;
    });
    let obj = find(checkList, (o) => {
      return o.id === task_id;
    });
    const newSubTask = [];
    obj.sub_tasks.forEach((task) => {
      let new_obj;
      if (is_mark) {
        new_obj = { ...task, is_mark: "true" };
      } else {
        new_obj = { ...task, is_mark: "false" };
      }
      newSubTask.push(new_obj);
    });
    delete checkList[index].sub_tasks;
    checkList[index].sub_tasks = newSubTask;
    setState({
      checklist: checkList,
    });
  };

  const addNewlyCreatedEvent = (eventObj) => {
    let calendarData = [...state.calendarEvents];
    calendarData.push({
      id: eventObj.id,
      title: eventObj.title,
      address: eventObj.address,
      start: new Date(
        moment(
          eventObj.date + " " + eventObj.start_time,
          "dddd,MMMM Do YYYY hh:mm a"
        ).format("YYYY-MM-DD hh:mm a")
      ),
      end: new Date(
        moment(
          eventObj.date + " " + eventObj.end_time,
          "dddd,MMMM Do YYYY hh:mm a"
        ).format("YYYY-MM-DD hh:mm a")
      ),
      type: "upcoming",
    });

    setState({
      upCommingEvents: [...state.upCommingEvents, { ...eventObj }],
      calendarEvents: calendarData,
    });
  };

  return {
    resetToolsState,
    activeCalendarEvent,
    setCalendarEvents,
    setSelectedEvent,
    setAddNewChecklist,
    setAddNewSubTask,
    setChecklistCategories,
    setSelectedChecklistCategory,
    setChecklist,
    setLoader,
    setMarkingForTask,
    addNewlyCreatedEvent,
    setCalendarAppointments,
    updateCalendarAppointment,
    ...state,
  };
};

export default useTools;
