import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .progress-main-title {
    color: var(--primary-dark-color);
    font-family: "TTCommons-DemiBold";
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 0 0 10px 0;
    padding-top: 18px;
  }
  .progress-title {
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 0 0 20px 0;
    padding-top: 2px;
  }
  .boost-profile-progressbar {
    max-width: 420px;
    margin: 0 auto;
    .RSPBprogressBar {
      .RSPBstep {
        &:nth-child(5) {
          .transitionStep {
            &:after {
              display: none !important;
            }
          }
        }
        .progress-item {
          margin-bottom: 0;
        }
      }
    }
  }
`;
