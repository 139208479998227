import React from "react";
import Joyride, { EVENTS, STATUS, ACTIONS } from "react-joyride";
import { useNavigate } from "react-router-dom";

import { useAppState } from "../context";

export default function TutorialAmbassador() {
  const navigate = useNavigate();
  const {
    run,
    stepIndex,
    steps,
    resetStateAmbassadorTutorial,
    bulkUpdateAmbassadorTutorial,
  } = useAppState("ambassadorTutorial");
  const { profile } = useAppState("profile");
  const { isProfileCompleted } = profile || {};

  const handleCallback = (data) => {
    const { index, type, status, action } = data || {};

    // Handle use case where profile is not completed, then pause the tutorial
    if (index === 2 && !isProfileCompleted) return;

    // Handles scenario where tour is started and user is not on root step route
    if (
      [ACTIONS.START].includes(action) &&
      steps[index]?.route &&
      window.location.pathname !== steps[index]?.route
    ) {
      navigate(steps[index]?.route); // Navigate to root path
      return;
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (
        steps[index + 1]?.route &&
        window.location.pathname !== steps[index + 1]?.route
      ) {
        // Next step on different route - Navigate
        navigate(steps[index + 1]?.route);
        // Add delay to catch element on new route
        setTimeout(() => {
          bulkUpdateAmbassadorTutorial({
            stepIndex: index + 1,
          });
        }, [600]);
      } else {
        // Next step on same route
        bulkUpdateAmbassadorTutorial({
          stepIndex: index + 1,
        });
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      resetStateAmbassadorTutorial();
    }
  };

  return (
    <Joyride
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      continuous={true}
      showSkipButton={true}
      hideBackButton={true}
      hideCloseButton={true}
      disableScrolling={true}
      callback={handleCallback}
      locale={{
        next: "Got it",
        last: "All done!",
        skip: "SKIP TUTORIAL",
      }}
      styles={{
        options: {
          arrowColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          overlayColor: "rgba(128, 148, 171, 0.4)",
          width: 382,
          height: 157,
          zIndex: 1000,
        },
      }}
    />
  );
}
