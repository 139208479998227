import { useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  jobs: [],
  jobsFilter: {},
  grouped_top_employer_jobs: {},
  savedJobs: [],
  showIndexJobComponents: true,
};

const useJob = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const searchSetJobs = (obj) => {
    setState({ jobs: [...obj.jobs] });
  };

  const setJobs = (obj) => {
    setState({ jobs: [...obj] });
  };

  const setJobsFilter = (obj) => {
    setState({ jobsFilter: { ...obj } });
  };

  const setGroupedTopEmployerJobs = (obj) => {
    setState({
      grouped_top_employer_jobs: {
        ...obj,
      },
    });
  };

  const setSavedJobs = (obj) => {
    setState({ savedJobs: [...obj] });
  };

  const setShowIndexJobComponents = (status = true) => {
    setState({ showIndexJobComponents: status });
  };

  return {
    searchSetJobs,
    setJobs,
    setJobsFilter,
    setGroupedTopEmployerJobs,
    setSavedJobs,
    setShowIndexJobComponents,
    ...state,
  };
};

export default useJob;
