import React from "react";

function Icon({ onClick, titleText = "information" }) {
  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 17 17"
      role={"img"}
    >
      <title>{titleText}</title>
      <g fill="none" fillRule="evenodd">
        <circle fill="#607790" cx="8.5" cy="8.5" r="8" />
        <path
          d="M9.22 13.5H7.38V6.14h1.84v7.36Zm-.88-8.32a.998.998 0 0 1-.728-.312.998.998 0 0 1-.312-.728c0-.277.104-.52.312-.728A.998.998 0 0 1 8.34 3.1c.277 0 .52.104.728.312.208.208.312.45.312.728 0 .277-.104.52-.312.728a.998.998 0 0 1-.728.312Z"
          fill="#FFF"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default React.memo(Icon);
