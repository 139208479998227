import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .mytopic-mainpage {
    margin-top: 8px;
    &__discover .shadow-btn {
      margin-bottom: 21px;
      display: flex;
      justify-content: flex-start;
      background: #ffffff !important;
      box-shadow: var(--card-box-shadow) !important;
      width: 100%;
      padding: 0 20px !important;
      height: auto;
      min-height: 50px;
      border-radius: 25px;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        span {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
          text-transform: initial !important;
        }
      }
    }
    &__saved .shadow-btn {
      margin-bottom: 21px;
      display: flex;
      justify-content: flex-start;
      background: #ffffff !important;
      box-shadow: var(--card-box-shadow) !important;
      width: fit-content;
      padding: 0 20px !important;
      height: auto;
      min-height: 50px;
      border-radius: 25px;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          margin: 0 5px 2px 0;
        }
        span {
          color: var(--field-label-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          margin-top: 2px;
          text-transform: initial !important;
        }
      }
    }
    .my-topic-page {
      .list {
        .info-panel {
          .info-panel-card {
            min-height: auto;
            padding: 25px 15px;
            border-radius: 10px;
            box-shadow: var(--card-box-shadow);
            .MuiAvatar-root {
              width: 50px;
              height: 50px;
              min-width: 50px;
            }
            .panel-header {
              width: calc(100% - 60px);
              padding-right: 0;
              .panel-title {
                width: 100%;
                .tags {
                  .tag-item {
                    border-radius: 10.5px;
                    background-color: #0030b5;
                    color: #ffffff;
                    font-family: "TTCommons-Regular";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 17.2px;
                    text-align: center;
                    height: 21px;
                    padding: 2px 8px 0 9px;
                    min-width: 53px;
                    width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    .middle-section {
      .title-withbutton {
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 10px;
        @media (max-width: 991px) {
          align-items: center;
        }
        .title-primary {
          @media (max-width: 991px) {
            margin-bottom: 0;
          }
          @media (max-width: 767px) {
            margin-bottom: 8px;
          }
        }
      }
    }
    &__suggest-topic {
      cursor: pointer;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow);
      display: flex;
      align-items: center;
      padding-inline: 20px;
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin-top: 3px;
      }
      &:hover {
        box-shadow: var(--card-box-shadow-hover);
      }
    }
    &__view-pinned {
      cursor: pointer;
      margin: 15px 0 15px 0;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow);
      display: flex;
      align-items: center;
      padding-inline: 20px;
      img {
        margin-right: 10px;
      }
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin-top: 3px;
      }
      &:hover {
        box-shadow: var(--card-box-shadow-hover);
      }
    }
    &__recommendation-detail-wrapper {
      margin-top: 15px;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 130px;
    }
  }
`;
