import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppState } from "../../../../context";
import { renderTimestamp } from "../../../../helper/helper";
import { scrollToActiveConversation } from "../../../../utils/common";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./ChatUsersListItem.module.scss";

const cx = createModuleStyleExtractor(styles);

const ChatUsersListItem = ({ user }) => {
  const {
    time,
    latest_message,
    user_image,
    title,
    conversation_id,
    portal_type,
    account_type,
    conversation_type,
  } = user || {};
  const {
    setCurrentConversationId,
    activeConversation,
    setCurrentSearchMessageItem,
    unreadMessagesCount,
    setTriggerReloadUnreadCountUpdate,
  } = useAppState("chat");
  const notificationStore = useSelector((store) => store.notificationStore);
  let [count, setCount] = useState(0);

  useEffect(() => {
    if (notificationStore.chat_specific_notifcations_count.length === 0) return;

    let index = notificationStore.chat_specific_notifcations_count.findIndex(
      (chat) => chat[0] === conversation_id
    );
    if (index !== -1)
      setCount(notificationStore.chat_specific_notifcations_count[index][1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStore]);

  const onItemClicked = (conversation_id) => {
    if (activeConversation === conversation_id) return;
    setCurrentConversationId(conversation_id);
    scrollToActiveConversation(conversation_id);
    setCurrentSearchMessageItem(null);
    unreadMessagesCount &&
      unreadMessagesCount > 0 &&
      setTriggerReloadUnreadCountUpdate(true);
  };
  return (
    <a
      href="/#"
      id={`chat_contact_list-interstride--${conversation_id}`}
      onClick={(e) => {
        e.preventDefault();
        onItemClicked(conversation_id);
      }}
      className={cx([
        `chat-user-preview`,
        activeConversation === conversation_id
          ? "chat-user-preview--active"
          : "",
      ])}
    >
      <div className={cx("chat-user-preview__profile")}>
        <CircularAvatar
          src={user_image}
          size={"50"}
          name={title}
          round={true}
        />
      </div>
      <div className={cx("chat-user-preview__details")}>
        <h4 className={cx("title")}>
          <span>{title}</span>
          {time && (
            <h5 className={cx("title__time")}>
              {time && renderTimestamp(time)}
            </h5>
          )}
          <h6 style={{ display: count === 0 ? "none" : "" }}>
            <span className="count">{count}</span>
          </h6>
        </h4>
        <div className={cx("chat-user-preview__details__tags")}>
          {portal_type === "admission" && (
            <div className={cx("badge")}>
              <span className={cx("badge__content")}>
                {account_type === "prospect_user" ? "Prospect" : "Admission"}
              </span>
            </div>
          )}
          {conversation_type === "request_meeting" && (
            <div className={cx("badge")}>
              <span className={cx("badge__content")}>Meeting</span>
            </div>
          )}
        </div>
        <p className={cx("description")}>{latest_message || ""}</p>
      </div>
    </a>
  );
};

export default memo(ChatUsersListItem);
