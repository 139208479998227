import { useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  mode: true,
  webId: null,
  webinarData: null,
};

const useMode = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getViewMode = (mode) => {
    setState({
      ...state,
      mode
    })
  }
  const setWebinarDetail = (data) => {
    setState({ ...state, webinarData: data, webId: data.id })
  }

  return {
    ...state,
    getViewMode,
    setWebinarDetail
  };
};

export default useMode;
