import React, { useEffect, useState, useLayoutEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./NewGroupStyles.module.scss";

import CardCustom from "../../../components/CardCustom/CardCustom";
import { useAppState } from "../../../context";
import { createModuleStyleExtractor } from "../../../utils/css";
import ChatContactListItem from "../../../components/NetworkDashboard/Messenger/ChatContactListItem/ChatContactListItem";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import CropperPic from "../../../components/Cropper";
import CustomDropzone from "../../../components/CustomDropzone/CustomDropzone";
import { Input } from "../../../components/atoms/Input/Input";
import { capitalizeFirstLetterOnType, toastify } from "../../../helper/helper";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import { fetchContactsList } from "../../../services/chat.service";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import { routes } from "../../../routes";
import { CircularAvatar } from "../../../components/atoms/CircularAvatar/CircularAvatar";

import deleteIcon from "../../../assets/svg/f-delete-icon.svg";

const cx = createModuleStyleExtractor(styles);

const NewGroup = () => {
  const navigate = useNavigate();
  const [applyFilter, setApplyFilter] = useState(false);
  const [groupAvatar, setGroupAvatar] = useState(null);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [cropperImage, setCropperImage] = useState(null);
  const [profilePreview, setProfilePreview] = useState("");
  const [currentState, setCurrentState] = useState(0);
  const [processingList, setProcessingList] = useState(false);
  // Filter States
  const [filterUserName, setFilterUserName] = useState("");
  const [filterDegree, setFilterDegree] = useState("");
  const [filterGraduationYear, setFilterGraduationYear] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterUserType, setFilterUserType] = useState("");

  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const {
    contacts,
    groupTags,
    networkFilters,
    setGroupTags,
    currentGroupModalId,
    getConversationByConversationID,
    setCurrentGroupModalId,
    setChatContactsList,
    setIsGroupCreating,
    setGroupCreateRequestData,
  } = useAppState("chat");

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout";
  });

  // When a group is created, update the state and navigate the user back to messages
  const onGroupCreateStateUpdate = (data) => {
    setIsGroupCreating(true);
    setGroupCreateRequestData(data);
  };

  const activeGroupId = currentGroupModalId;
  const getConversation = getConversationByConversationID(activeGroupId);
  const { group_details } = getConversation || {};

  const { degree, gradutaion_year, countries, user_types } =
    networkFilters || {};

  const changeState = async () => {
    const users = [userId, ...groupTags.map((tag) => parseInt(tag.id))];
    const groupInfo = {
      user_ids: users,
      group_chat: true,
    };
    if (currentState === 0) {
      // it means user is trying to update existing group
      if (activeGroupId !== -1) {
        const meta = {
          ...groupInfo,
          conversation_window_id: group_details.conversation_window_id,
        };
        setCurrentGroupModalId(-1);
        onGroupCreateStateUpdate(meta);
        setGroupTags([]);
        navigate(routes.NETWORK.MESSENGEN);
        return;
      }
      if (groupTags.length < 2) {
        toastify("error", "Please add atleast two members to the group");
        return;
      }
      setCurrentState(1);
      return;
    }

    if (currentState === 1) {
      const meta = {
        group_title: groupTitle,
        group_icon: groupAvatar,
        group_info: groupDescription,
        ...groupInfo,
      };
      if (!groupAvatar) {
        toastify("error", "Please choose a group image.");
        return;
      }
      if (!groupTitle) {
        toastify("error", "Please provide a group name.");
        return;
      }

      if (!groupInfo) {
        toastify("error", "Please provide a short description for your group.");
        return;
      }
      onGroupCreateStateUpdate(meta);
      navigate(routes.NETWORK.MESSENGEN);
    }
  };

  /**
   * Helps to determine if user already exists
   * @param {*} id
   * @returns
   */
  const doesTagExists = (id) =>
    groupTags.find((tag) => `${tag.id}` === `${id}`);

  /**
   * Helps to generate the dropdown options from API data
   * @param {} options
   * @returns
   */
  const generateOptions = (options) => {
    return [
      // { value: -1, label: "All" }, // Commented until BE API supports ALL option
      ...options.map((option, index) => {
        return { value: index, label: option };
      }),
    ];
  };

  /**
   * Trigger once the user is selected
   * @param {*} item
   * @returns
   */
  const onContactSelect = (item) => {
    const { id } = item;
    if (doesTagExists(id)) {
      // Remove from local and app state
      removeMember(id);
      return;
    }
    const tag = {
      id: `${item.id}`,
      text: item.full_name,
      user_profile: item.user_profile,
    };
    setGroupTags([...groupTags, tag]);
  };

  const removeMember = (deleteId) => {
    const _tags = groupTags.filter(
      (user) => Number(user.id) !== Number(deleteId)
    );
    setGroupTags(_tags);
  };

  /**
   * Crop Image Handler
   * @param {*} file
   * @param {*} url
   */
  const getCropData = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setGroupAvatar(reader.result);
    };
    setCropperImage(null);
  };

  const handleCallback = (file) => {
    file.forEach((item) => {
      const { type, size } = item || {};
      if (!["image/png", "image/jpg", "image/jpeg"].includes(type)) {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      } else if (parseInt(size / 1000) > 5000) {
        toastify("error", "Please select a file < 5MB.");
      } else {
        setProfilePreview(URL.createObjectURL(item));
        setCropperImage(URL.createObjectURL(item));
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = () => {
          setGroupAvatar(reader.result);
        };
      }
    });
  };

  /**
   * Filter Discover network
   */
  const filterDiscoverNetwork = async (resetFilter = false) => {
    setProcessingList(true);
    const options = {
      ...(filterUserName && { user_name: filterUserName }),
      ...(filterGraduationYear && {
        graduation_year: filterGraduationYear.label,
      }),
      ...(filterCountry && { country: filterCountry.label }),
      ...(filterDegree && { degree: filterDegree.label }),
      ...(filterUserType && { account_type: filterUserType.label }),
    };
    try {
      const response = await fetchContactsList(resetFilter ? {} : options);
      setProcessingList(false);
      if (response?.success) {
        setChatContactsList(response.data?.networks_list || []);
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        error?.data?.message
          ? error?.data?.message
          : "Oops! something went wrong"
      );
      setProcessingList(false);
    }
  };

  useEffect(() => {
    // On Component unload
    filterDiscoverNetwork(true);
    return () => {
      // Reset app state
      setGroupTags([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilter = (e) => {
    e.preventDefault();
    setFilterUserName("");
    setFilterUserType("");
    setFilterDegree("");
    setFilterCountry("");
    setFilterGraduationYear("");
    // Call API
    filterDiscoverNetwork(true);
  };

  const handleBack = () => {
    if (currentState === 0) {
      navigate(routes.NETWORK.MESSENGEN);
    } else {
      setCurrentState(0);
    }
  };

  return (
    <div className={cx("custom-container")}>
      <div className={cx("custom-container__header")}>
        <div className={cx("custom-container__header__title")}>
          <h2>New group message</h2>
        </div>
        <div className={cx("custom-container__header__options")}>
          <div className={cx("custom-container__header__options__back")}>
            <button onClick={handleBack}>
              <img src={BackArrow} alt="Back arrow" />
              <span>Back</span>
            </button>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3} sm={12} md={4}>
          {currentState === 0 && (
            <div className={cx("collapsible-view")}>
              <h2 className="title-primary mb-15">
                Search & filter{" "}
                {(filterUserName ||
                  filterUserType ||
                  filterDegree ||
                  filterCountry ||
                  filterGraduationYear) && ( // This conditional shall include all filter field states
                  <a
                    href="/#"
                    className="ngray-link ml-10"
                    onClick={handleResetFilter}
                  >
                    Clear all
                  </a>
                )}
              </h2>
              <div className={cx("filters-wrapper")}>
                <div className={cx("dialog-content__search-input")}>
                  <Input
                    label="Search network"
                    type="text"
                    className="a-input--single-chat"
                    labelClassName="a-input--single-chat__label"
                    value={filterUserName}
                    placeholder="Type a name"
                    onChange={(e) => setFilterUserName(e.target.value)}
                  />
                </div>
                {applyFilter && (
                  <React.Fragment>
                    <div className="mt-15">
                      <div className="form-field-group">
                        <DropdownCustom
                          name="user_type"
                          label="Filter by user type"
                          placeholder="User type"
                          justifyContent="left"
                          value={filterUserType}
                          options={
                            user_types
                              ? generateOptions(
                                  user_types.filter((item) => item !== "All")
                                )
                              : []
                          }
                          onChange={(e) => setFilterUserType(e)}
                        />
                      </div>
                      <div className="form-field-group">
                        <DropdownCustom
                          name="degree"
                          label="Filter by degree"
                          placeholder="Degree"
                          justifyContent="left"
                          value={filterDegree}
                          options={degree ? generateOptions(degree) : []}
                          onChange={(e) => setFilterDegree(e)}
                        />
                      </div>
                      <div className="form-field-group">
                        <DropdownCustom
                          name="country"
                          label={"Filter by nationality"}
                          placeholder="Nationality"
                          justifyContent="left"
                          value={filterCountry}
                          options={countries ? generateOptions(countries) : []}
                          onChange={(e) => setFilterCountry(e)}
                        />
                      </div>
                      <div className="form-field-group">
                        <DropdownCustom
                          name="graduationYear"
                          label={"Select by graduation year"}
                          placeholder={"Graduation year"}
                          justifyContent="left"
                          value={filterGraduationYear}
                          options={
                            gradutaion_year
                              ? generateOptions(gradutaion_year)
                              : []
                          }
                          onChange={(e) => setFilterGraduationYear(e)}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={cx("apply-filters-actions")}>
                  <div className="text-center mt-20">
                    <a
                      href="/#"
                      className="nlink-secondary-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setApplyFilter(!applyFilter);
                      }}
                    >
                      {applyFilter ? "Hide filters" : "Filters"}
                    </a>
                  </div>
                </div>
                <div className="mt-20">
                  <ButtonCustom
                    width={176}
                    height={50}
                    onClick={() => filterDiscoverNetwork()}
                    className={cx("search-button")}
                  >
                    Search
                  </ButtonCustom>
                </div>
              </div>
            </div>
          )}
        </Grid>

        <Grid item xs={12} lg={6} sm={12} md={6}>
          {processingList && (
            <div>
              <JobsListingEffect count={4} />
            </div>
          )}
          {currentState === 0 && !processingList && (
            <div>
              <h2 className="title-primary mb-15">People</h2>
              <div>
                {contacts && contacts.length > 0 ? (
                  contacts.map(
                    (item, index) =>
                      !item.user_deleted && (
                        <ChatContactListItem
                          key={index}
                          item={item}
                          onContactSelect={onContactSelect}
                          group={true}
                          groupTags={groupTags}
                        />
                      )
                  )
                ) : (
                  <div className="facelift-empty-boxwrap">
                    <h3>No student's are available for this search.</h3>
                  </div>
                )}
              </div>
              <br />
              <br />
            </div>
          )}
          {currentState === 1 && (
            <div className="group-setting-page">
              <h2 className="title-primary mb-15 text-center">
                Your group settings
              </h2>
              <div className="card">
                {cropperImage ? (
                  <div className="card__cropper-wrapper">
                    <CropperPic
                      image={cropperImage}
                      getCropData={getCropData}
                    />
                  </div>
                ) : (
                  <>
                    <label>Upload a group profile picture</label>
                    <div className="image-upload">
                      <CustomDropzone
                        text="Upload picture"
                        handleUploadFile={handleCallback}
                        showPreview={true}
                        preview={profilePreview}
                      />
                    </div>
                    <div className="group_name">
                      <TextboxCustom
                        label="Group name"
                        placeholder="Give the group a name"
                        value={groupTitle}
                        onChange={(e) =>
                          setGroupTitle(
                            capitalizeFirstLetterOnType(e.target.value)
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Group description</label>
                      <textarea
                        placeholder="Give a short description for your group."
                        className={cx("form-group__info")}
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                      />
                    </div>
                    <div className="button-wrapper">
                      <ButtonCustom
                        width={285}
                        height={50}
                        onClick={() => changeState()}
                      >
                        Create group
                      </ButtonCustom>
                    </div>
                    <div className="cancel-wrapper">
                      <span onClick={() => navigate(routes.NETWORK.MESSENGEN)}>
                        Cancel
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={3}>
          {groupTags.length > 0 && currentState === 0 && (
            <div className="group-members">
              <h2 className="title-primary mb-15">Your group message</h2>
              <CardCustom className="new-message-group-card">
                <ul>
                  {groupTags.map((item) => {
                    return (
                      <li key={item.id}>
                        <CircularAvatar
                          src={item.user_profile}
                          name={item.text}
                          round={true}
                          size={55}
                        />
                        <div className="user-name ml-10">{item.text}</div>
                        <a
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            removeMember(item.id);
                          }}
                          className="user-action"
                        >
                          <img src={deleteIcon} alt="Delete Icon" />
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <ButtonCustom
                  width={248}
                  onClick={() => changeState()}
                  className="focus-white"
                >
                  {currentGroupModalId === -1 ? "Next" : "Done"}
                </ButtonCustom>
              </CardCustom>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default NewGroup;
