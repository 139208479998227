import React, { useEffect, useState, useLayoutEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./NewMessageStyles.module.scss";
import { useAppState } from "../../../context";
import { Input } from "../../../components/atoms/Input/Input";
import { createModuleStyleExtractor } from "../../../utils/css";
import ChatContactListItem from "../../../components/NetworkDashboard/Messenger/ChatContactListItem/ChatContactListItem";
import { createFreshChatPreview } from "../../../utils/common";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import { fetchContactsList } from "../../../services/chat.service";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import { routes } from "../../../routes";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import { toastify, addAccessibilityClass } from "../../../helper/helper";

const cx = createModuleStyleExtractor(styles);

const NewMessage = () => {
  const navigate = useNavigate();
  const { setChatContactsList } = useAppState("chat");

  const [applyFilter, setApplyFilter] = useState(false);
  const [processing, setIsProcessing] = useState(false);
  //Network filter states
  const [filterUserName, setFilterUserName] = useState("");
  const [filterGraduationYear, setFilterGraduationYear] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterDegree, setFilterDegree] = useState("");
  const [filterUserType, setFilterUserType] = useState("");

  const {
    contacts,
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    networkFilters,
  } = useAppState("chat");

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout";
    addAccessibilityClass();
  });

  const { degree, gradutaion_year, countries, user_types } =
    networkFilters || {};

  /**
   * Helps to generate the dropdown options from API data
   * @param {} options
   * @returns
   */
  const generateOptions = (options) => {
    return [
      // { value: -1, label: "All" }, // Commented as the key of "ALl" needs to be handled on the BE
      ...options.map((option, index) => {
        return { value: index, label: option };
      }),
    ];
  };

  /**
   * Trigger once the user is selected
   * @param {*} item
   * @returns
   */
  const onContactSelect = (item) => {
    const { id } = item;

    const user = getChatContactListItem(id);
    const chat_preview = getChatPreviewByReceiverId(id);
    if (chat_preview) {
      setCurrentConversationId(chat_preview.conversation_id);
      navigate(routes.NETWORK.MESSENGEN);
      return;
    }

    const randomConversationId = new Date().getTime();
    const { preview, conversation } = createFreshChatPreview(
      user,
      randomConversationId
    );
    setChats([preview, ...chats]);
    setConversationInformation(randomConversationId, conversation);
    setCurrentConversationId(randomConversationId);
    createFreshChatRecord(randomConversationId); // we will track by this id
    navigate(routes.NETWORK.MESSENGEN);
  };

  /**
   * Filter Discover network
   */
  const filterDiscoverNetwork = async (resetFilter = false) => {
    setIsProcessing(true);
    const options = {
      ...(filterUserName && { user_name: filterUserName }),
      ...(filterGraduationYear && {
        graduation_year: filterGraduationYear.label,
      }),
      ...(filterCountry && { country: filterCountry.label }),
      ...(filterDegree && { degree: filterDegree.label }),
      ...(filterUserType && { account_type: filterUserType.label }),
    };
    try {
      const response = await fetchContactsList(resetFilter ? {} : options);
      setIsProcessing(false);
      if (response && response.success) {
        setChatContactsList(response.data?.networks_list || []);
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        error?.data?.message
          ? error?.data?.message
          : "Oops! something went wrong"
      );
      setIsProcessing(false);
    }
  };

  /**
   * Sometimes the data in the state is not immediate updated by the API.
   * Hence we allow our UI some breathing space via an intentional loader.
   */
  useEffect(() => {
    setIsProcessing(true);
    filterDiscoverNetwork(true);

    setTimeout(() => {
      setIsProcessing(false);
    }, 2000);
  }, []);

  const handleResetFilter = (e) => {
    e.preventDefault();
    setFilterUserName("");
    setFilterUserType("");
    setFilterDegree("");
    setFilterCountry("");
    setFilterGraduationYear("");
    // Call API
    filterDiscoverNetwork(true);
  };

  return (
    <div className={cx("custom-container")}>
      <div className={cx("custom-container__header")}>
        <div className={cx("custom-container__header__title")}>
          <h2>New message</h2>
        </div>
        <div className={cx("custom-container__header__options")}>
          <div className={cx("custom-container__header__options__back")}>
            <button
              onClick={() => navigate(routes.NETWORK.MESSENGEN)}
              className="shadow-btn"
            >
              <img src={BackArrow} alt="Back arrow" />
              <span>Back</span>
            </button>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3} sm={12} md={4}>
          <div className={cx("collapsible-view")}>
            <h2 className="title-primary mb-15 ">
              Search & filter
              {(filterUserName ||
                filterUserType ||
                filterDegree ||
                filterCountry ||
                filterGraduationYear) && ( // This conditional shall include all filter field states
                <a
                  href="/#"
                  className="ngray-link ml-10"
                  onClick={handleResetFilter}
                >
                  Clear all
                </a>
              )}
            </h2>
            <div className={cx("filters-wrapper")}>
              <div className={cx("dialog-content__search-input")}>
                <Input
                  type="text"
                  className="a-input--single-chat"
                  labelClassName={"a-input--single-chat__label"}
                  value={filterUserName}
                  onChange={(e) => setFilterUserName(e.target.value)}
                  label={"Search network"}
                  placeholder={"Type a name"}
                />
              </div>
              {applyFilter && (
                <React.Fragment>
                  <div className="mt-15">
                    <div className="form-field-group">
                      <DropdownCustom
                        name="user_type"
                        label="Filter by user type"
                        placeholder="User type"
                        justifyContent="left"
                        value={filterUserType}
                        options={
                          user_types
                            ? generateOptions(
                                user_types?.filter((item) => item !== "All")
                              )
                            : []
                        }
                        onChange={(e) => setFilterUserType(e)}
                      />
                    </div>
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="degree"
                      label="Filter by degree"
                      placeholder="Degree"
                      justifyContent="left"
                      value={filterDegree}
                      options={degree ? generateOptions(degree) : []}
                      onChange={(e) => setFilterDegree(e)}
                    />
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="country"
                      label={"Filter by nationality"}
                      placeholder="Nationality"
                      justifyContent="left"
                      value={filterCountry}
                      options={countries ? generateOptions(countries) : []}
                      onChange={(e) => setFilterCountry(e)}
                    />
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="graduationYear"
                      label={"Select by graduation year"}
                      placeholder={"Graduation year"}
                      justifyContent="left"
                      value={filterGraduationYear}
                      options={
                        gradutaion_year ? generateOptions(gradutaion_year) : []
                      }
                      onChange={(e) => setFilterGraduationYear(e)}
                    />
                  </div>
                </React.Fragment>
              )}
              <div className={cx("apply-filters-actions")}>
                <div className="text-center mt-20">
                  <a
                    href="/#"
                    className="nlink-secondary-dark"
                    onClick={(e) => {
                      e.preventDefault();
                      setApplyFilter(!applyFilter);
                    }}
                  >
                    {applyFilter ? "Hide filters" : "Filters"}
                  </a>
                </div>
              </div>
              <div className="mt-20">
                <ButtonCustom
                  height={50}
                  onClick={() => filterDiscoverNetwork()}
                >
                  Search
                </ButtonCustom>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          {processing ? (
            <JobsListingEffect count={4} />
          ) : (
            <div>
              <h2 className="title-primary mb-15">People</h2>
              {contacts && contacts.length > 0 ? (
                contacts.map((item, index) => (
                  <ChatContactListItem
                    key={index}
                    item={item}
                    onContactSelect={onContactSelect}
                  />
                ))
              ) : (
                <div className="facelift-empty-boxwrap">
                  <h3>No student's are available for this search.</h3>
                </div>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} lg={3} sm={12} md={2}>
          <div className="mb-40" />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewMessage;
