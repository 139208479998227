import React from "react";
import CourseIframeStyles from "./CourseIframeStyles";
import { useEffect } from "react";
import { useRef } from "react";

const CourseIframe = ({ coursesList, activeCourse }) => {
  const ref = useRef(null);

  useEffect(() => {
    let iframe = document.getElementById("iframeTarget");
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      // iframe.height = window.screen.width > 985 ? window.screen.height - 480 : window.screen.height - 360
      iframe.width =
        window.screen.width > 985
          ? window.screen.width - 90
          : window.screen.width - 30;
  }, []);

  return (
    <CourseIframeStyles className="course-iframe-wrapper">
      <iframe
        src={
          coursesList.find((course) => course.id == activeCourse)?.url ||
          "https://course.interstride.com/immigration/"
        }
        style={{ border: 0, width: "100%", height: "calc(100vh - 400px)" }}
        id="iframeTarget"
        ref={ref}
      />
    </CourseIframeStyles>
  );
};
export default CourseIframe;
