import React from "react";
import { memo } from "react";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../CustomDialog/CustomDialog";
const RemoveSearchModal = ({
  showDeleteSearchPopup,
  deleteSavedSearch,
  handleClose,
}) => {
  return (
    <CustomDialog
      open={showDeleteSearchPopup}
      title={"Delete saved search"}
      handleClose={handleClose}
      className="delete-saved-search-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <p className="warnning-text text-center">
        Are you sure you want to delete this saved search? This action cannot be
        reversed.
      </p>
      <div className="model-body d-flex justify-content-center align-items-center flex-flow-column ">
        <div className="btn mt-30 mb-30">
          <ButtonCustom width="238" onClick={deleteSavedSearch}>
            Delete Saved Search
          </ButtonCustom>
        </div>
        <a
          href="/#"
          className="ngray-link"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
          aria-label="cancel"
        >
          Cancel
        </a>
      </div>
    </CustomDialog>
  );
};
export default memo(RemoveSearchModal);
