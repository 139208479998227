import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getGraphData = async ({
  school_id = null,
  graph_type = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null,
}) => {
  const response = await axiosInstance.get(
    `schools/${school_id}/opts/graph_data`,
    {
      params: {
        graph_type,
        "filters[major]": major,
        "filters[year]": year,
        "filters[nationality]": nationality,
        "filters[degree]": degree,
        "filters[employment_type]": employment_type,
        "filters[country]": country,
      },
      headers: headers_with_token()?.headers,
    }
  );
  return response?.data;
};

export const getFiltersData = async ({ school_id = null }) => {
  const response = await axiosInstance.get(
    `schools/${school_id}/opts/discover_search_list`,
    headers_with_token()
  );
  return response?.data;
};

export const getGraphTableData = async ({
  school_id = null,
  page = 1,
  per_page = 10,
  search = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null,
  order_by = null,
  order_direction = null,
}) => {
  const response = await axiosInstance.get(
    `schools/${school_id}/opts/listing`,
    {
      params: {
        search: search?.length > 0 ? search : null,
        "pagination[page]": page,
        "pagination[per_page]": per_page,
        "filters[major]": major,
        "filters[year]": year,
        "filters[nationality]": nationality,
        "filters[degree]": degree,
        "filters[employment_type]": employment_type,
        "filters[country]": country,
        order_by,
        order_direction,
      },
      headers: headers_with_token()?.headers,
    }
  );
  return response?.data;
};
