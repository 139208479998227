import React from "react";

import { useAppState } from "../../../../context";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";

export default function DashboardAmbassadorInfoPopup({
  ambassadorInfoPopup,
  setAmbassadorInfoPopup,
}) {
  const { bulkUpdateAmbassadorTutorial } = useAppState("ambassadorTutorial");

  return (
    <CustomDialog
      className="ambassador-info-popup"
      title="Being an Ambassador on Interstride"
      open={ambassadorInfoPopup}
      handleClose={() => setAmbassadorInfoPopup(false)}
      padding={50}
    >
      <div className="ambassador-info-popup__body">
        <p>
          <br />
          As an Ambassador you get special access to support prospective
          students by interacting with them firsthand.
          <br />
          <br />
          Here are the key places for this special role:
          <br />
          <br />
          Your profile page
          <br />
          This is where you can preview your Ambassador profile as prospective
          students will see it.
          <br />
          <br />
          Messages
          <br />
          This is where you can view and filter messages you receive from
          prospective students.
          <br />
          <br />
          Communities
          <br />
          This is where you can view and filter Communities related to
          prospective students.
          <br />
          <br />
          Need a refresh?
        </p>
        <div className="ambassador-info-popup__body__button">
          <ButtonCustom
            onClick={() => {
              bulkUpdateAmbassadorTutorial({ run: true });
              setAmbassadorInfoPopup(false);
            }}
            width={238}
            height={50}
          >
            Restart tutorial
          </ButtonCustom>
        </div>
        <div className="ambassador-info-popup__body__back">
          <span
            onClick={() => {
              setAmbassadorInfoPopup(false);
            }}
          >
            Back
          </span>
        </div>
      </div>
    </CustomDialog>
  );
}
