import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
    .started-mainpage{
        display : block;

        .MuiDialog-container{
            .MuiPaper-root{
                .MuiDialogContent-root{
                    .MuiTypography-root{
                        .model-body{
                            .rating-box{
                                text-align : center;
                                background : green;
                            }
                        }
                    }
                }
            }
        }
    }
`;
