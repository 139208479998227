import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { config } from "../../constant/config";

const GoogleMapCustom = ({
  address = null,
  coordinates = null,
  label = "",
  wordMap = false,
  zoom = 8,
  ...props
}) => {
  const defaultCoordinates = { lat: 37.09024, lng: -95.712891 };
  const [location, setLocation] = useState(defaultCoordinates);
  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  const updateLocation = (coordinates) => {
    setLocation(coordinates);
  };

  useEffect(() => {
    if (address && address !== null) {
      var geo = new props.google.maps.Geocoder();
      geo.geocode({ address }, function (results, status) {
        if (status === props.google.maps.GeocoderStatus.OK) {
          updateLocation(results[0].geometry.location);
        } else {
          updateLocation(defaultCoordinates);
        }
      });
    } else if (coordinates && coordinates !== null) {
      setLocation(coordinates);
    }
  }, [address, coordinates]);

  const mapStyles = {
    width: "100%",
    height: "330px",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const onMarkerClick = (props, marker, e) =>
    setState({
      ...state,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  const onMapClicked = () => {
    if (state.showingInfoWindow) {
      setState({
        ...state,
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  return (
    <>
      {wordMap ? (
        <Map
          google={props.google}
          zoom={zoom}
          style={mapStyles}
          center={{ lat: 33.8869, lng: 9.5375 }}
          alt="Map"
        ></Map>
      ) : (
        <Map
          google={props.google}
          zoom={zoom}
          style={mapStyles}
          onClick={onMapClicked}
          center={location ? location : defaultCoordinates}
          alt="Map"
        >
          <Marker
            position={location ? location : defaultCoordinates}
            onClick={onMarkerClick}
            name={label}
            title={label}
          />
          <InfoWindow
            marker={state.activeMarker}
            visible={state.showingInfoWindow}
          >
            <div>
              <b>{state.selectedPlace.name}</b>
            </div>
          </InfoWindow>
        </Map>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: config().GOOGLE_SERVICE_API_KEY,
})(GoogleMapCustom);
