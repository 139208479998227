import React, { useState } from "react";

import EmploymentStyles from "./EmploymentStyles";
import {
  initialListingStateDataListing,
  initialStateTopEmployerGraph,
} from "../../JobPortal/EmploymentData/Utils/EmploymentDataConstants";
import TopEmployerGraph from "../../JobPortal/EmploymentData/Graphs/TopEmployerGraph/TopEmployerGraph";
import EmploymentDataTable from "../../JobPortal/EmploymentData/EmploymentDataTable/EmploymentDataTable";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

const Employment = ({ token }) => {
  // Employer Graph state
  const [stateTopEmp, setStateTopEmp] = useState(initialStateTopEmployerGraph);
  // Data listing table state
  const [tableState, setTableState] = useState(initialListingStateDataListing);

  const processGraphClick = () => {
    const element = document.getElementById("overlay-graph");
    if (element) element.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <EmploymentStyles>
      <div className="widget-employment-tab">
        <div className="employer-graph">
          <h2 className="title">Top 10 employers for international alumni</h2>
          <TopEmployerGraph
            widget
            token={token}
            state={stateTopEmp}
            setState={setStateTopEmp}
            processGraphClick={processGraphClick}
          />
        </div>
        <div className="employer-data" id="overlay-graph">
          <h2 className="title">All international employment data</h2>
          <EmploymentDataTable
            widget
            tableState={tableState}
            setTableState={setTableState}
          />
          <WidgetOverlay
            title="Access to full international employment data"
            route_type="employment_data"
          />
        </div>
      </div>
    </EmploymentStyles>
  );
};

export default Employment;
