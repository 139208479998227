import React from "react";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../../CustomDialog/CustomDialog";

export default function JobExperienceModal({ onClose = () => {} }) {
  return (
    <CustomDialog
      open={true}
      title={"Experience levels as definied from the U.S. Department of Labor"}
      handleClose={() => onClose(false)}
      isDisplayCloseButton={false}
      className="jobs-experience-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
        <div className="content-modal">
          <p>
            <span>Entry (Level I)</span> wage rates are assigned to job offers
            for beginning level employees who have only a basic understanding of
            the occupation. These employees perform routine tasks that require
            limited, if any, exercise of judgment. The tasks provide experience
            and familiarization with the employer’s methods, practices, and
            programs. The employees may perform higher level work for training
            and developmental purposes.
          </p>
          <p>
            These employees work under close supervision and receive specific
            instructions on required tasks and results expected. Their work is
            closely monitored and reviewed for accuracy. Statements that the job
            offer is for a research fellow, a worker in training, or an
            internship are indicators that a Level I wage should be considered.
          </p>
          <p>
            <span>Qualified (Level II)</span> wage rates are assigned to job
            offers for qualified employees who have attained, either through
            education or experience, a good understanding of the occupation.
            They perform moderately complex tasks that require limited judgment.
            An indicator that the job request warrants a wage determination at
            Level II would be a requirement for years of education and/or
            experience that are generally required as described in the O*NET Job
            Zones.
          </p>
          <p>
            <span>Experienced (Level III)</span> wage rates are assigned to job
            offers for experienced employees who have a sound understanding of
            the occupation and have attained, either through education or
            experience, special skills or knowledge. They perform tasks that
            require exercising judgment and may coordinate the activities of
            other staff. They may have supervisory authority over those staff. A
            requirement for years of experience or educational degrees that are
            at the higher ranges indicated in the O*NET Job Zones would be
            indicators that a Level III wage should be considered.
          </p>
          <p>
            Frequently, key words in the job title can be used as indicators
            that an employer’s job offer is for an experienced worker. Words
            such as ‘lead’ (lead analyst), ‘senior’ (senior programmer), ‘head’
            (head nurse), ‘chief’ (crew chief), or ‘journeyman’ (journeyman
            plumber) would be indicators that a Level III wage should be
            considered.
          </p>
          <p>
            <span>Fully competent (Level IV)</span> wage rates are assigned to
            job offers for competent employees who have sufficient experience in
            the occupation to plan and conduct work requiring judgment and the
            independent evaluation, selection, modification, and application of
            standard procedures and techniques. Such employees use advanced
            skills and diversified knowledge to solve unusual and complex
            problems. These employees receive only technical guidance and their
            work is reviewed only for application of sound judgment and
            effectiveness in meeting the establishment’s procedures and
            expectations. They generally have management and/or supervisory
            responsibilities.
          </p>
        </div>
        <div className="got-it-button">
          <ButtonCustom color="primary" onClick={() => onClose(false)}>
            Got It!
          </ButtonCustom>
        </div>
      </div>
    </CustomDialog>
  );
}
