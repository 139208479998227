import React, { memo } from "react";
import EmptyChatViewStyles from "./EmptyChatViewStyles";

const EmptyChatView = () => {
  return (
    <EmptyChatViewStyles>
      <div className="empty-chat-view">
        <h4>Welcome to Interstride’s Messaging Portal!</h4>
        <img src="/images/welcome.svg" width="400px" alt=""/>
        <p>
          This is the place to connect with our international student community. Whether you are looking for guidance on the application process or navigating life in the U.S., you can find it here from our international students.
        </p>
      </div>
    </EmptyChatViewStyles>
  );
};

export default memo(EmptyChatView);
