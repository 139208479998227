import { Grid } from "@mui/material";
import React, { memo } from "react";
import { ShimmerSocialPost } from "react-shimmer-effects";

const HomeFeedLoadingEffect = () => {
  return (
    <Grid container spacing={2}>
      {Array.from(Array(3).keys()).map((item, index) => (
        <Grid xs={12} item key={index}>
          <ShimmerSocialPost type="image" key={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(HomeFeedLoadingEffect);
