import React from "react";
import { useAppState } from "../../../../context";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CoverHeader } from "../../../atoms/CoverHeader/CoverHeader";
import Educations from "../Educations";
import styles from "./Education.module.scss";
const cx = createModuleStyleExtractor(styles);
export const ProfileEducation = () => {
  const { profile } = useAppState("profile");
  const { isProfileCompleted } = profile || {};
  return (
    <div
      className={cx([
        isProfileCompleted
          ? "profile-education-container__collapse"
          : "profile-education-container",
      ])}
    >
      <h1 className={cx("profile-education-container__top-heading")}>
        Education
      </h1>
      <CoverHeader>
        <Educations />
      </CoverHeader>
    </div>
  );
};
