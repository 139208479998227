import React from "react";
import EmptyMiddleBoxStyles from "./EmptyMiddleBoxStyles";

const EmptyMiddleBox = ({ title = null, subtitle = null }) => {
  return (
    <EmptyMiddleBoxStyles>
      <div className="facelift-empty-boxwrap">
        {title && <h3>{title}</h3>}
        {subtitle && <p>{subtitle}</p>}
      </div>
    </EmptyMiddleBoxStyles>
  );
};
export default EmptyMiddleBox;
