import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-resources-view {
    margin-top: 30px;
    @media (max-width: 906px) {
      margin-top: 24px;
    }
    .resource-categories-wrapper {
      .resource-categories-loader {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }
      .resource-categories-empty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 30px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 31px;
          text-align: center;
          margin: 0px 0px 10px 0px;
        }
      }
      .resource-categories-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;
        @media (max-width: 600px) {
          margin: 0 -4px;
        }
        .resource-categories-card {
          padding: 0 8px;
          width: 25%;
          margin: 0 0 16px 0;
          @media (max-width: 991px) {
            width: 33.33%;
          }
          @media (max-width: 600px) {
            width: 50%;
            padding: 0 4px;
            margin: 0 0 8px 0;
          }
          .card-content {
            padding: 20px;
            cursor: pointer;
          }
          .resource-categories-item {
            width: 100%;
            .resource-categories-image {
              width: 100%;
              height: 120px;
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              @media (max-width: 600px) {
                height: 80px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            span {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              display: block;
              padding: 4px 0 0 0;
            }
          }
        }
        .bg-overlay {
          .widget-overlay {
            height: 100%;
            .close {
              position: absolute;
              right: 60px;
              top: 100px;
              margin: 0;
              img {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .resource-categories-empty {
        img {
          max-width: 500px;
          margin: 18px auto;
          width: 100%;
        }
        h3 {
          font-size: 22px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
`;
