import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  position: -webkit-sticky;
  position: sticky;
  top: 14%;
  .recom-details-card {
    margin-top: 40px;
    padding: 20px;
    margin-bottom: 15px;
    &.mt-0 {
      margin-top: 0;
    }
    .card-content {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      flex-flow: column;

      .card-close-btn {
        position: absolute;
        top: -5px;
        right: -5px;
        display: flex;
      }
      > div {
        width: 100%;
      }
    }
    .profile-img {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 100px;
      margin: 0 auto 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .MuiAvatar-root {
        width: 100px;
        height: 100px;
      }
    }
    .profile-name {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      & > h3 {
        margin: 0;
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 27px;
        text-align: center;
        padding-top: 4px;
      }
      .border-star,
      .filled-star {
        margin: -2px 0 0 10px;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      margin: 6px 0px 20px 6px;
      .tag-item {
        background: #e5eaf7;
        height: 20px;
        display: flex;
        align-items: center;
        padding: 3px 5px 0 5px;
        box-sizing: border-box;
        text-transform: capitalize;
        font-family: "TTCommons-Regular";
        border-radius: 10.5px;
        background-color: #e5eaf7;
        min-width: 60px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        justify-content: center;
        margin: 1px;
      }
    }

    .sub-title {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      padding-top: 4px;
      width: 100%;
      + .sub-title {
        margin-top: 2px;
      }
    }
    .country-detail {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .c-flag {
        height: 15px;
        width: 22px;
        border-radius: 2px;
        background-color: #d8d8d8;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        margin: -2px 0 0 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          margin: 0;
        }
      }
      .country-name {
        margin-left: 11px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
    }
    .bio-detail {
      margin-top: 30px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
    .socials {
      display: flex;
      justify-content: center;
      align-items: center;

      .social-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin: 30px 15px 0;
        .MuiAvatar-root {
          border-radius: 100px !important;
        }
        span {
          margin-top: 4px;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
        }
      }
    }
    button {
      margin-top: 20px;
      width: 100%;
    }
  }
`;
