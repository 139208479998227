import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .calendar-card {
    background: transparent;
    box-shadow: none;
    margin-left: 20px;
    @media (max-width: 1024px) {
      margin-left: 16px;
    }
    @media (max-width: 991px) {
      margin-left: 0px;
    }
    .card-content {
      padding: 0 !important;
      .fc-header-toolbar {
        margin: 0 0 15px 0;
        .fc-toolbar-chunk {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          &:nth-child(1),
          &:nth-child(3) {
            display: none !important;
          }

          > div {
            display: flex;
            justify-content: center;
            align-items: center;

            .fc-button {
              background: transparent;
              border: 0 !important;
              outline: none !important;
              color: var(--primary-dark-color);
              font-size: 18px;
              padding: 0;
              margin: 0px 0 0 0;
              vertical-align: middle;
              box-shadow: none !important;
            }
            .fc-toolbar-title {
              font-size: 22px;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              padding: 0 8px;
              font-weight: 500;
              min-width: 150px;
              text-align: center;
            }
          }
        }
      }
      .fc-view-harness {
        border: 2px solid #ffffff;
        border-radius: 10px;
        background-color: #f1f4f7;
        overflow: hidden;
        .fc-daygrid {
          overflow-x: auto;
          .fc-scrollgrid {
            min-width: 600px;
            border: 0;
            thead {
              .fc-scrollgrid-section {
                td {
                  .fc-scroller {
                    table {
                      tr {
                        th {
                          border: 2px solid #ffffff;
                          .fc-scrollgrid-sync-inner {
                            height: auto;
                            padding: 20px 0px 14px;
                            white-space: nowrap;
                            a {
                              font-size: 18px;
                              font-weight: 600;
                              font-family: "TTCommons-DemiBold";
                              color: var(--primary-dark-color);
                              letter-spacing: 0;
                              line-height: 19px;
                              text-align: center;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            tbody {
              .fc-scrollgrid-section {
                .fc-scroller-harness {
                  .fc-scrollgrid-sync-table {
                    tbody {
                      td {
                        border: 2px solid #ffffff;
                        .fc-scrollgrid-sync-inner {
                          .fc-daygrid-day-top {
                            .fc-daygrid-day-number {
                              color: var(--primary-dark-color);
                              font-family: "TTCommons-Medium";
                              font-size: 18px;
                              font-weight: 500;
                              letter-spacing: 0;
                              line-height: 19px;
                              text-align: right;
                              padding: 8px;
                            }
                          }
                        }
                        &.fc-day-today {
                          .fc-scrollgrid-sync-inner {
                            .fc-daygrid-day-top {
                              .fc-daygrid-day-number {
                                height: 26px;
                                width: 26px;
                                margin: 8px;
                                color: #fff;
                                padding: 4px 0 0 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .fc-popover {
            border-radius: 10px;
            background-color: #ffffff !important;
            box-shadow: var(--card-box-shadow) !important;
            border: 0 !important;
            overflow: hidden;
            .fc-popover-header {
              padding: 8px 8px 4px 8px;
              background: #f1f4f7;
              .fc-popover-title {
                color: var(--primary-dark-color);
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                line-height: 16px;
                word-break: break-word;
                padding-top: 2px;
              }
              .fc-popover-close {
                color: var(--primary-dark-color) !important;
                opacity: 1;
                margin-top: -2px;
              }
            }
          }
        }
      }
      .fc .fc-daygrid-day-number {
        padding: 10px;
        font-size: 20px;
        font-family: "TTCommons-Medium";
        color: ${PRIMARY_COLOR};
      }
      .fc .fc-scrollgrid-section-liquid > td {
        height: 100%;
        border-right: 0 !important;
        border-bottom: 0;
      }
      .fc .fc-scrollgrid-section > td {
        border-right: 0 !important;
      }
      .fc .fc-daygrid-day.fc-day-today {
        background-color: transparent;
        .fc-daygrid-day-number {
          background: ${PRIMARY_COLOR};
          color: #fff;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 0 0 0;
          margin: 10px 10px 0 0;
        }
      }
      .fc .fc-daygrid-day-bottom {
        display: flex;
        font-size: 15px;
        color: var(--primary-dark-color);
        justify-content: flex-start;
        margin: -6px 0 0 20px;
        font-family: "TTCommons-DemiBold";
        font-weight: 600;
      }
      .fc-theme-standard th {
        border-top: 0 !important;
        border-left: 0 !important;
        &:last-child {
          border-right: 0 !important;
        }
      }
      .fc-theme-standard td {
      }
      .view-header {
        table {
          &.fc-col-header {
            border: 0 !important;
          }
        }
        .cal-day-header {
          .fc-scrollgrid-sync-inner {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            .fc-col-header-cell-cushion {
              font-size: 17px;
              font-family: "TTCommons-Medium";
              color: ${PRIMARY_COLOR};
              letter-spacing: 0.7px;
              font-weight: 500;
            }
          }
        }
      }
      .cal-event {
        &:hover {
          background: transparent;
        }
        > div {
          margin: 4px 2px;
          color: var(--primary-dark-color);
          font-size: 15px;
          font-family: "TTCommons-Medium";
          border: 0;
          word-break: break-word;
          white-space: normal;
          text-align: left;
          line-height: 17px;
          display: flex;

          &:before {
            content: "";
            width: 12px;
            height: 12px;
            min-width: 12px;
            background: #f76155;
            border-radius: 100px;
            display: inline-block;
            margin: 0px 3px 0 0;
          }
          // &.past-event {
          //   background: #e1e3e8;
          //   color: var(--gray-text-color);
          // }
          // &.upcoming-event {
          //   background: #d9dde8;
          //   color: ${PRIMARY_COLOR};
          // }
          // &.selected-event {
          //   background: ${PRIMARY_COLOR};
          //   color: #fff;
          // }
          img.custom-rss-icon {
            height: 12px;
            width: 12px;
            top: 15px;
            right: 74px;
            position: absolute;
            cursor: pointer;
          }
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            strong {
              font-family: "TTCommons-DemiBold";
              font-weight: 600;
            }
          }
        }

        .fc-daygrid-event-dot,
        .fc-event-time {
          border: 0;
          display: none;
        }

        .fc-event-title,
        .fc-event-title-container {
          align-items: center;
          text-align: center;
          color: ${PRIMARY_COLOR};
          font-size: 15px;
          font-family: "TTCommons-Regular";
          font-weight: normal;
          line-height: 15px;
        }
      }
    }
  }
`;
