import React, { useState, useEffect, useRef, memo } from "react";
import Chart from "react-google-charts";
import { isEmpty } from "lodash";

import { config } from "../../../constant/config";

const GOOGLE_SERVICE_API_KEY = config().GOOGLE_SERVICE_API_KEY;

const MarkerWorldMap = ({
  height = 600,
  valueType = "count", // "list "| "count"
  data = [],
  categoryLabel = "",
  valueLabel = "",
  onClick,
}) => {
  const [mapData, setMapData] = useState([]);
  const mapDataRef = useRef();

  useEffect(() => {
    mapDataRef.current = mapData;
  }, [mapData]);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      const labels = Object.keys(data);
      let fomattedData = [
        [
          categoryLabel,
          valueLabel,
          { type: "string", role: "tooltip", p: { html: true } },
        ],
      ];
      labels.forEach((key) => {
        const value = valueType === "list" ? data[key]?.length : data[key];
        fomattedData.push([
          key,
          value,
          (() =>
            `<div style="display:flex;flex-direction:row;font-weight:500;width:140px;">${value} ${valueLabel}${
              value > 1 && valueLabel !== "Alumni" ? "s" : ""
            }</div>`)(),
        ]);
      });
      setMapData([...fomattedData]);
    } else setMapData([]);
  }, [data]);

  const handleMarkerClick = (selectedId) => {
    if (!mapDataRef.current || isEmpty(mapDataRef.current)) return;
    if (selectedId?.length) {
      const selectedRecord = mapDataRef?.current?.[selectedId[0].row + 1];
      if (selectedRecord?.[0]) onClick(selectedRecord?.[0]);
    }
  };

  return (
    <div className="a-marker-world-map">
      <div className="chart-wrapper">
        <Chart
          chartType="GeoChart"
          width="100%"
          height={`${height}px`}
          data={mapData}
          options={{
            colorAxis: {
              colors: ["#000C3D", "#000C3D", "#000C3D"],
            },
            backgroundColor: "#FFF",
            datalessRegionColor: "#D1DDE5",
            defaultColor: "#D1DDE5",
            legend: false,
            displayMode: "markers",
            tooltip: {
              isHtml: true,
              textStyle: {
                color: "#000C3D",
                fontName: "TTCommons-Regular",
                fontSize: 18,
                fontWeight: 500,
              },
            },
          }}
          mapsApiKey={GOOGLE_SERVICE_API_KEY}
          chartEvents={[
            {
              eventName: "select",
              callback({ chartWrapper }) {
                if (onClick)
                  handleMarkerClick(chartWrapper?.getChart()?.getSelection());
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(MarkerWorldMap);
