import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .my-topic-page {
    &__filters {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-bottom: 15px;
      &__item {
        height: 35px;
        border-radius: 40px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          height: 15px;
        }
        &--active {
          background-color: #000485 !important;
          span {
            color: #ffffff;
          }
        }
      }
      > div:nth-child(1) {
        width: 70px;
      }
      > div:nth-child(2) {
        width: 112px;
      }
      > div:nth-child(3) {
        width: 137px;
      }
    }
    &__topic {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      &__card {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 71px;
        border-radius: 35.5px;
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: var(--card-box-shadow);
        padding-inline: 14px;
        &:hover {
          box-shadow: var(--card-box-shadow-hover);
        }
        &__avatar {
          .MuiAvatar-root {
            width: 45px;
            height: 45px;
          }
        }
        &__title {
          flex-grow: 1;
          &__container {
            &__topic-name {
              display: inline-block;
              padding-top: 2px;
              margin: 0 0 0 0;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              &--active {
                color: #ffffff;
              }
            }
            .ambassador-tag-community {
              display: inline-block !important;
              > div {
                margin: 0 0 0 0 !important;
              }
            }
          }
        }
        &__notification {
          .MuiChip-root {
            background-color: var(--secondary-color);
            color: #ffffff;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            height: 25px;
            .MuiChip-label {
              padding: 2px 10px 0px 10px !important;
            }
          }
        }
        &__favorite {
          width: 20px !important;
          height: 20px !important;
          .filled-star,
          .border-star {
            width: 20px !important;
            height: 20px !important;
          }
        }
        &--active {
          background-color: var(--primary-main-color);
        }
      }
    }
  }
`;
