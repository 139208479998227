import React, { useState } from "react";
import { Avatar } from "@mui/material";
import _find from "lodash/find";

import EventCardStyles from "./EventCardStyles";
import CheckIcon from "../../assets/svg/f-check.svg";
import LocationIcon from "../../assets/svg/f-location-pin.svg";

import CardCustom from "../CardCustom/CardCustom";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import InitialAvatar from "../InitialAvatar/InitialAvatar";
import CustomDialog from "../CustomDialog/CustomDialog";
import ViewProfileCard from "../Tools/BookAppointment/ViewProfileCard/ViewProfileCard";

const EventCard = ({
  id,
  title,
  subTitle,
  duration,
  tagName,
  onClick = () => {},
  className = "",
  myWebinars,
  item = null,
}) => {
  const [showProfile, setShowProfile] = useState(false);
  return (
    <EventCardStyles id={id}>
      <CardCustom className={`event-card ${className}`} onClick={onClick}>
        <div className={"event-item cursor-default"}>
          <div className="left">
            <h3 className="date">{subTitle}</h3>
            <h3 className="time">
              {duration}{" "}
              {item?.timezone_short ? ` (${item?.timezone_short})` : ""}
            </h3>
            <div className="flex align-items-center">
              {tagName && <span className="tag">{tagName}</span>}
              {_find(myWebinars, (o) => {
                return o.id === id;
              }) ? (
                <ButtonCustom
                  width="114"
                  height="25"
                  className="up-btn"
                  disabled={true}
                  rightIcon={CheckIcon}
                  contentCustomClass="customContent"
                >
                  <span className="pt-3">Registered</span>
                </ButtonCustom>
              ) : null}
            </div>
            {title && <h2 className="title">{title}</h2>}
            {item?.admin_user && (
              <a
                href="#"
                className="user-info-wrap no-underline"
                onClick={(e) => {
                  e.preventDefault();
                  setShowProfile(!showProfile);
                }}
              >
                <div className="avtar">
                  {item?.admin_user?.profile_image_url ? (
                    <Avatar
                      alt="Admin user"
                      src={item?.admin_user?.profile_image_url}
                    />
                  ) : (
                    <InitialAvatar
                      alt="admin user"
                      title={item?.admin_user?.first_name}
                      initialCount={1}
                    />
                  )}
                </div>
                <div className="user-info-details cursor-pointer">
                  <h4 className="header-primary">{`${item?.admin_user?.first_name} ${item?.admin_user?.last_name}`}</h4>
                </div>
              </a>
            )}
          </div>
          {item && (
            <div className="right">
              {item?.meeting_purpose?.name && (
                <div className="purpose-tag">{item?.meeting_purpose?.name}</div>
              )}
              <div className="meeting-description">
                <img src={LocationIcon} alt="Location pin" />
                {item?.meeting_type === "in_person" ||
                (!item?.meeting_type && item?.location) ? (
                  <span className="location">{item?.location}</span>
                ) : item?.meeting_type === "zoom" ||
                  (!item?.meeting_type && !item?.location) ? (
                  item?.join_url ? (
                    <a
                      href={item?.join_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="zoom-link"
                      aria-label="Zoom link"
                    >
                      Zoom link
                    </a>
                  ) : (
                    <span className="email">
                      Zoom link to be provided via email
                    </span>
                  )
                ) : null}
              </div>
            </div>
          )}
          {showProfile && (
            <CustomDialog
              className="feedback-dialog feedback-dialog-meet-page"
              open={showProfile}
              title=""
              dialogTitleClass="modal-title"
              handleClose={() => setShowProfile(false)}
            >
              <div className="model-body">
                <ViewProfileCard data={item.admin_user} isUserCard={true} />
              </div>
            </CustomDialog>
          )}
        </div>
      </CardCustom>
    </EventCardStyles>
  );
};

export default EventCard;
