import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./YouAllSet.module.scss";
import FinalIcon from "../../../assets/svg/FinalIcon";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import ViewIcon from "../../../assets/svg/ViewIcon";
import CustomSwitch from "../../CustomSwitch/CustomSwitch";
import { useState } from "react";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import InformationIcon from "../../../assets/svg/InformationIcon";
import { useAppState } from "../../../context";
import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

const cx = createModuleStyleExtractor(styles);

export const ProfileFinish = () => {
  const { profile, updateProfileState } = useAppState("profile");
  const {
    accessibility_help = false,
    is_network_popup_shown = false,
    allow_chat_from_external_network = false,
  } = profile || {};

  const [show, setShow] = useState(false);
  const [visibleNetwork, setIsVisibleNetwork] = useState(
    is_network_popup_shown
  );
  const [visibleNetworkPopup, setIsVisibleNetworkPopup] = useState(false);
  const [visibleNetworkDM, setIsVisibleNetworkDM] = useState(
    allow_chat_from_external_network
  );
  const [visibleNetworkDMPopup, setIsVisibleNetworkDMPopup] = useState(false);

  const [isAccessible, setIsAccessible] = useState(accessibility_help);
  const [isAccessiblePopup, setIsAccessiblePopup] = useState(false);

  const handleChangeAccessiblity = () => {
    setIsAccessible(!isAccessible);
    updateProfileState({
      profile: { ...profile, accessibility_help: !isAccessible },
    });
  };

  const handleChangeNetworkVisibility = () => {
    updateProfileState({
      profile: {
        ...profile,
        is_network_popup_shown: !visibleNetwork,
        ...(visibleNetwork ? { allow_chat_from_external_network: false } : {}),
      },
    });
    setIsVisibleNetwork(!visibleNetwork);
    visibleNetwork && setIsVisibleNetworkDM(false);
  };

  const handleChangeNetworkDMVisibility = () => {
    updateProfileState({
      profile: {
        ...profile,
        allow_chat_from_external_network: !visibleNetworkDM,
      },
    });
    setIsVisibleNetworkDM(!visibleNetworkDM);
  };

  const handlePopup = () => {
    setIsAccessiblePopup(false);
    setIsVisibleNetworkPopup(false);
    setIsVisibleNetworkDMPopup(false);
  };

  return (
    <div className={cx(["o-final-container"])}>
      <ProfilePreview
        defaultState={show}
        handleClose={() => setShow(false)}
        tab="student"
      />
      {(visibleNetworkPopup || isAccessiblePopup || visibleNetworkDMPopup) && (
        <CustomDialog
          open={
            visibleNetworkPopup || isAccessiblePopup || visibleNetworkDMPopup
          }
          title={
            visibleNetworkPopup
              ? "Visibility"
              : isAccessiblePopup
              ? "Accessibility help?"
              : "DM Visibility"
          }
          subTitle={
            visibleNetworkPopup
              ? "Hide your visibility from other users"
              : isAccessiblePopup
              ? "It is our mission to be inclusive and offer equal opportunities to all. We offer a customized version of the platform for those with accessibility needs."
              : "You may receive DMs from international students and alumni at your university and other Interstride schools. If selected, you may receive direct messages from international students and alumni at your university, as well as from other Interstride schools."
          }
          handleClose={handlePopup}
          className="signup-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="got-it-button">
              <ButtonCustom onClick={handlePopup}>Got It!</ButtonCustom>
            </div>
          </div>
        </CustomDialog>
      )}
      <div className={cx("o-final-container__header")}>
        <h3>You’re all set!</h3>
        <FinalIcon />
      </div>
      <CoverHeader>
        <div className={cx("o-final-container__switch")}>
          <div className={cx("o-final-container__switch__inside")}>
            <div className={cx("o-final-container__switch__inside__toggle")}>
              <CustomSwitch
                label="Profile visibility to network"
                handleChange={handleChangeNetworkVisibility}
                checked={visibleNetwork}
              />
            </div>
            <p className={cx("o-final-container__switch__inside__label")}>
              Profile visibility to network
            </p>
            <div className={cx("o-final-container__switch__inside__info")}>
              <InformationIcon onClick={() => setIsVisibleNetworkPopup(true)} />
            </div>
          </div>
          {visibleNetwork && (
            <>
              <div className="mt-10 mb-10" />
              <div className={cx("o-final-container__switch__inside")}>
                <div
                  className={cx("o-final-container__switch__inside__toggle")}
                >
                  <CustomSwitch
                    label="Allow DMs from all network"
                    handleChange={handleChangeNetworkDMVisibility}
                    checked={visibleNetworkDM}
                  />
                </div>
                <p className={cx("o-final-container__switch__inside__label")}>
                  Allow DMs from all network
                </p>
                <div className={cx("o-final-container__switch__inside__info")}>
                  <InformationIcon
                    onClick={() => setIsVisibleNetworkDMPopup(true)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="mt-10 mb-10" />
          <div className={cx("o-final-container__switch__inside")}>
            <div className={cx("o-final-container__switch__inside__toggle")}>
              <CustomSwitch
                label="Enable accessibility mode"
                handleChange={handleChangeAccessiblity}
                checked={isAccessible}
              />
            </div>
            <p className={cx("o-final-container__switch__inside__label")}>
              Enable accessibility mode
            </p>
            <div
              className={cx("o-final-container__switch__inside__info", "mt-20")}
            >
              <InformationIcon onClick={() => setIsAccessiblePopup(true)} />
            </div>
          </div>
          <div className="mt-10 mb-10" />
          <div
            className={cx("o-final-container__switch__preview")}
            onClick={() => setShow(true)}
          >
            <div className={cx("o-final-container__switch__preview__icon")}>
              <ViewIcon />
            </div>
            <span className={cx("o-final-container__switch__preview__label")}>
              Show what my profile looks like to my network
            </span>
          </div>
        </div>
      </CoverHeader>
    </div>
  );
};
