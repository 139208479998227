import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import webSocketReducer from "./reducers/webSocketReducer";
import optReducer from "./reducers/optReducer";
import preservedReducer from "./reducers/preservedReducer";
import gameplanReducer from "./reducers/gameplanReducer";
import jobReducer from "./reducers/jobReducer";
import networkReducer from "./reducers/networkReducer";
import meetReducer from "./reducers/meetReducer";
import notificationReducer from "./reducers/notificationReducer";
import profileReducer from "./reducers/profileReducer";
import userReducer from "./reducers/userReducer";

const persistConfig = {
  key: "interstride_student",
  storage: storage,
  blacklist: ["webSocketStore"],
};

const rootReducer = combineReducers({
  webSocketStore: webSocketReducer,
  optStore: optReducer,
  preservedStore: preservedReducer,
  gameplanStore: gameplanReducer,
  jobStore: jobReducer,
  networkStore: networkReducer,
  meetStore: meetReducer,
  notificationStore: notificationReducer,
  profileStore: profileReducer,
  userStore: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
