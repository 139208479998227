import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unread_notification_count: 0,
  topics_notifcations_count: 0,
  featured_job_notifications_count: 0,
  employer_job_notifications_count: 0,
  topic_specific_notifcations_count: [],
  chat_notifcations_count: 0,
  chat_specific_notifcations_count: [],
  deal_notifications_count: 0,
  webinar_notifications_count: 0,
  video_notifcations_count: 0,
};

export const notificationReducer = createSlice({
  name: "job",
  initialState,
  reducers: {
    setAllNotifications: (state, action) => {
      const {
        unread_notification_count,
        topics_notifcations_count,
        featured_job_notifications_count,
        employer_job_notifications_count,
        topic_specific_notifcations_count,
        chat_notifcations_count,
        chat_specific_notifcations_count,
        deal_notifications_count,
        webinar_notifications_count,
        video_notifcations_count,
      } = action.payload;
      state.unread_notification_count = unread_notification_count;
      state.topics_notifcations_count = topics_notifcations_count;
      state.featured_job_notifications_count = featured_job_notifications_count;
      state.employer_job_notifications_count = employer_job_notifications_count;
      state.topic_specific_notifcations_count =
        topic_specific_notifcations_count;
      state.chat_notifcations_count = chat_notifcations_count;
      state.chat_specific_notifcations_count = chat_specific_notifcations_count;
      state.deal_notifications_count = deal_notifications_count;
      state.webinar_notifications_count = webinar_notifications_count;
      state.video_notifcations_count = video_notifcations_count;
    },
    setLiveNotificationsTopic: (state, action) => {
      const {
        topic_id,
        topic_notifications_count,
        total_notifications_count,
        total_topic_notifications_count,
      } = action.payload;
      let _topic_specific_notifcations_count =
        state.topic_specific_notifcations_count;

      let index = _topic_specific_notifcations_count.findIndex(
        (element) => element[0] === topic_id
      );

      if (index === -1) {
        _topic_specific_notifcations_count.push([
          topic_id,
          topic_notifications_count,
        ]);
      } else {
        _topic_specific_notifcations_count[index][1] =
          topic_notifications_count;
      }

      state.unread_notification_count = total_notifications_count;
      state.topics_notifcations_count = total_topic_notifications_count;
      state.topic_specific_notifcations_count =
        _topic_specific_notifcations_count;
    },
    setLiveNotificationsChat: (state, action) => {
      const {
        chat_notifications_count,
        conversation_id,
        total_chat_notifications_count,
        total_notifications_count,
      } = action.payload;
      let index = state.chat_specific_notifcations_count.findIndex(
        (element) => element[0] === conversation_id
      );
      if (index === -1) {
        state.chat_specific_notifcations_count.push([
          conversation_id,
          chat_notifications_count,
        ]);
      } else {
        state.chat_specific_notifcations_count[index][1] =
          chat_notifications_count;
      }

      state.unread_notification_count = total_notifications_count;
      state.chat_notifcations_count = total_chat_notifications_count;
    },
    setLiveNotificationsEmployerJobs: (state, action) => {
      const { total_notifications_count, employer_job_notifications_count } =
        action.payload;
      if (employer_job_notifications_count && total_notifications_count) {
        state.unread_notification_count =
          state?.unread_notification_count + employer_job_notifications_count;
        state.total_notifications_count = total_notifications_count;
        state.employer_job_notifications_count =
          employer_job_notifications_count;
      }
    },
    setLiveNotificationsFeaturedJobs: (state, action) => {
      const { total_notifications_count, featured_job_notifications_count } =
        action.payload;
      if (featured_job_notifications_count && total_notifications_count) {
        state.unread_notification_count =
          state.featured_job_notifications_count +
          featured_job_notifications_count;
        state.total_notifications_count = total_notifications_count;
        state.featured_job_notifications_count =
          featured_job_notifications_count;
      }
    },
    readChatSpecificNotification: (state, action) => {
      const { index, chat_notifcations_count, unread_notification_count } =
        action.payload;

      if (state?.chat_specific_notifcations_count?.[index]?.length > 0)
        state.chat_specific_notifcations_count[index][1] = 0;

      state.unread_notification_count = unread_notification_count;
      state.chat_notifcations_count = chat_notifcations_count;
    },
    decrementUnreadNotificationCount: (state) => {
      if (state.unread_notification_count > 0)
        state.unread_notification_count = state.unread_notification_count - 1;
    },
    resetAllNotifications: () => initialState,
  },
});

export const {
  setAllNotifications,
  setLiveNotificationsTopic,
  setLiveNotificationsChat,
  setLiveNotificationsEmployerJobs,
  setLiveNotificationsFeaturedJobs,
  resetAllNotifications,
  readChatSpecificNotification,
  decrementUnreadNotificationCount,
} = notificationReducer.actions;

export default notificationReducer.reducer;
