import React from "react";
import PropTypes from "prop-types";
import Masonry from "react-masonry-css";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const propTypes = {
  children: PropTypes.node,
};

const BreakpointMasonry = ({ children, grid = 2 }) => {
  const theme = useTheme();
  const breakpointCols = {
    default: grid,
    [theme.breakpoints.values.xl]: grid,
    [theme.breakpoints.values.lg]: grid,
    [theme.breakpoints.values.md]: 1,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(0, 0, 2, 0),
        display: "flex",
        marginLeft: theme.spacing(-2),
        width: "inherit",
      }}
    >
      <Masonry
        breakpointCols={breakpointCols}
        style={{ display: "flex", width: "100%" }}
        columnClassName="masonry-column"
      >
        {children}
      </Masonry>
      {/** Manually applying the column style to ensure it's applied correctly. Tried with a box with sx but styles are not getting applied */}
      <style>{`
        .masonry-column {
          padding-left: ${theme.spacing(2)};
          background-clip: padding-box;
        }
      `}</style>
    </Box>
  );
};

BreakpointMasonry.propTypes = propTypes;

export default BreakpointMasonry;
