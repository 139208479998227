import React, { useState, useEffect, useLayoutEffect } from "react";
import moment from "moment";
import { isArray, isEmpty, compact } from "lodash";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import EmployerJobsDetailsStyles from "./EmployerJobsDetailsStyles";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { getEmployerJobDetails } from "../../../services/EmployerJobServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getDateTimeFormatted,
  addAccessibilityClass,
  capitalizeFirstLetter,
  getClickableLink,
} from "../../../helper/helper";
import ApplyJobDialog from "./ApplyJobDialog";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { routes } from "../../../routes";
import { isStaffAccountType } from "../../../utils/common";
import { setAppliedFromJobAlerts } from "../../../redux/reducers/jobReducer";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import InfoIcon from "../../../assets/svg/note-alert.svg";

const EmployerJobsDetails = () => {
  const dispatch = useDispatch();
  window.document.title = "Jobs Dashboard"; // Assigns page title
  const [jobData, setJobData] = useState({});
  const [employerLogo, setEmployerLogo] = useState(null);
  const [jobApplicantStatus, setJobApplicantStatus] = useState({});
  const [showApplyJobDialog, setShowApplyJobDialog] = useState(false);
  const [breadCrumb, setBreadcrumb] = useState([
    {
      title: "Jobs",
      path: "/jobs/search",
    },
  ]);
  const [showJobApplyButton, setShowJobApplyButton] = useState(false);

  const { users = {} } = useSelector((store) => store.userStore);
  const { selectedJob = null, appliedFromJobAlerts } = useSelector(
    (store) => store.jobStore
  );
  const [callingGetJobDetails, refreshGetJobDetails] = usePromise(
    getEmployerJobDetails
  );

  const isStaff =
    users && isStaffAccountType(users?.account_type, users?.user_type, true);
  const params = useParams();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const isURLBasedJob = selectedJob?.apply_through === "url";

  useEffect(() => {
    const { id } = params;
    if (selectedJob?.logo?.url) {
      setEmployerLogo(selectedJob.logo.url);
    }

    if (id) {
      refreshGetJobDetails(id, users?.id);
      return;
    }
    if (selectedJob && selectedJob !== null) {
      const { permalink } = selectedJob || {};
      if (permalink) {
        refreshGetJobDetails(permalink, users?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  const scrollBtnDisplay = function () {
    window.scrollY > 75
      ? setShowJobApplyButton(true)
      : setShowJobApplyButton(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollBtnDisplay);
    return function cleanupListener() {
      window.removeEventListener("scroll", scrollBtnDisplay);
    };
  }, []);

  useEffect(() => {
    try {
      if (queryParams.has("type")) {
        setBreadcrumb([
          ...breadCrumb.map((item) =>
            item.title === "Jobs"
              ? { ...item, path: "/jobs/featured-jobs" }
              : item
          ),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (
      callingGetJobDetails.hasFetched() &&
      callingGetJobDetails.hasErrors() === false &&
      callingGetJobDetails.data().data
    ) {
      setJobData(callingGetJobDetails.data().data.employer_job[0]);
      setJobApplicantStatus(callingGetJobDetails.data().data.applicant_status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobDetails.isFetching()]);

  const openJobDialog = () => {
    if (isURLBasedJob) {
      window.open(selectedJob?.job_application_url);
    } else {
      setShowApplyJobDialog(true);
    }
  };

  const renderVisaStatus = (visa) => {
    let parsedVisa = compact(JSON.parse(visa));
    if (isArray(parsedVisa) && parsedVisa?.length > 0) {
      return (
        <>
          <p className="tag-wrapper">
            <span>Work authorization</span>
            <div className="tag">
              {parsedVisa.map((d_data, index) => {
                if (d_data !== "Other (Please specify)") {
                  return <span key={index}>{d_data}</span>;
                } else {
                  return (
                    <>
                      {selectedJob?.visa_other && (
                        <span key={index}>{selectedJob?.visa_other}</span>
                      )}
                    </>
                  );
                }
              })}
            </div>
          </p>
        </>
      );
    } else if (!isEmpty(parsedVisa)) {
      return (
        <>
          <p className="tag-wrapper">
            <span>Work authorization</span>
            <div className="tag">
              <span>{parsedVisa}</span>
            </div>
          </p>
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderEmployerJobsDetails = (data) => {
    const { employer_logo, id } = data || {};
    let websiteUrl = data.website;
    if (!/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }

    return (
      <div className="job-requirement">
        <div className="jobhead">
          <h1>{capitalizeFirstLetter(data.title)}</h1>
          <p>
            <span>Position</span>
            {data.job_type}
          </p>
          {data.visa && renderVisaStatus(data.visa)}
          <p>
            <span>Location</span>
            {data.city_name !== null && data.city_name !== "null"
              ? `${capitalizeFirstLetter(data.city_name)}${
                  data.state_name !== null && data.state_name !== "null"
                    ? ","
                    : data.country_name !== null && data.country_name !== "null"
                    ? ","
                    : ""
                }`
              : ""}{" "}
            {data.state_name !== null && data.state_name !== "null"
              ? `${data.state_name}${
                  data.country_name !== null && data.country_name !== "null"
                    ? ","
                    : ""
                }`
              : ""}{" "}
            {data.country_name !== null && data.country_name !== "null"
              ? data.country_name
              : ""}
          </p>
          <div className="company-name-wrapper">
            <div className="company-name">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.website ? getClickableLink(data?.website) : ""}
                style={{ textDecoration: "none" }}
              >
                <CircularAvatar
                  name={data.title}
                  src={`${
                    employerLogo && selectedJob?.jobid === id
                      ? employerLogo
                      : employer_logo
                  }`}
                  size={85}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.website ? getClickableLink(data?.website) : ""}
                style={{ textDecoration: "none" }}
              >
                <p>{data.name}</p>
              </a>
            </div>

            <div className="btn-apply">
              {jobApplicantStatus !== null ? (
                <ButtonCustom
                  height={30}
                  width={100}
                  className="bg-gray"
                  aria-label="Already Applied"
                  isDisabled={isStaff}
                >
                  Already Applied
                </ButtonCustom>
              ) : (
                <div className="apply-btn-with-tooltip">
                  <ButtonCustom
                    height={30}
                    width={100}
                    color="secondary"
                    onClick={() => {
                      data?.url ? navigate(data.url) : openJobDialog();
                    }}
                    aria-label="Apply Job"
                    isDisabled={isStaff}
                  >
                    Apply to Job
                  </ButtonCustom>
                  {isStaff && (
                    <Tooltip
                      title={
                        isStaff
                          ? "You are unable to apply to the job as you are logged in as Staff"
                          : ""
                      }
                      placement="bottom"
                    >
                      <img src={InfoIcon} alt="Info" />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {(data?.roles_respo || data?.requirements) && (
          <div className="jobbody">
            {data?.roles_respo && (
              <div className="content">
                <h3>Roles and responsibilities</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{ __html: data.roles_respo }}
                  />
                </p>
              </div>
            )}
            {data?.requirements && (
              <div className="content">
                <h3>Requirements (skills & expertise)</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{ __html: data.requirements }}
                  />
                </p>
              </div>
            )}
          </div>
        )}

        <div className="flex justify-between align-items-center">
          <p className="posted-time">
            {data.postedon && data.postedon !== null
              ? `${getDateTimeFormatted(moment.utc(data.postedon))}`
              : ""}
          </p>
          {!isStaff && showJobApplyButton && (
            <div className="btn-apply">
              {jobApplicantStatus !== null ? (
                <ButtonCustom
                  width={156}
                  className="bg-gray"
                  aria-label="Already Applied"
                >
                  Already Applied
                </ButtonCustom>
              ) : (
                <ButtonCustom
                  width={156}
                  color="secondary"
                  onClick={() => {
                    data?.url ? navigate(data.url) : openJobDialog();
                  }}
                  aria-label="Apply Job"
                >
                  Apply to Job
                </ButtonCustom>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__job-details";
    addAccessibilityClass();
  }, []);

  useEffect(() => {
    if (jobData?.id) {
      if (breadCrumb.find((item) => item.title === jobData?.title)) return;
      setBreadcrumb([
        ...breadCrumb,
        {
          title: jobData?.title,
          path: window.location.pathname,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return (
    <EmployerJobsDetailsStyles>
      <div className="custom-container">
        <div className="employer-job-wrapper">
          <div className="f-main-title-with-button">
            <div className="btn-wrapper-left">
              <ButtonCustom
                className="back-to-btn"
                onClick={() => {
                  appliedFromJobAlerts
                    ? navigate(routes.JOBS.ALERT)
                    : navigate(routes.JOBS.SEARCH);
                  setAppliedFromJobAlerts(false);
                }}
              >
                <img src={BackArrow} width="21" alt="Back arrow" />
                {appliedFromJobAlerts
                  ? "Back to Saved Searches"
                  : "Back to Jobs"}
              </ButtonCustom>
            </div>
          </div>
          {callingGetJobDetails.isFetching() ? (
            <ShimmerPostDetails card cta variant="SIMPLE" />
          ) : (
            <>
              {/* {breadCrumb.length > 0 && (
                <div className="breadcrumb-div">
                  <nav
                    role="navigation"
                    aria-label="breadcrumb"
                    className="card-breadcrumb"
                  >
                    <ul>
                      {breadCrumb.map(({ title, path }, index) => (
                        <li key={index}>
                          {index === breadCrumb.length - 1 ? (
                            <span aria-current="page">
                              {title}
                              <span className="sr-only">Current Page</span>
                            </span>
                          ) : (
                            <a
                              aria-label={`Back to ${title}`}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(path);
                              }}
                              href
                              className="link-focus"
                            >
                              {title}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              )} */}

              {jobData && jobData?.id ? renderEmployerJobsDetails(jobData) : ""}
            </>
          )}
        </div>

        {/* Job apply popup */}
        {showApplyJobDialog && (
          <ApplyJobDialog
            open={showApplyJobDialog}
            setDialog={() => setShowApplyJobDialog(false)}
            jobData={jobData}
          />
        )}
      </div>
    </EmployerJobsDetailsStyles>
  );
};

export default EmployerJobsDetails;
