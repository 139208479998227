import React from "react";
import CustomMenuStyles from "./CustomMenuStyles";

const CustomMenu = ({
  isopen: isOpen = false,
  menuList = [],
  menuOnClick = (e) => {},
  align = "right",
  marginPercentage = "50%",
}) => {
  return (
    <CustomMenuStyles align={align} marginPercentage={marginPercentage}>
      {isOpen && (
        <ul className="submenu">
          {menuList.map((item, i) => (
            <li
              key={i}
              onClick={(e) => {
                if (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                menuOnClick(item);
              }}
            >
              <a
                href="/#"
                className="link-focus"
                onClick={(e) => {
                  e.preventDefault();
                }}
                aria-label={item.title}
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </CustomMenuStyles>
  );
};

export default CustomMenu;
