import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .category-list {
    display: block;
    .search-box {
      margin: 0 0 20px 0;
      > div {
        margin-bottom: 0;
      }
    }
    .categories {
      ul {
        margin: 0;
        list-style: none;
        padding: 0;
        .category-item {
          a {
            padding: 10px 15px 10px 15px;
            text-decoration: none;
            box-shadow: var(--card-box-shadow);
            background: #fff;
            border-radius: 100px;
            min-height: 50px;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            &:hover {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            &:focus {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
            @media (max-width: 991px) {
              box-shadow: none;
            }
          }
          .header-primary {
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            border-bottom: 1px solid transparent;
            padding-top: 6px;
          }
          .item {
            display: flex;
            align-items: center;
            text-decoration: none;
            width: 100%;
            .image {
              width: 30px;
              margin: 0 10px 0 0;
              display: flex;
              align-items: center;
              img {
                width: 100%;
              }
            }
          }
          + .category-item {
            margin-top: 10px;
            @media (max-width: 991px) {
              margin-top: 0px;
            }
          }
          &.active {
            .header-primary,
            .sub-text {
              color: #fff;
            }
            a {
              background-color: var(--primary-main-color);
            }
          }
        }
      }
    }
  }
  .hide-title {
    padding-top: 39px !important;
  }
`;
