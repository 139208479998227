import React, { memo } from "react";
import { useState } from "react";

import EmailUpdateDialog from "../../../Profile/EmailUpdateDialog";
import { CustomDialogBox } from "../../../Profile/ProfileInfo/ProfileInfoStyles";

const EditEmail = ({ flag, handleClose, type = "primary" }) => {
  const [isShow, setIsShow] = useState(flag);

  const handleOnClose = () => {
    setIsShow(false);
    handleClose && handleClose();
  };
  return (
    <CustomDialogBox
      open={isShow}
      title={type === "secondary" ? "Change secondary email address" : "Change email address"}
      handleClose={handleOnClose}
      isDisplayCloseButton={true}
      className="profile-accessibility-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <EmailUpdateDialog setUpdateEmailPopUp={handleOnClose} type={type}/>
    </CustomDialogBox>
  );
};

export default memo(EditEmail);
