import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-resources-tab {
    margin: 0 auto;
    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 5px 0 0 0;
      @media (max-width: 906px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 16px 0;
      }
    }
    .buttons-wrapper {
      display: flex;
      justify-content: center;
      button {
        margin: 0 5px;
      }
      .bg-white {
        background-color: #fff;
      }
    }
  }
`;
