import React from "react";

function ArrowIcon({ className = "", color = "#000C3D" }) {
  return (
    <svg className={className} width="19px" height="15px" viewBox="0 0 19 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Final-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Main-Dashboard" transform="translate(-328.000000, -499.000000)" stroke={color} strokeWidth="2">
                <g id="Saved" transform="translate(41.000000, 481.000000)">
                    <g id="Arrow" transform="translate(287.000000, 19.000000)">
                        <g id="Group-3" transform="translate(0.000000, 0.260870)">
                            <g id="Action/Go">
                                <polyline id="Path-3" transform="translate(14.001208, 6.297101) rotate(-90.000000) translate(-14.001208, -6.297101) " points="7.95652174 3 14.0012077 9.5942029 20.0458937 3"></polyline>
                                <path d="M15.5652174,6.29710145 L0,6.29710145" id="Line"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default React.memo(ArrowIcon);
