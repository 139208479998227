import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .delete--btn{
    margin-right: 12px;
    display: flex;
    svg{
      color: var(--gray-outline-color);
      cursor: pointer;
      font-size: 20px;
      &:hover{
        color: var(--primary-dark-color);                                                                                                
      }
    }
  }
  .search-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 15px 0;

    .search-list-card {
      padding: 18px 25px 14px 25px;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        transition: all 0.3s;
        .search-item {
          .card-actions-wrapper {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
        }
      }
      .MuiCardContent-root {
        padding: 0;
      }

      .search-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .header-primary {
          + .text-grey{
            margin-top: 0px;
          }
        }
        .content {
          /* display: block;
          width: calc(100% - 220px); */
          max-width: 450px;
          margin-right: auto;
        }
        .apply-btn.search-again-button{
          button{
            line-height: 33px;
            padding:2px 0 0;
            background-color: var(--link-color) !important;
            
          }
        }
        .search-again-button{
          background: var(--icon_background);
          display: flex;
          align-items: center;
          width: 35px;
          height: 35px;
          padding: 0 !important;
          min-width: 35px;
          justify-content: center;
          border-radius: 45px;
          &:hover{
            background: var(--icon_background_hover);
          }
          &:focus{
            box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
          }
        }
        .card-actions-wrapper {
          display: flex;
          align-items: center;
          padding-left: 8px;
          opacity: 0;
          visibility: hidden;
          &.show-actions {
            opacity: 1;
            visibility: visible;
          }
          @media (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
          }
          @media (max-width: 650px) {
            padding-left: 0px;
          }
          .nlink-btn {
            width: auto;
            min-width: 80px;
            white-space: nowrap;
          }
          div {
            + div {
              margin-left: 12px;
              @media (max-width: 991px) {
                margin-left: 8px;
              }
            }
          }

        }
        .apply-btn {
          width: auto;
          button {
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              text-align: center;
              line-height: 35px;
              height: auto;
              padding:0;
              box-shadow:none;
              .content{
                  margin:0;
              }
            }
          }
        }
      }
    }

    .jobs-notification-settings{
      .info-card{
        min-height: auto;
        .card-content{
          padding: 15px 20px;
          .panel-action{
            button{
              .content{
                min-width: auto;
              }
            }
          }
        }
      }
    }

  }
  .empty-search-box {
    padding: 20px;
    text-align: left;
    .card-content {
      padding:0;
    }
    .header-primary {
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      margin: 0 0 4px 0;
    }
    p{
      color: var(--gray-text-color);
      font-family:"TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin:0;
    }
    .suggestion {
      .apply-btn{
        @media(max-width:480px){
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
      }
      .save{
        margin-right:15px;
        @media(max-width:480px){
          margin-right: 0;
          margin-bottom: 8px;
          margin-top: 8px;
        }
      }
    }
  }

`;
