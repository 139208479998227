import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .webinar-section__header-heading {
    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
    }
    .custom-tab {
      text-align: center;
      margin-bottom: 28px;
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
      button {
        border-radius: 40px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
        width: 148px;
        height: 50px;
        padding: 18px 10px 15px 10px;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        color: var(--primary-dark-color);
        text-transform: capitalize;
        @media (max-width: 767px) {
          width: auto;
          padding: 18px 20px 15px 20px;
          height: 44px;
        }
        @media (max-width: 360px) {
          width: 100%;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        &:focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        + button {
          margin-left: 10px;
          @media (max-width: 767px) {
            margin-left: 8px;
          }
          @media (max-width: 360px) {
            margin-left: 0;
            margin-top: 8px;
          }
        }
        &.active {
          background-color: var(--primary-main-color);
          color: #fff;
          border-color: var(--primary-main-color);
        }
        &.disabled {
          color: var(--light-gray-color);
        }
        span {
          line-height: normal;
        }
      }
    }
  }
  .webinar-mainpage {
    .webinar-loading-item {
      max-width: 900px;
      margin: 0 auto 13px;
    }
    .webinar-section__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;

      .webinar-section__header-switch {
        // width: 240px;
        .flex-item--selected {
          background-color: ${PRIMARY_COLOR};
          color: #fff !important;
          border: 2px solid ${PRIMARY_COLOR} !important;
          border-left: none !important;
        }
        display: flex;

        .flex-item {
          -webkit-transition: all 0.3s linear 0s;
          transition: all 0.3s linear 0s;
          text-decoration: none;
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: ${PRIMARY_COLOR};
          &:first-child {
            border-radius: 32.5px 0 0 32.5px;
          }
          &:last-child {
            border-top-right-radius: 32.5px;
            border-bottom-right-radius: 32.5px;
            border-left: none;
          }
          flex-grow: 1;
          border: 1px solid red;
          box-sizing: border-box;
          height: 42px;
          padding-top: 3px;
          width: 147px;
          border: 2px solid #d1dde5;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          // background-color: ${PRIMARY_COLOR};
          &:focus {
            box-shadow: 0 0 8px 0 #000c3d !important;
            border-color: #000c3d !important;
          }
        }
      }

      .webinar-section__header-heading {
        display: flex;
        align-items: center;
        .title {
          span {
            color: ${PRIMARY_COLOR};
            justify-content: center;
            font-family: "TTCommons-Medium";
            font-weight: 500;
            letter-spacing: 0;
            font-size: 30px;
          }
        }
        .calendar-action {
          margin-left: 20px;
          margin-bottom: 7px;
          span,
          a {
            height: 40px;
            width: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 21.5px;
            background-color: #ffffff;
            box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
            svg {
              color: ${PRIMARY_COLOR};
              cursor: pointer;
            }
            &:focus {
              box-shadow: 0 0 8px 0 #000c3d !important;
              border-color: #000c3d !important;
            }
          }
        }
      }
    }

    .web-title {
      .mode {
        margin-left: 30px !important;
        button {
          height: auto;
          padding: 6px 10px 3px 10px;
          line-height: normal;
          width: auto;
          min-width: 162px;
          margin-left: 0;
        }
        &.list-mode {
          button {
            min-width: 122px;
          }
        }
      }
    }
  }
  .webinar-section {
    .MuiGrid-container {
      .MuiGrid-grid-xs-5 {
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      .MuiGrid-grid-xs-7 {
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
`;
