import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotificationManage } from "./NotificationManage/NotificationManage";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import NotificationDashboard from "./NotificationDashboard/NotificationDashboard";

export default function Notification() {
  return (
    <MainLayout
      title="Notifications"
      withSubHeader={false}
      showSubheaderHeading={false}
    >
      <Routes>
        <Route path="/" element={<NotificationDashboard />} />
        <Route path="/settings" element={<NotificationManage />} />
      </Routes>
    </MainLayout>
  );
}
