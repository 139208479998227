import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Popover } from "@mui/material";

import RightSidebarCard from "../../../pages/Tools/OPTCalculator/OPTCalculatorRightSidebar/RightSidebarCard/RightSidebarCard";
import OPTReviewStyles from "./OPTReviewStyles";
import { OPT_CALCULATOR_STATUS_LIST } from "../../../constant/mockdata";

import Notealert from "../../../assets/svg/note-alert.svg";

const OPTReview = () => {
  const {
    optCalculatorState = {},
    optRightSidebarStatus = [],
    optIndex = null,
  } = useSelector((store) => store.optStore);
  const [showPopover, setPopover] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };
  const handleClose = () => {
    setPopover(null);
  };
  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  if (
    (optIndex === 0 && !optCalculatorState?.full_time_cpt_used?.isAuthorized) ||
    (optIndex === 1 &&
      !optCalculatorState?.available_post_completion_opt?.isAuthorized)
  ) {
    return <></>;
  }

  if (optIndex === 0 && !optRightSidebarStatus?.[0]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 1 &&
    !optRightSidebarStatus?.[1]?.[1]?.value &&
    !optRightSidebarStatus?.[1]?.[2]?.value
  ) {
    return <></>;
  }

  if (
    optIndex === 2 &&
    (!optRightSidebarStatus?.[2]?.[0]?.value ||
      optRightSidebarStatus?.[2]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[1]?.value ||
      optRightSidebarStatus?.[2]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[2]?.value ||
      optRightSidebarStatus?.[2]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[3]?.value ||
      optRightSidebarStatus?.[2]?.[3]?.value ===
        "Invalid date and Invalid date")
  ) {
    return <></>;
  }

  if (optIndex === 3 && !optRightSidebarStatus?.[3]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 4 &&
    (!optRightSidebarStatus?.[4]?.[0]?.value ||
      optRightSidebarStatus?.[4]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[1]?.value ||
      optRightSidebarStatus?.[4]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[2]?.value ||
      optRightSidebarStatus?.[4]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[3]?.value ||
      optRightSidebarStatus?.[4]?.[3]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[4]?.value ||
      optRightSidebarStatus?.[4]?.[4]?.value === "Invalid date")
  ) {
    return <></>;
  }

  if (
    optIndex === 5 &&
    (!optRightSidebarStatus?.[5]?.[0]?.value ||
      optRightSidebarStatus?.[5]?.[0]?.value === "Invalid date")
  ) {
    return <></>;
  }

  return (
    <OPTReviewStyles>
      {optRightSidebarStatus?.map((d, idx) => {
        return (
          <>
            <div className="opt-review-title" key={idx}>
              {idx === 5 ? (
                <>
                  Determine Form I-765 filing deadline based on the date the DSO
                  recommends OPT in SEVIS
                  <a
                    href="/#"
                    onClick={handleClick}
                    className="alert-icon information-icon"
                    aria-label="Find Job Note"
                  >
                    <img src={Notealert} alt="Info" />
                  </a>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={showPopover}
                    onClose={handleClose}
                    className="popover-wrapper"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <>
                      Form I-765, also known as the “Application for Employment
                      Authorization” is used to determine a foreign worker’s
                      eligibility for a work permit in the US.{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://interstride.com/blog/what-is-form-i-765/#:~:text=byInterstride,provesuchtopotentialemployers"
                        }
                      >
                        Read more about Form I-765
                      </a>
                    </>
                  </Popover>
                </>
              ) : (
                <>{OPT_CALCULATOR_STATUS_LIST[idx]?.fullTitle}</>
              )}
            </div>
            <div className="opt-review-card" key={idx + "card"}>
              {d.map((data, idx) => (
                <RightSidebarCard data={data} key={idx} />
              ))}
            </div>
          </>
        );
      })}
      <p className="estimate-condition">
        *Assumes an additional 10-day mailing period.
      </p>
    </OPTReviewStyles>
  );
};
export default OPTReview;
