import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .panel-card {
    margin-bottom: 10px;

    .card-content {
      padding: 15px 20px 15px 20px;

      .panel-info {
        display: flex;
        align-items: center;

        .panel-details {
          .panel-title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 15px;
            margin: 0 0 4px 0;
            padding-top: 4px;
          }
          .panel-description {
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            color: var(--gray-color);
          }
        }
        .panel-actions {
          margin-left: auto;

          .panel-action-label {
            display: flex;
            align-items: center;
            white-space: nowrap;
            .switch-container {
              background-color: #ffffff;
              box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
              border-radius: 100px;
              padding: 18px 20px 13px;
              display: flex;
              align-items: center;
              color: #000c3d;
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              @media (max-width: 767px) {
                padding: 13px 12px 10px;
              }
              + .switch-container {
                margin-left: 10px;
              }
            }
            .MuiFormGroup-root {
              margin-left: 10px;
              @media (max-width: 767px) {
                margin-left: 2px;
              }
            }
          }
        }
      }

      .email-frequency {
        margin-top: 20px;
        font-size: 15px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};

        .email-frequency-title {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 19px;
        }

        .email-frequency-options {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 18px;

          li {
            display: flex;
            align-items: center;
            margin-right: 35px;
            > span {
              padding: 0;
              svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
                width: 16px;
                height: 16px;
                fill: ${PRIMARY_COLOR};
              }
              &.PrivateSwitchBase-root-7 {
                padding: 0px;
              }
            }
            .email-frequency-option-name {
              color: ${PRIMARY_COLOR};
              font-family: "TTCommons-Regular";
              font-size: 16px;
              letter-spacing: 0;
              line-height: 17px;
              margin-left: 10px;
              padding: 4px 0 0 0;
            }
          }
        }
      }

      .events-info {
        margin-top: 24px;
        font-size: 15px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};

        .event-label {
          color: ${PRIMARY_COLOR};
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 11px 0;
        }

        .events-list {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 18px;

          li {
            display: flex;
            align-items: center;
            margin-right: 35px;
            > span {
              padding: 0;
              svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
                width: 16px;
                height: 16px;
                fill: ${PRIMARY_COLOR};
              }
              &.PrivateSwitchBase-root-7 {
                padding: 0px;
              }
            }
            .event-name {
              color: ${PRIMARY_COLOR};
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 15px;
              margin-left: 10px;
              padding: 4px 0 0 0;
            }
          }
        }
      }
    }
  }
`;
