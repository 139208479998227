import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .custom-dialog-root {
    box-shadow: "0 1px 14px 3px rgba(128,148,171,0.4)";
    border-radius: "10px";
    overflow: "hidden";
  }
  .custom-dialog-dialogHeader {
    display: "flex";
    justify-content: "center";
    align-items: "center";
    flex-flow: "column";
    position: "relative";
  }
  .custom-dialog-dialogTitle {
    color: "var(--primary-dark-color)";
    font-size: "22px";
    font-family: '"TTCommons-Medium"';
    max-width: "355px";
    width: "100%";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    text-align: "center";
    line-height: "24px";
    font-weight: "500";
    margin: "0px";
  }
  .custom-dialog-dialogSubTitle {
    color: "var(--primary-dark-color)";
    font-size: "15px";
    font-family: '"TTCommons-Regular"';
    max-width: "340px";
    width: "100%";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    text-align: "center";
    line-height: "17px";
    margin-top: "10px";
  }
  .custom-dialog-dialogDescription {
    color: "#000485";
    font-size: "18px";
    max-width: "336px";
    width: "100%";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    text-align: "center";
    margin-bottom: "60px";
  }
  .custom-dialog-btnClose {
    position: "absolute";
    top: 0;
    right: 0;
    cursor: "pointer";
  }
  .custom-dialog-btnBack {
    position: "absolute";
    top: 0;
    left: 0;
    cursor: "pointer";
    display: "flex";
    align-items: "center";
    font-size: "18px";
    font-family: '"TTCommons-Medium"';
    color: "#000485";
  }
`;
