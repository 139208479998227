import React from "react";

import FeedbackStyles from "./FeedbackStyles";
import arrowImg from "../../../assets/svg/f-arrow.svg";
import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";


const Feedback = ({ handleModel }) => {
  const clickHandler = () => {
    handleModel(true);
  };

  return (
    <FeedbackStyles>
      <CardCustom className="feedback-alert">
        <div>
          <div className="title-primary">Tell us what you think!</div>
          <div className="sub-header">We’d love to hear your feedback.</div>
        </div>
        <ButtonCustom
          className="feedback-btn"
          width="265"
          height="60"
          onClick={clickHandler}
        >
          <img src={arrowImg} alt="Arrow right" />
        </ButtonCustom>
      </CardCustom>
    </FeedbackStyles>
  );
};

export default Feedback;
