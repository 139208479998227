import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .empty-list-with-image {
    padding: 0;
    text-align: start;
    h3,
    h4 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      margin: 0 auto 8px;
      max-width: 440px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .img-wrap {
      margin: 18px 0px 18px 0px;
      text-align: center;
      .default-empty-cover {
        width: 500px;
      }
      img {
        max-width: 100%;
      }
    }
    p {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin: 0 auto;
      max-width: 440px;
    }
  }
`;
