import { useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  user_posts: [],
  saved_user_posts: [],
};

const useUser = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const setUserPosts = (posts) => {
    setState({ user_posts: [...posts.user_posts] });
  };

  const addMoreUserPosts = (posts) => {
    setState({ user_posts: [...state.user_posts, ...posts.user_posts] });
  };

  const setSavedUserPosts = (posts) => {
    setState({ saved_user_posts: posts.saved_posts });
  };


  return {
    setUserPosts,
    setSavedUserPosts,
    addMoreUserPosts,
    ...state,
  };
};

export default useUser;
