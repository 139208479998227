import React, { useEffect, useState, useRef } from "react";

import { useAppState } from "../../../context";
import { toCapital } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";
import styles from "./Input.module.scss";

import Clear from "../../../assets/svg/Clear";

const cx = createModuleStyleExtractor(styles);

export const Input = ({
  id,
  type,
  value,
  name,
  label,
  placeholder = "",
  disabled = false,
  className = "",
  labelClassName,
  readonly = false,
  styles = {},
  isClearable = false,
  error = false,
  errorText = "",
  required = false,
  isEdit = false,
  isSecondEdit = false,
  validator,
  onClick,
  isCaptial = false,
  props = {},
  disableAccessibilityStyles = true,
  accessibilityLabel,
  onChange = noop,
  onKeyUp = noop,
  onEditClick = noop,
  onSecondEditClick = noop,
  handleClearState = noop,
  onKeyDown = noop,
  onFocus = noop,
}) => {
  const [state, setState] = useState(`${value}`);
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  const { profile } = useAppState("profile");
  const { accessibility_help } = profile || {};
  const handleClear = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setState("");
    handleClearState();
  };

  // Fix cursor position upon on change
  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, state]);

  const handleOnChange = (e) => {
    if (e?.target?.value && e?.target?.value?.trim() === "") return;
    try {
      const _value = `${e.target.value?.trim()}`;
      if (validator && _value) {
        if (!validator(_value)) {
          return;
        }
      }
      setCursor(e.target.selectionStart);
      onChange(e);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (validator) {
      if (!validator(value)) {
        console.log("invalid");
        return;
      }
    }
    if (typeof value !== "string") {
      setState(value);
      return;
    }
  }, [value, validator]);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <div className={cx("a-input-main-container")}>
      {label && (
        <div
          className={cx([
            "a-input--label",

            ...(labelClassName ? [labelClassName] : []),
          ])}
        >
          {label}
          <div className={cx("edit-option-container")}>
            {isEdit && (
              <a
                href="#"
                className={cx("edit-option")}
                onClick={(e) => {
                  e.preventDefault();
                  onEditClick();
                }}
              >
                {isEdit}
              </a>
            )}
            {isSecondEdit && (
              <>
                <span>{" | "}</span>
                <a
                  href="#"
                  className={cx("edit-option")}
                  onClick={(e) => {
                    e.preventDefault();
                    onSecondEditClick();
                  }}
                >
                  {isSecondEdit}
                </a>
              </>
            )}
          </div>
        </div>
      )}
      <div className={cx("a-input-container")}>
        <label htmlFor={id ? id : name} className="hide-element">
          {accessibilityLabel ? accessibilityLabel : label ? label : name}
        </label>
        <input
          {...props}
          ref={ref}
          autoComplete={"off"}
          readOnly={readonly}
          id={id ? id : name}
          disabled={disabled}
          className={cx([
            className,
            disabled ? "a-input--disabled" : "",
            readonly ? "a-input--readonly" : "",
          ])}
          type={type}
          value={isCaptial ? toCapital(state) : state}
          placeholder={placeholder}
          name={name}
          onKeyUp={onKeyUp}
          style={{
            ...styles,
            ...(!disableAccessibilityStyles &&
              accessibility_help && { border: "2px solid #7491aa" }),
          }}
          required={required}
          onChange={disabled ? noop : handleOnChange}
          onClick={onClick ?? noop}
          onKeyDown={onKeyDown}
          // onKeyDown={(event) => {
          //   return /[a-z]/i.test(event.key);
          // }}
          onFocus={onFocus && onFocus}
          title={label}
        />

        {error && (
          <div className={cx("error-container")}>
            {error && errorText ? errorText : " "}
          </div>
        )}
      </div>
      {isClearable && (
        <a href="#" onClick={handleClear} className={cx("input-clear-icon")}>
          <Clear color={"rgb(204, 204, 204)"} />
        </a>
      )}
    </div>
  );
};
