import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px 30px;
    &.message-page {
      padding-bottom: 35px .MuiGrid-spacing-xs-2 {
        margin-top: 0;
        margin-bottom: 0;
        .MuiGrid-item {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
`;
