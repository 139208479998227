import React, {
  useReducer,
  // useEffect
} from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const STEPS_AMBASSADOR = [
  {
    target: ".layout-header-bar",
    content: (
      <p className="ambassador-tutorial-body">
        Let’s give you a short tour about everything you need to know.
      </p>
    ),
    disableBeacon: true,
    placement: "center",
    offset: 5,
    route: "/dashboard",
  },
  {
    target: ".profile",
    content: (
      <p className="ambassador-tutorial-body">
        This is your Ambassador badge. You can always click on your badge to
        restart this tutorial
      </p>
    ),
    disableBeacon: true,
    placement: "right",
    offset: 5,
    route: "/dashboard",
  },
  {
    target: ".ambassador-profile-tutorial-one",
    content: (
      <p className="ambassador-tutorial-body">
        You can switch between your Student/Career profile and your Ambassador
        profile here.
      </p>
    ),
    disableBeacon: true,
    placement: "bottom",
    offset: 5,
    route: "/profile?tab=ambassador",
  },
  {
    target: ".ambassador-profile-tutorial-two",
    content: (
      <p className="ambassador-tutorial-body">
        You can also preview what your Ambassador profile looks like to
        prospective students here.
      </p>
    ),
    disableBeacon: true,
    placement: "right",
    offset: 5,
    route: "/profile?tab=ambassador",
  },
];

const initialArgs = {
  run: false,
  stepIndex: 0,
  steps: STEPS_AMBASSADOR,
};

const useAmbassadorTutorial = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  // Logger
  // useEffect(() => {
  //   console.log("useAmbState: ", state);
  // }, [state]);

  const bulkUpdateAmbassadorTutorial = (data) => {
    setState({ ...data });
  };

  const resetStateAmbassadorTutorial = () => {
    setState({ ...initialArgs });
  };

  return {
    ...state,
    bulkUpdateAmbassadorTutorial,
    resetStateAmbassadorTutorial,
  };
};

export default useAmbassadorTutorial;
