import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversationChannel: null,
};

export const webSocketReducer = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setConversationChannel: (state, action) => {
      state.conversationChannel = action.payload;
    },
    resetInitialState: () => initialState,
  },
});

export const { setConversationChannel, resetInitialState } =
  webSocketReducer.actions;

export default webSocketReducer.reducer;
