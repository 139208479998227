import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register plugin:
Chart.plugins.register(ChartDataLabels);

export default function HorizontalBarChart({
  labels = [],
  data = [],
  backgroundColor = "#D1DDE5",
  isPercentage = false,
  enableCursorPointer = false,
  onClickHandler = () => {},
}) {
  const [isMobiile, setIsMobile] = useState(window.innerWidth <= 500);
  const [redraw, setRedraw] = useState(false);

  const barData = {
    labels: isMobiile
      ? labels.map((label) =>
          label.length > 15 ? `${label.substring(0, 12)}...` : label
        )
      : labels,
    originalLabels: labels,
    datasets: [
      {
        data: data,
        backgroundColor,
        maxBarThickness: 25,
      },
    ],
  };

  // Track labels length. If they change, redraw the chart
  useEffect(() => {
    setRedraw(true);
  }, [labels?.length]);

  // Reset state of redraw if it is true
  useEffect(() => {
    if (redraw) setRedraw(false);
  }, [redraw]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 100,
      },
    },
    legend: {
      display: false,
    },
    tooltips: { enabled: false },
    hover: {
      mode: "nearest",
      onHover: (event, chartElement) => {
        event.target.style.cursor =
          enableCursorPointer && chartElement[0] ? "pointer" : "default";
      },
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontSize: isMobiile ? 12 : 18,
            fontFamily: "TTCommons-Regular",
            fontColor: "#000C3D",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        color: "#000C3D",
        anchor: "end",
        align: "end",
        font: {
          family: "TTCommons-Regular",
          size: isMobiile ? 12 : 18,
        },
        formatter: (value) => {
          return isPercentage ? value + "%" : value;
        },
      },
    },
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Method takes chart element and computes coordinates and returns the data in a callback to parent
  const computeChartCoordinates = (elem) => {
    let coordinates = {
      label: null,
      value: null,
    };
    const index = elem[0]?._index;
    if (index >= 0) {
      coordinates.label = barData?.originalLabels[index];
      coordinates.value = barData?.datasets[0]?.data[index];
    }
    onClickHandler(coordinates);
  };

  return (
    <HorizontalBar
      redraw={redraw}
      data={barData}
      onElementsClick={computeChartCoordinates}
      options={barOptions}
    />
  );
}
