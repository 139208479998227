import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .contact-career-card {
    margin-top: 15px;
    @media (max-width: 1366px) {
      margin-top: 16px;
    }
    .MuiCardContent-root {
      padding: 30px 20px;
      @media (max-width: 1366px) {
        padding: 16px;
      }
    }
    h2 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      margin: 0 0 6px 0;
    }
    .sub-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      line-height: 20px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 12px 0 0 0;
      li {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        span {
          color: var(--gray-text-color);
          margin-right: 10px;
          min-width: 46px;
        }
        + li {
          margin-top: 6px;
        }
      }
    }
  }
  .create-alert {
    border-radius: 10px;
    box-shadow: var(--card-box-shadow);
    padding: 30px 20px;
    margin: 15px 0 15px 0;
    position: relative;
    @media (max-width: 767px) {
      padding: 16px !important;
    }
    .MuiCardContent-root {
      padding: 0;
    }
    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      margin: 0 0 6px 0;
    }
    .small-title {
      font-size: 22px !important;
      font-weight: 500 !important;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      font-family: "TTCommons-Medium";
      max-width: 208px;
      margin: 0 auto 6px;
    }
    .close-btn {
      position: absolute;
      top: 32px;
      right: 30px;
      button {
        padding: 0;
        &:focus {
          box-shadow: 0 0 10px 5px #fff !important;
        }
      }
      svg {
        width: 18px;
        font-size: 18px;
        height: 18px;
      }
    }
    .sub-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      line-height: 20px;
    }
    .text-name {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      max-width: 260px;
      margin: 21px auto 0;
      font-family: "TTCommons-Regular";
    }
    .subscribe-btn {
      font-size: 18px;
      line-height: 19px;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid var(--link-color);
      cursor: pointer;
      width: auto;
      height: auto;
      background: transparent !important;
      border-radius: 0;
      margin-top: 15px;
      .content {
        text-transform: capitalize;
      }
    }
  }
  .small {
    padding: 15px;
    .close-btn {
      top: 18px;
    }
    .text-name {
      margin: 10px auto 10px;
      line-height: 22px;
    }
  }
`;
