import styled from "styled-components";
import { TextField } from "@mui/material";

export default styled(TextField)`
  width: ${(props) => props.width};
  height: ${(props) => (props.isMultiline ? "auto" : props.height)};

  & > div {
    border-radius: ${(props) =>
      props.multiline ? "20" : parseInt(props.height) / 2}px;
    margin-bottom: ${(props) => (props.multiline ? "10" : "0")}px;
  }

  & input,
  textarea {
    text-align: ${(props) => props.lableaignment};
    color: ${(props) => props.textcolor} !important;
    font-family: "TTCommons-Medium";
    ::-webkit-input-placeholder {
      color: var(--gray-text-color);
      opacity: 1;
    }
    ::-moz-placeholder {
      color: var(--gray-text-color);
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: var(--gray-text-color);
      opacity: 1;
    }
    :-moz-placeholder {
      color: var(--gray-text-color);
      opacity: 1;
    }
  }
`;
