const mimicFn = require("mimic-fn");
const getFnname = require("get-func-name");
const mem = (
  fn,
  {
    cacheKey = ([firstArgument]) => firstArgument,
    cache = new Map(),
    maxAge,
  } = {}
) => {
  const fnName = getFnname(fn);
  const memoized = function (...arguments_) {
    const key = cacheKey(arguments_) + fnName;

    if (cache.has(key) && cache.get(key).maxAge > Date.now()) {
      return cache.get(key).data;
    }

    const cacheItem = fn.apply(this, arguments_);

    cache.set(key, {
      data: cacheItem,
      maxAge: maxAge ? Date.now() + maxAge : Infinity,
    });

    return cacheItem;
  };

  try {
    mimicFn(memoized, fn);
  } catch (_) {
    return _;
  }
  return memoized;
};

export default mem;
