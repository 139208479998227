import React, {
  memo,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Popover, Typography } from "@mui/material";
import { ShimmerCategoryList } from "react-shimmer-effects";

import Container from "../../../components/Container/Container";
import MessengerStyles from "./MessengerStyles";
import SearchMessageView from "../../../components/NetworkDashboard/Messenger/SearchMessageView/SearchMessageView";
import InitChat from "../../../components/NetworkDashboard/Messenger/InitChat/InitChat";
import { useAppState } from "../../../context";
import SearchMessageBox from "../../../components/NetworkDashboard/Messenger/SearchMessageBox/SearchMessageBox";
import { routes } from "../../../routes";
import { addAccessibilityClass } from "../../../helper/helper";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import { getFavoriteNetworksAPI } from "../../../services/networkServices";
import { chatFilterOptions } from "../../../utils/common";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useComponentVisible } from "../../../hooks/useComponentVisible";

import Notealert from "../../../assets/svg/note-alert.svg";

const Messenger = (props) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const navigate = useNavigate();
  const persisteState = useRef();
  const state = useAppState("chat");
  const {
    isChatsLoading,
    isChatsLoaded,
    setChats,
    setCurrentConversationId,
    flushFreshChats,
    favoriteUsersCount,
    setFavoriteUsersCount,
    setChatFilterTabAndReloadChats,
  } = state;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, ["search", "search-load-more"]);
  const [showPopover, setPopover] = useState(null);
  const openPopover = Boolean(showPopover);
  const idPopover = openPopover ? "your-messages-popover" : undefined;
  useEffect(() => {
    persisteState.current = state;
  }, [state]);

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout";
    addAccessibilityClass();
  });

  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
    }
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  useEffect(() => {
    if (favoriteUsersCount === null) {
      refreshGetFavoriteNetworksAPI();
    }
    return () => {
      const { freshChat, chats, chatFilterTab } = persisteState.current;
      if (freshChat?.length > 0) {
        if (chats?.length > 0) {
          const _chats = chats.filter(
            (chat) => !freshChat.includes(chat.conversation_id)
          );
          setChats(_chats);
          _chats?.length > 0 &&
            setCurrentConversationId(_chats[0].conversation_id);
        }
        flushFreshChats();
      }
      // Reset chat filter if exiting the chat module
      backgroundHandleButtonNavigate();
    };
  }, []);

  /**
   * This background handler switches filter of chat preview list to "All"
   * And reload chats with all filter to reset state
   */
  const backgroundHandleButtonNavigate = () => {
    const { chatFilterTab } = persisteState.current;
    if (chatFilterTab !== chatFilterOptions[0]) {
      setChatFilterTabAndReloadChats(chatFilterOptions[0]);
    }
  };

  const handleInfoClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  return (
    <div id={"messenger-ref"} ref={ref}>
      <MessengerStyles>
        <Container className="messenger-page">
          <div className="messenger-page__header">
            <div className="messenger-page__header__title">
              <h2>Your messages</h2>
              <a
                href="/#"
                onClick={handleInfoClick}
                className="alert-icon information-icon"
                aria-label="Your messages note"
              >
                <img src={Notealert} alt="Note" />
              </a>
              <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={showPopover}
                onClose={handlePopoverClose}
                className="popover-wrapper"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography>
                  Kindly refrain from sharing personal or sensitive information
                  with others.
                </Typography>
                {accessibilityHelp === "true" && (
                  <div className="close-button">
                    <ButtonCustom onClick={handlePopoverClose}>
                      Got It!
                    </ButtonCustom>
                  </div>
                )}
              </Popover>
            </div>
            <div className="messenger-page__header__options">
              <div className="messenger-page__header__options__search">
                <SearchMessageBox
                  {...{ isComponentVisible, setIsComponentVisible }}
                />
              </div>
              <div className="messenger-page__header__options__buttons">
                <div className="messenger-page__header__options__buttons__new-group">
                  <ButtonCustom
                    className="shadow-btn"
                    onClick={() => {
                      backgroundHandleButtonNavigate();
                      navigate(routes.NETWORK.NEW_GROUP);
                    }}
                  >
                    New group <span>+</span>
                  </ButtonCustom>
                </div>
                <div className="messenger-page__header__options__buttons__new-messages">
                  <ButtonCustom
                    className="shadow-btn"
                    onClick={() => {
                      backgroundHandleButtonNavigate();
                      navigate(routes.NETWORK.NEW_MESSAGE);
                    }}
                  >
                    New message <span>+</span>
                  </ButtonCustom>
                </div>
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} sm={12} md={3}>
              <SearchMessageView />
            </Grid>
            <Grid item xs={12} lg={9} sm={12} md={9}>
              {isChatsLoading && (
                <div className="loader-container">
                  <ShimmerCategoryList
                    title={"Chats"}
                    items={6}
                    categoryStyle="STYLE_THREE"
                    text
                  />
                </div>
              )}
              {isChatsLoaded && !isChatsLoading && (
                <InitChat redirectUserId={props?.location?.state?.userid} />
              )}
            </Grid>
          </Grid>
        </Container>
      </MessengerStyles>
    </div>
  );
};
export default memo(Messenger);
