import { useEffect } from "react";

import { useAppState } from "../context";
import { getSavedJobsAPI } from "../services/JobServices";
import usePromise from "./usePromise/usePromise";

export const useSavedJobs = ({ fetchJobs = false } = {}) => {
  const { savedJobs = [], setSavedJobs = () => {} } = useAppState("job");
  const [callSavedJobs, refreshSavedJobs] = usePromise(getSavedJobsAPI);

  useEffect(() => {
    if (fetchJobs) refreshSavedJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callSavedJobs.hasFetched() && callSavedJobs.hasErrors() === false) {
      if (callSavedJobs.data()?.data) {
        setSavedJobs(callSavedJobs.data().data.favorite_jobs ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSavedJobs.isFetching()]);

  return {
    savedJobs,
    setSavedJobs,
    refreshSavedJobs,
    savedJobsLoading: callSavedJobs.isFetching(),
  };
};
