import React from "react";

import ChromeExtensionSlideStyles from "./ChromeExtensionSlideStyles";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";

import ChromeWebStore from "../../../../../assets/svg/chrome-web-store.svg";

const ChromeExtensionSlide = () => {
  return (
    <ChromeExtensionSlideStyles>
      <div className="chrome-extension-slide">
        <div className="top-image">
          <img src={ChromeWebStore} alt="Illustration" />
        </div>
        <div className="title text-primary">
          Get the Google chrome extension
        </div>
        <div className="sub-title text-primary">
          The Interstride H-1B Sponsorship Finder is a free extension that shows
          companies sponsoring H-1B visas on popular job boards.
        </div>
        <ButtonCustom
          width={265}
          onClick={() =>
            window.open(
              "https://chromewebstore.google.com/detail/interstride-h-1b-sponsors/oonholcildbjjpikkfiapadhiicmnimb",
              "_blank"
            )
          }
          keepLabelCase={true}
          className="bg-blue"
        >
          Get the extension
        </ButtonCustom>
      </div>
    </ChromeExtensionSlideStyles>
  );
};

export default ChromeExtensionSlide;
