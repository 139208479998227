import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .slick-slider {
    margin-right: 20px;
    margin-left: 20px;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .dashboard-post-preview {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image-preview-div,
    .video-preview-div,
    .pdf-preview-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slide-preview-image {
      max-width: 100%;
    }
  }

  .slick-dots {
    bottom: -18px;
    li {
      margin: 0;
      width: 15px;
      height: 10px;
      button {
        padding: 0;
        width: 15px;
        height: 10px;
        outline: 0 !important;
        box-shadow: none !important;
        &:before {
          width: 15px;
          height: 10px;
          width: 15px;
          height: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: var(--primary-main-color) !important;
          opacity: 0.1 !important;
        }
      }
      &.slick-active {
        button {
          &:before {
            color: var(--primary-main-color) !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .slick-arrow {
    &:before {
      color: var(--secondary-color);
      opacity: 1;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
`;
