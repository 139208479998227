export const config = () => {
  // Compute current url
  const currentURL = new URL(window.location.href);

  /// added by Tejal & Atif
  const commonConfiguration = {
    VERSION_CHECK_INTERVAL: 1800000, // 30 mins
    WEBINARS_PER_PAGE: 5,
    LINKEDIN_CLIENT_ID: "86fuf8qiovb0pw",
    GOOGLE_CLIENT_ID:
      "662715351295-41idhil6qd6qbqib1ll3dk5san21d9a8.apps.googleusercontent.com",
    GOOGLE_CLIENT_SECRET: "YHIqzBLlRjbofet-3wmP02Vm",
    OUTLOOK_CLIENT_ID: "53fefc4b-f059-4701-b377-0eb6091dabc0",
    GOOGLE_TAG_MANAGER_ID: "GTM-MKZDSCV",
  };
  const env = process.env.REACT_APP_ENV;
  const IS_PRODUCTION = !["stage", "build-pre-prod"].includes(env);
  const STAGING_API_URL =
    env === "stage"
      ? "https://web.testing.interstride.com/api/v1/"
      : "https://web.staging.interstride.com/api/v1/";
  const STAGING_SOCKET_URL =
    env === "stage"
      ? "wss://web.cable.testing2.interstride.com/cable?token="
      : "wss://staging.cable.interstride.com/cable?token=";
  const API_URL = IS_PRODUCTION
    ? "https://web.interstride.com/api/v1/"
    : STAGING_API_URL;
  const SOCKET_URL = IS_PRODUCTION
    ? "wss://cable.interstride.com/cable?token="
    : STAGING_SOCKET_URL;
  const BUGSNAG_API_KEY = IS_PRODUCTION
    ? "e0ab0eb4d2baf8c2cfbad0b471216391"
    : "ac3a919be6570db3a9bab371e72f6ba2";
  const LINKEDIN_REDIRECT_URI = IS_PRODUCTION
    ? "https://student.interstride.com/linkedin"
    : currentURL.origin + "/linkedin";
  const EMPAPI_URL = IS_PRODUCTION
    ? "https://employer.interstride.com/api"
    : "https://stageemployer.interstride.com/";
  const MIX_PANEL_PROJECT_KEY = IS_PRODUCTION
    ? "3d6a228ce991ac364f2414abe0b7ffb3"
    : "6588c50d93277836842d9f2850542744";
  const GOOGLE_SERVICE_API_KEY = IS_PRODUCTION
    ? "AIzaSyCal6I_mnG7mg9yWA4gWRwYogj-OEbWoXs"
    : "AIzaSyDStqZYYGixLPFVNaUdlBijidr_DnhjKpg";

  const VERSIONS = {
    JOBS_COUNTRY_LIST: 2.0,
  };

  return {
    API_URL,
    SOCKET_URL,
    BUGSNAG_API_KEY,
    LINKEDIN_REDIRECT_URI,
    EMPAPI_URL,
    MIX_PANEL_PROJECT_KEY,
    GOOGLE_SERVICE_API_KEY,
    VERSIONS,
    ...commonConfiguration,
  };
};
