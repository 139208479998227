import React, { useState } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import AppointmentCalendarStyles from "./AppointmentCalendarStyles";
import CardCustom from "../../../CardCustom/CardCustom";

const AppointmentCalendar = (props) => {
  const [initialized, setInitialized] = useState(false);
  return (
    <AppointmentCalendarStyles>
      <CardCustom className="calendar-card">
        <FullCalendar
          initialDate={
            props?.selectedDate
              ? moment(props.selectedDate).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")
          }
          viewClassNames="view-header"
          dayHeaderClassNames="cal-day-header"
          weekNumberClassNames="cal-week-number"
          eventClassNames="cal-event"
          plugins={[interactionPlugin, dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          headerToolbar={{
            left: "",
            center: "prev,title,next",
            right: "",
          }}
          droppable={true}
          editable={true}
          datesSet={(arg) => {
            const startDate = arg.start;
            let smonth = startDate.getMonth() + 1;
            let syear = startDate.getFullYear();
            let nextMonth = smonth === 12 ? 1 : smonth;
            let nextYear = smonth === 12 ? syear + 1 : syear;
            let monthArray = { month: nextMonth, year: nextYear };
            if (props?.initialMonths && !initialized) {
              props.setSelectedMonths(props.initialMonths);
              setInitialized(true);
            } else props.setSelectedMonths(monthArray);
          }}
          {...props}
        />
      </CardCustom>
    </AppointmentCalendarStyles>
  );
};

export default AppointmentCalendar;
