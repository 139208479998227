import React from "react";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import CustomDialog from "../CustomDialog/CustomDialog";

const ThanksDialog = ({ showModel, subModel }) => {
  const ModelHandler = (status) => {
    subModel(status);
  };

  return (
    <div className="feedback-model">
      <CustomDialog
        className="feedback-dialog"
        open={showModel}
        title="Thanks for sharing your feedback!"
        handleClose={() => ModelHandler(!showModel)}
      >
        <div className="model-body">
          <div className="feedback-reply">
            <div className="sub-header-primary">
              We value your opinion and will review your feedback soon.
            </div>
          </div>
          <div className="btn">
            <ButtonCustom
              width="124"
              onClick={() => ModelHandler(!showModel)}
              className="got-it"
            >
              Got it!
            </ButtonCustom>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};
export default ThanksDialog;
