import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getPromoteAPI = () => {
  return axiosInstance
    .get("promotes", headers_with_token())
    .then((res) => {
      return res.data;
    });
};
