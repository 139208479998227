import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .discover-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    gap: 10px;
    min-height: 50px;
    margin-bottom: 30px;
    @media (max-width: 790px) {
      margin-bottom: 0px;
    }
    &__title {
      height: fit-content;
      margin: 0 0 0 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
    }
    &__back {
      position: absolute;
      top: 0px;
      left: 0px;
      @media (max-width: 790px) {
        position: initial;
      }
      .shadow-btn {
        background: #fff !important;
        box-shadow: var(--card-box-shadow) !important;
        width: fit-content;
        padding: 10px 21px 0 21px;
        border-radius: 100px;
        transition: all 0.3s;
        display: inline-block;
        text-transform: initial !important;
        &:hover {
          box-shadow: var(--card-box-shadow-hover) !important;
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            margin: 0 5px 2px 0;
          }
          span {
            color: var(--field-label-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Medium";
            margin-top: 2px;
          }
        }
      }
    }
    &__new-topic {
      position: absolute;
      top: 0px;
      right: 0px;
      @media (max-width: 790px) {
        position: initial;
      }
      .shadow-btn {
        background: #fff !important;
        box-shadow: var(--card-box-shadow) !important;
        width: fit-content;
        padding: 5px 21px 0 21px;
        border-radius: 100px;
        transition: all 0.3s;
        display: inline-block;
        text-transform: initial !important;
        &:hover {
          box-shadow: var(--card-box-shadow-hover) !important;
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            margin-bottom: 2px;
          }
          span {
            color: var(--primary-dark-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Medium";
            margin-top: 2px;
          }
        }
      }
    }
  }
  .discover-mainpage {
  }
`;
