import _map from "lodash/map";

import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const notificationsListAPI = (filterBy) => {
  let url = "";
  _map(filterBy, (item) => {
    url += `filter_type[]=${item.key}&`;
  });
  url = url.substring(0, url.length - 1);
  if (url === "") {
    url = `filter_type[]=networking&filter_type[]=webinars&filter_type[]=job&filter_type[]=other_resources&filter_type[]=calendar&filter_type[]=topics&filter_type[]=appointment`;
  }
  return axiosInstance
    .get(`notifications?${url}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const removeNotificationAPI = (id) => {
  return axiosInstance
    .get(`notifications/${id}/remove_notification`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** Get Notification Settings */
export const getNotificationSettingsAPI = (userId) => {
  return axiosInstance
    .get(`notification_settings/${userId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** Change Notification Settings */
export const changeNotificationSettingsAPI = (settingObj) => {
  return axiosInstance
    .post(`/notification_settings`, settingObj, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateNotificationSettingsAPI = (settingObj) => {
  return axiosInstance
    .put(
      `notification_settings/update_notifications`,
      settingObj,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getUnsubscribeEmail = (token) => {
  return axiosInstance
    .put(
      `notification_settings/unsubsrcibe_email_without_signup`,
      { token },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** Notification count - Deprecated API use allNotificationCountAPI instead*/
// export const notificationCountAPI = () => {
//   return axiosInstance
//     .get(`notifications/notification_count`, headers_with_token())
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       //Todo: handle Error
//       console.log(error);
//     });
// };

/** All Notifications Count */
export const allNotificationsCountAPI = () => {
  return axiosInstance
    .get(`notifications/all_notifications_count`, headers_with_token())
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

/** Notification read count */
export const notificationReadCountAPI = (id) => {
  return axiosInstance
    .get("notifications/" + id + "/read", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** Notification subscribe-unsubcribe */
export const notificationSubscribeAPI = (type) => {
  return axiosInstance
    .get(`users/unsubscribe_notifications?type=${type}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const pastNotificationClearAPI = () => {
  return axiosInstance
    .get("notifications/remove_all_past_notifications", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const readAllNotificationApi = (filterBy) => {
  let url = "";
  _map(filterBy, (item) => {
    url += `filter_type[]=${item.key}&`;
  });
  url = url.substring(0, url.length - 1);
  if (url === "") {
    url = `filter_type[]=networking`;
  }
  return axiosInstance
    .get(`notifications/read_all_notification?${url}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const readChatNotification = (conversation_id) => {
  return axiosInstance
    .post(
      `notifications/read_chat_notifications`,
      { conversation_id },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};
