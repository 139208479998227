import React, { useEffect, useState } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { CircleSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";

import { config } from "../../../../constant/config";
import { toastify } from "../../../../helper/helper";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { signUpSSO } from "../../../../services/authenticationServices";

import linkedin from "../../../../assets/svg/f-linkedin-icon.svg";
import { disconnectAccountLinkedin } from "../../../../services/profile.service";
import { createModuleStyleExtractor } from "../../../../utils/css";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import styles from "./ConnectLinkedin.module.scss";
import { updateUsersPersistState } from "../../../../redux/reducers/userReducer";

const cx = createModuleStyleExtractor(styles);

const LINKEDIN_CLIENT_ID = config().LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = config().LINKEDIN_REDIRECT_URI;

const ConnectLinkedin = ({ dashboardWidget = false }) => {
  const dispatch = useDispatch();
  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const [callSignUpSSO, refreshSignUpSSO] = usePromise(signUpSSO);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  const handleFailure = (error) => {
    toastify("error", error.errorMessage);
  };

  const handleSuccess = (data) => {
    refreshSignUpSSO(userId, "linkedin", data.code, LINKEDIN_REDIRECT_URI);
  };

  const handleDisconnect = async (e) => {
    e.preventDefault();
    try {
      const response = await disconnectAccountLinkedin();
      if (response.success) {
        toastify("success", response.message);
        setShowDisconnectModal(false);
        dispatch(updateUsersPersistState({ is_linkedin_connected: false }));
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
    }
  };

  useEffect(() => {
    if (callSignUpSSO.hasFetched()) {
      if (callSignUpSSO.hasErrors()) {
        if (callSignUpSSO.error().data) {
          toastify("error", callSignUpSSO.error().data.message);
        } else {
          toastify("error", "Something went wrong. Please try again Later.");
        }
      } else if (callSignUpSSO.data()) {
        toastify("success", "Successfully connected to LinkedIn");
        dispatch(updateUsersPersistState({ is_linkedin_connected: true }));
      }
    }
  }, [callSignUpSSO.isFetching()]);

  return (
    <div className="linked-in profile-linkedin-container">
      {users?.is_linkedin_connected ? (
        <div className={cx("linkedin-container")}>
          <span className={cx("linkedin-container__heading")}>
            Connect your LinkedIn account
          </span>
          <a
            href="#"
            className={cx([
              "linkedin-container__button",
              dashboardWidget ? "linkedin-container__buttonleft" : "",
            ])}
            onClick={() => {
              setShowDisconnectModal(true);
            }}
          >
            <div
              className={cx([
                "linkedin-container__button__icon",
                users?.is_linkedin_connected && dashboardWidget
                  ? "linkedin-container__button__connected"
                  : "",
              ])}
            >
              <img src={linkedin} alt="linkedin" />
            </div>

            <span
              className={cx([
                "linkedin-container__button__description",
                dashboardWidget
                  ? "linkedin-container__buttonleft__descriptionleft"
                  : "",
              ])}
            >
              Disconnect account
            </span>
            <div className={cx("linkedin-container__button__loader")}>
              {callSignUpSSO.isFetching() && (
                <CircleSpinner size={20} color={"#607790"} />
              )}
            </div>
          </a>
        </div>
      ) : (
        <LinkedIn
          clientId={LINKEDIN_CLIENT_ID}
          redirectUri={LINKEDIN_REDIRECT_URI}
          scope="r_liteprofile,r_emailaddress"
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          supportIE
          tabindex="-1"
        >
          <div className={cx("linkedin-container")}>
            <span className={cx("linkedin-container__heading")}>
              Connect your LinkedIn account
            </span>
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className={cx("linkedin-container__button")}
            >
              <div className={cx("linkedin-container__button__icon")}>
                <img src={linkedin} alt="linkedin" />
              </div>

              <span className={cx("linkedin-container__button__description")}>
                Connect LinkedIn
              </span>
              <div className={cx("linkedin-container__button__loader")}>
                {callSignUpSSO.isFetching() && (
                  <CircleSpinner size={20} color={"#607790"} />
                )}
              </div>
            </a>
          </div>
        </LinkedIn>
      )}
      {setShowDisconnectModal && (
        <CustomDialog
          open={showDisconnectModal}
          title={"Are you sure you want to disconnect linkedin account?"}
          handleClose={() => setShowDisconnectModal(false)}
          className="profile-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn mt-25">
              <ButtonCustom
                width="238"
                height={50}
                onClick={(e) => handleDisconnect(e)}
              >
                Disconnect
              </ButtonCustom>
            </div>
          </div>
        </CustomDialog>
      )}
    </div>
  );
};

export default ConnectLinkedin;
