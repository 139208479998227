import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid2,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

import SearchFilterStyles from "./SearchFilterStyles";
import Textbox from "../../Textbox/TextboxCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import {
  US_VISA_LIST,
  US_VISA_LIST_DOMESTIC_VIEW,
  US_STATE_OPTIONS,
  JOB_POSITION_OPTIONS,
  JOB_WORK_TYPE_OPTIONS,
  JOB_DATE_POSTED_OPTIONS,
  JOB_DEGREE_OPTIONS,
  JOB_EXPERIENCE_OPTIONS,
} from "../../../constant/mockdata";
import CheckboxJob from "../../atoms/CheckboxJob/CheckboxJob";
import { PRIMARY_DARK_COLOR } from "../../../styles/variables";

import FilterIcon from "../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";
import QuestionIcon from "../../QuestionIcon/QuestionIcon";

const SearchFilter = ({
  filter = {},
  visaFilter = true,
  showSaveSearch = false,
  jobCategoriesCount = [],
  savedSearchKeyword = "",
  setFilter = () => {},
  onClearAllClick = () => {},
  applyFilters = () => {},
  handleSaveSearchClick = () => {},
}) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const [openFilter, toggleFilter] = useState(false);

  const { jobsCountryList = [] } = useSelector((store) => store.jobStore);
  const { users = {} } = useSelector((store) => store.userStore);
  const student_view_preference =
    users?.student_view_preference || "international";

  const {
    search,
    country,
    state,
    city,
    visa,
    job_type,
    work_type,
    publish_date,
    degree,
    experience = [],
  } = filter;

  const searchType = country && country === "us" ? "us" : "international";

  const [showVisaPopover, setVisaPopover] = useState(null);
  const openVisa = Boolean(showVisaPopover);
  const idVisa = openVisa ? "simple-visa-popover" : undefined;

  const [showKeywordPopover, setKeywordPopover] = useState(null);
  const openKeyword = Boolean(showKeywordPopover);
  const idKeyword = openKeyword ? "simple-keyword-popover" : undefined;

  const [showCityPopover, setCityPopover] = useState(null);
  const openCity = Boolean(showCityPopover);

  const [showExperiencePopover, setExperiencePopover] = useState(null);
  const openExperience = Boolean(showExperiencePopover);
  const idExperience = openCity ? "simple-experience-popover" : undefined;

  const [showDegreePopover, setDegreePopover] = useState(null);
  const openDegree = Boolean(showDegreePopover);
  const idDegree = openCity ? "simple-degree-popover" : undefined;

  const handleClose = (type = "visa") => {
    type === "visa"
      ? setVisaPopover(null)
      : type === "keyword"
      ? setKeywordPopover(null)
      : type === "experience"
      ? setExperiencePopover(null)
      : type === "degree"
      ? setDegreePopover(null)
      : setCityPopover(null);
  };

  const handleClick = (event, type = "visa") => {
    event.preventDefault();
    type === "visa"
      ? setVisaPopover(event.currentTarget)
      : type === "keyword"
      ? setKeywordPopover(event.currentTarget)
      : type === "experience"
      ? setExperiencePopover(event.currentTarget)
      : type === "degree"
      ? setDegreePopover(event.currentTarget)
      : setCityPopover(event.currentTarget);
  };

  const applyCurrentFilters = () => {
    toggleFilter(false);
    applyFilters();
  };

  const processCheckBoxClick = (value = null, type = null) => {
    if (type === "experience") {
      let _experience = [...(experience || [])];
      if (_experience?.includes(value)) {
        _experience = _experience.filter((item) => item !== value);
      } else {
        _experience.push(value);
      }
      setFilter({ experience: _experience });
      applyFilters({ experience: _experience?.join() });
    } else if (type === "degree") {
      let _degree = [...(degree || [])];
      if (_degree?.includes(value)) {
        _degree = _degree.filter((item) => item !== value);
      } else {
        _degree.push(value);
      }
      setFilter({ degree: _degree });
      applyFilters({ degree: _degree?.join() });
    } else if (type === "job_type") {
      let _job_type = [...(job_type || [])];
      if (_job_type?.includes(value)) {
        _job_type = _job_type.filter((item) => item !== value);
      } else {
        _job_type.push(value);
      }
      setFilter({ job_type: _job_type });
      applyFilters({ job_type: _job_type?.join() });
    } else if (type === "work_type") {
      let _work_type = [...(work_type || [])];
      if (_work_type?.includes(value)) {
        _work_type = _work_type.filter((item) => item !== value);
      } else {
        _work_type.push(value);
      }
      setFilter({ work_type: _work_type });
      applyFilters({ work_type: _work_type?.join() });
    }
  };

  return (
    <SearchFilterStyles>
      <div
        className={`search-filter collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-15">
          Search & filter
          <Link
            to="/#"
            className="ngray-link"
            onClick={(e) => {
              e.preventDefault();
              setFilter({
                search: "",
                state: "",
                city: "",
                job_type: "",
                publish_date: "",
                country: visaFilter === true ? "us" : "",
                visa:
                  student_view_preference === "international"
                    ? "top_1000_employers"
                    : US_VISA_LIST_DOMESTIC_VIEW[0].value,
              });

              if (onClearAllClick) onClearAllClick();
            }}
          >
            Clear all
          </Link>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon"
          >
            {openFilter ? (
              <img src={FilledFilterIcon} alt="Filter Icon" />
            ) : (
              <img src={FilterIcon} alt="Filter Icon" />
            )}
          </a>
        </h2>
        <Grid2 container>
          <Grid2 size={{ xs: 12 }}>
            <CardCustom className="search-card">
              {/* Search */}
              <div className="form-field-group">
                <label id="search-keyword-label" className="textbox-label">
                  Search keyword
                  <QuestionIcon
                    aria-label="Search keyword"
                    onClick={(e) => handleClick(e, "keyword")}
                  />
                </label>

                <Textbox
                  name="search"
                  type="text"
                  aria-labelledby="search-keyword-label"
                  placeholder="Enter title, skill, or company"
                  label=""
                  lableaignment="left"
                  value={search}
                  onChange={(e) => {
                    setFilter({ search: e.target.value });
                    // Apply filter dynamically if applied search keyword is removed
                    if (
                      savedSearchKeyword?.length > 0 &&
                      e.target.value?.length === 0
                    ) {
                      applyFilters({ search: "" });
                    }
                  }}
                  onEnterKey={applyCurrentFilters}
                />
                <Popover
                  id={idKeyword}
                  open={openKeyword}
                  anchorEl={showKeywordPopover}
                  onClose={() => handleClose("keyword")}
                  className="popover-wrapper"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography>
                    Search for job titles and positions related to your major
                    and degree
                  </Typography>
                  {accessibilityHelp === "true" && (
                    <div className="close-button">
                      <ButtonCustom onClick={() => handleClose("keyword")}>
                        Got It!
                      </ButtonCustom>
                    </div>
                  )}
                </Popover>
              </div>
              {/* Country */}
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder="Select country"
                  justifyContent="left"
                  options={jobsCountryList}
                  value={
                    country && country !== null
                      ? jobsCountryList.find((rec) => rec.value === country)
                      : ""
                  }
                  onChange={(e) => {
                    const params = {
                      country: e.value,
                      state: null,
                      city: null,
                      visa: e.value === "us" ? US_VISA_LIST[1].value : "",
                    };
                    setFilter(params);
                    applyFilters(params);
                  }}
                />
              </div>
              {/* Companies */}
              {visaFilter === true && searchType && searchType === "us" && (
                <div className="form-field-group">
                  <label id="visa-label" className="textbox-label">
                    Companies
                    <QuestionIcon
                      aria-label="Companies"
                      onClick={(e) => handleClick(e, "visa")}
                    />
                  </label>
                  <Popover
                    id={idVisa}
                    open={openVisa}
                    anchorEl={showVisaPopover}
                    onClose={() => handleClose("visa")}
                    className="popover-wrapper"
                    aria-labelledby="visa-label"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {student_view_preference === "international" ? (
                      <Typography>
                        <b>Top 500 H-1B:</b> Top 500 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship from the largest H-1B
                        employers. These employers may have global offices to
                        relocate you internationally if H-1B lottery is
                        unsuccessful.
                        <br />
                        <br />
                        <b>Top 1000 H-1B:</b> Top 1000 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship, but includes smaller
                        companies with limited sponsorship history.
                        <br />
                        <br />
                        <b>Sponsored last year:</b> The jobs were sponsored last
                        year. Highest probability of sponsorship.
                        <br />
                        <br />
                        <b>Cap-exempt:</b> Employers exempt from the H-1B
                        lottery process. High probability of sponsorship.
                        <br />
                        <br />
                        <b>International organization :</b> These organizations
                        can hire international students on special visa
                        categories. A great alternative to seeking an H-1B
                        sponsoring employer.
                        <br />
                        <br />
                        <b>All companies:</b> Relevant for domestic students.
                        Low probability of sponsorship for international
                        students.
                      </Typography>
                    ) : (
                      <Typography>
                        <b>Fortune 500:</b> The Fortune 500 is a list of the
                        largest 500 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Fortune 1000:</b> The Fortune 1000 is a list of the
                        largest 1000 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Crunchbase Unicorn:</b> The Crunchbase Unicorn Board
                        is a curated list of the most valuable private companies
                        in the world. Powered by Crunchbase’s comprehensive
                        data, this unicorn company list is updated as companies
                        are valued at $1 billion or more in a new funding round.
                      </Typography>
                    )}
                    {accessibilityHelp === "true" && (
                      <div className="close-button">
                        <ButtonCustom onClick={() => handleClose("visa")}>
                          Got It!
                        </ButtonCustom>
                      </div>
                    )}
                  </Popover>
                  <DropdownCustom
                    name="visa"
                    justifyContent="left"
                    options={
                      student_view_preference === "international"
                        ? US_VISA_LIST
                        : US_VISA_LIST_DOMESTIC_VIEW
                    }
                    value={(student_view_preference === "international"
                      ? US_VISA_LIST
                      : US_VISA_LIST_DOMESTIC_VIEW
                    ).find((rec) => rec.value === visa)}
                    onChange={(e) => {
                      setFilter({ visa: e.value });
                      applyFilters({ visa: e.value });
                    }}
                    ariaLabelledby="visa-label"
                  />
                </div>
              )}
              {/* Experience */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="experience-content"
                    id="experience-header"
                  >
                    <span className="job-filter-accordian__title">
                      Experience
                    </span>
                    <QuestionIcon
                      aria-label="Experience"
                      onClick={(e) => handleClick(e, "experience")}
                    />
                    <Popover
                      id={idExperience}
                      open={openExperience}
                      anchorEl={showExperiencePopover}
                      onClose={() => handleClose("experience")}
                      className="popover-wrapper"
                      aria-labelledby="experience-label"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography>
                        Note that this filter will only show jobs when required
                        experience is explicitly stated in the job description.
                        It's a small subset of the total jobs on the Interstride
                        platform since many job listings do not clearly state
                        experience level in their job description.
                        <br />
                        <br />
                        <b>Entry level :</b>{" "}
                        {`No work experience required or < 1 year`}
                        <br />
                        <br />
                        <b>Intermediate :</b> 2-5 years of work experience
                        <br />
                        <br />
                        <b>Experienced :</b> 5-10 years of work experience
                        <br />
                        <br />
                        <b>Advanced :</b> 10+ years of work experience
                      </Typography>
                      {accessibilityHelp === "true" && (
                        <div className="close-button">
                          <ButtonCustom
                            onClick={() => handleClose("experience")}
                          >
                            Got It!
                          </ButtonCustom>
                        </div>
                      )}
                    </Popover>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__checkboxes">
                      {JOB_EXPERIENCE_OPTIONS?.map((item, index) => {
                        const count =
                          jobCategoriesCount
                            ?.filter(
                              (category) => category.category === "experience"
                            )[0]
                            ?.data?.filter((data) =>
                              Object.keys(data)?.includes(item?.value)
                            )?.[0]?.[item?.value] || "0";
                        return (
                          <CheckboxJob
                            label={item.label}
                            key={index}
                            checked={experience?.includes(item?.value)}
                            onChange={() =>
                              processCheckBoxClick(item?.value, "experience")
                            }
                            count={count}
                            disabled={count && parseInt(count) === 0}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* Degree */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="degree-content"
                    id="degree-header"
                  >
                    <span className="job-filter-accordian__title">Degree</span>
                    <QuestionIcon
                      aria-label="Degree"
                      onClick={(e) => handleClick(e, "degree")}
                    />
                    <Popover
                      id={idDegree}
                      open={openDegree}
                      anchorEl={showDegreePopover}
                      onClose={() => handleClose("degree")}
                      className="popover-wrapper"
                      aria-labelledby="degree-label"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography>
                        Note that this filter will only show jobs when the
                        required degree is explicitly stated in the job
                        description. It's a small subset of the total jobs on
                        the Interstride platform since many job listings do not
                        clearly state degree requirements in their job
                        description.
                      </Typography>
                      {accessibilityHelp === "true" && (
                        <div className="close-button">
                          <ButtonCustom onClick={() => handleClose("degree")}>
                            Got It!
                          </ButtonCustom>
                        </div>
                      )}
                    </Popover>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__checkboxes">
                      {JOB_DEGREE_OPTIONS?.map((item, index) => {
                        const count =
                          jobCategoriesCount
                            ?.filter(
                              (category) => category.category === "degree"
                            )[0]
                            ?.data?.filter((data) =>
                              Object.keys(data)?.includes(item?.value)
                            )?.[0]?.[item?.value] || "0";
                        return (
                          <CheckboxJob
                            label={item.label}
                            key={index}
                            checked={degree?.includes(item?.value)}
                            onChange={() =>
                              processCheckBoxClick(item?.value, "degree")
                            }
                            count={count}
                            disabled={count && parseInt(count) === 0}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* Job type */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="job-type-content"
                    id="job-type-header"
                  >
                    <span className="job-filter-accordian__title">
                      Job type
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__checkboxes">
                      {JOB_POSITION_OPTIONS?.map((item, index) => {
                        const count =
                          jobCategoriesCount
                            ?.filter(
                              (category) =>
                                category.category === "employment_type"
                            )[0]
                            ?.data?.filter((data) =>
                              Object.keys(data)?.includes(item?.value)
                            )?.[0]?.[item?.value] || "0";
                        return (
                          <CheckboxJob
                            label={item.label}
                            key={index}
                            checked={job_type?.includes(item?.value)}
                            onChange={() =>
                              processCheckBoxClick(item?.value, "job_type")
                            }
                            count={count}
                            disabled={count && parseInt(count) === 0}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* Remote (key: work_type) */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="job-work-type-content"
                    id="job-work-type-header"
                  >
                    <span className="job-filter-accordian__title">Remote</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__checkboxes">
                      {JOB_WORK_TYPE_OPTIONS?.map((item, index) => {
                        const count =
                          jobCategoriesCount
                            ?.filter(
                              (category) => category.category === "work_type"
                            )[0]
                            ?.data?.filter((data) =>
                              Object.keys(data)?.includes(item?.value)
                            )?.[0]?.[item?.value] || "0";
                        return (
                          <CheckboxJob
                            label={item.label}
                            key={index}
                            checked={work_type?.includes(item?.value)}
                            onChange={() =>
                              processCheckBoxClick(item?.value, "work_type")
                            }
                            count={count}
                            disabled={count && parseInt(count) === 0}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* State */}
              {searchType === "us" && (
                <div className="job-filter-accordian">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="state-content"
                      id="state-header"
                    >
                      <span className="job-filter-accordian__title">State</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="job-filter-accordian__dropdown">
                        <DropdownCustom
                          name="state"
                          justifyContent="left"
                          options={US_STATE_OPTIONS}
                          value={
                            state && state !== null
                              ? US_STATE_OPTIONS.find(
                                  (rec) => rec.value === state
                                )
                              : null
                          }
                          onChange={(e) => {
                            setFilter({ state: e.value });
                            applyFilters({ state: e.value });
                          }}
                          placeholder="Please select a state"
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {/* City */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="city-content"
                    id="city-header"
                  >
                    <span className="job-filter-accordian__title">City</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__dropdown">
                      <Textbox
                        name="city"
                        type="text"
                        placeholder="Enter City"
                        label=""
                        lableaignment="left"
                        value={city ? city : ""}
                        onChange={(e) => setFilter({ city: e.target.value })}
                        onEnterKey={applyCurrentFilters}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* Date Posted */}
              <div className="job-filter-accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="date-posted-content"
                    id="date-posted-header"
                  >
                    <span className="job-filter-accordian__title">
                      Date Posted
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-filter-accordian__radio-group">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="date-posted-radio-buttons-group"
                          name="date-posted-radio-buttons-group"
                          value={
                            publish_date === null || publish_date === undefined
                              ? "empty"
                              : publish_date
                          }
                          onChange={(e) => {
                            setFilter({ publish_date: e.target.value });
                            applyFilters({ publish_date: e.target.value });
                          }}
                        >
                          {JOB_DATE_POSTED_OPTIONS?.map((item, index) => (
                            <div
                              key={index}
                              className="job-filter-accordian__radio-group__item"
                            >
                              <Radio
                                value={item.value}
                                disableRipple={true}
                                disableFocusRipple={true}
                                disableTouchRipple={true}
                                sx={{
                                  "&.Mui-checked": {
                                    color: PRIMARY_DARK_COLOR,
                                  },
                                  padding: "1px 6px 0 0",
                                }}
                              />
                              <p
                                className={`job-filter-accordian__radio-group__item__label ${
                                  publish_date === item.value
                                    ? "job-filter-accordian__radio-group__item__label--checked"
                                    : ""
                                }`}
                              >
                                {item.label}
                              </p>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {showSaveSearch && (
                <div className="text-center">
                  <Link
                    to="/#"
                    className="nlink-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveSearchClick();
                    }}
                  >
                    Save search
                  </Link>
                </div>
              )}
            </CardCustom>
          </Grid2>
        </Grid2>
      </div>
    </SearchFilterStyles>
  );
};
export default SearchFilter;
