import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const sendFeedBackAPI = async (data) => {
  return axiosInstance
    .post(`/send_feedbacks`, data, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const contactUsAPI = async (data) => {
  return axiosInstance
    .post(`/contact_us`, data, {
      headers: {
        ...headers_with_token().headers,
        "Content-Type": "multipart/form-data", // changing this to form-data to support as per API
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getContactCenterDetails = async () => {
  return axiosInstance
    .get("/school_career_center_details", headers_with_token())
    .then((res) => {
      return res.data;
    });
};
