import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import NewTopicStyles from "./NewTopicStyles";
import { useAppState } from "../../../context";
import Step1 from "../../../components/Topics/CreateNewTopic/Step1";
import Step2 from "../../../components/Topics/CreateNewTopic/Step2";
import { routes } from "../../../routes";
import { getTopicDetailsAPI } from "../../../services/networkServices";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";

const NewTopic = () => {
  const navigate = useNavigate();
  const [currentStep, setStep] = useState(1);
  const { setNewTopicDetail } = useAppState("topic");
  let editTopicId = useRef(null);

  useEffect(() => {
    // Edit Community
    if (window.location.search) {
      const url = new URL(window.location.href);
      editTopicId.current = url.searchParams.get("topicId")
        ? parseInt(url.searchParams.get("topicId"))
        : null;
    }
    if (editTopicId.current) {
      (async () => {
        const response = await getTopicDetailsAPI(editTopicId.current);
        const selectedTagsForTopic =
          response.data?.topic_details[0]?.participants_list
            ?.filter(
              (participant) =>
                participant.user_id !==
                response.data?.topic_details[0].topic_info?.created_by_id
            )
            ?.map((participant) => ({
              id: participant.user_id,
              text: participant.user_name,
              user_profile: participant.user_image,
            }));
        if (response.success)
          setNewTopicDetail({
            topicName: response.data?.topic_details[0].topic_info?.topic_name,
            description:
              response.data?.topic_details[0].topic_info?.topic_description,
            file: null,
            topicProfile:
              response.data?.topic_details[0].topic_info?.topic_icon,
            department: {},
            topicType:
              response.data?.topic_details[0].topic_info?.topic_type ===
              "public",
            selectedTagsForTopic,
          });
      })();
    }
    return () => {
      setNewTopicDetail({
        topicName: "",
        description: "",
        file: "",
        topicProfile: "",
        department: { value: "all", label: "All Departments" }, // {}
        topicType: true, // true - public , false - private
        selectedTagsForTopic: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (step) => {
    setStep(step);
  };
  const handlePrevious = () => {
    setStep(currentStep - 1);
  };

  const modelClose = () => {
    setStep(1);
    setNewTopicDetail({
      topicName: "",
      description: "",
      file: "",
      topicType: true,
      selectedTagsForTopic: [],
    });
  };

  return (
    <NewTopicStyles>
      <div className="custom-container">
        <div className="f-main-title-with-button mt-30 mb-30">
          <div className="back-btn-wrapper">
            <a
              className="shadow-btn"
              onClick={(e) => {
                e.preventDefault();
                currentStep === 1
                  ? navigate(routes.NETWORK.TOPICS)
                  : handlePrevious();
              }}
              href="/#"
              aria-label="Back"
            >
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>Back</span>
            </a>
          </div>
          <h2 className="f-main-title mb-0">
            {currentStep === 1 && editTopicId.current
              ? "Edit Community"
              : currentStep === 1 && editTopicId.current === null
              ? "Create a new Community"
              : "Notify network"}
          </h2>
        </div>
        <div className="create-topic-dialog">
          {currentStep === 1 && (
            <Step1 step={currentStep} getNextStep={handleNext} />
          )}
          {currentStep === 2 && (
            <Step2 handleClose={modelClose} editTopicId={editTopicId.current} />
          )}
        </div>
      </div>
    </NewTopicStyles>
  );
};

export default NewTopic;
