import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerSocialPost } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroll-component";

import UserImg from "../../../assets/user2.jpg";
import DashboardPost from "./DashboardPost";
import { useAppState } from "../../../context";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useQueryParams } from "../../../hooks/useQueryParams";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";

const DashboardMiddle = ({
  fetchMoreData,
  currentPage,
  totalPages,
  showPaginationLoader = false,
  ...props
}) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { user_posts } = useAppState("post");
  const query = useQueryParams();
  const [viewed, setViewed] = useState([]);
  const navigate = useNavigate(window);

  useEffect(() => {
    if (query.has("notificationId")) {
      const notificationId = query.get("notificationId");
      if (!document) return;
      const element = document.getElementById(`${notificationId}`);
      if (element && !viewed.includes(notificationId)) {
        element.scrollIntoView({ behavior: "smooth" });
        setViewed([...viewed, notificationId]);
        setTimeout(() => {
          navigate(window.location.pathname, { replace: true });
        }, 3000);
      }
    }
  }, [query, user_posts, viewed]);

  const LoadEffects = ({ count }) => {
    return Array.from(Array(count).keys()).map((item, index) => (
      <ShimmerSocialPost key={index} />
    ));
  };

  return (
    <div className="dashboard-middle-wrapper">
      {accessibilityHelp == "true" ? (
        <div>
          {user_posts.map((item, k) => (
            <DashboardPost
              postId={item.id}
              {...props}
              post={item}
              key={k}
              avatarImg={UserImg}
            />
          ))}

          {currentPage !== totalPages && totalPages > 0 && (
            <center>
              {showPaginationLoader ? (
                <LoadEffects count={2} />
              ) : (
                <ButtonCustom onClick={fetchMoreData}>Load More</ButtonCustom>
              )}
            </center>
          )}
        </div>
      ) : (
        <>
          {user_posts.length > 0 ? (
            <InfiniteScroll
              dataLength={user_posts.length}
              next={fetchMoreData}
              hasMore={currentPage !== totalPages && totalPages > 0}
              loader={<LoadEffects count={3} />}
              style={{ overflow: "none" }}
              endMessage={
                <center>
                  <h4 className="post-body text-primary">
                    No posts to view yet!
                  </h4>
                </center>
              }
            >
              {user_posts.map((item, k) => (
                <DashboardPost
                  postId={item.id}
                  {...props}
                  post={item}
                  key={k}
                  avatarImg={UserImg}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <EmptyListWithImage title={`Looks like there are no posts.`} />
          )}
        </>
      )}
    </div>
  );
};

export default memo(DashboardMiddle);
