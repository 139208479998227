import React from "react";
import ContainerStyles from "./ContainerStyles";

const Container = ({ className, children, padding = "0 35px" }) => {
  return (
    <ContainerStyles padding={padding}>
      <div className={`custom-container ${className}`}>{children}</div>
    </ContainerStyles>
  );
};

export default Container;
