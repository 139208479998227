import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .feedback-alert {
    padding: 22px 20px 18px;
    @media (max-width: 767px) {
      padding: 16px;
    }
    .MuiCardContent-root {
      padding: 0;
      display: flex;
      align-items: center;
    }
    .title-primary {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      margin: 0 0 6px 0;
    }
    .sub-header {
      color: var(--gray-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      line-height: 20px;
    }
    .feedback-btn {
      background: transparent !important;
      width: 34px;
      height: auto;
      margin-left: auto;
      min-width: 34px;
      .content {
        min-width: auto;
        img {
          vertical-align: middle;
        }
      }
    }
  }
`;
