import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .checklist-card {
    box-shadow: none;
    background: transparent;
    border-radius: initial;
    overflow: visible;
    .card-content {
      padding: 0;

      .checklist-items {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li {
          list-style: none;
          a {
            padding: 16px 20px 14px;
            text-decoration: none;
            background: #fff;
            display: flex;
            align-items: center;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            cursor: pointer;
            box-shadow: var(--card-box-shadow);
            border-radius: 100px;
            min-height: 50px;
            transition: all 0.3s;
            @media (max-width: 991px) {
              box-shadow: none;
            }
            span {
              padding-top: 3px;
            }
            &:focus {
              background-color: var(--primary-main-color);
              color: #fff;
            }
            &:hover {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
          }
          &.active {
            a {
              background-color: var(--primary-main-color);
              color: #fff;
            }
          }
          + li {
            margin-top: 10px;
            @media (max-width: 991px) {
              border-top: 1px solid var(--light-gray-color);
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
`;
