import React from "react";
import styles from "./Divider.module.scss";
export const LineDivider = ({ bold = false }) => {
  return (
    <hr
      style={{
        ...(bold && { color: "rgb(209, 221, 229)" }),
      }}
      className={styles.dividerLine}
    />
  );
};
