import styled from "styled-components";
import { WHITE_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .checklist-loading-container {
    background-color: ${WHITE_COLOR};
    padding: 20px;
    margin-bottom: 14px;
    border-radius: 10px;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  }
  .checklist-items {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 15px;
      .checklist-card {
        transition:all .3s;
        &:hover{
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          .btn-edit,.btn-close,.shadow-btn{
            opacity:1 !important;
            visibility:visible !important;
          }
        }
        .card-content {
          padding: 10px 20px 10px 20px;
          .checklist-task {
            display: flex;
            align-items: center;

            .checklist-control {
              display: flex;
              align-items: center;
              width: 100%;
              @media(max-width:767px){
                flex-wrap:wrap;
                justify-content: flex-end;
              }
              .checklist-title-wrapper{
                display: flex;
                width: 100%;
                align-items: flex-start;
                .ncheckbox-wrapper{
                  display: flex;
                  align-items: center;
                  margin: 1px 0 0 0;
                }
              }
              .MuiRadio-root {
                padding: 0;
                margin: 0 15px 0px 0;
              }

              .MuiIconButton-colorPrimary {
                &:hover {
                  background-color: transparent;
                }
              }
              .MuiIconButton-root {
                &:hover {
                  background-color: transparent;
                }
              }

              & span.Mui-checked + div {
                text-decoration: line-through;
                text-decoration-color: var(--secondary-color);
              }
              .ncheckbox-wrapper{
                @media(max-width:767px){
                  margin: 0px 0 8px 0 !important;
                }
              }
              .title {
                font-size: 18px;
                font-family: "TTCommons-Regular";
                line-height:19px
                padding: 2px 0 0 0;
                width:100%;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-right:0;
                color: var(--primary-dark-color);
                padding-right:8px;
                @media(max-width:767px){
                  width:calc(100% - 35px);
                  padding-right:0;
                  margin: 0 0 8px 0;
                }
                input[type="text"] {
                  font-family:"TTCommons-Medium";
                  font-weight:500;
                  border: 0;
                  font-size: 18px;
                  line-height:19px;
                  padding: 2px 0 0 0;
                  color: var(--primary-dark-color);
                  outline: 0 !important;
                  width: 100%;
                  &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: var(--field-label-color) !important;
                  }
                  &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: var(--field-label-color) !important;
                  }
                  &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: var(--field-label-color) !important;
                  }
                  &:-moz-placeholder {
                    /* Firefox 18- */
                    color: var(--field-label-color) !important;
                  }
                }
                > div {
                  &:first-child {
                    max-width: 100%;
                    width: 100%;
                  }
                  &:last-child {
                    max-width: 100%;
                    width: 100%;
                  }
                }
                .link-text {
                  display: inline-block;
                  margin-left: 20px;
                  color: var(--secondary-color);
                  font-family: "TTCommons-Regular";
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 19px;
                  text-decoration: underline;
                  margin-left: 0;
                }
                &.completed {
                  > div.primaryTask {
                    text-decoration: line-through;
                  }
                }
                .nlink-secondary {
                  text-align: left;
                  margin-left:0;
                  border-bottom:0 !important;
                  text-decoration:underline;
                  text-underline-offset:4px;
                  line-height: 22px;
                }
              }

              .subtitle {
                font-size: 18px;
                font-family: "TTCommons-Regular";
                line-height:19px
                padding: 0px 0 0 0;
                width: 100%;
                color: var(--primary-dark-color);
                padding-right:8px;
                @media(max-width:767px){
                  width:calc(100% - 35px);
                  padding-right:0;
                  margin: 0 0 8px 0;
                }
                input[type="text"] {
                  font-size: 18px;
                  font-family:"TTCommons-Medium";
                  font-weight:500;
                  line-height:19px;
                  padding: 2px 0 0 0;
                  color: var(--primary-dark-color);
                  outline: 0 !important;
                  width: 100%;
                  border: 0;
                }

                &.completed {
                  text-decoration: line-through;
                }
              }
              .btn-edit {
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                cursor: pointer;
                padding: 0;
                min-width: auto;
                box-shadow: none;
                flex: 0 0 35px;
                height: 35px;
                width: 35px;
                border-radius: 100px;
                background-color: var(--icon_background);
                transition:all .3s;
                opacity:0;
                visibility:hidden;
                &.show-actions {
                  opacity:1;
                  visibility:visible;
                }
                @media(max-width:1024px){
                  opacity:1;
                  visibility:visible;
                }
                img{
                  width:15px;
                }
                &:hover{
                  background-color: var(--icon_background_hover);
                }
                + button{
                  margin-left:12px;
                }
              }
              button + button,a{
                margin-left:12px;
              }
              .btn-close {
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                cursor: pointer;
                padding: 0;
                min-width: auto;
                box-shadow: none;
                flex: 0 0 35px;
                height: 35px;
                width: 35px;
                border-radius: 100px;
                background-color: var(--icon_background);
                transition:all .3s;
                opacity:0;
                visibility:hidden;
                &.show-actions {
                  opacity:1;
                  visibility:visible;
                }
                @media(max-width:1024px){
                  opacity:1;
                  visibility:visible;
                }
                img{
                  width:14px;
                }
                &:hover{
                  background-color: var(--icon_background_hover);
                }
                &:last-child{
                  margin-right:0;
                }
                + button{
                  margin-left:12px;
                }
              }
            }
            .shadow-btn {
              background: #fff !important;
              box-shadow: var(--card-box-shadow) !important;
              color: var(--primary-dark-color) !important;
              width: auto;
              padding: 18px 20px 13px;
              height: auto;
              min-height: 50px;
              font-size: 18px;
              font-weight: 500;
              line-height: 19px;
              font-family: "TTCommons-Medium";
              border-radius: 100px;
              white-space: nowrap;
              width: auto;
              min-width: 150px;
              text-transform: initial;
              transition: all 0.3s;
              opacity:0;
              visibility:hidden;
              &.show-actions {
                opacity:1;
                visibility:visible;
              }
                @media(max-width:1024px){
                  opacity:1;
                  visibility:visible;
                }
                @media (max-width: 767px) {
                  padding: 12px 12px 8px;
                  min-height: 35px;
                }
              &:hover {
                box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
              }
              .content {
                min-width: auto;
                display: flex;
                img {
                  margin: -4px 0 0 8px;
                  width: 8px;
                  vertical-align: middle;
                  &.plus-icon {
                    width: 14px;
                  }
                }
              }
            }
            .btn-save{
              height: 35px;
              background: var(--link-color);
              padding-top: 4px;
              min-width: 114px;
              @media(max-width:767px){
                min-width: 100px;
              }
            }
            button {
              padding: 2px 0 0 0;
              min-width: 134px;
              cursor: pointer;
              @media(max-width:767px){
                height: 35px;
              }
            }
          }
          .sub-tasks {
            list-style: none;
            padding: 0;
            margin-left: 50px;
            margin-top: 15px;
            @media(max-width:767px){
              margin-left: 36px;
            }
            li {
              margin-bottom: 25px;
              &:last-child {
                margin-bottom: 0 !important;
              }
              .checklist-control {
                align-items: center;
                .ncheckbox-wrapper {
                  margin: -4px 0 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;
