import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .book-appointment-search-wrapper {
    .find-job-section {
      display: block;
      .search-card {
        padding: 20px 20px;
        overflow: visible;
        .MuiCardContent-root {
          padding: 0;
          @media (max-width: 991px) {
            max-width: 320px !important;
            margin: 0 auto;
          }
        }

        .searchBtn {
          display: block;
          margin-top: 30px;
          button {
            width: 100%;
            background-color: var(--link-color) !important;
          }
        }
      }
    }

    .AppointmentSearch {
      margin-bottom: 15px;
      .card-content {
        padding: 15px;
        min-height: 100px;
        display: flex;
        align-items: center;
      }
      .search-item {
        display: flex;
        align-items: center;
        width: 100%;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .imgWrapper {
          margin: 0 10px 0 0;
          width: 70px;
          height: 70px;
          border-radius: 100px;
          overflow: hidden;
          min-width: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        > .content {
          width: 100%;
          max-width: 600px;
          padding-right: 15px;
          @media (max-width: 767px) {
            max-width: calc(100% - 80px);
            padding-right: 0;
          }
          .header-primary {
            margin: 0;
            padding: 4px 0 0 0;
            line-height: 18px;
          }
          .sub-header-primary {
            margin: 2px 0 0 0;
          }
        }
        .btn-wrap {
          margin-left: auto;
          display: flex;
          @media (max-width: 767px) {
            margin-left: 80px;
          }
          button {
            min-width: 80px;
            white-space: nowrap;
            padding: 7px 8px 3px;
            height: 32px;
            box-sizing: border-box;

            .content {
              min-width: auto;
            }
          }
        }
      }
      &.active {
        background: ${PRIMARY_COLOR};
        color: #fff;
        .header-primary,
        .sub-header-primary,
        .text-grey {
          color: #fff;
        }
      }
    }
    .search-list {
      .MuiGrid-container {
        .MuiGrid-grid-xs-4 {
          .view-profile-card {
            margin-top: 39px;
            @media (max-width: 1366px) {
              margin-top: 0;
            }
            .card-content {
              max-width: 280px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .f-search-wrapper {
    margin-top: -15px;
    margin-bottom: 38px;
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }
    .filter-item {
      label {
        margin: 6px 10px 0 0;
      }
    }
    + .MuiGrid-container {
      > .MuiGrid-grid-xs-3 {
        @media (max-width: 1366px) {
          max-width: 30%;
          flex-basis: 30%;
        }
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      > .MuiGrid-grid-xs-9 {
        @media (max-width: 1366px) {
          max-width: 70%;
          flex-basis: 70%;
        }
        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;
        }
        .MuiGrid-container {
          .MuiGrid-grid-xs-8 {
            @media (max-width: 600px) {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
          .MuiGrid-grid-xs-4 {
            @media (max-width: 600px) {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
  .MuiGrid-container {
    > .MuiGrid-grid-xs-3 {
      @media (max-width: 1366px) {
        max-width: 35%;
        flex-basis: 35%;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      .view-profile-card {
        margin-top: 34px;
        @media (max-width: 991px) {
          margin-top: 0px;
          margin-bottom: 0;
        }
        .card-content {
          max-width: 280px;
          margin: 0 auto;
        }
      }
    }
    > .MuiGrid-grid-xs-9 {
      @media (max-width: 1366px) {
        max-width: 65%;
        flex-basis: 65%;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      .search-list {
        > .MuiGrid-container {
          .MuiGrid-grid-xs-8 {
            @media (max-width: 1366px) {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
          .MuiGrid-grid-xs-4 {
            @media (max-width: 1366px) {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
  .meet-time-wrapper {
    padding: 0;
    margin-top: 10px;
    overflow: visible;
    .card-content {
      padding: 25px;
      form {
        max-width: 238px;
        margin: 0 auto;
        .d-flex {
          > div {
            width: 100%;
            .timezoneDropdown {
              width: 100%;
              margin: 0;
            }
          }
        }
        + div {
          max-width: 238px;
          margin: 0 auto;
        }
      }
    }
  }
  .search-list-card {
    margin: 0 0 20px 0;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    &.active {
      background-color: ${PRIMARY_COLOR};
      .content {
        .text-grey,
        .header-primary {
          color: #fff;
        }
      }
    }
  }
  .sub-title {
    font-size: 20px;
    color: ${PRIMARY_COLOR};
  }
`;
