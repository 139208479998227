import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentType: null,
  meetingPurposes: [],
};

export const meetReducer = createSlice({
  name: "meet",
  initialState,
  reducers: {
    setAppointmentType: (state, action) => {
      state.appointmentType = action.payload;
    },
    setMeetingPurposes: (state, action) => {
      state.meetingPurposes = action.payload;
    },
  },
});

export const { setAppointmentType, setMeetingPurposes } = meetReducer.actions;

export default meetReducer.reducer;
