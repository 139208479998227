import React, { useState, useLayoutEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { toastify } from "../../helper/helper";
import { useQueryParams } from "../../hooks/useQueryParams";
import { verifySecondaryEmail } from "../../services/profile.service";
import { Spinner } from "../ProfileModule/Spinner/Spinner";
import { addAccessibilityClass } from "../../helper/helper";
import { routes } from "../../routes";
import MainLayout from "../Layouts/MainLayout/MainLayout";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

export const VerifySecondaryEmail = () => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const authToken = localStorage.getItem("authToken");
  const email = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");
  const keepSignedIn = localStorage.getItem("keepSignedIn");
  const isLogin = sessionStorage.getItem("isLogin");
  const authExpire = localStorage.getItem("authTokenExpiration");
  const authExpiration = moment(authExpire).unix();
  const currDate = moment().add(5, "minutes").unix();
  const query = useQueryParams();

  const isUserLoggedIn = useMemo(() => {
    if (
      userId &&
      email &&
      authToken &&
      (isLogin === "1" || keepSignedIn === "1" || currDate <= authExpiration)
    ) {
      return true;
    }
    return false;
  }, [userId, email, authToken, isLogin, keepSignedIn]);

  const onClick = () => {
    navigate(isUserLoggedIn ? routes.PROFILE : routes.LOGIN);
  };
  const doVerifyEmail = async (email) => {
    setVerifying(true);
    setVerified(false);
    try {
      const response = await verifySecondaryEmail(email);
      if (response?.success) {
        setVerified(true);
        setVerifying(false);
        if (isUserLoggedIn) {
          navigate(isUserLoggedIn ? routes.DASHBOARD : routes.LOGIN);
          toastify("error", "Secondary email verified successfully");
        }
      }
    } catch (error) {
      const { data } = error;
      if (!data?.success) {
        toastify("error", data?.message);
      }
      setVerified(false);
      setVerifying(false);
    }
  };

  useLayoutEffect(() => {
    if (query.has("email")) {
      const email = decodeURIComponent(query.get("email"));
      if (!document) return;
      doVerifyEmail(email);
    }
  }, [query]);

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__authlayout";
    addAccessibilityClass();
  }, []);

  const Content = () => {
    return (
      <div className="secondary-email-container">
        <div>
          {verifying ? (
            <div>
              <Spinner />
              <br />
            </div>
          ) : (
            <div>
              {verified ? (
                <>
                  <h3>Email verification successful</h3>
                  {
                    <ButtonCustom onClick={onClick}>
                      {!isUserLoggedIn ? "Login" : "Go to profile"}
                    </ButtonCustom>
                  }
                </>
              ) : (
                <>
                  <h3>Email verification failed</h3>
                  {
                    <ButtonCustom onClick={onClick}>
                      {!isUserLoggedIn ? "Login" : "Go to profile"}
                    </ButtonCustom>
                  }
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  return isUserLoggedIn ? (
    <MainLayout title="Email Verification" viewOnlyNavbar={true}>
      <Content />
    </MainLayout>
  ) : (
    <Content />
  );
};
