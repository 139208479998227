import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .export-dropdown {
    button {
      background: #f1f4f7;
      width: 35px;
      height: 35px;
      box-shadow: none;
    }
    ul.submenu {
      top: auto;
      right: auto;
    }
  }
`;
