import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getBootProfileAPI = async (userId) => {
  return axiosInstance
    .get(`/boost_profiles/${userId}`, headers_with_token())
    .then((res) => {
      // console.log('boost data =>', res.data.data.boost_profile)
      return res.data.data;
    });
};
