import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DashboardPostSliderStyles from "./DashboardPostSliderStyles";

const DashboardPostSlider = ({
  files = [],
  isClickable,
  handleClick = () => {},
}) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  let settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: accessibilityHelp === "true" ? false : true,
    autoplaySpeed: 3000,
  };

  return (
    <DashboardPostSliderStyles
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={!isClickable ? { cursor: "not-allowed" } : {}}
    >
      <Slider {...settings}>
        {files.map((file, index) => (
          <div
            className={`uploaded-preview-wrapper ${
              !isClickable ? "pointer-none" : ""
            }`}
            key={`dashboard-post-preview-${file.id ?? index}`}
          >
            {file?.file_content_type?.includes("image") ? (
              <div className="image-preview-div">
                <img
                  className="slide-preview-image"
                  src={file.file_url}
                  key={file.file_url}
                  alt="preview"
                  onClick={handleClick}
                />
              </div>
            ) : file?.file_content_type?.includes("video") ? (
              <div className="video-preview-div">
                <video
                  className="slide-remove-video"
                  src={file.file_url}
                  controls
                  poster={file.thumb_file_url}
                  onClick={handleClick}
                />
              </div>
            ) : null}
          </div>
        ))}
      </Slider>
    </DashboardPostSliderStyles>
  );
};

export default DashboardPostSlider;
