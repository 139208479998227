import React, { useEffect, useState } from "react";

import ButtonCustom from "../ButtonCustom/ButtonCustom";
import CustomDialog from "../CustomDialog/CustomDialog";
import { config } from "../../constant/config";
import { versionInfoAPI } from "../../services/authenticationServices";
import { compareVersions, clearCacheData } from "../../helper/helper";

export const AppVersionManager = () => {
  const [showVersionDialog, setShowVersionDialog] = useState(false);
  const [versionInfo, setVersionInfo] = useState({});

  const getVersionInfo = async () => {
    const response = await versionInfoAPI();

    if (response.success && response.data) {
      const versionCheckInterval = config().VERSION_CHECK_INTERVAL ?? 180000;

      setTimeout(() => {
        getVersionInfo();
      }, versionCheckInterval);

      const latestVersionData = response.data.find(
        (version) => version.portal === "student"
      );

      if (latestVersionData) {
        const latestAppVersion = latestVersionData.version;
        setVersionInfo({ ...latestVersionData });

        const prevAppVersion = localStorage.getItem("app_version");
        localStorage.setItem("app_version", latestAppVersion);

        if (!latestAppVersion || !prevAppVersion) return;

        const versionCheck = compareVersions(prevAppVersion, latestAppVersion);

        // Show dialog for any type of version upgrade
        if (["major", "minor", "patch"].includes(versionCheck)) {
          setShowVersionDialog(true);
        }
      }
    }
  };

  useEffect(() => {
    getVersionInfo();
  }, []);

  const onReload = () => {
    clearCacheData();
    setShowVersionDialog(false);
    if (versionInfo.button_link) {
      window.open(versionInfo.button_link, "_blank");
    } else {
      window.location.reload();
    }
  };

  return (
    <CustomDialog
      open={showVersionDialog}
      title={versionInfo.title}
      className="app-version-dialog"
      dialogTitleClass="modal-title"
      isDisplayCloseButton={false}
      dialogSubTitleClass="text-para"
    >
      <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
        <div
          className="version-content"
          dangerouslySetInnerHTML={{ __html: versionInfo.message }}
        />
        <ButtonCustom type="submit" onClick={onReload} keepLabelCase={true}>
          {versionInfo.button_title}
        </ButtonCustom>
      </div>
    </CustomDialog>
  );
};
