import { useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  countries: [],
  countryDetails: {}
};

const useCountryInsight = appState => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const setCounties = data => {
    setState({
      countries: data
    });
  };

  const setCountriesDetails = data => {
    setState({
      countryDetails: data
    });
  };

  // const updateStatusList = (updateIndexNumber) => {
  //   let newStatusList = Object.assign([], state.statusList);
  //   newStatusList[updateIndexNumber].isCompleted = true;
  //   setState({ statusList: newStatusList });
  //   let total = _size(_filter(state.statusList, { isCompleted: true }));
  //   setState({ totalCompletedStatus: total });
  // };

  return {
    setCounties,
    setCountriesDetails,
    ...state
  };
};

export default useCountryInsight;
