import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .network-tab-content-wrapper {
    position: relative;
    padding-bottom: 185px;
    .widget-network-tab {
      max-width: 670px;
      margin: 0 auto;
      .title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        margin: 0 0 24px 0;
        padding: 5px 0 0 0;
        @media (max-width: 906px) {
          font-size: 22px;
          line-height: 22px;
          margin: 0 0 16px 0;
        }
      }
      .network-list-wrapper {
        .info-panel {
          .info-panel-card {
            padding: 15px 30px 15px 15px;
            @media (max-width: 600px) {
              padding: 15px;
              margin-bottom: 10px;
            }
            margin-bottom: 15px;
            .card-content {
              @media (max-width: 600px) {
                flex-wrap: wrap;
                align-items: flex-start;
              }
              .panel-header {
                @media (max-width: 600px) {
                  flex-wrap: wrap;
                  padding-right: 0;
                }
                .panel-title {
                  padding: 0;
                  &:first-child {
                    @media (max-width: 600px) {
                      flex-wrap: wrap;
                    }
                    > div {
                      &:first-child {
                        color: var(--primary-dark-color);
                        font-family: "TTCommons-Medium";
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 17px;
                        padding: 3px 0 0 0;
                        @media (max-width: 600px) {
                          width: 100%;
                        }
                      }
                      &.tags {
                        @media (max-width: 600px) {
                          margin-left: 0;
                          margin-top: 4px;
                        }
                        .tag-item {
                          span {
                            line-height: 28px;
                          }
                        }
                      }
                    }
                  }
                  .panel-sub-title {
                    margin: 4px 0 6px 0;
                    padding: 2px 0 0 0;
                  }
                }
              }
              .panel-actions--discover {
                @media (max-width: 600px) {
                  margin-top: 6px;
                }
                .panel-actions--discover__action {
                  button {
                    @media (max-width: 600px) {
                      height: 28px;
                      min-height: auto;
                      padding: 6px 8px 3px;
                    }
                  }
                }
                .panel-actions--discover__star {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;
