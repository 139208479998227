import styled from "styled-components";
import { BADGE_BACKGROUND_COLOR, PRIMARY_COLOR } from "../../styles/variables";
export default styled.div.attrs({ className: "" })`
  .badge--default {
    background: ${BADGE_BACKGROUND_COLOR};
    border-radius: 10.5px;
    background-color: ${BADGE_BACKGROUND_COLOR};
    color: ${PRIMARY_COLOR};
    font-family: "TTCommons-Regular";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    display: inline-block;
    padding: 3px 8px 0px;
    margin: -3px 0 0 10px;
    white-space: nowrap;
  }
`;
