import styled from "styled-components";
import { Button } from "@mui/material";
import {
  ACTIVE_BTN_BLUE_COLOR,
  INACTIVE_BTN_BLUE_COLOR,
  ACTIVE_BTN_HOVER_BLUE_COLOR,
  INACTIVE_BTN_GRAY_COLOR,
  SECONDARY_COLOR,
} from "../../styles/variables";

export default styled(Button)`
  width: ${(props) =>
    props.width.toString().includes("%") ? props.width : `${props.width}px`};
  height: ${(props) => props.height}px;
  border-radius: ${(props) => parseInt(props.height) / 2}px;
  font-family: "TTCommons-Medium";
  font-size: ${(props) => props.fontSize}px;
  text-transform: ${(props) => (props.keepLabelCase ? "none" : "lowercase")};
  padding: 3px 0 0 0;
  box-shadow: none !important;
  overflow: hidden;
  cursor: ${(props) => (props.removeCursor ? "default" : "pointer")};
  background: ${(props) =>
    props.color === "secondary" ? SECONDARY_COLOR : ""};

  .content::first-letter {
    text-transform: ${(props) => (props.keepLabelCase ? "none" : "uppercase")};
  }
  .job-apply-dialog::first-letter {
    text-transform: none;
  }
  &:disabled {
    background: ${(props) =>
      props.color === "secondary" ? SECONDARY_COLOR : ""};
    opacity: 0.5;
    color: #fff !important;
    //  box-shadow: none !important;  --Focus style
  }

  &:hover {
    background: ${(props) =>
      props.color === "secondary" ? "var(--secondary-color)" : ""};
    // opacity: 0.9;
  }
  &:focus {
    background: ${(props) =>
      props.color === "secondary" ? SECONDARY_COLOR : ""};
    opacity: 0.9;
    // box-shadow: 0 0 0px 2px #000485, inset 0 0 10px rgba(0, 0, 0, 0.3) !important; --Focus style
  }

  &.bg-light-blue {
    background: ${ACTIVE_BTN_BLUE_COLOR};
    &:hover {
      background: ${ACTIVE_BTN_HOVER_BLUE_COLOR};
    }
    &:disabled {
      background: ${INACTIVE_BTN_BLUE_COLOR};
      color: #ffff;
    }
  }

  &.bg-gray {
    background: var(--f-light-gray-color);
    &:hover {
      background: var(--gray-color-btn-hover);
    }
    &:disabled {
      background: ${INACTIVE_BTN_GRAY_COLOR};
      color: #ffff;
    }
  }

  &.bg-widget {
    background: var(--widget-theme-color);
    &:focus {
      background: var(--widget-theme-color);
    }
  }

  &.bg-blue {
    background: #00043c;
    &:focus {
      background: #00043c;
    }
  }

  &.bg-white {
    background: var(--white-color);
    color: #000c3d;
    box-shadow: var(--card-box-shadow) !important;
    text-transform: initial;
    padding: 4px 10px 0 10px;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: auto;
      img {
        margin: -4px 0 0 4px;
      }
    }
    &:focus {
      background: var(--white-color);
      color: #000c3d;
    }
    // &--widget {
    //   color: var(--widget-theme-color);
    //   &:focus {
    //     color: var(--widget-theme-color);
    //   }
    // }
  }
  &.ml-10 {
    margin-left: 10px;
  }
  .content {
    position: relative;
    min-width: 100px;
    .button-spin {
      position: inherit;
      display: inline-block;
      vertical-align: middle;
      transform: none;
      right: auto !important;
      margin: 0 0 0 10px;
    }
  }
  .icon {
    float: left;
    margin-right: 15px;
    color: #1877f2;
  }

  ${(props) => (props.keepLabelCase ? `text-transform:none !important` : "")}
`;
