import React, { useState } from "react";
import styles from "./Career.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import InformationIcon from "../../../assets/svg/InformationIcon";
import { CareerIntro } from "./CareerIntro/CareerIntro";
import { CareerVisa } from "./CareerVisa/CareerVisa";
import { CareerInformation } from "./CareerInformation/CareerInformaion";
import { ProfileCareer } from "./index";
import ProfilePopup from "../ProfilePopup/ProfilePopup";

const cx = createModuleStyleExtractor(styles);

export const Career = () => {
  const [element, setElement] = useState(null);
  const handleClick = (e) => {
    try {
      setElement(e.currentTarget);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setElement(null);
  };
  return (
    <ProfileCareer>
      {({ career, student_view_preference = "international" }) => (
        <>
          <ProfilePopup
            open={Boolean(element)}
            onClose={handleClose}
            anchorEl={element}
            text="Knowing your immigration status helps us connect you to companies that actively hire international candidates in their hiring process. We will never share this data with third parties without your consent."
          />

          <div className={cx(["o-career-container"])}>
            <h1 className={cx("o-career-container__top-heading")}>Career</h1>
            <CoverHeader>
              <CareerIntro data={career} />
            </CoverHeader>
          </div>

          <div className={cx(["o-career-information"])}>
            <h1 className={cx("o-career-information__top-heading")}>
              Career information
            </h1>
            <CoverHeader>
              <CareerInformation data={career} />
            </CoverHeader>
          </div>

          {student_view_preference === "international" && (
            <div className={cx(["o-career-visa-status"])}>
              <h1 className={cx("o-career-visa-status__top-heading")}>
                Visa Status
                <span>
                  <InformationIcon onClick={handleClick} />
                </span>
              </h1>
              <CoverHeader>
                <CareerVisa data={career} />
              </CoverHeader>
            </div>
          )}
        </>
      )}
    </ProfileCareer>
  );
};
