import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .textbox-label {
    width: ${(props) => props.width};
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 3px;
    font-size: 15px;
    color: var(--field-label-color);
  }
  margin-bottom: 25px;
  .MuiFormHelperText-root.Mui-error {
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    margin: 5px 8px 0px 8px;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    > div:first-child {
      display: none;
    }
  }
  .css-xb97g8:hover {
    background-color: transparent;
    color: inherit;
  }
`;
