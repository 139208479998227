import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .empty-chat-view {
    min-height: calc(100vh - 250px);
    padding: 10px;
    margin-left: 10px;
    background-color: #fff;
    box-shadow: var(--card-box-shadow);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h4 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      margin: 30px 0 30px 0;
      padding-top: 4px;
    }
    p {
      color: var(--gray-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      max-width: 670px;
      margin: 24px auto 30px;
    }
  }
  @media screen and (max-width: 786px) {
    .empty-chat-view {
      width: 100%;
      height: 100%;
      margin-left: 0;
      margin-top: 10px;
      padding: 0px;
      img {
        width: 250px;
      }
      p {
        width: 90%;
      }
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;
