import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .filled-star {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .border-star {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`;
