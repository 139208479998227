import { useEffect } from "react";
import { useAppState } from "../context";
import { fetchDiscoverNetworkFilters } from "../services/chat.service";
export const useInitChat = () => {
  const { setNetworkFilters, networkFilters } = useAppState("chat");
  const load = async () => {
    const [filters] = await Promise.all([fetchDiscoverNetworkFilters()]);
    setNetworkFilters(filters.data);
  };
  useEffect(() => {
    load();
  }, []);

  return {
    networkFilters,
  };
};
