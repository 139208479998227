import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CustomCalendarDemoStyles from "./CustomCalendarDemoStyles";
import CardCustom from "../CardCustom/CardCustom";
import moment from "moment";
import RSS from "../../assets/png/handshake_rss_logo.png";
import { isUndefined } from "lodash";

const CustomCalendarDemo = ({
  events,
  handleEventClick,
  selectedEvent,
  setSelectedMonths,
}) => {
  useEffect(() => {
    //Added dynamic area labels for month navigation icons for accessibility - TBS
    const nextButtons = document.getElementsByClassName("fc-next-button");
    const prevButtons = document.getElementsByClassName("fc-prev-button");
    if (nextButtons && nextButtons.length > 0)
      nextButtons[0].setAttribute("aria-label", "Next Month");
    if (prevButtons && prevButtons.length > 0)
      prevButtons[0].setAttribute("aria-label", "Previous Month");
  }, []);

  const renderEventContent = ({ event }) => {
    let startTime = moment
      .unix(event.extendedProps?.epoch_time)
      .local()
      .format("hh:mm a");
    let eventClass =
      event.extendedProps.type === "upcoming" ? "upcoming-event" : "past-event";
    if (selectedEvent !== null && event.id === selectedEvent.toString())
      eventClass = "selected-event";
    return (
      <div className={eventClass}>
        {event.extendedProps.rss_feed_id &&
        event.extendedProps.rss_feed_id != null ? (
          <img src={RSS} className="custom-rss-icon" alt="RSS Icon" />
        ) : (
          ""
        )}
        {!isUndefined(event.extendedProps.epoch_time) ? (
          <span>
            <strong>{startTime}</strong> {" - "} {event.title}
          </span>
        ) : (
          <span>{event.title}</span>
        )}
      </div>
    );
  };

  return (
    <CustomCalendarDemoStyles>
      <CardCustom className="calendar-card">
        <FullCalendar
          viewClassNames="view-header"
          dayHeaderClassNames="cal-day-header"
          weekNumberClassNames="cal-week-number"
          eventClassNames="cal-event"
          plugins={[interactionPlugin, dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={events}
          headerToolbar={{
            left: "",
            center: "prev,title,next",
            right: "",
          }}
          dayHeaderFormat={({ date }) => moment(date).format("dddd")}
          droppable={true}
          editable={true}
          eventStartEditable={true}
          dayMaxEvents={1}
          eventContent={renderEventContent}
          eventClick={(info) => {
            handleEventClick(info);
          }}
          eventOrder={"date, start"}
          datesSet={(arg) => {
            if (!isUndefined(setSelectedMonths)) {
              const startDate = arg.start;
              let smonth = startDate.getUTCMonth() + 1;
              let syear = startDate.getUTCFullYear();
              let nextMonth = smonth === 12 ? 1 : smonth + 1;
              let nextYear = smonth === 12 ? syear + 1 : syear;
              let monthArray = { month: nextMonth, year: nextYear };
              setSelectedMonths(monthArray);
            }
          }}
          showNonCurrentDates={true}
        />
      </CardCustom>
    </CustomCalendarDemoStyles>
  );
};

export default CustomCalendarDemo;
