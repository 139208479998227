import mixpanel from "mixpanel-browser";
import { config } from "./constant/config";

const MIX_PANEL_PROJECT_KEY = config().MIX_PANEL_PROJECT_KEY;

mixpanel.init(MIX_PANEL_PROJECT_KEY);

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
