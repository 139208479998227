import React from "react";

import ButtonCustom from "./components/ButtonCustom/ButtonCustom";
import errorimage from "./assets/svg/error404.svg";

const ErrorWrapper = ({ clearError }) => {
  return (
    <div className="error-wrapper">
      <h2>
        Woops!
        <br />
        Looks like something went wrong.
      </h2>
      <div className="img-wrap">
        <img src={errorimage} alt="error" />
      </div>
      <h3>We’ll point you back to where you were.</h3>
      <ButtonCustom
        width={238}
        height={50}
        onClick={() => {
          if (clearError) clearError();
          else window.location.reload();
        }}
      >
        Back to previous
      </ButtonCustom>
    </div>
  );
};

export default ErrorWrapper;
