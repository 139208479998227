import React from "react";

function Icon({ onClick, color }) {
  const customColor = color ? color : "#000C3D";
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ cursor: "pointer" }}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" stroke={customColor}>
          <path d="M10 4c-4.09 0-7.585 2.488-9 6 1.415 3.512 4.91 6 9 6s7.585-2.488 9-6c-1.415-3.512-4.91-6-9-6zm0 10c-2.258 0-4.09-1.792-4.09-4S7.741 6 10 6s4.09 1.792 4.09 4-1.832 4-4.09 4zm0-6.4c-1.358 0-2.455 1.072-2.455 2.4 0 1.328 1.097 2.4 2.455 2.4s2.455-1.072 2.455-2.4c0-1.328-1.097-2.4-2.455-2.4z"></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
