import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _get from "lodash/get";

import CalendarLeftSidebarStyles from "../Calendar/CalendarLeftSidebar/CalendarLeftSidebarStyles";
import { useAppState } from "../../../context";
import { routes } from "../../../routes";
import CardCustom from "../../CardCustom/CardCustom";
import EventCard from "../../EventCard/EventCard";

const EventList = ({ events = [] }) => {
  const navigate = useNavigate();
  const { setSelectedEvent } = useAppState("tools");

  const EmptyEventsList = () => {
    return (
      <CardCustom className="empty-events-box">
        <div className="empty-item">
          <h3 className="header-primary">
            You don’t have any upcoming appointments!
          </h3>
          <div className="description">
            You can create a new appointment by going to Meet and clicking Book
            appointment.
          </div>
        </div>
      </CardCustom>
    );
  };

  return (
    <CalendarLeftSidebarStyles>
      {!events || events.length < 1 ? (
        <EmptyEventsList />
      ) : (
        events.map((item, i) => {
          let eventDate = "";
          if (typeof item.date !== "undefined") {
            eventDate = moment(item.date).format("MMM DD, YYYY");
          } else {
            eventDate = moment(item.appointment_date_mobile).format(
              "MMM DD, YYYY"
            );
          }

          return (
            <EventCard
              key={i}
              title={_get(item, "title", "")}
              subTitle={eventDate}
              duration={`${_get(item, "start_time", "")} - ${_get(
                item,
                "end_time",
                ""
              )}`}
              actionName="View details"
              onCardClick={() => setSelectedEvent({ ...item })}
              navigateToCalender={true}
              openCalender={() => navigate(routes.TOOLS.CALENDAR)}
            />
          );
        })
      )}
    </CalendarLeftSidebarStyles>
  );
};

export default EventList;
