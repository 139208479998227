import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .resource-list {
    .saved-resource-loading-item {
      margin-bottom: 10px;
    }
    display: block;
    .MuiCardContent-root {
      padding: 0;
    }
    .resouces-cards {
      padding: 30px 20px;
      .header-primary {
        margin: 0 0 17px 0;
        min-height: 38px;
        display: flex;
        span {
          display: inline-block;
          max-width: 350px;
        }
        svg {
          width: 18px;
          height: 18px;
          float: right;
          margin-left: auto;
        }
        .star {
          margin: -2px 0 0 8px;
        }
      }
      .sub-header-primary {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin: 0 0 7px 0;
      }
      .content {
        display: flex;
        justify-content: space-between;
        .text-grey {
          display: flex;
          svg {
            width: 15px;
            height: 15px;
            margin: -2px 0 0 0;
          }
        }
      }
    }

    .empty-item {
      text-align: center;
      padding: 40px 25px;
      .header-primary {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 0 auto 8px;
        max-width: 325px;
      }
      .description {
        color: var(--gray-text-color);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        max-width: 325px;
        margin: 0 auto;
      }
    }
  }
`;
