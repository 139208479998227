import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import LearnDashboard from "./LearnDashboard/LearnDashboard";
import Webinars from "./Webinars/Webinars";
import Container from "../../components/Container/Container";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import { routes } from "../../routes";
import Courses from "./Courses/Courses";
import Resources from "./ResourcesV2/Resources";
import StudentServices from "./StudentServices/StudentServices";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../utils/common";

import coursesFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Courses.svg";
import resourceFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Resources.svg";
import studentFocusServiceIcon from "../../assets/FocusIcons/MenuIcons/Learn/StudentServices.svg";
import webinarsFocusIcon from "../../assets/FocusIcons/MenuIcons/Learn/Webinars.svg";
import CoursesIcon from "../../assets/svg/Courses.svg";
import resourceIcon from "../../assets/svg/Resources.svg";

const Learn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions = {}, users = {} } = useSelector(
    (store) => store.userStore
  );

  useEffect(() => {
    if (isEmpty(permissions)) return;

    if (
      (location.pathname.includes(routes.LEARN.WEBINARS) &&
        !(
          permissions["webinar"] &&
          permissions["pastOrUpcomingWebinarsAvailable"]
        )) ||
      (location.pathname.includes(routes.LEARN.COURSES) &&
        !(
          permissions["courses"] &&
          users?.student_view_preference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
        )) ||
      (location.pathname.includes(routes.LEARN.RESOURCES) &&
        !permissions["resources"]) ||
      (location.pathname.includes(routes.LEARN.STUDENT_SERVICES) &&
        !(
          permissions["student_services"] &&
          users?.student_view_preference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
        ))
    )
      navigate(routes.DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const tabs = [
    ...(permissions["webinar"] && permissions["pastOrUpcomingWebinarsAvailable"]
      ? [
          {
            title: "Webinars",
            toolTip: "Register for live webinars and watch past recordings",
            avatar: webinarsFocusIcon,
            focusAvatar: webinarsFocusIcon,
            navigateURL: routes.LEARN.WEBINARS,
            isActive: location.pathname === routes.LEARN.WEBINARS,
          },
        ]
      : []),
    ...(permissions["courses"] &&
    users?.student_view_preference ===
      STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "Courses",
            toolTip: "Essential courses",
            avatar: CoursesIcon,
            focusAvatar: coursesFocusIcon,
            navigateURL: routes.LEARN.COURSES,
            isActive: location.pathname === routes.LEARN.COURSES,
          },
        ]
      : []),
    ...(permissions["resources"]
      ? [
          {
            title: "Resources",
            toolTip:
              "Resource library of career readiness and immigration resources",
            avatar: resourceIcon,
            focusAvatar: resourceFocusIcon,
            navigateURL: routes.LEARN.RESOURCES,
            isActive: location.pathname === routes.LEARN.RESOURCES,
          },
        ]
      : []),
    ...(permissions["student_services"] &&
    users?.student_view_preference ===
      STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "Student Services",
            toolTip: "Essential services for your life in the U.S.",
            avatar: studentFocusServiceIcon,
            focusAvatar: studentFocusServiceIcon,
            navigateURL: routes.LEARN.STUDENT_SERVICES,
            isActive: location.pathname === routes.LEARN.STUDENT_SERVICES,
          },
        ]
      : []),
  ];

  return (
    <MainLayout
      title="Learn Dashboard"
      withSubHeader={true}
      tabs={tabs}
      location={location}
      viewOnlyNavbar
    >
      <Container>
        <Routes>
          <Route path="/" element={<LearnDashboard />} />

          {permissions["webinar"] &&
            permissions["pastOrUpcomingWebinarsAvailable"] && (
              <>
                <Route path="/webinars" element={<Webinars />} />
                <Route path="/webinars/live" element={<Webinars />} />
                <Route path="/webinars/on-demand" element={<Webinars />} />
                <Route path="/webinars/registered" element={<Webinars />} />
              </>
            )}

          {permissions["resources"] && (
            <Route path="/resources" element={<Resources />} />
          )}

          {permissions["student_services"] && (
            <Route path="/student-services" element={<StudentServices />} />
          )}

          <Route
            path="/courses"
            element={<Courses permissions={permissions} />}
          />
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default Learn;
