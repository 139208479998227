import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .recommanded-list {
    display: block;
    .recommand-item {
      display: flex;
      padding: 0px;
      justify-content: space-between;
      align-items: center;
      .header-primary {
        max-width: 100%;
      }
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        .nlink-btn {
          width: auto;
          min-width: 80px;
          white-space: nowrap;
        }
        div {
          + div {
            margin-left: 12px;
            @media (max-width: 991px) {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .card-wrapper {
      height: 100%;
      .MuiPaper-root {
        padding: 19px 25px 17px;
        min-height: 75px;
        height: 100%;
        display: flex;
        align-items: center;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          .recommand-item {
            .card-actions-wrapper {
              opacity: 1;
              visibility: visible;
              transition: all 0.3s;
            }
          }
        }
        .MuiCardContent-root {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
`;
