import React from "react";

import { widgetRedirection } from "../../../utils/common";
import WidgetOverlayStyles from "./WidgetOverlayStyles";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";

import CloseIcon from "../../../assets/svg/close.svg";

const WidgetOverlay = ({
  title = "",
  closeOption = false,
  closeOverlay = () => {},
  route_type,
}) => {
  const handleLoginClick = () => {
    widgetRedirection(route_type);
  };

  return (
    <WidgetOverlayStyles>
      <div className="widget-overlay">
        {closeOption && (
          <span className="close">
            <img src={CloseIcon} alt="close" onClick={closeOverlay} />
          </span>
        )}
        <h2 className="title">{title}</h2>
        <ButtonCustom
          className={"bg-widget"}
          onClick={handleLoginClick}
          aria-label={`Log in`}
        >
          Log in
        </ButtonCustom>
      </div>
    </WidgetOverlayStyles>
  );
};

export default WidgetOverlay;
