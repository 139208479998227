import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .title-boxs {
    align-items: center;
  }
  .discover-network-wrapper {
    .collapsible-view {
      .title-primary {
        .ngray-link {
          margin-left: 12px;
        }
      }
    }
    .search-card {
      button {
        margin: 20px 0 0 0;
        width: 100%;
        background-color: var(--link-color) !important;
      }
    }
    .title-boxs {
      align-items: flex-start;
    }
    .sort-by {
      margin-bottom: 5px;
      top: -4px;
      position: relative;
    }
    #detail-panel {
      > div {
        + div {
          .savedNetwork {
            margin-top: 16px;
          }
        }
      }
    }
    .savedNetwork {
      cursor: pointer;
      border-radius: 25px;
      box-shadow: var(--card-box-shadow);
      padding: 12px 20px 8px;
      text-decoration: none;
      width: 100%;
      margin: 40px 0 0 0;
      @media (max-width: 1366px) {
        margin: 0px 0 0 0;
      }
      .section-content {
        .icon {
          margin: -2px 10px 0 0;
          display: flex;
          align-items: center;
        }
        .text {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 20px;
          margin: 0;
          span {
            margin-left: 3px;
          }
        }
      }
      .section-action {
        img {
          margin-top: 4px;
        }
      }
    }
  }
`;
