import React, { memo, useEffect, useState } from "react";
import {
  ShimmerTitle,
  ShimmerCircularImage,
  ShimmerSectionHeader,
} from "react-shimmer-effects";

import ReceiverProfileStyles from "./ReceiverProfileStyles";
import ChatActionsGroup from "../ChatActionsGroup/ChatActionsGroup";
import { useAppState } from "../../../../context";
import StudentProfile from "../../../StudentProfile/StudentProfile";
import { fetchUserProfileById } from "../../../../services/profile.service";

const ReceiverProfile = () => {
  const [profileLoader, setProfileLoader] = useState(true);
  const [userProfile, setUserProfile] = useState({});

  const { getConversationByConversationID } = useAppState("chat");
  const { user_info } = getConversationByConversationID() || {};
  const user_id = user_info?.id;

  useEffect(() => {
    if (!user_id) return;

    // Call API and fetch user profile
    (async () => {
      setProfileLoader(true);
      try {
        const response = await fetchUserProfileById(user_id);
        if (response?.success) {
          setUserProfile(response?.data?.user_info[0]);
        } else {
          setUserProfile(404);
        }
        setProfileLoader(false);
      } catch (error) {
        setUserProfile(404);
        setProfileLoader(false);
        console.error(error);
      }
    })();
  }, [user_id]);

  return (
    <ReceiverProfileStyles>
      <div className="user-profile__container">
        {profileLoader ? (
          <div className="profile-shimmer">
            <ShimmerCircularImage size={90} />
            <ShimmerTitle
              line={1}
              variant={"primary"}
              className={"profile-shimmer__title"}
            />
            <ShimmerSectionHeader center={true} />
          </div>
        ) : !profileLoader && userProfile === 404 ? (
          <div className="user-profile__container__profile">
            <h3 className="user-profile__container__profile__not-available">
              User profile currently unavailable
            </h3>
          </div>
        ) : (
          <div className="user-profile__container__profile">
            <div className="user-profile__container__profile__actions">
              <ChatActionsGroup />
            </div>
            <StudentProfile user={userProfile} />
          </div>
        )}
      </div>
    </ReceiverProfileStyles>
  );
};
export default memo(ReceiverProfile);
