import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .score-card {
    .card-content {
      padding: 0;
      .score-chart {
        position: relative;
        padding: 30px;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 35px;
          line-height: 38px;
          font-family: "TTCommons-DemiBold";
          color:var(--primary-main-color);
          margin-top: 3px;
        }

      }
    }

    .score-list {
      margin: 0;
      padding: 0;
      list-style: none;
      padding:0 20px 30px 20px;
      li {
          display: flex;
          align-items: center;
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          font-family: "TTCommons-Medium";
          text-decoration: none;
          line-height: 19px;
          > div{
            font-family: "TTCommons-DemiBold";
            color: var(--primary-dark-color);
          }
          + li{
            margin-top:20px;
          }
        }
      }
    }
  }
`;
