import React, { memo } from "react";

function WidgetWebinars() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke="#000C3D" stroke-width="1.2">
          <path
            d="M16.4328852,17 L6.56711483,17 C5.97787805,17 5.5,16.5221045 5.5,15.9328462 L5.5,5.92510047 C5.5,5.41651443 5.91649921,5 6.4255051,5 L16.5744949,5 C17.0830624,5 17.5,5.41651443 17.5,5.92510047 L17.5,15.9328462 C17.5,16.5221045 17.022122,17 16.4328852,17 Z"
            id="Stroke-1"
          ></path>
          <path
            d="M16.4328852,32.1575 L6.56711483,32.1575 C5.97787805,32.1575 5.5,31.6796045 5.5,31.0903462 L5.5,21.0826005 C5.5,20.5740144 5.91649921,20.1575 6.4255051,20.1575 L16.5744949,20.1575 C17.0830624,20.1575 17.5,20.5740144 17.5,21.0826005 L17.5,31.0903462 C17.5,31.6796045 17.022122,32.1575 16.4328852,32.1575 Z"
            id="Stroke-1-Copy-2"
          ></path>
          <path
            d="M31.4779589,17 L21.6121886,17 C21.0229518,17 20.5450738,16.5221045 20.5450738,15.9328462 L20.5450738,5.92510047 C20.5450738,5.41651443 20.961573,5 21.4705789,5 L31.6195687,5 C32.1281361,5 32.5450738,5.41651443 32.5450738,5.92510047 L32.5450738,15.9328462 C32.5450738,16.5221045 32.0671957,17 31.4779589,17 Z"
            id="Stroke-1-Copy"
          ></path>
          <path
            d="M31.4779589,32.1575 L21.6121886,32.1575 C21.0229518,32.1575 20.5450738,31.6796045 20.5450738,31.0903462 L20.5450738,21.0826005 C20.5450738,20.5740144 20.961573,20.1575 21.4705789,20.1575 L31.6195687,20.1575 C32.1281361,20.1575 32.5450738,20.5740144 32.5450738,21.0826005 L32.5450738,31.0903462 C32.5450738,31.6796045 32.0671957,32.1575 31.4779589,32.1575 Z"
            id="Stroke-1-Copy-3"
          ></path>
          <path d="M8.78409508,9.1853 L14.2157016,9.1853" id="Stroke-9"></path>
          <path
            d="M8.78409508,12.8147 L14.2157016,12.8147"
            id="Stroke-13"
          ></path>
          <path
            d="M23.6592754,24.0466 L29.090882,24.0466"
            id="Stroke-15"
          ></path>
          <path
            d="M23.6592754,27.6765 L29.090882,27.6765"
            id="Stroke-19"
          ></path>
          <path
            d="M29.3750279,11.000189 C29.3750279,12.6567954 28.0321167,14 26.3750279,14 C24.7183171,14 23.3750279,12.6567954 23.3750279,11.000189 C23.3750279,9.34320459 24.7183171,8 26.3750279,8 C28.0321167,8 29.3750279,9.34320459 29.3750279,11.000189 Z"
            id="Stroke-21"
          ></path>
          <path
            d="M14.5450738,26.157689 C14.5450738,27.8142954 13.2021626,29.1575 11.5450738,29.1575 C9.888363,29.1575 8.54507377,27.8142954 8.54507377,26.157689 C8.54507377,24.5007046 9.888363,23.1575 11.5450738,23.1575 C13.2021626,23.1575 14.5450738,24.5007046 14.5450738,26.157689 Z"
            id="Stroke-21-Copy"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetWebinars);
