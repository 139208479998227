import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getChecklistCategories = () => {
  return axiosInstance
    .get("checklist_categories", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getChecklistByCategoryId = (categoryId) => {
  return axiosInstance
    .get(
      `checklist_categories/checklist_subcategories?checklist_category_id=${categoryId}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getChecklistCategoryWithTask = () => {
  return axiosInstance
    .get(
      `checklist_categories/get_checklist_with_task`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const addTaskAPI = (
  categoryId,
  taskName,
  subCategoryTaskName = "",
  subCategoryTaskLink = ""
) => {
  let requestData = {
    checklist_subcategory: {
      task_name: taskName,
      subcategory_name: subCategoryTaskName,
      subcategory_link: subCategoryTaskLink,
      category_id: categoryId,
    },
  };
  return axiosInstance
    .post("checklist_categories/add_task", requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const addSubTaskAPI = (subcategoryId, title) => {
  let requestData = {
    checklist: {
      title: title,
      subcategory_id: subcategoryId,
    },
  };
  return axiosInstance
    .post("checklist_categories/add_subtask", requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const markTaskAPI = (taskId, isMark = true) => {
  let requestData = {
    user_subchecklist: {
      subcategory_id: taskId,
      is_mark: `${isMark}`,
    },
  };
  return axiosInstance
    .post("checklist_categories/mark_task", requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const markSubTaskAPI = (subTaskId, isMark = true) => {
  let requestData = {
    user_checklist: {
      checklist_id: subTaskId,
      is_mark: `${isMark}`,
    },
  };
  return axiosInstance
    .post(
      "checklist_categories/mark_subtask",
      requestData,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const removeTaskAPI = (taskId) => {
  let requestData = {
    checklist_subcategory: {
      id: taskId,
    },
  };
  let config = { data: requestData, ...headers_with_token() };
  return axiosInstance
    .delete("checklist_categories/remove_task", config)
    .then((res) => {
      return res.data;
    });
};

export const removeSubTaskAPI = (subTaskId) => {
  let requestData = {
    checklist: {
      id: subTaskId,
    },
  };
  let config = { data: requestData, ...headers_with_token() };
  return axiosInstance
    .delete("checklist_categories/remove_subtask", config)
    .then((res) => {
      return res.data;
    });
};

export const editTaskAPI = (
  taskId,
  taskName,
  categoryId,
  subcategory_name = "",
  subcategory_link = ""
) => {
  let requestData = {
    checklist_subcategory: {
      id: taskId,
      task_name: taskName,
      category_id: categoryId,
      subcategory_name,
      subcategory_link,
    },
  };
  return axiosInstance
    .patch(
      "checklist_categories/update_task",
      requestData,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const editSubTaskAPI = (subTaskId, title, taskId) => {
  let requestData = {
    checklist: {
      id: subTaskId,
      title: title,
      subcategory_id: taskId,
    },
  };
  return axiosInstance
    .patch(
      "checklist_categories/update_subtask",
      requestData,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const sortableTaskAPI = (data) => {
  return axiosInstance
    .post("checklist_categories/sortable_subcategory", data, headers_with_token())
    .then((res) => {
      return res.data;
    });
};