import React, { memo } from "react";

function WidgetEmployment() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke="#000C3D" stroke-width="1.2">
          <path
            d="M23.4860908,17.4028653 C28.4554019,19.5169224 32,25.0410718 32,31.5230151 C32,31.5230151 25.3958965,34.4028653 18.4271492,34.4028653 C11.4584019,34.4028653 6,31.5230151 6,31.5230151 C6,25.078504 9.50463163,19.5791878 14.4295732,17.438829 C15.7240464,18.4796097 17.247522,19 19,19 C21.628717,19 23.4860908,17.438829 23.4860908,17.4028653 Z"
            id="Stroke-3"
          ></path>
          <path
            d="M25,10.5 C25,13.8120155 22.3135196,16.5 18.9990309,16.5 C15.6864804,16.5 13,13.8120155 13,10.5 C13,7.18604651 15.6864804,4.5 18.9990309,4.5 C22.3135196,4.5 25,7.18604651 25,10.5 Z"
            id="Stroke-5"
          ></path>
          <polygon
            id="Path-6"
            points="18.0707801 19.3201243 16.5517939 28.9313801 19.0679753 32 21.628717 28.9313801 19.9887318 19.3201243"
          ></polygon>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetEmployment);
