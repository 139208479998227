import React from "react";
import { ImpulseSpinner } from "react-spinners-kit";

import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import styles from "./FooterActions.module.scss";

const cx = createModuleStyleExtractor(styles);

export const FooterActions = ({
  next = true,
  prev = true,
  onNext,
  onPrev,
  last = false,
  loading = false,
}) => {
  return (
    <>
      <div className={cx("profile-module__footer-actions")}>
        {prev && (
          <Button
            modifier={ButtonModifier.PROFILE_PREVIOUS_BUTTON}
            onClick={onPrev ?? noop}
          >
            Previous
          </Button>
        )}
        {next && (
          <Button
            modifier={ButtonModifier.PROFILE_NEXT_BUTTON}
            onClick={onNext ?? noop}
            accessiblityClass="a-default-accessibility"
          >
            {loading ? (
              <div>
                <ImpulseSpinner size={30} backColor="#ffffff" frontColor="#ffffff" />
              </div>
            ) : (
              <>{last ? "Return to profile" : "Next"}</>
            )}
          </Button>
        )}
      </div>
      <p className={cx("profile-module__footer-copyright")}>
        All mandatory fields are marked with an asterisk (*).
      </p>
    </>
  );
};
