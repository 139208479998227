import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clone } from "lodash";
import { Popover, Typography } from "@mui/material";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import ButtonCustom from "../../../../../components/ButtonCustom/ButtonCustom";
import OPTPeriodCalculator from "../../../../../components/OPTCalculator/OPTPeriodCalculator/OPTPeriodCalculator";

import FullTimeCPTUsedStyles from "./FullTimeCPTUsedStyles";

import {
  disableNext,
  updateFullTimeCPTUsed,
} from "../../../../../redux/reducers/optReducer";

import { hasNull } from "../../../../../utils/common";

import { optPeriodPlaceholder } from "../../../../../constant/mockdata";

import InfoIcon from "../../../../../assets/svg/note-alert.svg";

const FullTimeCPTUsed = () => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const dispatch = useDispatch();
  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const { full_time_cpt_used = {} } = optCalculatorState;
  const { isAuthorized = false, value = null } = full_time_cpt_used; // null value is intended to keep the input clear and show placeholder
  const [showPopover, setPopover] = useState(null);
  const openPopover = Boolean(showPopover);
  const idPopover = openPopover ? "opt-title-popover" : undefined;

  useEffect(() => {
    if (!isAuthorized) {
      dispatch(disableNext(false));
    } else if (
      [...full_time_cpt_used?.value?.map((val) => hasNull(val))]?.includes(true)
    ) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
  }, [full_time_cpt_used]);

  const handleInfoClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  return (
    <FullTimeCPTUsedStyles>
      <OPTQueryContainer
        title={
          <>
            Have you been authorized for <b>full-time</b> CPT?
            <a
              href="/#"
              onClick={handleInfoClick}
              className="alert-icon information-icon"
              aria-label="Find Job Note"
            >
              <img src={InfoIcon} alt="Note" />
            </a>
          </>
        }
      >
        <Popover
          id={idPopover}
          open={openPopover}
          anchorEl={showPopover}
          onClose={handlePopoverClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography>
            If you have 12 months or more of full-time CPT, you are ineligible
            for OPT, but part-time CPT is fine and will not stop you from doing
            OPT. To learn more, visit the{" "}
            <a
              href={"https://www.ice.gov/sevis/practical-training"}
              target="_blank"
              rel="noopener noreferrer"
              className="popover-link"
            >
              ICE website.
            </a>
          </Typography>
          {accessibilityHelp === "true" && (
            <div className="close-button">
              <ButtonCustom onClick={handlePopoverClose}>Got It!</ButtonCustom>
            </div>
          )}
        </Popover>
        <div className="test-action-btns-wrapper">
          <ButtonCustom
            color={isAuthorized ? "primary" : ""}
            className={isAuthorized ? "" : "shadow-btn"}
            onClick={() =>
              dispatch(updateFullTimeCPTUsed({ isAuthorized: true }))
            }
          >
            Yes
          </ButtonCustom>
          <ButtonCustom
            color={isAuthorized ? "" : "primary"}
            className={isAuthorized ? "shadow-btn" : ""}
            onClick={() =>
              dispatch(
                updateFullTimeCPTUsed({
                  isAuthorized: false,
                  value: [clone(optPeriodPlaceholder)],
                })
              )
            }
          >
            No
          </ButtonCustom>
        </div>
      </OPTQueryContainer>
      {isAuthorized ? (
        <div className="fulltime-cpt-input-wrapper">
          <OPTQueryContainer
            title={
              "Enter the start and end dates for all periods of full-time CPT used at the current level of study."
            }
          >
            <OPTPeriodCalculator
              type={"all_periods_of_fulltime_cpt"}
              optPeriodList={value}
              setOPTPeriodList={(val) => dispatch(updateFullTimeCPTUsed(val))}
            />
          </OPTQueryContainer>
        </div>
      ) : (
        ""
      )}
    </FullTimeCPTUsedStyles>
  );
};

export default FullTimeCPTUsed;
