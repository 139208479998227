import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .chrome-extension-slide {
    .top-image {
      width: 58px;
      margin: 0 auto 6px;
    }
    .title {
      font-size: 25px;
      font-weight: 700;
      line-height: 24px;
      font-family: "TTCommons-DemiBold";
      color: var(--primary-dark-color);
    }
    .sub-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      max-width: 287px;
      margin: 6px 0 !important;
    }
  }
`;
