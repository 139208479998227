import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

import JobsTutorialTourStyles from "./JobsTutorialTourStyles";
import CustomDialog from "../../CustomDialog/CustomDialog";
import { VideoPlayer } from "../../VideoPlayer/VideoPlayer";

const JobsTutorialTour = ({ isOpen, onSkipClick, country_of_origin }) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    width: "100%",
    mute: true,
    sources: [
      {
        src:
          country_of_origin !== "United States"
            ? "https://interstride.s3.amazonaws.com/miscellaneous/assets/videos/INT_Students_v03.mp4"
            : "https://interstride.s3.amazonaws.com/miscellaneous/assets/videos/DOM_Students_v02.mp4",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <JobsTutorialTourStyles>
      <CustomDialog
        title="Watch this quick tutorial to get the most from the jobs portal"
        open={isOpen}
        handleClose={() => onSkipClick()}
        className="jobs-tutorial-dialog-modal"
        dialogTitleClass={"jobs-tutorial__title"}
        fullWidth={!true}
        maxWidth={"md"}
        dialogContentClassname={"jobs-tutorial-dialog-modal__dialog-content"}
        overrideDefaultWidth={false}
      >
        <div className="dialog-content">
          <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          <div className="text-center mt-30">
            <a className="ngray-link" href="#" onClick={() => onSkipClick()}>
              Skip
            </a>
          </div>
        </div>
      </CustomDialog>
    </JobsTutorialTourStyles>
  );
};

export default JobsTutorialTour;
