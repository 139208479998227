import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { dataURLtoFile } from "../../constant/base64ToFile";
import ButtonCustom from "../../components/ButtonCustom/ButtonCustom";

// const useForceUpdate = () => {
//   const [, setValue] = useState(0); // integer state
//   return () => setValue((value) => ++value);
// };
const CropperPic = (props) => {
  // const forceUpdate = useForceUpdate();
  const [cropper, setCropper] = useState();

  // useEffect(() => {
  //   if (typeof cropper !== "undefined") {
  //     // console.log("called");
  //     setTimeout(() => {
  //       cropper.setCropBoxData({
  //         height:
  //           window.location.pathname == "/network/messenger" ||
  //           window.location.pathname == "/network/discover-topics"
  //             ? 200
  //             : 400,
  //         width: 200,
  //         top: 100,
  //         left: ["/profile", "/profile/v2"].includes(window.location.pathname)
  //           ? window.screen.availWidth <= 350
  //             ? -10
  //             : window.screen.availWidth <= 380
  //             ? 60
  //             : window.screen.availWidth <= 420
  //             ? 80
  //             : window.screen.availWidth <= 768
  //             ? 200
  //             : 185
  //           : window.location.pathname == "/network/messenger/new-group"
  //           ? window.screen.availWidth <= 350
  //             ? 200
  //             : window.screen.availWidth <= 380
  //             ? 57
  //             : window.screen.availWidth <= 420
  //             ? 80
  //             : window.screen.availWidth <= 768
  //             ? 200
  //             : 60
  //           : window.location.pathname == "/onboarding/profile"
  //           ? window.screen.availWidth <= 350
  //             ? 30
  //             : window.screen.availWidth <= 380
  //             ? 56
  //             : window.screen.availWidth <= 420
  //             ? 76
  //             : window.screen.availWidth <= 768
  //             ? 200
  //             : 183
  //           : window.location.pathname == "/dashboard"
  //           ? window.screen.availWidth <= 350
  //             ? 14
  //             : window.screen.availWidth <= 380
  //             ? 45
  //             : window.screen.availWidth <= 420
  //             ? 60
  //             : window.screen.availWidth <= 768
  //             ? 165
  //             : 145
  //           : window.location.pathname == "/network/discover-topics"
  //           ? window.screen.availWidth <= 350
  //             ? 30
  //             : window.screen.availWidth <= 380
  //             ? 55
  //             : window.screen.availWidth <= 420
  //             ? 75
  //             : window.screen.availWidth <= 768
  //             ? 200
  //             : 300
  //           : 300,
  //       });
  //     }, 100);

  //     //forceUpdate();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [forceUpdate]);

  const getCropData = () => {
    if (typeof cropper !== "undefined" && cropper.getCroppedCanvas()) {
      var base64Url = cropper.getCroppedCanvas().toDataURL();
      if (props.profileInfo == true) {
        base64Url = cropper.getCroppedCanvas().toDataURL("image/jpeg");
      }

      const getObjectFile = dataURLtoFile(base64Url, "hello.txt");

      props.getCropData(getObjectFile, base64Url);
    }
  };
  return (
    <>
      <Cropper
        className={`custome-cropper ${props.className ?? ""}`}
        style={{ height: 400, width: "100%" }}
        aspectRatio={1 / 1}
        src={props.image}
        viewMode={1}
        center={true}
        cropBoxResizable={false}
        dragCrop={false}
        dragMode={"move"}
        guides={false}
        toggleDragModeOnDblclick={false}
        background={false}
        cropBoxMovable={false}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />

      <ButtonCustom
        className="crop-btn"
        onClick={() => {
          getCropData();
        }}
      >
        Crop Image
      </ButtonCustom>
    </>
  );
};

export default CropperPic;
