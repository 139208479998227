import React, { useState } from "react";
import _, { isString } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useAppState } from "../../context";
import { toastify } from "../../helper/helper";
import {
  updateCareerInformation,
  updateEducationalInformation,
  updateExperienceInformation,
  updatePersonalInformation,
} from "../../services/profile.service";
import {
  validateEducationForm,
  validatePersonalForm,
} from "../../utils/common";
import { updatePersistState } from "../../redux/reducers/userReducer";

export const useUpdateUpdateIndividualProfile = () => {
  const dispatch = useDispatch();
  const { users = {} } = useSelector((store) => store.userStore);
  const state = useAppState("profile");
  const [loading, setLoading] = useState(false);
  const {
    //// forms to pass into update api
    personalForm,
    educationsForm,
    experienceForm,
    careerForm,

    ///////// update local forms
    setLocalPersonalForm,
    setLocalExperienceForm,
    setLocalCareerForm,

    //// profile information as whole
    profile,
    setProfile,
    updateProfileState,

    //////////// to keep track if form is changed
    isEducationChanged,
    isExperienceChanged,
    isCareerUpdated,
    isPersonalChanged,

    departmentList,
  } = state || {};

  const { isProfileCompleted } = profile || {};
  /**
   * Update personal information
   * @returns
   */
  const updatePersonal = async (callback) => {
    let response = validatePersonalForm(personalForm);
    if (response !== 200) {
      toastify("error", response);
      return false;
    }

    if (!isPersonalChanged) return true;
    setLoading(true);
    try {
      const response = await updatePersonalInformation(personalForm);
      if (response?.success) {
        let replaceObj = {};
        Object.keys(response.data).forEach(function (key) {
          if (_.has(users, key)) {
            replaceObj[key] = response.data[key];
          }
        });
        setLocalPersonalForm({ ...response?.data });
        setProfile({
          ...profile,
          personal: response?.data,
        });
        dispatch(updatePersistState({ users: { ...users, ...replaceObj } }));
        updateProfileState({ isPersonalChanged: false });
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      console.log(error);
      toastify(
        "error",
        error?.data?.message
          ? error?.data?.message
          : "Oops! something went wrong"
      );
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  };

  /**
   * Update education information
   * @returns
   */
  const updateEducation = async () => {
    let response = validateEducationForm(
      educationsForm,
      personalForm.account_type,
      { departmentList }
    );
    if (response) {
      if (isString(response)) {
        toastify("error", response);
      } else {
        toastify("error", "Please fix errors in education section");
      }

      return;
    }
    if (response) {
      toastify("error", "Please fix errors in education section");
      return false;
    }
    if (!isEducationChanged) return true;
    try {
      setLoading(true);
      dispatch(
        updatePersistState({ users: { ...users, educations: educationsForm } })
      );
      const response = await updateEducationalInformation({
        educations: educationsForm,
      });

      if (response?.success) {
        const updatedProfileState = {
          ...profile,
          educations: [...response?.data.educations],
        };
        updateProfileState({
          profile: updatedProfileState,
          educationsForm: response?.data.educations,
          isEducationChanged: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return true;
  };

  /**
   * Update experience information
   * @returns
   */
  const updateExperience = async () => {
    // if (
    //   !experienceForm?.is_student ||
    //   experienceForm?.experiences?.length > 1 ||
    //   (Object.keys(experienceForm?.experiences[0])?.length > 1 &&
    //     experienceForm?.experiences[0]?.draftId)
    // ) {
    //   // if it is not student
    //   const invalidExperiences = validateExperiences(
    //     experienceForm?.experiences
    //   );
    //   if (invalidExperiences) {
    //     toastify("error", "Please fix errors in experience section");
    //     return false;
    //   }
    // }
    // const invalidExtraExperience = validateExperiences(
    //   experienceForm?.extra_experiences,
    //   "extra_experiences"
    // );
    // if (invalidExtraExperience) {
    //   toastify("error", "Please fix errors in leadership experience section");
    //   return false;
    // }

    const hasLanguageError = experienceForm?.languages?.some(
      ({ level, name, draftId }) => {
        if (draftId) {
          if ((!level && name) || (level && !name)) {
            return true;
          }
        }
      }
    );
    if (hasLanguageError) {
      toastify("error", "Please fix errors in language section");
      return false;
    }

    if (!isExperienceChanged && isProfileCompleted) {
      return true;
    }
    try {
      setLoading(true);
      const response = await updateExperienceInformation({
        ...experienceForm,
      });

      //Todo: verify it
      if (response?.success) {
        setLocalExperienceForm({ ...response?.data });
        setProfile({
          ...profile,
          experience: { ...response?.data },
        });
        updateProfileState({ isExperienceChanged: false });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return true;
  };

  /**
   * Update career information
   * @returns
   */
  const updateCareer = async () => {
    if (!isCareerUpdated) return true;
    setLoading(true);
    try {
      const response = await updateCareerInformation({
        career: careerForm,
      });

      if (response?.success) {
        setLocalCareerForm({ ...response?.data });
        setProfile({
          ...profile,
          career: { ...response?.data },
        });
        dispatch(
          updatePersistState({ users: { ...users, ...response?.data } })
        );
        updateProfileState({ isCareerUpdated: false });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return true;
  };

  return {
    updatePersonal,
    updateEducation,
    updateExperience,
    updateCareer,
    loading,
  };
};
