import React from "react";

import NotificationSettingsStyles from "./NotificationSettingsStyles";
import NetworkingSettings from "./NetworkingSettings/NetworkingSettings";
import TopicsSettings from "./TopicsSettings/TopicsSettings";
import JobsSettings from "./JobsSettings/JobsSettings";
import WebinarSettings from "./WebinarSettings/WebinarSettings";
import BookAppointmentSettings from "./BookAppointmentSettings/BookAppointmentSettings";
import CalendarEventSettings from "./CalendarEventSettings/CalendarEventSettings";

const NotificationSettings = ({ activeTab, isFetching }) => {
  let getPage = () => {
    switch (activeTab) {
      case "chat":
        return <NetworkingSettings isFetching={isFetching} />;
      case "topics":
        return <TopicsSettings isFetching={isFetching} />;
      case "jobs":
        return <JobsSettings isFetching={isFetching} />;
      case "others":
        return <WebinarSettings isFetching={isFetching} />;
      case "appointment":
        return <BookAppointmentSettings isFetching={isFetching} />;
      case "calendar":
        return <CalendarEventSettings isFetching={isFetching} />;
      default:
        return <div></div>;
    }
  };

  return <NotificationSettingsStyles>{getPage()}</NotificationSettingsStyles>;
};

export default NotificationSettings;
