import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .saved-searches {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    .search-list {
      .saved-search-content {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 0 16px 0 0;
        @media (max-width: 1024px) {
          padding: 0 0px 0 0;
        }
      }
      &.two-columns {
        flex-wrap: nowrap !important;
        > .MuiGrid-root {
          max-width: 785px;
          margin-right: 20px;
        }
        .edit-saved-search-wrapper {
          max-width: 320px;
          margin-top: -40px;
          width: 100%;
          margin-bottom: 15px;
          .searchBtn {
            margin: 20px 0 0 0;
            button {
              width: 100%;
            }
          }
          .MuiPaper-root {
            overflow: visible;
            .MuiCardContent-root {
              overflow: visible;
            }
          }
        }
      }
    }
  }
`;
