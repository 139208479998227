import React from "react";
import { Tooltip } from "@mui/material";

import { NoUserImage } from "../../../../../helper/helper";
import NotificationInfoPanelStyles from "./NotificationInfoPanelStyles";
import CardCustom from "../../../../CardCustom/CardCustom";
import CustomSwitch from "../../../../CustomSwitch/CustomSwitch";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";

const NotificationInfoPanel = ({
  notificationType = "",
  avatarImg,
  title,
  tabs = [],
  subTitle = [],
  onClick = () => {},
  actionName = "Unfollow",
  checked = false,
  isDisabled = false,
  handleChange,
  isManageNotification = false,
  topicShowDefault = false,
}) => {
  return (
    <NotificationInfoPanelStyles>
      <CardCustom className="info-card">
        <div className="panel-info">
          {!isManageNotification && (
            <div className="panel-avatar">
              <img
                src={
                  avatarImg && avatarImg.length > 0 ? avatarImg : NoUserImage
                }
                alt="Avatar"
              />
            </div>
          )}
          <div className="info-description">
            <div className="d-flex align-items-center">
              <span className="title">{title}</span>
              {tabs.length > 0 && (
                <div className="panel-tabs">
                  {tabs.map((item, i) => (
                    <div className="tabs-items" key={i}>
                      {item.text}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {subTitle.length > 0 && (
              <span className="sub-title">{subTitle.join(" | ")}</span>
            )}
          </div>
        </div>
        <div className="panel-action ml-auto">
          <Tooltip
            title={
              checked
                ? `Mute this ${notificationType} notifications`
                : `Unmute this ${notificationType} notifications`
            }
          >
            <div className="notification-switch-container">
              <CustomSwitch
                handleChange={handleChange}
                checked={checked}
                isDisabled={isDisabled}
              />
            </div>
          </Tooltip>
          <ButtonCustom
            onClick={onClick}
            className={topicShowDefault ? "d-none" : "bg-gray"}
            height={30}
            width={100}
          >
            {actionName}
          </ButtonCustom>
        </div>
      </CardCustom>
    </NotificationInfoPanelStyles>
  );
};

export default NotificationInfoPanel;
