///// Use Reducer function when there are many local states involved
////  Make more professional, clean and stable component

export const defaultState = {
  reportText: "",
  dropdown: false,
  modal: {
    isOpen: false,
    title: "",
    btnText: "",
    bottomText: "",
    showTextBox: false,
    activeActionId: -1,
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "action.dropdown":
      return {
        ...state,
        dropdown: action.value,
      };
    case "chat.report.text":
      return {
        ...state,
        reportText: action.value,
      };
    case "chat.action":
      return {
        ...state,
        modal: {
          ...action.modal,
        },
      };
    case "action.reset":
      return {
        ...state,
        modal: {
          isOpen: false,
          title: "",
          btnText: "",
          bottomText: "",
          showTextBox: false,
        },
        activeActionId: action.id,
      };
    default:
      return {
        ...state,
      };
  }
};
