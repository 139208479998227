import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .savedNetwork {
    cursor: pointer;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 14px 20px 8px;
    text-decoration: none;
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;
    .section-content {
      .icon {
        margin: -2px 10px 0 0;
        display: flex;
        align-items: center;
      }
      .text {
        padding-top: 4px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0;
        padding-top: 0;
        span {
          margin-left: 3px;
        }
      }
    }
  }
`;
