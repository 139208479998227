import React, { useEffect, useState } from "react";
import { extend, reject } from "underscore";
import { findIndex, isUndefined } from "lodash";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import ChecklistRightSidebarStyles from "./ChecklistRightSidebarStyles";
import Image from "../../../../assets/svg/empty_checklist.svg";
import { useAppState } from "../../../../context";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import CardCustom from "../../../CardCustom/CardCustom";
import ChecklistTaskItemsList from "./ChecklistTaskItemsList";
import ChecklistSubTaskList from "./ChecklistSubTaskList";
import EmptyListWithImage from "../../../EmptyListWithImage/EmptyListWithImage";
import {
  addTaskAPI,
  getChecklistByCategoryId,
  addSubTaskAPI,
  markSubTaskAPI,
  markTaskAPI,
  removeTaskAPI,
  removeSubTaskAPI,
  editSubTaskAPI,
  editTaskAPI,
} from "../../../../services/ChecklistServices";

const useForceUpdate = () => {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value);
};

const ChecklistRightSidebar = () => {
  let {
    checklist,
    setAddNewSubTask,
    checkListCategories,
    selectedCategoriesIndex,
    setChecklist,
    loader,
  } = useAppState("tools");

  const currentCategoryId = checkListCategories[selectedCategoriesIndex].id;
  const forceUpdate = useForceUpdate();
  const [callmarkSubTaskAPI, refreshmarkSubTaskAPI] =
    usePromise(markSubTaskAPI);

  const [activeEditTask, setActiveEditTask] = useState(null);
  const [activeEditSubTask, setActiveEditSubTask] = useState(null);
  const [hideSubtaskButton, setHideSubtaskButton] = useState(false);

  useEffect(() => {
    document.getElementById("interstride").classList.add("checklist-dnd");
    return () => {
      document.getElementById("interstride").classList.remove("checklist-dnd");
    };
  }, []);

  const getRequestChecklistById = async () => {
    const response = await getChecklistByCategoryId(
      checkListCategories[selectedCategoriesIndex].id
    );

    if (response.success) {
      setChecklist(
        checkListCategories[selectedCategoriesIndex].id,
        response.data
      );
    } else {
      toastify("error", response.message);
    }
  };

  useEffect(() => {
    if (callmarkSubTaskAPI.hasFetched()) {
      if (callmarkSubTaskAPI.hasErrors() === false) {
        toastify("success", callmarkSubTaskAPI.data().message);
        let existIndex = findIndex(checklist, {
          id: callmarkSubTaskAPI.data().data.user_checklist.task_id,
        });

        if (
          !isUndefined(checklist[existIndex]) &&
          checklist[existIndex].sub_tasks &&
          checklist[existIndex].sub_tasks.length
        ) {
          let existSubIndex = findIndex(checklist[existIndex].sub_tasks, {
            id: callmarkSubTaskAPI.data().data.user_checklist.sub_task_id,
          });
          if (existSubIndex >= 0) {
            checklist[existIndex].sub_tasks[existSubIndex].is_mark =
              callmarkSubTaskAPI.data().data.user_checklist.is_mark.toString();
          }
        }
        forceUpdate();
      } else {
        getRequestChecklistById();
      }
    }
  }, [callmarkSubTaskAPI.isFetching()]);

  const createNewTask = async (categoryId, taskName) => {
    try {
      const resopnse = await addTaskAPI(currentCategoryId, taskName);
      let checklistdata = reject(checklist, function (data) {
        if (!data.id) {
          return data;
        }
      });

      checklistdata.unshift(
        extend(resopnse.data.checklist_subcategory, {
          is_mark: false,
          sub_tasks: [],
        })
      );
      setChecklist(checkListCategories[selectedCategoriesIndex].id, {
        tasks: checklistdata,
      });
    } catch (error) {
      toastify("error", error.data.message);
    }
  };

  const createNewSubTask = async (sub_id, task) => {
    const response = await addSubTaskAPI(sub_id, task);
    if (!response.success) {
      let checklistdata = checklist;

      let existIndex = findIndex(checklistdata, {
        id: response.data.checklist.task_id,
      });

      let particularSubTaskData = reject(
        checklistdata[existIndex].sub_tasks,
        function (data) {
          if (!data.id) {
            return data;
          }
        }
      );

      checklistdata[existIndex].sub_tasks = particularSubTaskData;

      if (existIndex >= 0) {
        checklistdata[existIndex].sub_tasks.push(
          extend(response.data.checklist, {
            is_mark: false,
            user_id: localStorage.getItem("userId"),
          })
        );
      }

      setChecklist(checkListCategories[selectedCategoriesIndex].id, {
        tasks: checklistdata,
      });
    } else {
      toastify("error", response.message);
    }
  };

  const taskMarked = async (id, check) => {
    const response = await markTaskAPI(id, check);

    if (response.success) {
      toastify("success", response.message);
      getRequestChecklistById();
    } else {
      toastify("error", response.message);
    }
  };

  const editTask = async (taskId, taskName) => {
    const response = await editTaskAPI(taskId, taskName, currentCategoryId);

    if (response.success) {
      toastify("success", response.message);
      setActiveEditTask(null);
      getRequestChecklistById();
    } else {
      toastify("error", response.message);
    }
  };

  const editSubTask = async (
    id,
    taskName,
    task_id,
    clearActiveEditTask = true
  ) => {
    const response = await editSubTaskAPI(id, taskName, task_id);

    if (response.success) {
      toastify("success", response.message);
      if (clearActiveEditTask) {
        setActiveEditSubTask(null);
      }
      getRequestChecklistById();
    } else {
      toastify("error", response.message);
    }
  };

  const removeTaskFn = async (taskId) => {
    const response = await removeTaskAPI(taskId);

    if (response.success) {
      toastify("success", response.message);
      getRequestChecklistById();
    } else {
      toastify("error", response.message);
    }
  };

  const removeSubTaskFn = async (taskId) => {
    const response = await removeSubTaskAPI(taskId);

    if (response.success) {
      toastify("success", response.message);
      getRequestChecklistById();
    } else {
      toastify("error", response.message);
    }
  };

  const removeStaticTask = (index) => {
    let checkListData = checklist;
    checkListData.splice(index, 1);

    setChecklist(checkListCategories[selectedCategoriesIndex].id, {
      tasks: checkListData,
    });
  };

  const removeSubTaskStaticFn = (mainIndex, SubIndex) => {
    let checkListData = checklist;
    checkListData[mainIndex]["sub_tasks"].splice(SubIndex, 1);

    setChecklist(checkListCategories[selectedCategoriesIndex].id, {
      tasks: checkListData,
    });
  };

  return (
    <ChecklistRightSidebarStyles>
      {checklist && checklist?.length > 0 ? (
        <ol className="checklist-items">
          {checklist.map((value) => (
            <li key={value.id}>
              <CardCustom className="checklist-card">
                <ChecklistTaskItemsList
                  taskItem={value}
                  taskIndex={findIndex(checklist, function (o) {
                    return o.id === value.id;
                  })}
                  setAddNewSubTask={setAddNewSubTask}
                  createNewTask={createNewTask}
                  editTask={editTask}
                  editSubtask={editSubTask}
                  taskMark={taskMarked}
                  removeTask={removeTaskFn}
                  removeStaticTask={removeStaticTask}
                  activeEditTask={activeEditTask}
                  setActiveEditTask={setActiveEditTask}
                  activeEditSubTask={activeEditSubTask}
                  hideSubtaskButton={hideSubtaskButton}
                  setHideSubtaskButton={setHideSubtaskButton}
                />
                {value.sub_tasks && value.sub_tasks.length > 0 && (
                  <ul className="sub-tasks">
                    {value.sub_tasks &&
                      value.sub_tasks.map((task, ti) => (
                        <li key={ti}>
                          <ChecklistSubTaskList
                            task={task}
                            mainIndex={findIndex(checklist, function (o) {
                              return o.id === value.id;
                            })}
                            subIndex={ti}
                            createSubTask={createNewSubTask}
                            subCategoryId={value.id}
                            subTaskMark={refreshmarkSubTaskAPI}
                            removeSubTask={removeSubTaskFn}
                            removeSubTaskStaticFn={removeSubTaskStaticFn}
                            editSubtask={editSubTask}
                            editTask={editTask}
                            activeEditTask={activeEditTask}
                            activeEditSubTask={activeEditSubTask}
                            setActiveEditSubTask={setActiveEditSubTask}
                            setHideSubtaskButton={setHideSubtaskButton}
                          />
                        </li>
                      ))}
                  </ul>
                )}
              </CardCustom>
            </li>
          ))}
        </ol>
      ) : (
        <>
          {loader ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => (
                <div className="checklist-loading-container" key={index}>
                  <ShimmerCategoryItem text title />{" "}
                </div>
              ))}
            </>
          ) : (
            checklist?.length === 0 && (
              <EmptyListWithImage
                Image={Image}
                title={"Looks like there are no tasks in this checklist"}
              />
            )
          )}
        </>
      )}
    </ChecklistRightSidebarStyles>
  );
};

export default ChecklistRightSidebar;
