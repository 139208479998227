import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getMajorList = (school_id = null) => {
  return axiosInstance
    .get(`users/get_major_list${school_id ? `?school_id=${school_id}` : ""}`)
    .then((res) => {
      return res.data;
    });
};

export const getUniversitiesList = () => {
  return axiosInstance.get(`users/global_universities`).then((res) => {
    return res.data;
  });
};

export const getCitiesOfCuntry = (country) => {
  return axiosInstance
    .get(`/users/cities?country=${country}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};
