import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .tools-gameplan {
    .f-main-title-with-button {
      margin-bottom: 15px;
    }
    .progressbar-wrapper {
      .RSPBprogressBar {
        align-items: flex-start;
      }
    }
    .f-main-title-with-button {
      min-height: 50px;
      @media (max-width: 767px) {
        min-height: auto;
      }
    }
  }
  .score-chart {
    position: relative;
    padding: 0 0 0 0;
    margin: 20px 0 20px;
    span {
      position: absolute;
      top: 6px;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-family: "TTCommons-DemiBold";
      color: ${PRIMARY_COLOR};
    }
  }
  .multi-select-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
      color: ${PRIMARY_COLOR};
      font-size: 18px;
      font-family: "TTCommons-Regular";
    }
  }
  .mono-select-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      color: ${PRIMARY_COLOR};
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  .game-start-content {
    max-width: 670px;
    margin: 0 auto;
  }
  .game-start-selection {
    .test-controls {
      max-width: 550px;
    }
  }
  .game-questionnaries {
    margin-top: -10px;
    .test-controls {
      .custom-options-list {
        max-width: 650px;
        margin: 0 auto 0px;
        a {
          white-space: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
    .progressbar-wrapper {
      max-width: 825px;
      margin: 0 auto;
      .RSPBprogressBar {
        .RSPBstep {
          &:nth-child(8) {
            .transitionStep {
              &:after {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
  .test-title {
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    font-family: "TTCommons-Medium";
    color: var(--primary-dark-color);
    text-align: center;
    margin: 0 0 8px 0;
  }
  .test-sub-title {
    font-size: 18px;
    font-family: "TTCommons-Regular";
    color: var(--primary-dark-color);
    text-align: center;
    line-height: 19px;
    max-width: 440px;
    margin: 0 auto 30px;
    p {
      margin: 0px;
    }
  }
  .blue-bg-card {
    border-radius: 20px;
    background-color: #f1f4f7;
    padding: 20px;
    padding-bottom: 32px;
    max-width: 365px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    p,
    .title {
      font-family: "TTCommons-Medium";
      color: var(--primary-dark-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      margin: 0;
      b {
        font-family: "TTCommons-Medium";
        font-weight: 500;
      }
    }

    .ol-listing {
      margin: 10px 0px 0px 0px;
      padding: 0;
      list-style: none;
    }
    li {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      + li {
        margin-top: 4px;
      }
    }
  }
  .test-controls {
    .label {
      margin: 0 0 20px 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 18px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      width: 100%;
    }
    .blue-bg-card {
      max-width: 481px;
      padding-bottom: 20px;
      ul {
        margin: 8px 0 0 0;
        padding: 0 0px 0px 24px;
        li {
          + li {
            margin-top: 6px;
          }
        }
      }
    }
  }
  .test-action-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
    .shadow-btn {
      background: #fff !important;
      box-shadow: var(--card-box-shadow) !important;
      color: var(--field-label-color) !important;
      width: auto;
      padding: 18px 20px 13px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
      img {
        vertical-align: middle;
        margin: -2px 8px 0 8px;
        width: 8px;
        transform: rotate(180deg);
      }
    }
    button {
      max-width: 205px;
      min-width: 205px;
      @media (max-width: 767px) {
        max-width: 100%;
        min-width: auto;
        width: 50% !important;
      }
      + button {
        margin-left: 15px;
      }
    }
  }
`;
