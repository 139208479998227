import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .smartbanner {
    box-shadow: none !important;
    background: #f8f8f9 !important;
    position: fixed;
    top: 0;
    width: 100%;
    .smartbanner-container {
      padding: 8px 12px !important;
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
      .smartbanner-close {
        background: transparent !important;
        font-family: "TTCommons-Regular" !important;
        border: 0 !important;
        text-shadow: none !important;
        color: #c3c3c6 !important !important;
        width: auto !important;
        height: auto !important;
        box-shadow: none !important;
        margin: 0 !important;
        font-size: 34px !important;
        padding: 0 !important;
        line-height: normal !important;
        display: inline-block !important;
        text-decoration: none !important;
        color: #bfc0c2 !important;
      }
      .smartbanner-icon {
        background-color: #fff !important;
        margin: 0 8px !important;
        border: 1px solid #eaeaea !important;
        background-size: 48px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
      }
      .smartbanner-info {
        text-align: left !important;
        .smartbanner-title {
          color: #000c3d !important;
          font-family: "TTCommons-Medium" !important;
          font-weight: 500 !important;
          font-size: 18px !important;
          line-height: normal !important;
          text-shadow: none !important ;
        }
        .smartbanner-author {
          font-family: "TTCommons-Regular" !important;
          font-size: 12px !important;
          color: #000c3d !important ;
          text-shadow: none !important ;
        }
      }
      .smartbanner-wrapper {
        width: auto !important;
        max-width: initial !important;
        margin-left: auto !important;
        .smartbanner-button {
          position: initial !important;
          border-radius: 100px !important;
          color: #fff !important;
          background: #007aff !important;
          font-family: "TTCommons-Medium" !important;
          font-size: 15px !important;
          font-weight: 500 !important;
          letter-spacing: 0 !important;
          line-height: 17px !important;
          min-width: 60px !important;
          padding: 8px 8px 4px 8px !important;
          height: auto !important;
          font-family: "TTCommons-Medium" !important;
          font-size: 12px !important;
          span {
            background: transparent !important;
            font-family: "TTCommons-Medium" !important;
            font-size: 15px !important;
            font-weight: 500 !important;
            text-transform: initial !important;
            text-shadow: none !important;
          }
        }
      }
    }
  }
`;
