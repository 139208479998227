import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .dashboard-leftsidebar {
    position: relative;
    .slick-list {
      max-width: 300px;
      margin: 0 auto;
    }
    .MuiCard-root {
      border-radius: 0;
      box-shadow: none;
      background: transparent;
      overflow: visible;
    }
    .boost-profile-card {
      .MuiCardContent-root {
        padding: 0;
      }
      .profile {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px 20px;
        box-shadow: var(--card-box-shadow);
        border-radius: 10px;
        margin: 0 0 20px 0;
        background: #fff;
        @media (max-width: 767px) {
          padding: 16px;
        }
        .profile-icon {
          width: 70px;
          height: 70px;
          overflow: hidden;
          border-radius: 100px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          .MuiAvatar-root {
            height: 70px;
            width: 70px;
          }
        }
        .profile-description {
          width: calc(100% - 70px);
          padding-left: 10px;
          .profile-description-line-one {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 5px;
          }
        }
        .profile-name {
          display: flex;
          font-size: 22px;
          font-family: "TTCommons-DemiBold";
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          color: var(--primary-dark-color);
          margin: 0 5px 0 0;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
        .profile-ambassador-tag {
          background: rgba(247, 100, 95, 0.09);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 115px;
          border-radius: 12.5px;
          cursor: pointer;
          img {
            margin-right: 4.5px;
          }
          span {
            height: 11px;
            width: 74px;
            color: #a80c06;
            font-family: "TTCommons-Medium";
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
          }
        }

        .bio {
          display: flex;
          width: 100%;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .display-widgets {
          display: flex;
          width: 100%;
          align-items: center;
          margin: 6px 0 0;

          .widgets-item {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            margin: 0 8px 0 0;
            cursor: pointer;
            a {
              text-align: center;
              text-decoration: none;
              display: flex;
              align-items: center;
            }
            img {
              height: 25px;
            }
            button {
              border: none;
              background: none;
              div {
                display: flex;
                align-items: center;
              }
            }
          }
          .connect-label {
            min-width: 120px;
            height: 25px;
            border-radius: 12.5px;
            background-color: var(--link-color);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px 2px 0 2px;
            span {
              height: 13.5px;
              color: #ffffff;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
            }
          }
        }
      }
      .boost-profile {
        background: #fff;
        color: var(--primary-dark-color);
        padding: 30px 20px 30px;
        box-shadow: var(--card-box-shadow);
        border-radius: 10px;
        @media (max-width: 767px) {
          padding: 16px;
        }
        .text-center {
          .nlink-secondary {
            margin-top: 20px;
            display: inline-block;
          }
        }
        .title {
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          margin: 0 0 15px 0;
          padding: 4px 0 0 0;
        }
        .sub-title {
          margin: 0px 0 14px 0;
          width: 100%;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
        }
        button {
          height: auto;
          padding: 17px 35.5px 12px 36.87px;
          border-radius: 45px;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 21px;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          margin-top: 15px;
          width: 100%;
        }
        .progressbar-wrapper {
          max-width: 420px;
          margin: 0 auto;
          .RSPBprogressBar {
            .RSPBstep {
              &:nth-child(5) {
                .transitionStep {
                  &:after {
                    display: none !important;
                  }
                }
              }
              .progress-item {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .dashboard-menus {
      margin: 30px 0 0 0;
      @media (max-width: 767px) {
        margin: 16px 0 0 0;
      }
      ul {
        margin: 0;
        li {
          margin: 0 8px 18px;
          @media (max-width: 767px) {
            margin: 0 8px 8px;
          }
          p {
            margin: 0;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Regular";
          }
          a {
            margin: 0;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Regular";
            display: flex;
            align-items: center;
            font-weight: 400;
            color: var(--f-light-gray-color);
            border-bottom: 2px solid transparent;
            svg {
              width: 19px;
              height: 19px;
              vertical-align: middle;
              margin: -2px 0 0 0;
            }
          }
        }
      }
    }
    .save-card {
      .card-content {
        padding: 0;
        box-shadow: var(--card-box-shadow);
        padding: 12px 20px 8px;
        border-radius: 45px;
        background: #fff;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
        .save-posts {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .posts {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 20px;
            margin: 0;
            display: flex;
            align-items: center;
            img {
              margin: -2px 10px 0 0;
            }
          }
          .posts-count {
            margin-left: auto;
            a {
              border-bottom: 0;
              display: flex;
              border: 0 !important;
            }
            img {
              vertical-align: middle;
            }
          }
        }
      }
      &.no-save-post {
        .card-content {
          padding: 20px 20px 15px;
          border-radius: 10px;
          background: #fff;
          .save-post-dec {
            .posts-dec {
              font-size: 15px;
              letter-spacing: 0;
              line-height: 15px;
              font-family: "TTCommons-Regular";
            }
            img.ml-auto,
            svg.ml-auto {
              margin: 0px 15px 0px auto !important;
            }
            button {
              box-shadow: none;
              height: 40px;
              width: 40px;
              min-width: 40px;
              border-radius: 100px;
              background-color: var(--icon_background);
              svg {
                fill: var(--primary-dark-color);
              }
            }
          }
        }
      }
    }
  }
`;
