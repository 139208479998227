import React from "react";
import Avatar, { Cache, ConfigProvider } from "react-avatar";
const cache = new Cache({
  // Keep cached source failures for up to 1 day
  sourceTTL: 1 * 24 * 3600 * 1000,
  // Keep a maximum of 20 entries in the source cache
  sourceSize: 20,
});
export const CircularAvatar = ({
  wrapperClass,
  src,
  name,
  alt,
  color,
  fgColor,
  size,
  round,
  maxInitials = 2,
  textSizeRatio,
  initials,
  value,
}) => {
  return (
    <div className={wrapperClass ?? ""}>
      <ConfigProvider cache={cache}>
        <Avatar
          value={value}
          initials={initials}
          textSizeRatio={textSizeRatio}
          maxInitials={maxInitials}
          name={name}
          src={src}
          alt={alt}
          color={
            localStorage.getItem("accessibilityHelp") == "true"
              ? "#5C728A"
              : color
          }
          round={round}
          fgColor={fgColor}
          size={size}
        />
      </ConfigProvider>
    </div>
  );
};
