import React from "react";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const CustomAddToCalendarButton = (props) => {
  let addToCalenderBtnStyle = {
    "btn-background": "inherit",
    "btn-text": "inherit",
    "btn-shadow": "0 1px 14px 3px rgba(209,221,229,0.4)",
    "btn-border": "transparent",
    font: "inherit",
  };

  addToCalenderBtnStyle = Object.entries(addToCalenderBtnStyle)
    .reduce(
      (style, [key, value]) => (style = [...style, `--${key}: ${value};`]),
      []
    )
    .join(" ");
  return <AddToCalendarButton {...props} styleLight={addToCalenderBtnStyle} />;
};

export default CustomAddToCalendarButton;
