import { useContext } from "react";
import AppContext from "./AppContext";

const useAppState = (container) => {
  const [containers] = useContext(AppContext ?? {}) ?? [];

  // Check if we even have an appContext
  if (!containers) {
    console.log("State Machine cannot find context.");
    // return empty state if there's no appContext
    return {};
  }

  // return entire state if there's no parameter
  if (!container) return containers;

  const containerInstance = containers?.[container] ?? undefined;
  if (
    containerInstance === undefined ||
    containerInstance === null ||
    !containerInstance
  ) {
    console.log("containers available", Object?.keys(containers ?? {}));
    console.log(
      `AppState Container '${container}' does not exist. See the console for available containers.`
    );
  }
  return containerInstance ?? {};
};

export default useAppState;
