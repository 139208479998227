import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .matters-card {
    margin-bottom: 10px;
    padding:0;
    .card-content {
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 10px;

      .card-left-side {
        min-width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 60px;
        padding: 0;
        .MuiAvatar-root {
            width: 70px;
            height: auto;
            svg {
              width:100%;
            }
            img {
              width: 100%;
            }
        }
        img{
          max-width:100%;
        }
      }

      .card-right-side {
        padding-left:10px;
        .matter-title {
          font-size: 18px;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          line-height: 19px;
          margin: 15px 0 4px 0;
      }
        }
        .matter-content {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Regular";
          margin-bottom: 15px;
        }
      }
    }
  }
`;
