import React, { useState, useEffect } from "react";
import { Grid, Avatar, Popover, Typography, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toString } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import TopFeaturedJobsListStyles from "./TopFeaturedJobsListStyles";
import Notealert from "../../../assets/svg/note-alert.svg";
import RSS from "../../../assets/png/handshake_rss_logo.png";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  capitalizeFirstLetter,
  processJobLocationTime,
} from "../../../helper/helper";
import { MAX_TITLE_LENGTH } from "../../../constant/mockdata";
import CardCustom from "../../CardCustom/CardCustom";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { toastify } from "../../../helper/helper";
import StarIcon from "../../StarIcon/StarIcon";

import {
  getFeaturedJobsAPI,
  updateFavouriteJobsAPI,
} from "../../../services/JobServices";
import { getFilteredEmployerJobsAPI } from "../../../services/EmployerJobServices";
import { Mixpanel } from "../../../mixpanel";
import { useSavedJobs } from "../../../hooks/useSavedJobs";
import { setSelectedJob } from "../../../redux/reducers/jobReducer";

const TopFeaturedJobsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FEATURED_JOB_TITLE_MAX_LENGTH = MAX_TITLE_LENGTH - 20; // 20px for fratured jobs tag
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const [adminJobsList, setAdminJobsList] = useState([]);
  const [employerJobsList, setEmployerJobsList] = useState([]);
  const [showPopover, setPopover] = useState(null);

  const { savedJobs = [], refreshSavedJobs = () => {} } = useSavedJobs();

  const [callAdminJobsAPI, refreshAdminJobsAPI] =
    usePromise(getFeaturedJobsAPI);
  const [callEmployerJobsAPI, refreshEmployerJobsAPI] = usePromise(
    getFilteredEmployerJobsAPI
  );
  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  useEffect(() => {
    if (users && users != null) {
      refreshEmployerJobsAPI(users?.id);
    }
    refreshAdminJobsAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callEmployerJobsAPI.hasFetched() &&
      callEmployerJobsAPI.hasErrors() === false
    ) {
      if (callEmployerJobsAPI.data() && callEmployerJobsAPI.data().data) {
        const { employer_jobs = [] } = callEmployerJobsAPI.data().data;
        setEmployerJobsList(employer_jobs);
      } else {
        setEmployerJobsList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEmployerJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callAdminJobsAPI.hasFetched() &&
      callAdminJobsAPI.hasErrors() === false
    ) {
      if (
        callAdminJobsAPI.data() &&
        callAdminJobsAPI.data().data &&
        callAdminJobsAPI.data().data.jobs
      ) {
        setAdminJobsList(callAdminJobsAPI.data().data.jobs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAdminJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data() &&
      callingUpdateFavouriteJobsAPI.data().message
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  const updateFavourites = (params) => {
    refreshUpdateFavouriteJobsAPI(params);
  };

  const favJobKeyList = savedJobs.map(({ job_id }) => job_id);

  const handleFeaturedEmployerJobClick = (data, websiteUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    if (!data.applicant_status)
      Mixpanel.track("Job apply click", {
        job_source: "ep_featured",
        job_title: data.title,
        job_employer_name: data.name,
        job_country: data.country_name,
        job_state: data.state_name,
        job_city: data.city_name,
        job_full_location: `${
          data.city_name !== null && data.city_name !== "null"
            ? `${data.city_name}${
                data.state_name !== null && data.state_name !== "null"
                  ? ","
                  : ""
              }`
            : ""
        } ${
          data.state_name !== null && data.state_name !== "null"
            ? data.state_name
            : ""
        }`,
      });

    if (data.details === "url") window.open(websiteUrl);
    else {
      dispatch(setSelectedJob(data));
      const { permalink } = data;
      if (permalink) navigate(`/jobs/job-details/${permalink}?type=featured`);
      else navigate("jobs/job-details");
    }
  };

  const handleFeaturedAdminJobClick = (data, websiteUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    Mixpanel.track("Job apply click", {
      job_source: "ap_featured",
      job_title: data?.job_title,
      job_employer_name: data?.company,
      job_country: data.country,
      job_state: data?.state,
      job_city: data?.city,
      job_full_location: `${
        data?.city !== null && data?.city !== "null"
          ? `${capitalizeFirstLetter(data?.city)}${
              data?.state !== null && data?.state !== "null" ? "," : ""
            }`
          : ""
      } ${data?.state !== null && data?.state !== "null" ? data?.state : ""}`,
    });

    if (websiteUrl) window.open(websiteUrl);
  };

  const renderEmployerJobs = (data, i) => {
    let websiteUrl = data.url;
    if (data.details === "url" && !/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`employer-job-${i}`}>
        <CardCustom
          onClick={(e) => handleFeaturedEmployerJobClick(data, websiteUrl, e)}
        >
          <div className="search-item-tags">
            <Tooltip
              title={
                "Featured opportunities are sourced directly from employers or Interstride partners"
              }
              placement="bottom"
            >
              <span className="tag-item cursor-pointer">Featured</span>
            </Tooltip>
          </div>
          <div className="search-item employer-job">
            <div className="avtar">
              {data.logo.url && data.logo.url !== null ? (
                <Avatar
                  variant="rounded"
                  alt="Rounded logo"
                  src={data.logo.url}
                />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data.name}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                {data.title &&
                data.title.length > FEATURED_JOB_TITLE_MAX_LENGTH ? (
                  <Tooltip title={capitalizeFirstLetter(data.title)}>
                    <span>
                      {capitalizeFirstLetter(
                        data.title.substring(0, FEATURED_JOB_TITLE_MAX_LENGTH)
                      )}
                      ...
                    </span>
                  </Tooltip>
                ) : (
                  <span>{capitalizeFirstLetter(data.title)}</span>
                )}
              </div>
              <div className="sub-header-primary">{data.name}</div>
              <div className="text-grey">
                {processJobLocationTime({
                  city: data?.city_name,
                  state: data?.state_code,
                  country: !["United States", "USA", "US"].includes(
                    data?.country_name
                  )
                    ? data?.country_name
                    : null,
                  time: data?.postedon,
                })}
              </div>
            </div>
            {data.applicant_status && data.applicant_status !== null ? (
              <>
                <div
                  className={`card-actions-wrapper ${
                    accessibilityHelp === "true" ? "show-actions" : ""
                  }`}
                >
                  <div>
                    <a
                      href
                      className="nlink-btn bg-gray"
                      aria-label="Already Applied"
                      onClick={(e) => e.preventDefault()}
                    >
                      Applied
                    </a>
                  </div>
                </div>
                {favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data?.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            ) : (
              <>
                <div
                  className={`card-actions-wrapper ${
                    accessibilityHelp === "true" ? "show-actions" : ""
                  }`}
                >
                  <div>
                    <a
                      href={`jobs/job-details/${data?.permalink}`}
                      className="nlink-btn nbg-secondary"
                      aria-label={`Apply for ${data.title}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      Apply
                    </a>
                  </div>
                </div>
                {favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const renderAdminJobs = (data, i) => {
    let websiteUrl = data.link;
    if (!/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`featured-job-${i}`}>
        {data?.rss_feed_id && data?.rss_feed_id != null ? (
          <img src={RSS} className="custom-rss-icon" alt="Webinar-Rss" />
        ) : (
          ""
        )}
        <CardCustom
          onClick={(e) => handleFeaturedAdminJobClick(data, websiteUrl, e)}
        >
          <div className="search-item-tags">
            <Tooltip
              title={
                "Featured opportunities are sourced directly from employers or Interstride partners"
              }
              placement="bottom"
            >
              <span className="tag-item cursor-pointer">Featured</span>
            </Tooltip>
          </div>
          <div className="search-item featured-job">
            <div className="avtar">
              {data?.logo_url && data?.logo_url !== null ? (
                <img
                  alt="Avatar"
                  src={data?.logo_url}
                  style={{
                    "border-radius": "4px",
                    height: "50px",
                    "max-width": "50px",
                  }}
                />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data?.company}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                {data?.job_title &&
                data?.job_title.length > FEATURED_JOB_TITLE_MAX_LENGTH ? (
                  <Tooltip title={capitalizeFirstLetter(data?.job_title)}>
                    <span>
                      {capitalizeFirstLetter(
                        data?.job_title.substring(
                          0,
                          FEATURED_JOB_TITLE_MAX_LENGTH
                        )
                      )}
                      ...
                    </span>
                  </Tooltip>
                ) : (
                  <span>{capitalizeFirstLetter(data?.job_title)}</span>
                )}
              </div>
              <div className="sub-header-primary">{data?.company}</div>
              <div className="text-grey">
                {processJobLocationTime({
                  city: data.city,
                  state: data.state,
                  country:
                    data.country !== "United States" &&
                    data.country !== "USA" &&
                    data.country !== "US" &&
                    data.country,
                  time: data.created_at,
                })}
              </div>
            </div>
            <div
              className={`card-actions-wrapper ${
                accessibilityHelp === "true" ? "show-actions" : ""
              }`}
            >
              <div>
                <a
                  href={websiteUrl ?? "/#"}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="nlink-btn nbg-secondary"
                  aria-label={`Apply for ${data?.job_title}`}
                  onClick={(e) => e.preventDefault()}
                >
                  Apply
                </a>
              </div>
            </div>
            {favJobKeyList.includes(toString(data.id)) ? (
              <StarIcon
                aria-label={`Remove ${data.job_title} from saved jobs`}
                variant="filled"
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "unlike",
                    job_title: data.job_title,
                  });
                }}
                className="ml-8"
              />
            ) : (
              <StarIcon
                aria-label={`Add ${data.job_title} to saved jobs`}
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "like",
                    job_title: data.job_title,
                    city: data.city,
                    state: data.state,
                    company: data.company,
                    country: !["United States", "USA", "US"].includes(
                      data?.country_name
                    )
                      ? data?.country_name
                      : null,
                    url: websiteUrl,
                    publish_date: data.created_at,
                    logo_url: data.logo_url,
                  });
                }}
                className={`card-actions-wrapper ml-8 ${
                  accessibilityHelp === "true" ? "show-actions" : ""
                }`}
              />
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const totalJobs = employerJobsList.length + adminJobsList.length;

  if (totalJobs === 0) {
    return <></>;
  }

  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  const isLoading =
    callEmployerJobsAPI.isFetching() || callAdminJobsAPI.isFetching();

  return (
    <TopFeaturedJobsListStyles>
      <div className="top-featured-jobs-list">
        <div className="title-boxs mb-15">
          <h2 className="title-primary" id={"featured-jobs-section-title"}>
            Featured jobs
            <a
              href="/#"
              onClick={handleClick}
              className="alert-icon information-icon"
              aria-label="featured jobs note"
            >
              <img src={Notealert} alt="Note" />
            </a>
          </h2>
          {!isLoading && totalJobs > 0 && (
            <Link
              to="/jobs/featured-jobs"
              className="nlink-secondary view-all link-focus"
              aria-describedby={`featured-jobs-section-title`}
              aria-label={`View all${
                accessibilityHelp === "true" ? " featured jobs" : ""
              } (
                ${totalJobs})`}
            >
              View all{accessibilityHelp === "true" ? " featured jobs" : ""} (
              {totalJobs})
            </Link>
          )}
        </div>

        {isLoading ? (
          <JobsListingEffect />
        ) : (
          <>
            {employerJobsList.length > 0 &&
              employerJobsList
                .slice(0, 3)
                .map((data, i) => renderEmployerJobs(data, i))}
            {employerJobsList.length < 3 &&
              adminJobsList.length > 0 &&
              adminJobsList
                .slice(0, 3 - employerJobsList.length)
                .map((data, i) => renderAdminJobs(data, i))}
          </>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={showPopover}
          onClose={handleClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography>
            <p>
              Featured opportunities are sourced directly from employers or
              Interstride partners{" "}
            </p>
            <p>
              By clicking on the employment opportunity links [or job postings]
              below, you are agreeing to share information, including personal
              information with third party employers and/or other recruiting
              platforms.
            </p>
          </Typography>
          {accessibilityHelp === "true" && (
            <div className="close-button">
              <ButtonCustom onClick={handleClose}>Got It!</ButtonCustom>
            </div>
          )}
        </Popover>
      </div>
    </TopFeaturedJobsListStyles>
  );
};
export default TopFeaturedJobsList;
