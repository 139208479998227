import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt-period-entry > div {
    width: 43%;
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
  .opt-period-entry__header {
    label {
      width: 43%;
    }
  }
`;
