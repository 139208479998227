import styled from "styled-components";
import {
  PRIMARY_COLOR,
  INACTIVE_BTN_GRAY_COLOR,
} from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .featured-jobs-list {
    .featured-jobs-list {
      .MuiPaper-root {
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        }
      }
    }
    .title-primary {
      display: flex;
      align-items: center;
    }
    .search-top {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      .sort-by {
        span.sortby {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .react-custom-dropdown {
          color: ${PRIMARY_COLOR};
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          > div:nth-child(3) {
            margin: 0 !important;
            > div {
              margin: 0 !important;
              > div {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                margin: 0 !important;
                overflow: visible !important;
                > div {
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin: 0 !important;
                  > &:hover {
                    background: ${PRIMARY_COLOR};
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .card-wrapper {
      .MuiCardContent-root {
        padding: 16px;
      }
      .MuiPaper-root {
        cursor: pointer;
        &:hover {
          .search-item {
            .card-actions-wrapper {
              opacity: 1;
              visibility: visible;
              transition: all 0.3s;
            }
          }
        }
      }
    }
    .search-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      &.featured-job {
        .content {
          .header-primary {
            align-items: center;
          }
        }
      }
      .avtar {
        margin-right: 15px;
      }
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        border-radius: 100px;
      }
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        padding-left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        .nlink-btn {
          width: auto;
          min-width: 80px;
          white-space: nowrap;
        }
        div {
          + div {
            margin-left: 12px;
            @media (max-width: 991px) {
              margin-left: 8px;
            }
          }
        }
        .bg-gray {
          color: #ffffff;
          background: var(--gray-color);
          &:hover {
            background: var(--gray-color-btn-hover);
          }
          &:disabled {
            background: ${INACTIVE_BTN_GRAY_COLOR};
            color: #ffff;
          }
        }
      }
      .content {
        margin-right: auto;
        max-width: calc(100% - 170px);
        @media (max-width: 600px) {
          max-width: 100%;
        }
        .header-primary {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0px;
          span {
            display: inline-block;
            white-space: normal;
            word-break: break-word;
            &:first-child {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
              @media (max-width: 600px) {
                width: 100%;
                display: block;
                white-space: normal;
              }
            }
          }
          .star {
            margin: -3px 0 0 10px;
            a {
              padding-left: 8px;
            }
          }
          .tag-item {
            background: #e5eaf7;
            border-radius: 10.5px;
            background-color: #e5eaf7;
            color: ${PRIMARY_COLOR};
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            display: inline-block;
            padding: 3px 8px 0px;
            margin: -3px 0 0 10px;
            white-space: nowrap;
          }
        }
      }
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        padding-left: 0;
        .nlink-btn {
          width: auto;
          min-width: 80px;
          white-space: nowrap;
        }
        div {
          + div {
            margin-left: 12px;
            @media (max-width: 991px) {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .custom-rss-icon {
      height: 15px;
      width: 15px;
      position: absolute;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
`;
