import { useReducer } from "react";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../utils/common";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  forgotPassword: { email: null, password: null, confirmPassword: null },
  forgotPasswordToken: null,
  personal: {
    school_user_email: null,
    firstName: null,
    lastName: null,
    password: null,
    degree: null,
    major: null,
    graduation_year: null,
    accessibility_help: false,
    department_ids: [],
    user_profile: null,
    student_view_preference: STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
  },
};

const useAuth = (appState) => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const setUserEmail = (forgotEmail) => {
    setState({
      forgotPassword: { ...state.forgotPassword, email: forgotEmail },
    });
  };

  const setUserPassword = (forgotPassword, forgotConfirmPassword) => {
    setState({
      forgotPassword: {
        ...state.forgotPassword,
        password: forgotPassword,
        confirmPassword: forgotConfirmPassword,
      },
    });
  };

  const setForgotPasswordToken = (token) => {
    setState({ forgotPasswordToken: token });
  };

  const setUniversityEmail = (uniEmail) => {
    setState({
      personal: { ...state.personal, school_user_email: uniEmail },
    });
  };

  const setStudentViewPreference = (student_view_preference) => {
    setState({
      personal: { ...state.personal, student_view_preference },
    });
  };

  const setPersonalDetails = (
    firstName,
    lastName,
    password,
    accessibility_help = false,
    user_profile = null
  ) => {
    if (user_profile) {
      setState({
        personal: {
          ...state.personal,
          firstName,
          lastName,
          password,
          accessibility_help,
          user_profile,
        },
      });
    } else {
      setState({
        personal: {
          ...state.personal,
          firstName,
          lastName,
          password,
          accessibility_help,
        },
      });
    }
  };

  const setInitialProfileDetails = () => {
    setState({
      personal: {
        school_user_email: null,
        firstName: null,
        lastName: null,
        password: null,
        degree: null,
        major: null,
        graduation_year: null,
        accessibility_help: false,
        department_ids: [],
        user_profile: null,
      },
    });
  };

  const setProfileDetails = (user_profile) => {
    setState({
      personal: { ...state.personal, user_profile: user_profile },
    });
  };

  const setUniversityDetails = (degree, major, graduation_year) => {
    setState({
      personal: { ...state.personal },
      degree,
      major,
      graduation_year,
    });
  };
  return {
    setUserEmail,
    setUserPassword,
    setForgotPasswordToken,
    setUniversityEmail,
    setPersonalDetails,
    setUniversityDetails,
    setProfileDetails,
    setInitialProfileDetails,
    setStudentViewPreference,
    ...state,
  };
};

export default useAuth;
