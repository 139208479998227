import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  . visa-mainpage {
    .search-list {
      .search-title {
        .title-primary {
          display: flex;
          align-items: center;
          svg {
            width: 21px;
            height: 21px;
            margin: -5px 0 0 0;
          }
        }
      }
    }
  }
  .cluster {
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;
