import React, { useState } from "react";

import PhoneNumberSelect from "../PhoneNumberSelect/PhoneNumberSelect";

// Need to use this as a component to avoid using heavy phone formatting libraries and to achieve same format as phone number input
export const FormattedPhoneNumber = ({ number = "" }) => {
  const [formattedPhoneNo, setFormattedPhoneNo] = useState(number);

  return (
    <>
      {formattedPhoneNo}
      <div style={{ display: "none" }}>
        <PhoneNumberSelect
          name="phone_number"
          onMount={(formattedValue) => {
            setFormattedPhoneNo(formattedValue);
          }}
          value={number}
        />
      </div>
    </>
  );
};
