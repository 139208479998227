import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px 30px;
  }
  .saved-posts-wrapper {
    max-width: 670px;
    margin: 0 auto;
    > div {
      > div {
        > div {
          > div {
            width: 100% !important;
            padding: 0;
          }
        }
      }
    }
    .title-primary {
      .ngray-link {
        margin-left: 12px;
      }
    }
    .title-boxs {
      align-items: flex-start;
    }
    .sort-by {
      margin-bottom: 5px;
      top: -4px;
      position: relative;
    }
    .info-panel-card {
      .card-content {
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .panel-header {
          @media (max-width: 767px) {
            width: calc(100% - 80px);
            padding-right: 0;
          }
          .panel-title {
            @media (max-width: 767px) {
              flex-wrap: wrap;
            }
            > div {
              &:first-child {
                @media (max-width: 767px) {
                  width: 100%;
                }
              }
              &.tags {
                @media (max-width: 767px) {
                  margin: 0 8px 4px 0;
                }
              }
            }
          }
        }
        .panel-actions {
          @media (max-width: 767px) {
            margin: 0px 0 0 80px;
          }
        }
      }
    }
  }
`;
