import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  text-align: center;
  .ensICS {
    min-width: 202px;
  }
  .ensICS + .ensICS {
    margin-left: 13px;
  }

  .opt-period-entry {
    display: flex;
    align-items: center;
    gap: 6px;

    .clearable-date-picker input {
      padding: 16.65px 24px 12.65px 8px;
    }

    .react-datepicker__close-icon {
      padding: 0;
    }

    .duration-dropdown {
      max-width: 115px;
      .react-custom-dropdown > div {
        padding: 4px 0px 0 5px;
      }
    }

    .field-group {
      div {
        margin-bottom: 0;
      }
    }
    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
    + .opt-period-entry {
      margin-top: 15px;
      @media (max-width: 480px) {
        border-top: 1px solid var(--icon_background);
        margin-top: 4px;
      }
    }
    &__header {
      display: flex;
      margin: 18px 0 12px 0;
      gap: 6px;
      @media (max-width: 480px) {
        display: none;
      }
      label {
        width: 33.33%;
        text-align: center;
        font-size: 18px;
        line-height: 19px;
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        &:nth-child(1),
        &:nth-child(2) {
          min-width: 190px;
        }
        &:last-child {
          max-width: 70px;
          padding-right: 15px;
        }
      }
    }
    > div {
      width: 33.33%;
      position: relative;
      @media (max-width: 480px) {
        margin-top: 15px;
        width: 100%;
        padding: 0;
      }
      + div {
        @media (max-width: 480px) {
          margin-top: 8px;
        }
      }
      .react-datepicker-wrapper {
        @media (max-width: 480px) {
          width: 100%;
        }
      }
      .field-group {
        width: 100%;
        margin-bottom: 0;
        @media (max-width: 480px) {
          display: flex;
          flex-flow: row;
        }
        .textbox-label {
          display: none;
          @media (max-width: 480px) {
            display: block;
            width: 40%;
            text-align: left;
          }
        }
      }
      &.actions {
        display: flex;
        max-width: 70px;
        padding-right: 15px;
        @media (max-width: 480px) {
          padding-right: 35px;
          min-height: 35px;
          align-items: center;
        }
        label {
          display: none;
          @media (max-width: 480px) {
            padding: 5px 0 0 0;
            line-height: 14px;
            margin: 0 0 6px 0px;
            font-size: 15px;
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            display: block;
            width: 40%;
            text-align: left;
          }
        }
        .day-count {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          margin: 0 auto;
          @media (max-width: 480px) {
            padding: 5px 0 0 0;
          }
        }
      }
    }
    &__delete {
      position: absolute;
      right: -10px;
      text-decoration: none;
      color: var(--field-label-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
    .hidden {
      opacity: 0;
      display: none;
    }
  }
  hr {
    margin-top: 30px;
    margin-bottom: 25px;
    @media (max-width: 480px) {
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }
  .add-another-action {
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;
    border-bottom: 1px solid var(--link-color);
  }
`;
