import { useState, useReducer } from "react";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  isDisplayFooter: false,
};

const useApp = () => {
  const [fatalError, setFatalError] = useState(false);
  const [state, setState] = useReducer(reducer, initialArgs);

  const handleOpenFooter = () => {
    setState({ isDisplayFooter: true });
  };
  const handleCloseFooter = () => {
    setState({ isDisplayFooter: false });
  };

  return {
    ...state,
    handleOpenFooter,
    handleCloseFooter,
    setFatalError,
    fatalError,
  };
};

export default useApp;
