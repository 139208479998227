import React, { useState, useEffect } from "react";
import VisaStyles from "./VisaStyles";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import VisaSearchFilter from "../../../components/Jobs/Visa/VisaSearchFilter/VisaSearchFilter";
import { experienceOptions, presetFilters } from "../../../constant/mockdata";
import SaveCompanies from "../../../components/Jobs/Visa/SaveCompanies/SaveCompanies";
import FilterComapany from "../../../components/Jobs/Visa/FilterComapany/FilterComapany";
import SearchResult from "../../../components/Jobs/Visa/SearchResult/SearchResult";
import MyMap from "../../../components/Jobs/Visa/MyMap/MyMap";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  exportCompaniesAPI,
  getCompaniesAPI,
  getSavedCompaniesAPI,
  updateFavouriteCompanyAPI,
} from "../../../services/JobServices";
import { toastify } from "../../../helper/helper";
import {
  setAppliedVisaFilters,
  setCompanies,
} from "../../../redux/reducers/jobReducer";

const Visa = (props) => {
  const dispatch = useDispatch();
  const { appliedVisaFilters = {}, companies = [] } = useSelector(
    (store) => store.jobStore
  );

  const [mode, setMode] = useState(true);
  const [callingGetCompaniesAPI, refreshGetCompaniesAPI] =
    usePromise(getCompaniesAPI);
  const [callingExportCompaniesAPI, refreshExportCompaniesAPI] =
    usePromise(exportCompaniesAPI);
  const [callingGetCompaniesPaginationAPI, refreshGetCompaniesPaginationAPI] =
    usePromise(getCompaniesAPI);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [preserveFilters, setPreserveFilter] = useState(
    props?.location?.state?.preserveFilters
  );

  const INITIAL_FILTERS = {
    job_classification: null,
    job_title: null,
    visa: "h1b",
    year: 2024,
    industryType: "industry",
    industry: null,
    company: null,
    city: null,
    state: null,
    experience: null,
    graduationYear: null,
    functionFilter: null,
    presetFilter: null,
  };

  const [currentCompanyFilter, setCurrentCompanyFilter] = useState({});

  const [savedCompaniesList, setSavedCompaniesList] = useState([]);
  const [callSavedCompaniesAPI, refreshSavedCompaniesAPI] =
    usePromise(getSavedCompaniesAPI);

  const [callingUpdateFavouriteCompanyAPI, refreshUpdateFavouriteCompanyAPI] =
    usePromise(updateFavouriteCompanyAPI);

  useEffect(() => {
    if (
      callingUpdateFavouriteCompanyAPI.hasFetched() &&
      callingUpdateFavouriteCompanyAPI.hasErrors() === false
    ) {
      refreshSavedCompaniesAPI();
      toastify("success", callingUpdateFavouriteCompanyAPI.data().message);
    }
  }, [callingUpdateFavouriteCompanyAPI.isFetching()]);

  useEffect(() => {
    if (preserveFilters) {
      setCurrentCompanyFilter({ ...appliedVisaFilters });
      callGetCompaniesAPI(appliedVisaFilters);
    } else {
      dispatch(setAppliedVisaFilters({ ...INITIAL_FILTERS }));
      setCurrentCompanyFilter({ ...INITIAL_FILTERS });
      callGetCompaniesAPI(INITIAL_FILTERS);
    }
    refreshSavedCompaniesAPI();
  }, []);

  useEffect(() => {
    if (
      callSavedCompaniesAPI.hasFetched() &&
      callSavedCompaniesAPI.hasErrors() === false
    ) {
      if (callSavedCompaniesAPI.data() && callSavedCompaniesAPI.data().data) {
        setSavedCompaniesList(
          callSavedCompaniesAPI.data().data.favorite_companies
        );
      }
    }
  }, [callSavedCompaniesAPI.isFetching()]);

  const onCompaniesExport = (exportType) => {
    callGetCompaniesAPI({}, exportType);
  };

  const callGetCompaniesAPI = (newFilters = {}, exportType = false) => {
    const {
      job_classification,
      job_title,
      visa,
      year,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
      presetFilter,
    } = { ...currentCompanyFilter, ...newFilters };

    if (!exportType) {
      setShowLoader(true);
      dispatch(
        setAppliedVisaFilters({ ...currentCompanyFilter, ...newFilters })
      );
      setCurrentPage(0);
    }

    const body = {
      year,
      limit: "10",
      offset: "0",
      id: "search",
      search: {
        type: visa && visa !== null ? visa : "",
        company_ids: company && company !== null ? [company.value] : [],
        industry_ids: industry && industry !== null ? [industry] : [],
        state_ids: state && state !== null ? [state.value] : [],
        city_ids: city && city !== null ? [city.value] : [],
        job_title_ids:
          job_classification && job_classification !== null
            ? [job_classification.value]
            : [],
        soc_title_ids: job_title && job_title !== null ? [job_title.value] : [],
        graduation_year_ids:
          graduationYear && graduationYear !== null ? [graduationYear] : [],
      },
    };
    if (
      experience !== null &&
      visa !== null &&
      (visa === "h1b" || visa === "greencard")
    ) {
      body.wage_level = experience;
    }

    // Need to pass presetfilter if any for export
    if (presetFilter && exportType) {
      body[presetFilter] = true;
      // Pass only type param in body for filters
      body.search = { type: body.search.type };
    }

    if (exportType) refreshExportCompaniesAPI({ ...body, [exportType]: true });
    else refreshGetCompaniesAPI(body);
  };

  const getPaginationData = () => {
    const {
      job_classification,
      job_title,
      visa,
      year,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
      presetFilter,
    } = appliedVisaFilters;

    const body = {
      year,
      limit: "10",
      offset: ((currentPage + 1) * 10).toString(),
      id: "search",
      search: {
        type: visa && visa !== null ? visa : "",
        company_ids: company && company !== null ? [company.value] : [],
        industry_ids: industry && industry !== null ? [industry] : [],
        state_ids: state && state !== null ? [state.value] : [],
        city_ids: city && city !== null ? [city.value] : [],
        job_title_ids:
          job_classification && job_classification !== null
            ? [job_classification.value]
            : [],
        soc_title_ids: job_title && job_title !== null ? [job_title.value] : [],
        graduation_year_ids:
          graduationYear && graduationYear !== null ? [graduationYear] : [],
      },
      // Pass saved present filters on pagination if any
      ...(presetFilter ? { [presetFilter]: true } : {}),
    };

    if (
      experience !== null &&
      visa !== null &&
      (visa === "h1b" || visa === "greencard")
    ) {
      body.wage_level = experience;
    }

    refreshGetCompaniesPaginationAPI(body);

    setCurrentPage(currentPage + 1);
  };

  const clearAllAPI = () => {
    setShowLoader(true);
    const {
      job_classification,
      job_title,
      visa,
      year,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
    } = INITIAL_FILTERS;

    setCurrentCompanyFilter({
      ...INITIAL_FILTERS,
    });
    dispatch(setAppliedVisaFilters({ ...INITIAL_FILTERS }));
    setCurrentPage(0);

    const body = {
      year,
      limit: "10",
      offset: "0",
      id: "search",
      search: {
        type: visa && visa !== null ? visa : "",
        company_ids: company && company !== null ? [company.value] : [],
        industry_ids: industry && industry !== null ? [industry] : [],
        state_ids: state && state !== null ? [state.value] : [],
        city_ids: city && city !== null ? [city.value] : [],
        job_title_ids:
          job_classification && job_classification !== null
            ? [job_classification.value]
            : [],
        soc_title_ids: job_title && job_title !== null ? [job_title.value] : [],
        graduation_year_ids:
          graduationYear && graduationYear !== null ? [graduationYear] : [],
      },
    };

    if (
      experience !== null &&
      visa !== null &&
      (visa === "h1b" || visa === "greencard")
    ) {
      body.wage_level = experience;
    }

    refreshGetCompaniesAPI(body);
  };

  const callGetCompaniesAPIpresetFilter = (presetFilter = {}) => {
    setShowLoader(true);
    const {
      job_classification,
      job_title,
      visa,
      year,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
    } = INITIAL_FILTERS;

    const body = {
      year,
      limit: "10",
      offset: "0",
      id: "search",
      search: {
        type: visa && visa !== null ? visa : "",
        company_ids: company && company !== null ? [company.value] : [],
        industry_ids: industry && industry !== null ? [industry] : [],
        state_ids: state && state !== null ? [state.value] : [],
        city_ids: city && city !== null ? [city.value] : [],
        job_title_ids:
          job_classification && job_classification !== null
            ? [job_classification.value]
            : [],
        soc_title_ids: job_title && job_title !== null ? [job_title.value] : [],
        graduation_year_ids:
          graduationYear && graduationYear !== null ? [graduationYear] : [],
      },
      [presetFilter?.presetFilter]: true,
    };

    if (
      experience !== null &&
      visa !== null &&
      (visa === "h1b" || visa === "greencard")
    ) {
      body.wage_level = experience;
    }

    // Update search filter fields conditionally to match the preset filter
    if (presetFilter?.presetFilter === presetFilters[0].value) {
      setCurrentCompanyFilter({
        ...INITIAL_FILTERS,
        ...presetFilter,
        experience: experienceOptions[0].value,
      });
    } else if (presetFilter?.presetFilter === presetFilters[1].value) {
      setCurrentCompanyFilter({
        ...INITIAL_FILTERS,
        ...presetFilter,
        state: { value: 1, label: "New York" },
        city: { value: 65, label: "New York City" },
      });
    } else if (presetFilter?.presetFilter === presetFilters[2].value) {
      setCurrentCompanyFilter({
        ...INITIAL_FILTERS,
        ...presetFilter,
        state: { value: 4, label: "California" },
      });
    } else if (presetFilter?.presetFilter === presetFilters[3].value) {
      setCurrentCompanyFilter({
        ...INITIAL_FILTERS,
        ...presetFilter,
        industry: presetFilter?.industryCode,
      });
    } else {
      setCurrentCompanyFilter({
        ...INITIAL_FILTERS,
        ...presetFilter,
      });
    }
    dispatch(setAppliedVisaFilters({ ...INITIAL_FILTERS, ...presetFilter }));
    setCurrentPage(0);

    // Call API
    refreshGetCompaniesAPI(body);
  };

  useEffect(() => {
    if (
      callingGetCompaniesAPI.hasFetched() &&
      callingGetCompaniesAPI.hasErrors() === false &&
      callingGetCompaniesAPI.data() &&
      callingGetCompaniesAPI.data().data
    ) {
      const length =
        callingGetCompaniesAPI.data().data.companies_by_petitions.length;
      setHasMore(length === 10);
      dispatch(
        setCompanies([
          ...callingGetCompaniesAPI.data().data.companies_by_petitions,
        ])
      );
      setShowLoader(false);

      // Remove preserved state once initial data is loaded
      if (preserveFilters) {
        setPreserveFilter(false);
        window.history.replaceState({}, "");
      }
    }
  }, [callingGetCompaniesAPI.isFetching()]);

  useEffect(() => {
    if (
      callingExportCompaniesAPI.hasFetched() &&
      callingExportCompaniesAPI.hasErrors() === false &&
      callingExportCompaniesAPI.data() &&
      callingExportCompaniesAPI.data().message
    ) {
      toastify("success", callingExportCompaniesAPI.data().message);
    }
  }, [callingExportCompaniesAPI.isFetching()]);

  useEffect(() => {
    if (
      !callingGetCompaniesAPI.isFetching() &&
      callingGetCompaniesPaginationAPI.hasFetched() &&
      callingGetCompaniesPaginationAPI.hasErrors() === false &&
      callingGetCompaniesPaginationAPI.data() &&
      callingGetCompaniesPaginationAPI.data().data
    ) {
      const length =
        callingGetCompaniesPaginationAPI.data().data.companies_by_petitions
          .length;
      setHasMore(length === 10);
      if (currentPage !== 0)
        dispatch(
          setCompanies([
            ...companies,
            ...callingGetCompaniesPaginationAPI.data().data
              .companies_by_petitions,
          ])
        );
    }
  }, [callingGetCompaniesPaginationAPI.isFetching()]);

  const getViewMode = (m) => {
    setMode(m);
  };

  const updateFavourites = ({ company_id, tab_type, employer_name, like }) => {
    refreshUpdateFavouriteCompanyAPI({
      tab_type:
        tab_type && like === "unlike" ? tab_type : appliedVisaFilters.visa,
      company_id,
      company_name: employer_name,
      like,
    });
  };

  const setFilter = (changedFilters) => {
    setCurrentCompanyFilter({
      ...currentCompanyFilter,
      ...changedFilters,
      presetFilter: null,
    });
  };

  return (
    <VisaStyles>
      <div className="visa-mainpage">
        {mode ? (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <VisaSearchFilter
                filter={currentCompanyFilter}
                setFilter={setFilter}
                onSearchClick={callGetCompaniesAPI}
                onPresetFilterClick={callGetCompaniesAPIpresetFilter}
                onClearAllClick={clearAllAPI}
                preserveFilters={preserveFilters}
              />
              <div className="ShowResponsiveDiv">
                <SaveCompanies
                  savedCompaniesList={savedCompaniesList}
                  loader={callSavedCompaniesAPI.isFetching()}
                  refreshSavedCompaniesAPI={refreshSavedCompaniesAPI}
                  updateFavourites={updateFavourites}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <FilterComapany
                initialFilters={INITIAL_FILTERS}
                loader={showLoader}
                filter={currentCompanyFilter}
                sendMode={getViewMode}
                savedCompaniesList={savedCompaniesList}
                updateFavourites={updateFavourites}
                fetchMoreData={getPaginationData}
                hasMore={hasMore}
                currentPage={currentPage}
                showPaginationLoader={callingGetCompaniesPaginationAPI.isFetching()}
                onCompaniesExport={onCompaniesExport}
              />
            </Grid>
            <Grid item xs={3}>
              <SaveCompanies
                savedCompaniesList={savedCompaniesList}
                loader={callSavedCompaniesAPI.isFetching()}
                refreshSavedCompaniesAPI={refreshSavedCompaniesAPI}
                updateFavourites={updateFavourites}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <SearchResult
                savedCompaniesList={savedCompaniesList}
                chageViewMode={() => {
                  setMode(true);
                }}
                openMap={!mode}
                updateFavourites={updateFavourites}
              />
            </Grid>
            <Grid item xs={9}>
              <MyMap sendMode={getViewMode} INITIAL_FILTERS={INITIAL_FILTERS} />
            </Grid>
          </Grid>
        )}
      </div>
    </VisaStyles>
  );
};

export default Visa;
