import React from "react";
import { Doughnut } from "react-chartjs-2";

import CareerGamePlanLeftSidebarStyles from "./CareerGamePlanLeftSidebarStyles";
import CardCustom from "../../../CardCustom/CardCustom";
import {
  ACCESSIBILITY_SECONDARY_COLOR,
  ICON_BACKGROUND_HOVER,
  BLUE_COLOR,
} from "../../../../styles/variables";

const CareerGamePlanLeftSidebar = ({ categoryList = [], userScore = 0 }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  const data = {
    labels: ["Your score", "Improvement"],
    datasets: [
      {
        data: [userScore, 100 - userScore],
        backgroundColor: [
          BLUE_COLOR,
          accessibilityHelp == "true"
            ? ACCESSIBILITY_SECONDARY_COLOR
            : ICON_BACKGROUND_HOVER,
        ],
      },
    ],
  };
  return (
    <CareerGamePlanLeftSidebarStyles>
      <CardCustom className="score-card">
        <div className="score-chart">
          <Doughnut
            data={data}
            options={options}
            width={115}
            height={115}
            aria-label="Score Chart"
          />
          <span>{userScore}%</span>
        </div>
        <ul className="score-list">
          {categoryList.length > 0 &&
            categoryList.map((category, i) => (
              <li key={i}>
                <span>{category.category_name}</span>
                <div className="ml-auto score-percentage">{`${category.score}%`}</div>
              </li>
            ))}
        </ul>
      </CardCustom>
    </CareerGamePlanLeftSidebarStyles>
  );
};

export default CareerGamePlanLeftSidebar;
