import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .custom-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px 30px;
  }
  .boost-profile-content {
    margin: 0;
    .MuiGrid-spacing-xs-3 {
      margin-top: 0;
      margin-bottom: 0;
      .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .content-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      font-family: "TTCommons-Medium";
      margin: 0 0 15px 0;
      padding-top: 4px;
    }
    .dialogDescription {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: center;
      margin-bottom: 60px;
      width: 336px;
    }
  }
`;
