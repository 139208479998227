import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .position-filter {
    .search-title {
      .title-primary {
        display: flex;
        align-items: center;
        a {
          &:first-child {
            display: flex;
          }
        }
        .ngray-link {
          margin-left: 12px;
          text-decoration: none !important;
        }
      }
    }
    .MuiCard-root {
      overflow: visible;
    }
    .card-content {
      padding: 20px 20px;
      overflow: visible;
      position: relative;
      .searchBtn {
        button {
          width: 100%;
          margin-top: 15px;
          background-color: var(--link-color) !important;
        }
      }
    }
  }
`;
