import React, { useEffect } from "react";
import CalendarLeftSidebarStyles from "./CalendarLeftSidebarStyles";
import EventCard from "../../../EventCard/EventCard";
import _get from "lodash/get";
import { useAppState } from "../../../../context";
import cls from "classnames";
import CardCustom from "../../../CardCustom/CardCustom";
import { useLocation } from "react-router-dom";
import where from "underscore/modules/where";
import moment from "moment";
import { sortDateTimeArray } from "../../../../helper/helper";

const CalendarLeftSidebar = ({
  events = [],
  hideRescheduleButton = false,
  handleEventClick = () => {},
}) => {
  let query = new URLSearchParams(useLocation().search);
  let event_id = query.get("event_id");
  sortDateTimeArray(events, "date");
  const { setSelectedEvent, selectedEvent } = useAppState("tools");

  useEffect(() => {
    if (event_id && events && events.length) {
      let existItem = where(events, { id: parseInt(event_id) });
      if (existItem && existItem.length) {
        setSelectedEvent({
          ...existItem[0],
          hideRescheduleButton: hideRescheduleButton,
        });
      }
    }
  }, [event_id, events]);

  const EmptyEventsList = () => {
    return (
      <div className="facelift-empty-boxwrap">
        <h3>Looks like you have no events!</h3>
        <p>Create an event by clicking on the top right button.</p>
      </div>
    );
  };
  return (
    <CalendarLeftSidebarStyles>
      {!events || events.length === 0 ? (
        <EmptyEventsList />
      ) : (
        events.map((item, i) => {
          return (
            <EventCard
              id={item.id}
              key={i}
              className={cls("cursor-pointer event-card")}
              title={""}
              subTitle={
                item && item.date
                  ? moment(item.date).format("dddd MMMM Do, YYYY")
                  : moment(item.appointment_date_mobile).format(
                      "dddd, MMMM Do YYYY"
                    )
              }
              duration={`${_get(item, "start_time", "")} - ${_get(
                item,
                "end_time",
                ""
              )}`}
              item={item}
              actionName="View details"
            />
          );
        })
      )}
    </CalendarLeftSidebarStyles>
  );
};

export default CalendarLeftSidebar;
