import React, { useEffect, useState, useLayoutEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useDispatch } from "react-redux";

import Container from "../../../components/Container/Container";
import NotificationRightsidebar from "../../../components/Notification/NotificationDashboard/NotificationRightsidebar/NotificationRightsidebar";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import NotificationDashboardStyles from "./NotificationDashboardStyles";
import { routes } from "../../../routes";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  notificationsListAPI,
  removeNotificationAPI,
  notificationReadCountAPI,
  pastNotificationClearAPI,
  readAllNotificationApi,
} from "../../../services/notificationServices";
import { toastify, addAccessibilityClass } from "../../../helper/helper";
import { Mixpanel } from "../../../mixpanel";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import { resetAllNotifications } from "../../../redux/reducers/notificationReducer";

import NoNotificationIcon from "../../../assets/svg/no_notification.svg";

const NotificationDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFetching, setFetching] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [notificatioReDirectionUrl, setNotificationReDirectionUrl] =
    useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const {
    setNotifications,
    notificationFilters,
    deleteNotification,
    past_notifications,
    new_notifications,
  } = useAppState("notifications");

  const [callingNotificationsListAPI, refreshNotificationsListAPI] =
    usePromise(notificationsListAPI);
  const [callingRemoveNotificationAPI, refreshRemoveNotificationAPI] =
    usePromise(removeNotificationAPI);
  const [callingNotificationReadCountAPI, refreshNotificationReadCountAPI] =
    usePromise(notificationReadCountAPI);
  const [callPastNotificationClearAPI, refreshPastNotificationClearAPI] =
    usePromise(pastNotificationClearAPI);
  const [, refreshReadAllNotificationApi] = usePromise(readAllNotificationApi);

  useEffect(() => {
    let filterBy = filter(notificationFilters, (data) => {
      return data.checked === true;
    });
    setFetching(true);
    refreshNotificationsListAPI(filterBy);
    // Reset all notifications in Persist State
    dispatch(resetAllNotifications());
  }, []);

  useEffect(() => {
    if (
      callPastNotificationClearAPI.hasFetched() &&
      callPastNotificationClearAPI.hasErrors() === false
    ) {
      let filterBy = filter(notificationFilters, (data) => {
        return data.checked === true;
      });
      setFetching(true);
      refreshNotificationsListAPI(filterBy);
    }
  }, [callPastNotificationClearAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationsListAPI.hasFetched() &&
      callingNotificationsListAPI.hasErrors() === false &&
      callingNotificationsListAPI.data() &&
      callingNotificationsListAPI.data().data
    ) {
      setFetching(false);
      setNotifications(callingNotificationsListAPI.data().data);
      let filterBy = filter(notificationFilters, (data) => {
        return data.checked === true;
      });
      refreshReadAllNotificationApi(filterBy);
    }
  }, [callingNotificationsListAPI.isFetching()]);

  useEffect(() => {
    if (
      callingRemoveNotificationAPI.hasFetched() &&
      callingRemoveNotificationAPI.hasErrors() === false
    ) {
      deleteNotification(notificationId, notificationStatus);
      setNotificationId(null);
      setNotificationStatus(null);
      toastify("success", callingRemoveNotificationAPI.data().message);
    }
  }, [callingRemoveNotificationAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationReadCountAPI.hasFetched() &&
      callingNotificationReadCountAPI.hasErrors() === false
    ) {
      if (selectedItemId && selectedItemId != undefined) {
        navigate(notificatioReDirectionUrl + `?item_id=${selectedItemId}`);
      } else {
        navigate(notificatioReDirectionUrl);
      }
    }
  }, [callingNotificationReadCountAPI.isFetching()]);

  const handleDelete = (id, isNew) => {
    setNotificationId(id);
    refreshRemoveNotificationAPI(id);
    setNotificationStatus(isNew);
  };

  const handleClear = () => {
    refreshPastNotificationClearAPI();
  };

  const readAndRedirectNotification = (
    notiId,
    notificationReDirectUrl,
    itemId
  ) => {
    setNotificationReDirectionUrl(notificationReDirectUrl);
    setSelectedItemId(itemId);
    refreshNotificationReadCountAPI(notiId);
  };

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout";
    addAccessibilityClass();
  });

  return (
    <NotificationDashboardStyles>
      <div className="NotificationWrap">
        <Container>
          <div className="btn-test">
            <ButtonCustom
              width={173}
              className="bg-gray"
              onClick={() => {
                navigate(routes.NOTIFICATION.SETTINGS);
                Mixpanel.track("Manage Settings Button Clicked");
              }}
            >
              Manage Settings
            </ButtonCustom>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isFetching ? (
                <div className="notification-loader">
                  <JobsListingEffect count={4} />
                </div>
              ) : !isFetching &&
                new_notifications.length === 0 &&
                past_notifications.length === 0 ? (
                <div className="no-notification-img-wrapper">
                  <span>You are all caught up!</span>
                  <img src={NoNotificationIcon} alt="No Notification" />
                </div>
              ) : (
                <>
                  <NotificationRightsidebar
                    title="New Notifications"
                    notifications={new_notifications}
                    handleDelete={handleDelete}
                    isFetching={isFetching}
                    noTitle="Looks like you have no new notifications!"
                    isNew={true}
                    readApiCall={(notiId, notificationReDirectUrl, itemId) =>
                      readAndRedirectNotification(
                        notiId,
                        notificationReDirectUrl,
                        itemId
                      )
                    }
                  />
                  <NotificationRightsidebar
                    title="Past Notifications"
                    clearTitle="Clear All"
                    notifications={past_notifications}
                    handleDelete={handleDelete}
                    handleClear={handleClear}
                    noTitle="Looks like you have no past notifications!"
                    className="mt-40"
                    isNew={false}
                    readApiCall={(notiId, notificationReDirectUrl, itemId) =>
                      readAndRedirectNotification(
                        notiId,
                        notificationReDirectUrl,
                        itemId
                      )
                    }
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </NotificationDashboardStyles>
  );
};

export default NotificationDashboard;
