import React from "react";
import { Routes, Route } from "react-router-dom";
import CareerGameplanDashboard from "./CareerGameplanDashboard/CareerGameplanDashboard";

const CareerGameplan = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CareerGameplanDashboard />} />
      </Routes>
    </>
  );
};

export default CareerGameplan;
