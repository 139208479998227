import React, { memo } from "react";

function WidgetResources() {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g id="widget-category-icon" stroke="#000C3D" stroke-width="1.2">
          <g id="Group" transform="translate(4.000000, 7.000000)">
            <path
              d="M22.3238571,24.6152542 L1.04314286,24.6152542 C0.466714286,24.6152542 9.41469125e-14,24.1533898 9.41469125e-14,23.5838983 L9.41469125e-14,0.894491525 C9.41469125e-14,0.402118644 0.407142857,-1.77635684e-15 0.904285714,-1.77635684e-15 L22.4622857,-1.77635684e-15 C22.9594286,-1.77635684e-15 23.367,0.402118644 23.367,0.894491525 L23.367,23.5838983 C23.367,24.1533898 22.8998571,24.6152542 22.3238571,24.6152542 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M14.8659429,12.3076695 C14.8659429,14.0458051 13.4409429,15.4542797 11.6833714,15.4542797 C9.9258,15.4542797 8.5008,14.0458051 8.5008,12.3076695 C8.5008,10.5695339 9.9258,9.16063559 11.6833714,9.16063559 C13.4409429,9.16063559 14.8659429,10.5695339 14.8659429,12.3076695 Z"
              id="Stroke-3"
            ></path>
            <path
              d="M20.6654571,12.3076695 C20.6654571,12.3076695 16.6441714,17.267839 11.6834571,17.267839 C6.72274286,17.267839 2.70102857,12.3076695 2.70102857,12.3076695 C2.70102857,12.3076695 6.72274286,7.34707627 11.6834571,7.34707627 C16.6441714,7.34707627 20.6654571,12.3076695 20.6654571,12.3076695 Z"
              id="Stroke-5"
            ></path>
            <polyline
              id="Stroke-7"
              points="23.3668286 9.54101695 29.6359714 6.87491525 29.6359714 17.7405932 23.3668286 15.8439831"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetResources);
