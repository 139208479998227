import React from "react";

import BoostProfileMiddleStyles from "./BoostProfileMiddleStyles";
import CardCustom from "../../CardCustom/CardCustom";
import CompleteProfile from "./Statuses/CompleteProfile";
import NetworkWithSomeone from "./Statuses/NetworkWithSomeone";
import CreateJob from "./Statuses/CreateJob";
import RegisterWebinar from "./Statuses/RegisterWebinar";
import RateInterstride from "./Statuses/RateInterstride";

const BoostProfileMiddle = ({ title = "", selectedTab }) => {
  const getProfileComponent = () => {
    switch (selectedTab) {
      case "completed_user_profile":
        return <CompleteProfile />;
      case "network_connection":
        return <NetworkWithSomeone />;
      case "job_search":
        return <CreateJob />;
      case "registered_webinar":
        return <RegisterWebinar />;
      case "rate_interstride":
        return <RateInterstride />;
      default:
        break;
    }
  };

  return (
    <BoostProfileMiddleStyles>
      <h2 className="content-title text-primary">{title}</h2>
      <CardCustom className="boost-profile-card">
        {getProfileComponent()}
      </CardCustom>
    </BoostProfileMiddleStyles>
  );
};

export default BoostProfileMiddle;
