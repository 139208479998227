import React, { useEffect } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import cls from "classnames";

import DropdownStyles from "./DropdownStyles";
import { PRIMARY_DARK_COLOR } from "../../styles/variables";

import chevArrowDownIcon from "../../assets/svg/chevArrowDown.svg";
import chevArrowUpIcon from "../../assets/svg/chevArrowUp.svg";
import FocusDownArrow from "../../assets/FocusIcons/Common/DropdownDownArrow.svg";

const DropdownIndicator = (props) => {
  return (
    <div className="field-group dropdown-indicator">
      <components.DropdownIndicator {...props}>
        <img
          className="mt-3"
          alt="Dropdown arrow"
          src={
            props.selectProps.menuIsOpen
              ? chevArrowUpIcon
              : props.isFocused
              ? FocusDownArrow
              : chevArrowDownIcon
          }
        />
      </components.DropdownIndicator>
    </div>
  );
};

const DropdownCustom = ({
  onClick = () => {},
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {},
  onInputChange = () => {},
  className = "",
  name,
  value,
  addText = "Add Option",
  placeholder,
  width = "100%",
  height = 50,
  label = "",
  showLabelAtTop = true,
  options = [],
  justifyContent = "center",
  isMulti = false,
  error = false,
  touched = false,
  required = false,
  isLoading = false,
  isDisabled = false,
  defaultValue = "",
  noOptionsMessage = "No options",
}) => {
  const colourStyles = {
    multiValue: (styles) => ({
      ...styles,
      background: "#E5EAF7",
      borderRadius: "10px",
      color: PRIMARY_DARK_COLOR,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: PRIMARY_DARK_COLOR,
    }),
    valueContainer: (styles) => ({
      ...styles,
      justifyContent: justifyContent,
      padding: "0 8px 2px 20px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    indicatorsContainer: (styles) => ({ ...styles, padding: "0 12px 0 0" }),
    indicatorContainer: (styles) => ({ ...styles, backgroundColor: "pink" }),
    menu: (styles) => ({
      ...styles,
      border: "2px solid var(--gray-outline-color)",
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
      boxShadow: "var(--card-box-shadow)",
      padding: "0px 0px 0px 0px !important",
      overflow: "hidden",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "7px",
        boxSizing: "border-box",
      },
      "::-webkit-scrollbar-track": {
        background: "white",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        background: "rgba(0, 0, 0, 0.2)",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: "white",
      width: `${width}px`,
      minHeight: `${height}px`,
      height: "max-content",
      border: "2px solid var(--gray-outline-color)",
      borderRadius: "45px",
      color: "var(--field-label-color)",
      fontFamily: "TTCommons-Regular",
      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "0",
      lineHeight: "20px",
      // --Focus style
      // boxShadow: isFocused ? "0 0 8px 0px #000c3d !important" : "",
      borderColor: isFocused ? "var(--field-label-color) !important" : "",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: isFocused ? "0px 0px 0px 0px" : "0px",
        padding: "13px 20px 8.13px 20px",
        backgroundColor: isDisabled
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "var(--primary-dark-color)"
          : null,
        color: isDisabled
          ? PRIMARY_DARK_COLOR
          : isSelected
          ? PRIMARY_DARK_COLOR
          : isFocused
          ? "#fff"
          : PRIMARY_DARK_COLOR,
        fontSize: "18px",
        fontFamily: "TTCommons-Regular",
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: "21.7px",
        cursor: isDisabled ? "not-allowed" : "default",
        justifyContent: "flex-start",
        textAlign: "start",
        display: "flex",
        ":active": {
          ...styles[":active"],
        },
      };
    },
    input: (styles) => ({
      ...styles,
      textAlign: justifyContent,
      color: "var(--gray-text-color)",
      fontFamily: "TTCommons-Regular",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: !showLabelAtTop && PRIMARY_DARK_COLOR,
      fontFamily: "TTCommons-Regular",
    }),
    singleValue: (styles) => ({
      ...styles,
      justifyContent: justifyContent,
      color: PRIMARY_DARK_COLOR,
      fontFamily: "TTCommons-Regular",
      top: "55%",
    }),
  };

  const id = Math.random();

  useEffect(() => {
    const input = document.getElementById(`dropdown-input-${id}`);
    if (input) input.setAttribute("aria-required", required);
  }, []);

  return (
    <div className="field-group">
      <DropdownStyles>
        {label && showLabelAtTop && (
          <label
            className={cls("textbox-label", "text-primary")}
            style={{
              justifyContent: justifyContent,
              margin: "0 0 6px 8px",
            }}
          >
            {`${label}${required ? "*" : ""}`}
          </label>
        )}
        <CreatableSelect
          isLoading={isLoading}
          isDisabled={isDisabled}
          formatCreateLabel={(inputValue) => `${addText} "${inputValue}"`}
          components={{
            DropdownIndicator,
          }}
          captureMenuScroll={false}
          className={` ${className} ${isMulti && "multi-select"}`}
          minMenuHeight="200"
          name={name}
          options={options}
          styles={colourStyles}
          onClick={onClick}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          onInputChange={onInputChange}
          value={value?.value && value}
          placeholder={placeholder ? placeholder : label}
          isMulti={isMulti}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary50: "#0030B5",
              primary25: "#0030B5",
              primary: "var(--gray-outline-color)",
            },
          })}
          aria-label={placeholder ? placeholder : label}
          inputId={`dropdown-input-${id}`}
          defaultValue={defaultValue}
          noOptionsMessage={() => noOptionsMessage}
        />

        {!!error && touched && (
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            id="email-helper-text"
            role="alert"
            aria-live="assertive"
          >
            {error}
          </p>
        )}
      </DropdownStyles>
    </div>
  );
};

export default DropdownCustom;
