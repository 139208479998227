import React, { memo, useState } from "react";

import { useAppState } from "../../../../context";
import PasswordUpdateDialog from "../../../Profile/PasswordUpdateDialog";
import { CustomDialogBox } from "../../../Profile/ProfileInfo/ProfileInfoStyles";

const EditPassword = ({ flag, handleClose }) => {
  const [isShow, setIsShow] = useState(flag);
  const { setLocalPersonalFormInfo, personalForm } = useAppState("profile");
  const handleOnClose = () => {
    setLocalPersonalFormInfo("password", null);
    setIsShow(false);
    handleClose && handleClose();
  };
  return (
    <CustomDialogBox
      open={isShow}
      title={personalForm.is_sso_user ? "Set password" : "Change password"}
      handleClose={handleOnClose}
      isDisplayCloseButton={true}
      className="profile-accessibility-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <PasswordUpdateDialog setUpdateEmailPopUp={handleOnClose} />
    </CustomDialogBox>
  );
};

export default memo(EditPassword);
