import React from "react";
import SavedSearchesStyles from "./SavedSearchesStyles";
import SavedSearches from "../../../../components/Jobs/SavedSearches/SavedSearches";

const SavedSearchesList = () => {
  return (
    <SavedSearchesStyles>
      <div className="saved-searches">
        <SavedSearches />
      </div>
    </SavedSearchesStyles>
  );
};
export default SavedSearchesList;
