import React, { useState, useEffect } from "react";
import filter from "underscore/modules/filter";
import values from "underscore/modules/values";

import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import profileArrowIcon from "../../../../assets/svg/profileDownArrowIcon.svg";
import rateInterstride from "../../../../assets/png/f-rate-interstride-step5.png";
import BlueStarEmptyIcon from "../../../../assets/svg/f-unclicked-star.svg";
import BlueStarFillIcon from "../../../../assets/svg/f-clicked-star.svg";
import { sendFeedBackAPI } from "../../../../services/helpCenterServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import CustomRating from "../../../CustomRating/CustomRating";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import BoostPopup from "../../../../components/BoostDialog/BoostDialog";
import { useAppState } from "../../../../context";

const RateInterstride = () => {
  const { statusList, setStatusList } = useAppState("boostProfile");
  const [showModel, SetModel] = useState(false);
  const [showModel3, SetModel3] = useState(false);
  const [currentRate, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [point, setPoint] = useState(0);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [totalTrueValues, settotalTrueValues] = useState(0);

  const [callingSendFeedBackAPI, refreshSendFeedBackAPI] =
    usePromise(sendFeedBackAPI);

  const ModelHandler = (status) => {
    SetModel(status);
    if (!status) {
      setRating(0);
      setPoint(0);
      setComment("");
    }
  };

  const handleRating = (value) => {
    setRating(value);
  };

  const hanldeChange = (e) => {
    setComment(e.target.value);
  };

  const handlePoint = (p) => {
    setPoint(p);
  };

  const handleSubmit = () => {
    const payload = {
      send_feedback: {
        rate: currentRate,
        comment: comment,
        recommended_rate: point,
      },
    };
    if (currentRate > 0 && point > 0 && comment !== "") {
      refreshSendFeedBackAPI(payload);
    } else {
      toastify("error", "Please complete all the fields.");
    }
  };

  useEffect(() => {
    if (callingSendFeedBackAPI.hasFetched()) {
      if (callingSendFeedBackAPI.hasErrors()) {
        toastify("error", "Something went wrong. Please try again.");
      } else {
        SetModel(false);
        setRating(0);
        setPoint(0);
        setComment("");

        if (
          callingSendFeedBackAPI.data().data &&
          callingSendFeedBackAPI.data().data.boost_profile &&
          callingSendFeedBackAPI.data().data.boost_profile.rate_interstride
        ) {
          setStatusList(callingSendFeedBackAPI.data().data.boost_profile);

          const response = filter(
            values(callingSendFeedBackAPI.data().data.boost_profile),
            function (data) {
              return data == true;
            }
          ).length;

          settotalTrueValues(response);

          console.log("response", response);

          setShowBoostPopup(true);
        }
      }
    }
  }, [callingSendFeedBackAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  return (
    <>
      <div className="status-wrapper">
        <div className="status-title text-primary">How to do it</div>
        <div className="status-sub-title">
          Go to the Help Center and click on the banner below to leave feedback.
        </div>
        <div className="status-box d-flex justify-content-center align-items-center flex-flow-column rate-interstride pos-relative">
          <div className="rate-wrapper d-flex justify-content-center align-items-center flex-flow-column">
            <img
              src={profileArrowIcon}
              className="rate-arrow"
              alt="Down Arrow"
            />
            <img
              src={rateInterstride}
              className="steps-img step5"
              alt="Rate Interstride"
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <ButtonCustom
              height={60}
              width={248}
              onClick={() => ModelHandler(!showModel)}
            >
              Give Us Feedback
            </ButtonCustom>
          </div>
        </div>
      </div>

      {showModel && (
        <div className="feedback-model">
          <CustomDialog
            className="feedback-dialog"
            open={showModel}
            title="We’d love to hear your feedback"
            dialogTitleClass="modal-title"
            handleClose={() => ModelHandler(!showModel)}
          >
            <div className="model-body">
              <div className="rating-box">
                <div className="sub-header-primary">Give us a rating*</div>
                <div className="custom-rating">
                  <CustomRating
                    start={0}
                    stop={5}
                    emptySymbol={
                      <img src={BlueStarEmptyIcon} alt="Non-rated star" />
                    }
                    fullSymbol={<img src={BlueStarFillIcon} alt="Rated star" />}
                    fractions={1}
                    value={currentRate}
                    onClick={(rate) => handleRating(rate)}
                    type="stars"
                  />
                </div>
              </div>
              <div className="about-box">
                <TextboxCustom
                  name="about"
                  placeholder="Tell us more..."
                  isMultiline={true}
                  value={comment}
                  onChange={hanldeChange}
                  label={"Tell us more*"}
                  lableAignMent="center"
                  className="custom-textarea"
                  maxLength={1000}
                />
              </div>
              <div className="friend-refer">
                <div className="sub-header-primary title">
                  Would you recommend Interstride to a friend?*
                </div>
                <div className="points-rating-wrapper">
                  <CustomRating
                    start={0}
                    stop={10}
                    fractions={1}
                    value={point}
                    onClick={(rate) => handlePoint(rate)}
                    type="points"
                    highlightSelectedOnly
                  />
                </div>
                <div className="like">
                  <div className="sub-header-primary">Not Likely</div>
                  <div className="sub-header-primary ml-auto text-right">
                    Extremely likely
                  </div>
                </div>
              </div>
              <div className="btn">
                <ButtonCustom width="248" onClick={() => handleSubmit()}>
                  Submit Feedback
                </ButtonCustom>
              </div>
              <div className="text-center">
                <a
                  href="/#"
                  className="ngray-link"
                  onClick={(e) => {
                    e.preventDefault();
                    ModelHandler(!showModel);
                  }}
                  aria-label="Cancel feedback submission"
                >
                  Cancel
                </a>
              </div>
            </div>
          </CustomDialog>
        </div>
      )}

      {showModel3 && (
        <div className="feedback-model">
          <CustomDialog
            className="feedback-dialog"
            open={showModel3}
            title="Thanks for sharing your feedback!"
            handleClose={() => ModelHandler(!showModel3)}
          >
            <div className="model-body">
              <div className="feedback-reply">
                <div className="sub-header-primary">
                  We value your opinion and will review your feedback soon.
                </div>
              </div>
              <div className="btn">
                <ButtonCustom
                  width="124"
                  onClick={() => SetModel3(!showModel3)}
                  className="got-it"
                >
                  Got It!
                </ButtonCustom>
              </div>
            </div>
          </CustomDialog>
        </div>
      )}

      {showBoostPopup && (
        <BoostPopup
          message={
            totalTrueValues == statusList.length
              ? "Your profile boost is complete"
              : "You've just share your valuable feedback."
          }
          sub_message={
            totalTrueValues == statusList.length
              ? "You're ready to gain the most from the Interstride platform."
              : "You're on the way to gaining the most from the Interstride platform."
          }
          totalSteps={statusList.length}
          totalTrueValues={parseInt(totalTrueValues)}
          closeFn={closeFN}
        />
      )}
    </>
  );
};

export default RateInterstride;
