import React from "react";

import AuthGoogleProfile from "../../../AuthGoogle/AuthGoogleProfile";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import ConnectLinkedin from "../../../ProfileModule/Personal/ConnectLinkedin/ConnectLinkedin";

const DashboardSocialPopup = ({
  setIsUserConnected,
  isUserConnected,
  handleClose,
  callGetBootProfileAPI,
  open,
}) => {
  return (
    <CustomDialog
      title="Connect your accounts"
      subTitle="Connect your account so you can sign-in via LinkedIn or Google."
      open={open}
      handleClose={handleClose}
      padding={50}
      className="sync-events-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <div className="social-accounts-wrap">
        <div className="linkedin accounts">
          <ConnectLinkedin dashboardWidget={true} />
        </div>
        <div className="google accounts">
          <label>Connect your Google account</label>
          <AuthGoogleProfile
            setIsUserConnected={setIsUserConnected}
            isUserConnected={isUserConnected}
            isGoogleConnected={isUserConnected?.google}
            isDisabled={
              isUserConnected?.google || callGetBootProfileAPI.isFetching()
            }
          />
        </div>
        <ButtonCustom
          width={248}
          onClick={() => handleClose()}
          className="focus-white"
        >
          Continue
        </ButtonCustom>
      </div>
    </CustomDialog>
  );
};

export default DashboardSocialPopup;
