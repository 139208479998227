import React from "react";
import TextboxCustomStyles from "./TextboxCustomStyles";
import TextboxStyles from "./TextboxStyles";
import { GRAY_COLOR, PRIMARY_COLOR } from "../../styles/variables";
import cls from "classnames";
import MaskedInput from "react-text-mask";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"x"}
      showMask
    />
  );
};

const TextboxCustom = ({
  onClick = (e) => {},
  onChange = (e) => {},
  onBlur = (e) => {},
  onKeyPress = (e) => {},
  name,
  value,
  type = "text",
  placeholder,
  width = "100%",
  height = 60,
  label = "",
  lableaignment = "center",
  textcolor = GRAY_COLOR,
  isMultiline = false,
  marginBottom = 25,
  rows = 3,
  error = false,
  errorText = "",
  required = false,
  ...props
}) => {
  return (
    <TextboxStyles
      width={width}
      lableaignment={lableaignment}
      marginBottom={marginBottom}
    >
      {label && (
        <label
          style={{
            width: width,
            margin:
              lableaignment === "left"
                ? "0px 0px 8px 20px"
                : "0px 0px 8px 12px",
          }}
          // className={cls("form-label textbox-label", {
          //   "text-primary": error === false,
          //   "text-secondary": error === true
          // })}
          className={cls("form-label textbox-label", "text-primary")}
        >
          {`${label}${required ? "*" : ""}`}
        </label>
      )}
      <TextboxCustomStyles
        lableaignment={lableaignment}
        textcolor={
          !value || value === null || value.replace(/\D/g, "").length === 0
            ? "var(--gray-text-color)"
            : PRIMARY_COLOR
        }
        className="custom-textbox form-control"
        name={name}
        id={name}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        width={width}
        height={height}
        InputProps={{
          inputComponent: TextMaskCustom,
          value,
          onChange: onChange,
          onBlur: onBlur,
          onKeyPress: onKeyPress,
          onClick: onClick,
          error,
          helperText: errorText ? errorText : "",
        }}
        inputProps={{ "aria-label": placeholder }}
        FormHelperTextProps={{
          role: "alert",
          "aria-live": "assertive",
        }}
        {...props}
      />
    </TextboxStyles>
  );
};

export default TextboxCustom;
