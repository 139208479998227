import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt-review-card {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    margin-bottom: 15px;
    padding: 20px 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  text-align: center;
  h2 + .img-container {
    margin-top: 30px;
  }
  .opt-review-title {
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin: 30px 0 15px 0;
    color: var(--primary-dark-color);
    .alert-icon {
      margin: 0;
    }
    img {
      vertical-align: middle;
      margin: -4px 0 0 5px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
      margin: 0 0 10px 0;
    }
  }
  .opt-review-title:first-child {
    margin-top: 0; /* No space above the first element */
  }
`;
