import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .new-msg-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        .MuiDialogContent-root {
          .MuiTypography-root {
            .makeStyles-dialogTitle-4 {
              color: red !important;
            }
          }
        }
      }
    }
  }
`;
