import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .chat-attachments {
    margin-top: 0;
    .chat-attachments__item {
      display: flex;
      align-items: center;
      margin-right: 8px;

      .file {
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        margin-right: 8px;
      }
      .icon {
        background: var(--icon_background);
        display: flex;
        align-items: center;
        width: 35px;
        height: 35px;
        padding: 0 !important;
        min-width: 35px;
        transition: all 0.3s;
        border-radius: 100px;
        justify-content: center;
        margin-left: auto;
        cursor: pointer;
        &:hover {
          background: var(--icon_background_hover);
        }
      }
    }
  }
  .hidden-input {
    position: absolute;
    top: -99999px;
    left: -342432px;
    visibility: hidden;
    opacity: 0;
  }
  .chat-textbox {
    position: relative;
    width: 100%;
    height: auto;
    border: 2px solid var(--gray-outline-color);
    border-radius: 10px;
    padding: 6px 20px 12px 20px;
    .MuiInputBase-multiline {
      padding: 6px 0 7px;
    }
    .label-hidden + .MuiInput-formControl {
      margin-top: 0 !important;
    }
    @media (max-width: 1366px) {
      padding: 8px;
    }
    .chat-textbox__actions {
      position: relative;
      display: flex;
      align-items: center;
      .chat-textbox__actions--icon {
        cursor: pointer;
        background: var(--icon_background);
        width: 35px;
        height: 35px;
        border-radius: 100px;
        min-width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        svg {
          cursor: pointer;
          color: var(--gray-color);
          font-size: 20px;
          fill: var(--primary-dark-color);
          width: 28px;
          font-size: 24px !important;
        }
        &.attachment-icon {
          svg {
            transform: rotate(45deg);
          }
        }
        &.emoji-icon {
          margin-left: auto;
        }
        &:focus {
          box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        }
        &:hover {
          background: var(--icon_background_hover);
        }
      }
      .chat-textbox__actions--emoji-wrapper {
        position: absolute;
        bottom: 40px;
        width: 100%;
      }
    }
  }
`;
