import React, { useEffect } from "react";
import { useState } from "react";
import { useAppState } from "../../context";
import { fetchDepartments } from "../../services/profile.service";
export const useFetchDepartments = (currentSchool, callback) => {
  const { departmentList, setDepartmentsList } = useAppState("profile");

  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const handleDepartments = async () => {
    if (currentSchool) {
      if (callback) {
        callback();
      }
      if (loading) return;
      if (departmentList[currentSchool]) {
        return departmentList[currentSchool];
      }
      setLoading(true);
      (async () => {
        const response = await fetchDepartments(currentSchool);
        if (response?.success) {
          setDepartments(response?.data || []);
          setDepartmentsList(currentSchool, response?.data || []);
          setLoading(false);
        }
      })();
      // return () => {
      //   const controller = new AbortController();
      //   controller.abort();
      // };
    }
  };
  useEffect(() => {
    handleDepartments();
  }, [currentSchool]);

  return { loading, departments };
};
