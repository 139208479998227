import React from "react";

import { useAppState } from "../../../../context";
import { formatOptions } from "../../../../helper/helper";
import { createModuleStyleExtractor } from "../../../../utils/css";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import styles from "./CareerVisa.module.scss";

const cx = createModuleStyleExtractor(styles);

export const CareerVisa = ({ data }) => {
  const { essentials, updateCareerForm } = useAppState("profile") || {};
  const { visa_statuses, eligible_statuses } = essentials || {};
  const { elegible_for_stem_otp, visa_status, sponsored_for_h_1b_lottery } =
    data || {};

  return (
    <div className={cx("o-career-container")}>
      <div className={cx("o-career-container__item")}>
        <DropdownCustom
          label="Are you or will you be elegible for STEM OPT?"
          name="elegible_for_stem_otp"
          options={formatOptions(eligible_statuses) || []}
          onChange={(item) =>
            updateCareerForm("elegible_for_stem_otp", item?.value)}
          isSearch
          value={elegible_for_stem_otp ? { label: elegible_for_stem_otp, value: elegible_for_stem_otp} :  null}
          containerClassName={
            elegible_for_stem_otp?.value ? "a-dropdown-container__options__valid": ""
          }
          placeholder="Select"
          className={`profile-react-custom-dropdown ${elegible_for_stem_otp?.value ? "border-gray" : ""}`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
          isClearable={true}
        />
      </div>
      <div className={cx("o-career-container__item")}>
        <DropdownCustom
          label="What's your current visa status is U.S?"
          name="visa_status"
          options={formatOptions(visa_statuses) || []}
          onChange={(item) =>
            updateCareerForm("visa_status", item?.value)}
          isSearch
          value={visa_status ? { label: visa_status, value: visa_status} : null}
          containerClassName={
            visa_status?.value ? "a-dropdown-container__options__valid": ""
          }
          placeholder="Select"
          className={`profile-react-custom-dropdown ${visa_status?.value ? "border-gray" : ""}`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
          isClearable={true}
        />
      </div>
      <div className={cx("o-career-container__item")}>
        <DropdownCustom
          label="Previously been sponsored for H-1B lottery?"
          name="sponsored_for_h_1b_lottery"
          options={formatOptions(eligible_statuses) || []}
          onChange={(item) =>
            updateCareerForm("sponsored_for_h_1b_lottery", item?.value)}
          isSearch
          value={sponsored_for_h_1b_lottery ? { label: sponsored_for_h_1b_lottery, value: sponsored_for_h_1b_lottery} : null}
          containerClassName={
            sponsored_for_h_1b_lottery?.value ? "a-dropdown-container__options__valid": ""
          }
          placeholder="Select"
          className={`profile-react-custom-dropdown ${sponsored_for_h_1b_lottery?.value ? "border-gray" : ""}`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
          isClearable={true}
        />
      </div>
    </div>
  );
};
