import React, { useEffect, useState } from "react";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomRating from "../CustomRating/CustomRating";
import TextboxCustom from "../Textbox/TextboxCustom";
import BlueStarEmptyIcon from "../../assets/svg/f-unclicked-star.svg";
import BlueStarFillIcon from "../../assets/svg/f-clicked-star.svg";
import { toastify } from "../../helper/helper";
import { sendFeedBackAPI } from "../../services/helpCenterServices";
import usePromise from "../../hooks/usePromise/usePromise";

const FeedbackDialog = ({
  handleModel,
  showModel,
  subModel,
  onClose = () => {},
}) => {
  const [currentRate, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [point, setPoint] = useState(0);

  const [callingSendFeedBackAPI, refreshSendFeedBackAPI] =
    usePromise(sendFeedBackAPI);

  const ModelHandler = (status) => {
    handleModel(status);
    onClose();
    if (!status) {
      setRating(0);
      setPoint(0);
      setComment("");
    }
  };

  const handleRating = (value) => {
    setRating(value);
    // alert('Rating' + value)
  };
  const hanldeChange = (e) => {
    setComment(e.target.value);
  };
  const handlePoint = (p) => {
    setPoint(p);
  };

  const handleSubmit = () => {
    const payload = {
      send_feedback: {
        rate: currentRate,
        comment: comment,
        recommended_rate: point,
      },
    };
    if (currentRate > 0 && point > 0 && comment !== "") {
      refreshSendFeedBackAPI(payload);
    } else {
      toastify("error", "Please complete all the fields.");
    }
  };

  useEffect(() => {
    if (callingSendFeedBackAPI.hasFetched()) {
      if (callingSendFeedBackAPI.hasErrors()) {
        toastify("error", "Something went wrong. Please try again.");
      } else {
        // toastify("success", callingSendFeedBackAPI.data().message);
        handleModel(false);
        setRating(0);
        setPoint(0);
        setComment("");
        subModel(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingSendFeedBackAPI.isFetching()]);

  return (
    <div className="feedback-model">
      <CustomDialog
        className="feedback-dialog"
        open={showModel}
        title="We’d love to hear your feedback"
        dialogTitleClass="modal-title"
        handleClose={() => ModelHandler(!showModel)}
      >
        <div className="model-body">
          <div className="rating-box">
            <div className="sub-header-primary">Give us a rating*</div>
            <div className="custom-rating">
              <CustomRating
                start={0}
                stop={5}
                emptySymbol={
                  <img src={BlueStarEmptyIcon} alt="Non-rated star" />
                }
                fullSymbol={<img src={BlueStarFillIcon} alt="Rated star" />}
                fractions={1}
                value={currentRate}
                onClick={(rate) => handleRating(rate)}
                type="stars"
              />
            </div>
          </div>
          <div className="about-box">
            <TextboxCustom
              name="about"
              placeholder="Share any thoughts on what is working and how we can improve."
              isMultiline={true}
              value={comment}
              onChange={hanldeChange}
              label={"Tell us more*"}
              lableAignMent="center"
              className="custom-textarea"
              maxLength={1000}
            />
          </div>
          <div className="friend-refer">
            <div className="sub-header-primary title">
              Would you recommend Interstride to a friend?*
            </div>
            <div className="points-rating-wrapper">
              <CustomRating
                start={0}
                stop={10}
                fractions={1}
                value={point}
                onClick={(rate) => handlePoint(rate)}
                type="points"
                highlightSelectedOnly
              />
            </div>
            <div className="like">
              <div className="sub-header-primary">Not Likely</div>
              <div className="sub-header-primary ml-auto text-right">
                Extremely likely
              </div>
            </div>
          </div>
          <div className="btn">
            <ButtonCustom width="248" onClick={() => handleSubmit()}>
              Submit FeedBack
            </ButtonCustom>
          </div>
          <div className="text-center">
            <a
              href="#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                ModelHandler(!showModel);
              }}
              aria-label="cancel"
            >
              Cancel
            </a>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

export default FeedbackDialog;
