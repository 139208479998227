import React from "react";

import MobileAppSlideStyles from "./MobileAppSlideStyles";

import { APP_AD_IMAGE } from "../../../../../helper/helper";
import AppGooglePlay from "../../../../../assets/svg/app-google-play.svg";
import AppAppleStore from "../../../../../assets/svg/app-apple-store.svg";

const MobileAppSlide = () => {
  return (
    <MobileAppSlideStyles>
      <div className="mobile-app-slide">
        <div className="title text-primary">Download our new app</div>
        <div className="sub-title text-primary">
          Jobs and internships at your fingertips
        </div>
        <div className="app-image">
          <img src={APP_AD_IMAGE} alt="click" />
        </div>
        <div className="app-buttons">
          <a
            href={
              "https://play.google.com/store/apps/details?id=com.interstride"
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={AppGooglePlay} alt="click" />
          </a>
          <a
            href={"https://apps.apple.com/us/app/interstride/id1254437834"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={AppAppleStore} alt="click" />
          </a>
        </div>
      </div>
    </MobileAppSlideStyles>
  );
};

export default MobileAppSlide;
