import { Link, useMatch } from "react-router-dom";

const NavLink = ({
  to = "",
  onClick = () => {},
  children = <></>,
  setActivePath, // didn't assign default value purposefully for a conditional check
  getProps = () => {},
  onBlur = () => {},
}) => {
  // add the wild card to match deeper URLs
  const match = useMatch(to + "/*");
  setActivePath && match && setActivePath(match.pathname);
  return (
    <Link
      className={match ? "active" : ""}
      onClick={onClick}
      onBlur={onBlur}
      getProps={getProps}
      to={to}
    >
      {children}
    </Link>
  );
};

export default NavLink;
