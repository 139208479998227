import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .textbox-label {
    width: ${(props) => props.width};
    display: flex;
    justify-content: center;
    padding: 5px 0 0 0;
    line-height: 16px;
    margin: 0 0 6px 0px;
    font-size: 15px;
    color: var(--field-label-color);
    font-family: "TTCommons-Regular";
  }
  margin-bottom: 16px;
  .MuiFormHelperText-root.Mui-error {
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    margin: 5px 8px 0px 8px;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
  }
  .css-xb97g8:hover {
    background-color: transparent;
    color: inherit;
  }
`;
