import React, { useState, useEffect } from "react";
import cls from "classnames";

import TextboxCustomStyles from "./TextboxCustomStyles";
import TextboxStyles from "./TextboxStyles";
import { noop } from "../../utils/noop";
import { GRAY_COLOR } from "../../styles/variables";

const TextboxCustom = ({
  onClick = () => {},
  onChange = () => {},
  onBlur = () => {},
  onKeyPress,
  onEnterKey,
  id,
  name,
  value,
  type = "text",
  placeholder,
  width = "100%",
  height = 60,
  label = "",
  lableaignment = "center",
  textcolor = GRAY_COLOR,
  isMultiline = false,
  marginBottom = 16,
  rows = 3,
  error = false,
  errorText = "",
  required = false,
  isDisable = false,
  maxLength = null,
  min,
  max,
  step,
  withInfoIcon = false,
  isEdit = false,
  isSecondEdit = false,
  onEditClick = noop,
  onSecondEditClick = noop,
  labelClass = "",
  ...props
}) => {
  const [_value, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <TextboxStyles
      width={width}
      lableaignment={lableaignment}
      marginBottom={marginBottom}
      className="field-group"
    >
      {label && (
        <label className={cls(["textbox-label", "text-primary", labelClass])}>
          {label}
          <div className={cls("edit-option-container")}>
            {isEdit && (
              <a
                href="#"
                className={cls("edit-option")}
                onClick={(e) => {
                  e.preventDefault();
                  onEditClick();
                }}
              >
                {isEdit}
              </a>
            )}
            {isSecondEdit && (
              <>
                <span>{" | "}</span>
                <a
                  href="#"
                  className={cls("edit-option")}
                  onClick={(e) => {
                    e.preventDefault();
                    onSecondEditClick();
                  }}
                >
                  {isSecondEdit}
                </a>
              </>
            )}
          </div>
        </label>
      )}
      <TextboxCustomStyles
        lableaignment={lableaignment}
        textcolor={textcolor}
        className={cls([
          "custom-textbox",
          "form-control",
          props.readonly ? "cursor-notallowed" : "",
        ])}
        name={name}
        id={id ? id : name}
        type={type}
        value={name === "gpa" ? _value : value}
        variant="outlined"
        placeholder={placeholder}
        width={width}
        height={height}
        onClick={(e) => onClick(e)}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onKeyPress={
          onKeyPress || onEnterKey
            ? (e) => {
                if (onKeyPress) onKeyPress(e);
                else if (e.key === "Enter" && onEnterKey) onEnterKey(e);
              }
            : () => {}
        }
        multiline={isMultiline}
        minRows={isMultiline ? rows : 0}
        error={error}
        helperText={errorText ? errorText : ""}
        disabled={isDisable}
        required={required}
        FormHelperTextProps={{
          role: "alert",
          "aria-live": "assertive",
        }}
        {...props}
        {...(maxLength !== null
          ? {
              inputProps: {
                ...(type === "gpa" && {
                  value: _value,
                }),
                ...(step && { step }),
                ...(min && {
                  min,
                }),
                ...(max && {
                  max,
                }),
                maxLength,
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
                autoComplete: "new-password",
              },
            }
          : {
              inputProps: {
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
                autoComplete: "new-password",
              },
            })}
      />
    </TextboxStyles>
  );
};

export default TextboxCustom;
