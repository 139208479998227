import styled from "styled-components";
export default styled.div`
  h2.sidebar-title {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 15px 0;
    padding-top: 2px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
      margin: 0 0 10px 0;
      text-align: center;
    }
  }
  .profile-status-card {
    padding: 0;
    @media (max-width: 991px) {
      margin-top: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        a {
          padding: 13px 15px 12px 20px;
          font-family: "TTCommons-Medium";
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          cursor: pointer;
          text-decoration: none;
          border-radius: 100px;
          box-shadow: var(--card-box-shadow);
          color: var(--primary-dark-color);
          background: #fff;
          transition: all 0.3s;
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }
          span {
            padding-top: 4px;
          }
          &.text-white {
            color: #fff;
            background: var(--primary-main-color);
          }
          &.disabled {
            color: var(--gray-text-color);
            cursor: not-allowed;
          }
        }
        + li {
          margin-top: 10px;
        }
        .incompleted-profile {
          border-radius: 100px !important;
          background-color: var(--secondary-color);
          width: 97px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-family: "TTCommons-Regular";
          padding-top: 4px;
          line-height: 17px;
          color: #fff;
        }
        .completed-profile {
          border-radius: 100px !important;
          background-color: #f1f4f7;
          width: 97px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-family: "TTCommons-Regular";
          padding-top: 4px;
          line-height: 17px;
          color: var(--primary-dark-color);
          img {
            margin: -4px 0 0 5px;
          }
        }
        .completed-only-icon {
          width: 25px;
          img {
            margin: initial !important;
          }
        }
      }
    }
  }
`;
