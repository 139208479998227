import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
    .align-items-center {
      justify-content: center;
    }
    .saved-jobs-list{
        max-width:670px;
        margin:0 auto;
        position:relative;
        .sort-by{
          position: absolute;
          top: -66px;
          right: -60px;
          @media(max-width:991px){
            position: initial;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 8px;
            max-width: 160px;
            margin-left: auto;
          }
        }
        .search-item {
            display : flex;
            flex-wrap : no-wrap;
            align-items: center;
            .avtar {
              margin-right: 15px;
              .MuiAvatar-root {
                width: 50px;
                height: 50px;
                border-radius: 100px;
              }
            }
            .content {
                margin-right: auto;
                max-width: calc(100% - 190px);
                  @media (max-width: 600px) {
                    max-width: 100%;
                  }
                .header-primary {
                    display: flex;
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                    display: block;
                    @media (max-width: 600px) {
                      width: 100%;
                      display: block;
                      white-space: normal;
                    }
                }
            }
            .card-actions-wrapper{
              display: flex;
              align-items: center;
              padding-left:8px;
              opacity:0;
              visibility:hidden;
              @media(max-width:1024px){
                opacity:1;
                visibility:visible;
              }
              &.show-actions {
                opacity: 1;
                visibility: visible;
              }
              .nlink-btn{
                width: auto;
                min-width: 80px;
                white-space: nowrap;
              }
              div {
                + div{
                  margin-left:12px;
                  @media(max-width:991px){
                    margin-left:8px;
                  }
                }
              }
            }
        }
        .card-wrapper{
            .MuiPaper-root {
                padding: 20px 15px !important;
                margin-bottom: 15px;
                transition:all .3s;
                cursor:pointer;
                @media(max-width:767px){
                  padding: 15px 15px !important;
                }

                &:hover {
                  box-shadow: 0 1px 14px 3px var(--gray-outline-color);
                  .search-item{
                    .card-actions-wrapper{
                      opacity: 1;
                      visibility:visible;
                    }
                  }
                }
                .MuiCardContent-root {
                    padding: 0;
                    .employer-job img{
                      object-fit: scale-down !important;
                    }
                }
            }
        }
    }
    .empty-alerts-box {
      .card-content {
        padding: 39px 18px 30px 18px;
        box-sizing: border-box;
      }
      .empty-item {
        text-align: center;
        .header-primary {
          font-size: 22px;
          line-height: 21px;
          margin: 0 0 10px 0;
        }
        .text-grey {
          font-size: 18px;
          line-height: 17px;
          font-family: "TTCommons-Medium";
        }
    }
`;
