import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .search-filter {
    .react-custom-dropdown {
      div[class*="-placeholder"] {
        color: var(--field-label-color) !important;
      }
    }
    .ngray-link {
      margin-left: 12px;
    }
    .search-card {
      padding: 20px 20px;
      overflow: visible;
      position: relative;
      .MuiCardContent-root {
        padding: 0 0 !important;
        @media (max-width: 991px) {
          padding: 16px !important;
        }
      }
      .searchBtn {
        display: block;
        button {
          width: 100%;
          background-color: var(--link-color) !important;
        }
      }
      .save-search {
        text-align: center;
        .text-secondary {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          text-decoration: none;
          border-bottom: 1px solid var(--secondary-color);
        }
      }
    }
  }
`;
