import React from "react";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import moment from "moment";
import isNull from "underscore/modules/isNull";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../../helper/helper";
import smallPersonIcon from "../../../../assets/svg/f-small-person-icon.svg";
import CircleCheck from "../../../../assets/svg/f-circle-check.svg";
import webinarIcon from "../../../../assets/png/Others.png";

const Dialog = ({
  handleClose,
  isOpen,
  details,
  loader = false,
  type,
  btn,
  isAdmin,
}) => {
  return (
    <>
      <CustomDialog
        open={isOpen}
        // title="Job Search Strategy for International Students"
        // subTitle="Thursday, June 18th 2019"
        /* title={details && details.name} */
        handleClose={handleClose}
        className={`webinar-dialog ${loader && "web-detail-opening"}`}
        /* loader={loader} */
      >
        {details && (
          <div className="webinar-dialog-content">
            <div className="img-left">
              {details.webinar_image && details.webinar_image.length > 0 ? (
                //  eslint-disable-next-line
                <img src={details.webinar_image} alt="Webinar Display" />
              ) : (
                <img
                  className="default-image"
                  src={webinarIcon}
                  alt="Webinar Image"
                />
              )}

              {/* <img src={details.webinar_image} alt="Remy Sharp" /> */}
              {details.webinar_category_name && (
                <div className="webinarCategory">
                  {details.webinar_category_name}
                </div>
              )}
            </div>
            <div className="header-primary">{details.name} </div>
            <div className="decsription">{details.description}</div>
            {details.presenter && details.presenter.length > 0 && (
              <h5>
                <img src={smallPersonIcon} alt="Small person icon" />
                {details.presenter}
              </h5>
            )}

            <h3 className="date">
              {moment
                .unix(details?.epoch_time)
                .local()
                .format("dddd, MMMM Do, YYYY")}
            </h3>
            <h3 className="time">
              {`${moment
                .unix(details?.epoch_time)
                .local()
                .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                details?.epoch_time,
                details && details.duration && !isNull(details.duration)
                  ? details.duration.split(" ")[0]
                  : 30,
                "minutes"
              )}`}{" "}
              ({getLocalTzAbbr()})
            </h3>
            <div className="btn">
              {type === "upcoming" && details.register_link !== "" && (
                <ButtonCustom
                  width="200"
                  height="50"
                  // className='up-btn bg-gray' // ng-gray should conditional
                  className={`up-btn ${btn.className}`} // ng-gray should conditional
                  onClick={() => window.open(details.register_link)}
                  isDisabled={
                    btn.disabled ||
                    (details?.webinar_category_name === "Admin Training" &&
                      !isAdmin)
                  }
                >
                  {btn.showIcon ? (
                    <div className="flex justify-between align-items-center">
                      <span className="pt-3">{btn.text}</span>
                      <img src={CircleCheck} className="button-image" />
                    </div>
                  ) : (
                    <div>{btn.text}</div>
                  )}
                </ButtonCustom>
              )}
              {type === "past" && details.recording_link !== "" && (
                <ButtonCustom
                  width="200"
                  height="50"
                  className="past-btn"
                  onClick={() => window.open(details.recording_link)}
                >
                  {/* {btnText} */}
                  {btn.text}
                </ButtonCustom>
              )}
            </div>
          </div>
        )}
      </CustomDialog>
    </>
  );
};

export default Dialog;
