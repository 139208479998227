import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SectionFooter.module.scss";
import { useAppState } from "../../../context";

import RemoveIcon from "../../../assets/svg/RemoveIcon";

const cx = createModuleStyleExtractor(styles);

export const SectionFooter = ({ label, isRemove = false, handleClick }) => {
  const { profile } = useAppState("profile");
  const { isProfileCompleted } = profile || {};
  return (
    <div
      className={cx([
        "o-professional-language__footer",
        isProfileCompleted ? "o-professional-language__footer__completed" : "",
      ])}
    >
      <a
        className={cx("o-professional-language__footer__footer-container")}
        href="/#"
        onClick={(e) => {
          e.preventDefault();
          handleClick(isRemove);
        }}
      >
        <span>{label}</span>
        <div>{isRemove ? <RemoveIcon /> : "+"}</div>
      </a>
    </div>
  );
};
