import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .discover-topics-content {
    max-width: 1015px;
    margin: 0 auto;

    .sort-by {
      margin-bottom: 8px;
      justify-content: flex-end;
    }
    .info-panel-card {
      padding: 20px;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        padding: 15px;
        margin-bottom: 10px;
      }
      .card-content {
        @media (max-width: 767px) {
          flex-wrap: wrap;
          align-items: flex-start;
        }
        .MuiAvatar-root {
          width: 90px;
          height: 90px;
          min-width: 90px;
          @media (max-width: 767px) {
            width: 70px;
            height: 70px;
            min-width: 70px;
          }
        }
        .panel-header {
          margin-left: 0;
          width: 100%;
          padding-right: 50px;
          padding-left: 18px;
          padding-top: 4px;
          @media (max-width: 767px) {
            width: calc(100% - 70px);
            padding: 0 0 0 15px;
          }
          .panel-title {
            padding-top: 0;
            margin-bottom: 4px;
            @media (max-width: 767px) {
              flex-wrap: wrap;
              margin-bottom: 0;
            }
            > div {
              &:first-child {
                @media (max-width: 767px) {
                  width: 100%;
                }
              }
            }
            .tags {
              margin-top: -6px;
              @media (max-width: 767px) {
                margin: 0 0 4px 0;
              }
              .tag-item {
                margin: 0 8px 0px 0;
              }
            }
          }
          .panel-intro {
            .description {
              color: var(--gray-text-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              width: 100%;
              overflow: visible;
              max-width: 100%;
              white-space: normal;
            }
          }
          .panel-info-contact {
            display: flex;
            align-items: center;
            margin: 2px 0 0 0;
            @media (max-width: 480px) {
              flex-wrap: wrap;
              margin-top: 4px;
            }
            .member-count {
              font-family: "TTCommons-Medium";
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              color: var(--primary-dark-color);
              margin-right: 15px;
              margin-top: 3px;
              @media (max-width: 480px) {
                margin-bottom: 4px;
              }
            }
            .type-global {
              span {
                display: inline-block;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--link-color);
                margin-right: 15px;
                margin-top: 4px;
                @media (max-width: 480px) {
                  margin-bottom: 4px;
                }
              }
            }
            .created-by {
              display: flex;
              align-items: center;
              position: relative;
              > span {
                color: var(--field-label-color);
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                text-align: center;
                margin-right: 8px;
                margin-top: 3px;
                &:first-child {
                  white-space: nowrap;
                }
              }
              a {
                display: flex;
                align-items: center;
                text-decoration: none;
                .MuiAvatar-root {
                  width: 25px;
                  height: 25px;
                  min-width: 25px;
                  margin-right: 8px;
                }
                span {
                  font-family: "TTCommons-Regular";
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  color: var(--primary-dark-color);
                  margin-right: 20px;
                  margin-top: 3px;
                }
              }
              &__popup {
                position: absolute;
                top: 30px;
                right: 20px;
                width: 325px;
                z-index: 30;
              }
            }
          }
        }

        .panel-actions {
          width: 100%;
          max-width: 144px;
          @media (max-width: 767px) {
            margin-top: 8px;
            margin-left: 85px;
          }
          button {
            background: var(--link-color) !important;
            width: 100%;
            height: 50px;
          }
        }
      }
    }
  }
`;
