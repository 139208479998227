import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .category-card {
    .category-list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {
          padding: 10px 15px 10px 15px;
          text-decoration: none;
          box-shadow: var(--card-box-shadow);
          background: #fff;
          border-radius: 100px;
          min-height: 50px;
          display: flex;
          align-items: center;
          color: var(--primary-dark-color);
          transition: all 0.3s;
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }

          span {
            padding-top: 5px;
          }

          img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
          span {
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            border-bottom: 1px solid transparent;
            padding-top: 5px;
            border-bottom: 1px solid transparent;
          }
        }

        &.active {
          a {
            background: ${PRIMARY_COLOR};
            color: #fff;
          }
        }
        + li {
          margin-top: 10px;
        }
      }
    }
  }
`;
