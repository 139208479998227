import React, { useEffect } from "react";
import { useAppState } from "../../../context";
import { uniqueId } from "../../../utils/common";
export const ProfileExperience = ({ children }) => {
  const { experienceForm, profile, setLocalExperienceForm } =
    useAppState("profile");
  const { experience } = profile || {};
  const { experiences, extra_experiences, languages } = experience || {};
  // console.log(experienceForm);
  //expereinces,extra_expereinces,languages

  useEffect(() => {
    if (profile && profile?.experience) {
      setLocalExperienceForm({
        ...experienceForm,
        experiences: [
          ...experiences,
          ...(!experiences?.length ? [{ draftId: uniqueId() }] : []),
        ],
        extra_experiences: [
          ...extra_experiences,
          ...(!extra_experiences?.length ? [{ draftId: uniqueId() }] : []),
        ],
        languages: [
          ...languages?.filter((item) => item?.level && item?.name),
          ...(!languages?.length ? [{ draftId: uniqueId() }] : []),
        ],
      });
    }
  }, [profile]);
  return children({
    experiences: experienceForm,
    loaded: true,
    loading: false,
  });
};
