export const defaultState = {
  isEditEmail: false,
  isEditSecondaryEmail: false,
  isEditImage: false,
  cities: [],
  cropperImage: undefined,
  personalForm: null,
  accessibilityHelp: localStorage.getItem("accessibilityHelp"),
  isPassword: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "state.update":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "state.updateSecondaryEmail":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "state.bulk.update":
      return {
        ...state,
        ...action.payload,
      };
    case "state.personal.update":
      return {
        ...state,
        personalForm: { ...state?.personalForm, ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
