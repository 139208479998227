import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .sub-menu-notification-badge {
    margin-left: 6px;
    margin-top: 3px;
    background: #0030b5;
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 2px;
    padding-top: 4px;
    border-radius: 12px;
    font-size: 14px;
    font-family: "TTCommons-Regular";
  }
  .sub-menu {
    display: flex;
    flex-wrap: wrap;
    background: #fafbfd;
    padding: 0;
    margin: 0;
    justify-content: center;
    list-style: none;
    border-bottom: 2px solid #ebf1f7;
    padding: 10px 0 10px;
    min-height: 56px;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }
    li {
      position: relative;
      display: flex;
      align-items: center;
      > div {
        position: relative;
        z-index: 1;
      }
      + li {
        margin-left: 82px;
      }
      &.active {
        text-decoration: none;
        &:before {
          content: "";
          background: url(/f-subheader-active-arrow.svg);
          width: 22px;
          height: 22px;
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
          left: 50%;
          bottom: -30px;
          transform: translateX(-50%);
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background: #fafbfd;
          left: 50%;
          transform: translateX(-50%);
          bottom: -12px;
          z-index: 0;
        }
      }
      a {
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        white-space: nowrap;

        img {
          display: block;
          margin: 0 8px 0 0;
          min-width: 35px;
          width: 35px;
          box-sizing: border-box;
          height: 35px;
        }
        .appointmentIcon {
          width: 45px;
          height: 40px;
        }
        span {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          padding: 4px 0 0;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 19px;
          margin-top: 1px;
          border-bottom: 1px solid transparent;
        }
        &:focus {
          span {
            border-bottom-color: var(--primary-dark-color) !important;
          }
        }
      }
    }
    + h1 {
      font-family: "TTCommons-DemiBold";
      margin: 50px 0 47px;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center;
    }
  }
`;
