import React from "react";
import cls from "classnames";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDialogStyles from "./CustomDialogStyles";
import BackArrow from "../../assets/svg/back-arrow.svg";
import BackArrowFocusIcon from "../../assets/FocusIcons/Common//BackArrow.svg";
import Close from "../../assets/svg/close.svg";
import Loader from "../Loader/Loader";
import { PRIMARY_COLOR } from "../../styles/variables";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "0 1px 14px 3px rgba(128,148,171,0.4)",
    borderRadius: "10px",
    overflow: "hidden",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    position: "relative",
  },
  dialogTitle: {
    color: "var(--primary-dark-color)",
    fontSize: "22px",
    fontFamily: '"TTCommons-Medium"',
    maxWidth: "355px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "24px",
    fontWeight: "500",
    margin: "0px",
  },
  dialogSubTitle: {
    color: "var(--primary-dark-color)",
    fontSize: "15px",
    fontFamily: '"TTCommons-Regular"',
    maxWidth: "340px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "17px",
    marginTop: "10px",
  },
  dialogDescription: {
    color: PRIMARY_COLOR,
    fontSize: "18px",
    maxWidth: "336px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "60px",
  },
  btnClose: {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
  },
  btnBack: {
    position: "absolute",
    top: 0,
    left: 0,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontFamily: '"TTCommons-Medium"',
    color: PRIMARY_COLOR,
  },
}));
const CustomDialog = ({
  open = false,
  title = "",
  subTitle = "",
  Title2 = "",
  showMoreTitle = false,
  width = 670,
  height,
  children,
  isDisplayCloseButton = true,
  isDisplayBackButton = false,
  backButtonLabel = "",
  handleClose = () => {},
  handleBack = () => {},
  padding = 20,
  marginTop = 0,
  className,
  loader = false,
  dialogTitleClass = null,
  dialogSubTitleClass = null,
  dialogContentClassname = "",
  fullWidth = false,
  maxWidth = "md",
  scroll = "paper",
  overrideDefaultWidth = true, //this is because width prop was assigned default value
  disableBackdropClick = false,
}) => {
  const classes = useStyles();

  return (
    <CustomDialogStyles>
      <Dialog
        onClose={() => handleClose()}
        open={open}
        className={cls(className, classes.root)}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={true}
        aria-labelledby="modal-title"
        TransitionProps={{ role: "presentation" }}
        scroll={scroll}
      >
        {loader ? (
          <Loader />
        ) : (
          <DialogContent
            style={{
              ...(width && overrideDefaultWidth && { width: `${width}px` }),
              ...(height && { height: `${height}px` }),
            }}
            className={dialogContentClassname}
          >
            <DialogContentText
              component="div"
              id="modal-description"
              style={{ padding: `${padding}px`, marginTop: `${marginTop}px` }}
            >
              <div className={classes.dialogHeader}>
                {isDisplayBackButton && (
                  <div className={classes.btnBack}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleBack();
                      }}
                      href="/#"
                      aria-label="Back"
                      className="nback-arrow-link link-focus"
                      onFocus={() => {
                        let img = document.getElementById(`BackArrow`);
                        if (img) img.src = BackArrowFocusIcon;
                      }}
                      onBlur={() => {
                        let img = document.getElementById(`BackArrow`);
                        if (img) img.src = BackArrow;
                      }}
                    >
                      <img
                        src={BackArrow}
                        id="BackArrow"
                        className="back-link"
                        width="18px"
                        alt="Back arrow"
                      />
                      {backButtonLabel && (
                        <span style={{ marginTop: "3px" }}>
                          {backButtonLabel}
                        </span>
                      )}
                    </a>
                  </div>
                )}
                {title !== false && (
                  <h2
                    id="modal-title"
                    className={`${classes.dialogTitle} modal-title ${
                      dialogTitleClass ?? ""
                    }`}
                  >
                    {title}
                  </h2>
                )}

                {showMoreTitle && (
                  <h2
                    id="modal-title"
                    className={`${classes.dialogTitle} modal-title`}
                  >
                    {Title2}
                  </h2>
                )}

                {subTitle && (
                  <div
                    className={
                      dialogSubTitleClass !== null
                        ? dialogSubTitleClass
                        : classes.dialogSubTitle
                    }
                  >
                    {subTitle}
                  </div>
                )}
                {isDisplayCloseButton && (
                  <div className="closeModal">
                    <a
                      href="/#"
                      className="card-close-btn modal-close-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose(e);
                      }}
                    >
                      <img
                        src={Close}
                        id="CloseIcon"
                        width="18px"
                        alt={`Close ${title ? title : ""} Dialog`}
                      />
                    </a>
                  </div>
                )}
              </div>
              {children}
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
    </CustomDialogStyles>
  );
};

export default CustomDialog;
