import React from "react";

import CustomDialog from "../../../CustomDialog/CustomDialog";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { communitiesTabOptions } from "../../../../utils/common";
import { useAppState } from "../../../../context";

const Dialog = ({ handleClose = () => {}, isOpen = false }) => {
  const { changeTab } = useAppState("topic");

  const handleClickDiscover = () => {
    handleClose();
    changeTab(communitiesTabOptions[1]);
  };

  return (
    <div>
      <CustomDialog
        open={isOpen}
        title="Looks like you haven’t followed any Community yet."
        subTitle="Click below to discover Communities and add one to your list."
        handleClose={handleClose}
        className="discover-topic-dialog"
        dialogTitleClass="title"
        dialogSubTitleClass="sub-title"
        TransitionProps={{ role: "presentation" }}
      >
        <div className="modal-btn-wrap">
          <div className="btn">
            <ButtonCustom width="250" height={60} onClick={handleClickDiscover}>
              Discover Communities
            </ButtonCustom>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

export default Dialog;
