import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/lab";

import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MuiPagination.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function MuiPagination({
  page = 1,
  total_pages = 1,
  per_page = 10,
  changePaginationFn = () => {},
}) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState({
    value: per_page,
    label: per_page,
  });

  useEffect(() => {
    if (page && page !== selectedPage) {
      setSelectedPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (per_page) {
      setPageSize({ value: per_page, label: per_page });
    }
  }, [per_page]);

  const handleChange = (event, value) => {
    setSelectedPage(value);
    changePaginationFn(value, pageSize.value);
  };

  return (
    <div className={cx("a-pagination")}>
      <div className={cx("a-pagination__dropdown")}>
        <span>Rows per page : </span>
        <DropdownCustom
          name="Rows"
          title="Rows"
          value={pageSize}
          height={40}
          options={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          onChange={(data) => {
            setPageSize(data);
            changePaginationFn(1, data.value);
          }}
          ariaLabel="Rows"
        />
      </div>
      <div className={cx("a-pagination__body")}>
        <Pagination
          count={total_pages}
          page={selectedPage}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
