import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getNotificationSettingsAPI,
  notificationSubscribeAPI,
  updateNotificationSettingsAPI,
} from "../../../services/notificationServices";
import Container from "../../../components/Container/Container";
import NotificationCategories from "../../../components/Notification/NotificationSettings/NotificationCategories/NotificationCategories";
import NotificationSettings from "../../../components/Notification/NotificationSettings/NotificationSettings/NotificationSettings";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import { updatePersistState } from "../../../redux/reducers/userReducer";

export const NotificationManage = () => {
  const dispatch = useDispatch();
  const { users = {}, permissions = {} } = useSelector(
    (store) => store.userStore
  );

  const [activeTab, setActiveTab] = useState("jobs");
  const [isFetching, setFetching] = useState(false);
  const [subscribeEmail, setEmailSubscribe] = useState(false);
  const [subscribePush, setPushSubscribe] = useState(false);
  const [callingGetNotificationSettingsAPI, refreshGetNotificationSettingsAPI] =
    usePromise(getNotificationSettingsAPI);
  const [
    callingGetNotificationSubscribeAPI,
    refreshGetNotificationSubscribeAPI,
  ] = usePromise(notificationSubscribeAPI);
  const [
    callingUpdateNotificationSettingsAPI,
    refreshUpdateNotificationSettingsAPI,
  ] = usePromise(updateNotificationSettingsAPI);

  const { setNotificationSettings } = useAppState("notifications");

  const { id: userId } = users || {};

  useEffect(() => {
    setFetching(true);
    refreshGetNotificationSettingsAPI(userId);

    permissions["chat"]
      ? setActiveTab("chat")
      : permissions["topics"]
      ? setActiveTab("topics")
      : setActiveTab("jobs");
  }, []);

  useEffect(() => {
    setEmailSubscribe(
      users && users.unsubscribe_notifications
        ? users.unsubscribe_notifications
        : false
    );

    setPushSubscribe(
      users && users.unsubscribe_push_notifications
        ? users.unsubscribe_push_notifications
        : false
    );
  }, [users]);

  const SubscribeEmailFn = () => {
    var emailStatus = !subscribeEmail;
    setEmailSubscribe(emailStatus);

    if (emailStatus == false) {
      var types = [
        "webinars",
        "resources",
        "services",
        "events",
        "user_posts",
        "job_saved_searches",
        "recommended_jobs",
        "followed_topics",
        "created_topics",
        "new_topics",
        "follow_requests",
        "group_messages",
        "group_invites",
        "network_messages",
      ];
    } else {
      types = [];
    }
    const settingObj = {
      is_email_notification: true,
      notification_setting: {
        email_notifications: types,
      },
    };

    refreshGetNotificationSubscribeAPI("email");
    refreshUpdateNotificationSettingsAPI(settingObj);
  };

  const SubscribePushFn = () => {
    let pushStatus = !subscribePush;
    setPushSubscribe(pushStatus);
    if (pushStatus == false) {
      var types = [
        "webinars",
        "resources",
        "services",
        "events",
        "user_posts",
        "job_saved_searches",
        "recommended_jobs",
        "followed_topics",
        "created_topics",
        "new_topics",
        "follow_requests",
        "group_messages",
        "group_invites",
        "network_messages",
      ];
    } else {
      types = [];
    }
    const settingObj = {
      is_push_notification: true,
      notification_setting: {
        push_notifications: types,
      },
    };

    refreshGetNotificationSubscribeAPI("push");
    refreshUpdateNotificationSettingsAPI(settingObj);
  };

  useEffect(() => {
    if (
      callingUpdateNotificationSettingsAPI.hasFetched() &&
      callingUpdateNotificationSettingsAPI.hasErrors() === false &&
      callingUpdateNotificationSettingsAPI.data()
    ) {
      setNotificationSettings(
        callingUpdateNotificationSettingsAPI.data().data.notifications
      );
      setFetching(true);
      refreshGetNotificationSettingsAPI(userId);
    }
  }, [callingUpdateNotificationSettingsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetNotificationSubscribeAPI.hasFetched() &&
      callingGetNotificationSubscribeAPI.hasErrors() === false &&
      callingGetNotificationSubscribeAPI.data() &&
      callingGetNotificationSubscribeAPI.data().data
    ) {
      setFetching(false);
      dispatch(
        updatePersistState(callingGetNotificationSubscribeAPI.data().data)
      );
    }
  }, [callingGetNotificationSubscribeAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetNotificationSettingsAPI.hasFetched() &&
      callingGetNotificationSettingsAPI.hasErrors() === false &&
      callingGetNotificationSettingsAPI.data() &&
      callingGetNotificationSettingsAPI.data().data
    ) {
      setFetching(false);
      setNotificationSettings(
        callingGetNotificationSettingsAPI.data().data.notification_setting
      );
    }
  }, [callingGetNotificationSettingsAPI.isFetching()]);

  return (
    <div className="notifaction-managesetting">
      <h2 className="f-main-title mb-30">Manage notifications</h2>
      <Container>
        <div className="notification-switch">
          <div className="notification-switch-content">
            All emails
            <CustomSwitch
              handleChange={() => SubscribeEmailFn()}
              checked={!subscribeEmail}
            />
          </div>
          <div className="notification-switch-content">
            All in app push
            <CustomSwitch
              handleChange={() => SubscribePushFn()}
              checked={!subscribePush}
            />
          </div>
        </div>
        <div className="container-body">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h2 className="title-primary mb-15">Categories</h2>
              <NotificationCategories
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Grid>
            <Grid item xs={9}>
              <NotificationSettings
                activeTab={activeTab}
                isFetching={isFetching}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
